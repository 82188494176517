import React, {useContext, useEffect, useState} from 'react';
import {ExpiredRideTable} from '../component/ExpiredRideTable';
import BandOptionRadio from "../../../common/component/BandOptionRadio";
import {SetLoadingContext} from '../../../context';
import {HistoryDto} from "../ride.interface";
import {Card} from 'antd';

interface Props {
  getExpiredRides: () => Promise<HistoryDto[]>,
}

const ExpiredMobilityPage = (props: Props) => {
  const [expiredRides, setExpiredRides] = useState<HistoryDto[]>([]);
  const [selectedBand, setSelectedBand] = useState('0');
  const setLoading = useContext(SetLoadingContext)!;

  useEffect(() => bootstrap(), []);

  const bootstrap = () => {
    setLoading(true);
    props.getExpiredRides()
      .then(setExpiredRides)
      .catch(err => {
        window.alert("정보를 불러오는데 실패하였습니다");
        console.log(err);
        setLoading(false);
      })
      .then(() => setLoading(false));
  }

  const filteredExpiredRides = expiredRides.filter(e => {
    if (selectedBand === '0') return true;
    else return e.band.bandId.toString() === selectedBand
  });

  return (
    <div>
      <Card title={`만료된이용 (${filteredExpiredRides.length})`}>
        <BandOptionRadio selectedBand={selectedBand} setSelectedBand={setSelectedBand}/>
      </Card>
      <ExpiredRideTable
        expiredRides={filteredExpiredRides}
      />
    </div>
  );
}

export default ExpiredMobilityPage;
