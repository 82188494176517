import React from 'react';
import ExpiredMobilityPage from "../page/ExpiredMobilityPage";
import {getExpiredMobilityRides} from "../ride.service";

function ExpiredMopedRide() {
  function getExpiredMopedRides() {
    return getExpiredMobilityRides('MOPED')
  }

  return (
    <ExpiredMobilityPage
      getExpiredRides={getExpiredMopedRides}
    />
  )
}

export default ExpiredMopedRide;
