import React from 'react';
import * as marker from '../marker_constants';
import { MarkerProps, MarkerImage, Size } from '../Map';
import { LocationDto } from '../../common.interface';

const path_start = marker.path_start;
const path_riding = marker.path_riding;
const path_end = marker.path_end;

const imageUrls: Record<string, string> = {
  'start': path_start,
  'riding': path_riding,
  'end': path_end,
};

const MARKER_IMAGE_SIZE: Size = {
  width: 24,
  height: 36,
};

const MARKER_IMAGE_SIZE2: Size = {
  width: 18,
  height: 27,
};

export function PathMarker(location: LocationDto, part: string): MarkerProps {
  const position: LocationDto = {
    latitude: location.latitude,
    longitude: location.longitude
  };

  const imageUrl = imageUrls[part];

  const image: MarkerImage = {
    src: imageUrl,
    size: part === 'riding' ? MARKER_IMAGE_SIZE2 : MARKER_IMAGE_SIZE,
    zIndex: 1
  };

  return {
    identifier: 'Path',
    image,
    position,
  };
}