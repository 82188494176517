import React from 'react';
import {Table} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {DEFAULT_TABLE_SIZE} from '../../constants'
import {MobilityDto} from "../../domain/mobility/mobility.interface";
import {BandDto} from "../../domain/band/band.interface";

type DataSource = BandDto | MobilityDto;

export interface Props<T> {
  datasource: T[];
  columnType: ColumnsType<T>;
  sorterToolTip?: string;
  bordered?: boolean;
}

const BaseTable: <T extends DataSource>(p: React.PropsWithChildren<Props<T>>) => React.ReactElement<Props<T>> = ({
                                                                                                                   datasource,
                                                                                                                   columnType,
                                                                                                                   sorterToolTip,
                                                                                                                   bordered = true,
                                                                                                                 }) => {
  return (
    <Table
      dataSource={datasource}
      columns={columnType}
      bordered={bordered}
      showSorterTooltip={sorterToolTip ? {title: sorterToolTip} : undefined}
      pagination={{
        pageSize: DEFAULT_TABLE_SIZE,
        showSizeChanger: false
      }}
    />
  );
};

export default BaseTable;
