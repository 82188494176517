import React, {useState, VFC, useEffect} from 'react';
import {Col, DatePicker, Form, Input, InputNumber, Row, Select, Spin, Radio} from 'antd';
import BaseModal from '../../../common/component/BaseModal';
import {MobilityDto, MobilityType} from "../../mobility/mobility.interface";
import {MopedLeaseRentalCreateDto} from "../rental.interface";
import {RiderTableList} from "../../rider/rider.interface";
import {getAllMopeds} from "../../../services/moped";
import {getAllScooters} from "../../../services/scoot";
import {getRidersPagination} from "../../rider/rider.service";
import {Pagination} from "../../../common/url/pagination";
import {postLeaseRental} from "../leaseRental.service";
import moment from "moment";

interface Props {
  tabVal: MobilityType
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
}

const {Option} = Select;

const AddLeaseContractModal: VFC<Props> = ({tabVal, isVisible, setIsVisible}) => {

  const [formInstance] = Form.useForm();
  const [formValues, setFormValues] = useState<MopedLeaseRentalCreateDto | undefined>(undefined);

  const [mopeds, setMopeds] = useState<MobilityDto[]>([]);
  const [scooters, setScooters] = useState<MobilityDto[]>([]);
  const [riders, setRiders] = useState<RiderTableList[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [contractNumber, setContractNumber] = useState('');
  const [searchStr, setSearchStr] = useState('')

  const envProdcutId = process.env.NODE_ENV === 'production'
    ? tabVal === 'MOPED' ? 1000000060 : 1000000199
    : tabVal === 'MOPED' ? 1000000060 : 1000000147

  useEffect(() => {
    if (tabVal === 'SCOOTER') {
      getAllScooters().then(setScooters);
    } else {
      getAllMopeds().then(setMopeds);
    }
  }, [tabVal]);

  useEffect(() => {
    if (searchStr.length >= 2) {
      setIsSearching(true);
      getRidersPagination(Pagination.rider(
        0,
        30,
        searchStr,
        '',
        '',
        ''
      ))
        .then(e => setRiders(e.items))
        .then(() => setIsSearching(false))
    }
  }, [searchStr]);

  const selectMopedOptions = mopeds.map((e, i) => {
    return <Option value={e.mopedId!} key={'moped-' + i}>{e.shortImei}</Option>
  });

  const selectScooterOptions = scooters.map((e, i) => {
    return <Option value={e.scooterId!} key={'scooter-' + i}>{e.shortImei}</Option>
  });

  const selectRiderOptions = riders.map((e, i) => {
    return <Option value={e.riderId} key={'rider-' + i}>{e.riderName + ' / ' + e.phoneNo}</Option>
  });

  const onSubmitForm = async (formValues: MopedLeaseRentalCreateDto | undefined) => {
    if (formValues) {
      formValues.productId = envProdcutId;
      formValues.endAt = moment(formValues.endAt).format('YYYY-MM-DDT23:59:59');
      postLeaseRental(formValues, tabVal).then(e => {
        alert('등록 되었습니다');
        window.location.reload();
      });
    }
  }

  return (
    <BaseModal
      headerTitle={`리스/렌탈 고객 추가`}
      isVisible={isVisible}
      handleCancel={() => setIsVisible(false)}
      width={800}
      handleOk={() => onSubmitForm(formValues)}
      // handleOk={() => {}}
      okText={'제출'}
    >
      <Form
        name="productForm"
        onValuesChange={(changedVal, allVal) => {
          setFormValues(allVal)
        }}
        form={formInstance}
        onFinish={onSubmitForm}
      >
        <Row gutter={[16, 4]}>
          <Col span={24}>
            <Form.Item name={'contractName'}>
              <Input
                addonBefore="리스계약"
                value={contractNumber}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'mobilityLendType'}>
              <Radio.Group style={{width: '100%'}}>
                <Radio.Button style={{width: '50%', textAlign: 'center'}} value={'LEASE'}>리스</Radio.Button>
                <Radio.Button style={{width: '50%', textAlign: 'center'}} value={'RENTAL'}>렌탈</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={tabVal === 'SCOOTER' ? 'scooterId' : 'mopedId'}
            >
              <Select
                showSearch={true}
                placeholder={'기기를 선택해주세요'}
                filterOption={(input, option) => {
                  if (option) {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } else return false;
                }}
              >
                {
                  tabVal === 'SCOOTER'
                  ? selectScooterOptions
                  : selectMopedOptions
                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name={'riderId'}>
              <Select
                showSearch={true}
                placeholder={'계약자를 선택해주세요'}
                onSearch={setSearchStr}
                style={{width: '100%'}}
                notFoundContent={isSearching ? <Spin size="small"/> : null}
                filterOption={(input, option) => {
                  if (option) {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } else return false;
                }}
              >
                {selectRiderOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name={'contractAt'}>
              <DatePicker
                placeholder={'계약일을 선택해주세요'}
                name={'leaseStart'}
                style={{width: '100%'}}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name={'startAt'}>
              <DatePicker
                placeholder={'인수일을 선택해주세요'}
                name={'startAt'}
                style={{width: '100%'}}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name={'endAt'}>
              <DatePicker
                placeholder={'종료일을 선택해주세요'}
                name={'endAt'}
                style={{width: '100%'}}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name={'monthlyInstallment'}>
              <InputNumber
                style={{width: '100%'}}
                placeholder={'리스/렌탈 기간을 입력해주세요 (월)'}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name={'lendAmount'}>
              <InputNumber
                style={{width: '100%'}}
                placeholder={'월 납부액을 입력해주세요'}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'lendTotalAmount'}>
              <InputNumber
                style={{width: '100%'}}
                placeholder={'총 납부액을 입력해주세요'}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name={'residualValueAmount'}>
              <InputNumber
                style={{width: '100%'}}
                placeholder={'잔존가치금을 입력해주세요'}
              />
            </Form.Item>
          </Col>

          {
            tabVal === 'SCOOTER'
              ? null
              : (
                <Col span={12}>
                  <Form.Item name={'depositAmount'}>
                    <InputNumber
                      style={{width: '100%'}}
                      placeholder={'키보증금 입력해주세요'}
                    />
                  </Form.Item>
                </Col>
              )
          }

          <Col span={24}>
            <Form.Item name={'memo'}>
              <Input placeholder={'메모할 내용을 입력해주세요'}/>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name={'createdBy'}>
              <Input placeholder={'계약 담당자'}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </BaseModal>
  );
};

export default AddLeaseContractModal;


