import React, {useState} from 'react';
import {Badge} from 'antd';
import Table, {ColumnsType} from 'antd/lib/table';
import {getBandTag, parseDateTime} from "../../../utils/organizeData";
import moment from "moment";
import NameALink from "../../../common/component/NameALink";
import {DEFAULT_TABLE_SIZE} from '../../../constants'
import {PurchaseDto} from "../purchase.interface";
import {PaginationRes} from "../../../common/common.interface";
import { Pagination } from '../../../common/url/pagination';
import { buildUrl } from '../../../common/url/utils';
import {useTranslation} from "react-i18next";

interface Props {
  pagination: Pagination,
  paginationRes: PaginationRes,
  purchases: PurchaseDto[]
}

export function PurchaseHistoryTable({purchases, pagination, paginationRes}: Props) {
  const {t} = useTranslation();
  // add member modal
  const [isModalVisible, setModalVisible] = useState(false);

  function closeModal() {
    setModalVisible(false);
    // onUpdate && onUpdate();
  }

  const [searchText, setSearchText] = useState<any>('');
  const [searchedColumn, setSearchColumn] = useState<any>(null);

  const handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    if (clearFilters) {
      clearFilters();
    }
    setSearchText('');
  };

  const riderColumn: ColumnsType<PurchaseDto> = [
    {
      key: 'id',
      title: t('common-purchase') + 'ID',
      align: 'center',
      render: (val, row) => row.purchaseId
    },
    {
      key: 'riderName',
      title: t('common-name'),
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName}/>
    },
    {
      key: 'bandName',
      title: t('common-band'),
      align: 'center',
      render: (val, row, i) => getBandTag(row.rider.band.bandName, i)
    },
    {
      key: 'productName',
      title: t('common-product-name'),
      align: 'center',
      render: (val, row) => row.product.title
    },
    {
      key: 'purchaseAmount',
      title: t('common-price'),
      dataIndex: 'purchaseAmount',
      align: 'center',
      render: (val) => val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'
    },
    {
      key: 'cancelYn',
      title: t('common-cancelYn'),
      dataIndex: 'canceled',
      align: 'center',
      render: (val) => !val ? <Badge color="green" text={t('common-purchase')}/> : <Badge color="red" text={t('purchaseCanceled')}/>
    },
    {
      key: 'purchaseDate',
      title: t('purchaseDate'),
      dataIndex: 'purchaseAt',
      align: 'center',
      render: (val) => val ? parseDateTime(moment(val).unix() * 1000) : ''
    },
  ];

  const handleTableChange = (pgn: any) => {
    const newPagination = Pagination.purchaseFrom(pagination, { page: pgn.current - 1 });
    window.location.href = buildUrl('/admin/purchase-history', newPagination.getParams());
  }

  return (
    <>
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={
          purchases.map((r, i) => ({key: String(i + 1), ...r,}))
        }
        onChange={handleTableChange}
        pagination={{
          current: paginationRes.page + 1,
          total: paginationRes.totalItem,
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
    </>
  );
}