import {MapWrapper, MarkerWrapper} from '../Types';
import {utils} from '../Utils';
import {EventType, MarkerProps} from '../Map';
import {PartialVisibilityChangeableMapPlugin} from "./PartialVisibilityChangeableMapPlugin";

export abstract class AbstractMarkerPlugin<T> extends PartialVisibilityChangeableMapPlugin<MarkerWrapper> {
  private readonly refreshSeconds: number;

  private refreshTimeout?: NodeJS.Timeout;

  private selectedData: T | null = null;
  private markers: MarkerWrapper[] = [];

  protected constructor(refreshSeconds: number = 0) {
    super();
    this.refreshSeconds = refreshSeconds;
  }

  onMapUpdated() {
    this.updateMarkers();
  }

  onInitialize() {
    if (this.refreshSeconds) {
      this.refreshTimeout = setInterval(() => {
        this.updateMarkers();
      }, this.refreshSeconds * 1000);
    }
  }

  onDestroy() {
    if (this.refreshTimeout) {
      clearInterval(this.refreshTimeout);
    }
  }

  render() {
    if (!this.selectedData) return null;
    return this.renderSelectedComponent(this.selectedData);
  }

  getEntities(): MarkerWrapper[] {
    return this.markers;
  }

  show(entity: MarkerWrapper) {
    this.getMap().then(map => utils.setMap(entity, map));
  }

  hide(entity: MarkerWrapper) {
    utils.setMap(entity, null);
  }

  // Only visible from descendant class.
  protected abstract renderSelectedComponent(selectedData: T): JSX.Element

  protected abstract getData(): Promise<T[]>

  protected abstract convertToMarkerProps(data: T[]): { entity: T, props: MarkerProps }[]

  protected setSelectedData(selectedData: T | null) {
    this.selectedData = selectedData;
    this.refresh();
  }

  private async updateMarkers() {
    const data = await this.getData();
    const props = this.convertToMarkerProps(data);

    const map = await this.getMap();
    const newMarkers = props.map(({entity, props}) => {
      const marker = utils.createMarker(map as MapWrapper, props, entity);
      utils.addListener(marker, EventType.CLICK, () => this.setSelectedData(entity));
      utils.addListener(marker, EventType.MOUSEOVER, () => marker.openInfoWindow(map));
      utils.addListener(marker, EventType.MOUSEOUT, () => marker.closeInfoWindow());
      return marker;
    });

    this.markers.forEach(marker => utils.setMap(marker, null));
    this.markers = newMarkers;

    this.update();
  }
}
