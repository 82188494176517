import {BaseMapPlugin} from "./BaseMapPlugin";

type Constructor<T> = { new (...args: any[]): T};

class MapPluginManager {
  readonly plugins: BaseMapPlugin[] = [];

  usePlugin(plugin: BaseMapPlugin): MapPluginManager {
    this.plugins.push(plugin);
    return this;
  }

  applyPlugin<T extends BaseMapPlugin>(className: Constructor<T>, apply: (plugin: T) => void) {
    const p = this.plugins.find(e => e instanceof className) as T | undefined;
    if (p == null) {
      throw new Error(`Plugin ${className} is not registered.`);
    }
    apply(p);
  }
}

export { MapPluginManager };
