import React, {FC, useState} from 'react';
import BaseModal from '../../../common/component/BaseModal';
import {Input, Select} from 'antd';
import {Row, Col} from 'antd';
import {Classes} from "reactcss";
import {addAppInfo} from "../appinfo.service";

const {Option} = Select;

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppInfoAddModal: FC<Props> = ({isVisible, setIsVisible}) => {

  const [appType, setAppType] = useState<string>('');
  const [appVersion, setAppVersion] = useState<string>('')

  const handleSubmitButton = () => {
    const newAppInfo = {
      appName: appType,
      appVersion: appVersion,
    }

    addAppInfo(newAppInfo).then(e => {
      alert("성공적으로 등록되었습니다");
      window.location.reload();
    }).catch(() => {
      alert("등록에 실패하였습니다");
      window.location.reload();
    })
  }

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={handleSubmitButton}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'앱 정보 등록'}
      width={'80%'}
    >
      <Row
        gutter={[16, 16]}
        justify={'center'}
        align={'middle'}
      >
        <Col span={24}>
          <Select
            allowClear
            style={{width: '100%'}}
            placeholder="어플리케이션 종류를 선택해주세요"
            onChange={(e: string) => setAppType(e)}
          >
            <Option value={"DaYRidER_iOS"}>IOS 어플</Option>
            <Option value={"DaYRidER_aNDRoid"}>ANDROID 어플</Option>
          </Select>
        </Col>
        <Col span={24}>
          <Input
            placeholder={"버전을 입력해주세요"}
            onChange={(e) => setAppVersion(e.target.value)}
          />
        </Col>
      </Row>
    </BaseModal>
  );
};

export default AppInfoAddModal;