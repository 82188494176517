import {MapWrapper, MarkerWrapper, PolygonWrapper} from "../../Types";
import {ListenerEventData, utils} from "../../Utils";
import {EventType} from "../../Map";
import {LocationDto} from "../../../common.interface";
import {cursorTracker} from "../../CursorTracker";

export abstract class AbstractDragBehavior<T extends MarkerWrapper | PolygonWrapper> {
  protected map: MapWrapper;
  private offset?: LocationDto;
  private delta?: LocationDto;

  private isRegistered = true;

  protected constructor(map: MapWrapper, target: T) {
    this.map = map;
    this.attachListener(target);
  }

  setMap(map: MapWrapper) {
    this.map = map;
  }

  unregister() {
    this.isRegistered = false;
  }

  forceDrag() {
    // Warning: Use with caution! Cursor should be placed inside the draggable target.
    // Or unexpected behavior might occur.
    this.startDragging();
  }

  private attachListener(target: T) {
    utils.addListener(target, EventType.MOUSEDOWN, e => this.startDragging(e));
    utils.addListener(target, EventType.MOUSEUP, () => this.stopDragging());
    utils.addListener(target, EventType.MOUSEMOVE, e => this.dragging(this.getLocation(e)));
    cursorTracker.addListener(this.map, p => this.dragging(utils.pointToLocation(this.map, p)));
  }

  private startDragging(e?: ListenerEventData) {
    if (!this.isRegistered) return;

    const offset = this.getLocation(e);
    if (offset == null) return;

    this.offset = offset;
    utils.setDraggable(this.map, false);

    this.delta = {latitude: 0, longitude: 0};
  }

  private stopDragging() {
    if (!this.isRegistered) return;

    this.offset = undefined;
    utils.setDraggable(this.map, true);

    if (this.delta == null) return;
    this.onDragDone(this.delta);
    this.delta = undefined;
  }

  private dragging(destination: LocationDto | null) {
    if (!this.isRegistered) return;
    if (destination == null || this.offset == null || this.delta == null) return;

    const delta = {
      latitude: destination.latitude - this.offset.latitude,
      longitude: destination.longitude - this.offset.longitude,
    };
    this.onDrag(delta);
    this.offset = destination;

    this.delta.latitude += delta.latitude;
    this.delta.longitude += delta.longitude;
  }

  private getLocation(e?: ListenerEventData): LocationDto | null {
    if (e?.location && !Array.isArray(e.location)) return e.location;
    return this.getCursorLocation();
  }

  private getCursorLocation(): LocationDto | null {
    const cursor = cursorTracker.getCursorPosition(this.map);
    if (cursor == null) return null;
    return utils.pointToLocation(this.map, cursor);
  }

  protected abstract onDrag(delta: LocationDto): void;

  protected abstract onDragDone(delta: LocationDto): void;
}
