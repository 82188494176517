import {LocationDto} from "../common.interface";
import {Undoable} from "../work_history/work_history";
import {ArrayList} from "../../utils/array_list";

export class EditWork implements Undoable {
  private readonly original: LocationDto;
  private readonly target: LocationDto;
  private readonly coordinates: ArrayList<LocationDto>;

  constructor(original: LocationDto, target: LocationDto, coordinates: ArrayList<LocationDto>) {
    this.original = original;
    this.target = target;
    this.coordinates = coordinates;

    const index = coordinates.indexOf(original);
    if (index === -1) throw new Error('No such coordinate');

    coordinates.set(index, target);
  }

  undo() {
    const index = this.coordinates.indexOf(this.target);
    if (index === -1) throw new Error('No such coordinate');

    this.coordinates.set(index, this.original);
  }
}
