import React from 'react';
import {Typography} from "antd";

interface Props {
  textVal: string | number;
}

const {Paragraph} = Typography;

export default function Copyable({textVal}: Props) {
  return <Paragraph style={{margin: 0}} copyable={true}>{textVal}</Paragraph>;
}