import { Button, Typography, Menu, Dropdown } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../../context';
import { AuthService } from '../../../services/auth';
import './AccountInfo.less';
import { getBand } from '../../band/band.service';
import { BandSimpleDto } from '../../band/band.interface';
import { DownOutlined, FontColorsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export function AccountInfo() {

  const account = useContext(AccountContext)!;
  const bandId = account.band.bandId;
  const [band, setBand] = useState<BandSimpleDto | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    if (bandId) {
      await getBand(bandId).then(setBand);
    }
  };

  const handleLanguageChange = (e: any) => {
    i18n.changeLanguage(e.key);
  };

  const languageMenu = (
    <Menu onClick={handleLanguageChange}>
      <Menu.Item key={'ko'}>🇰🇷 {t('header-language-kor')}</Menu.Item>
      <Menu.Item key={'en'}>🇺🇸 {t('header-language-eng')}</Menu.Item>
      <Menu.Item key={'jp'}>🇯🇵 {t('header-language-jpn')}</Menu.Item>
    </Menu>
  );

  if (account) {
    return (
      <div className='account-info'>
        {/*<Typography.Title level={4} style={{ color: '#ffffff' }}>*/}
        {/*  {band?.bandName}*/}
        {/*</Typography.Title>*/}
        <Typography style={{ color: '#ffffff', margin: '0 10px' }}>{account.email} ({band?.bandName})</Typography>
        <div style={{ margin: '0 10px' }}>
          <Dropdown
            overlay={languageMenu}
            onVisibleChange={() => setIsVisible(!isVisible)}
            visible={isVisible}
          >
            <a className='ant-dropdown-link' onClick={e => e.preventDefault()} style={{ color: '#ffffff' }}>
              {t('header-language')}
            </a>
          </Dropdown>
        </div>
        <Button type='text' style={{ color: '#ffffff' }} onClick={AuthService.logout}>
          {t('header-language-logout')}
        </Button>
      </div>
    );
  } else {
    return <></>;
  }
}
