import React, {useState, VFC} from 'react';
import { useTranslation } from 'react-i18next';
import {Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import BaseModal from '../../../common/component/BaseModal';
import {deleteAccount, getAccounts} from '../account.service';
import ResetPasswordModal from '../modal/ResetPasswordModal';
import ModifyAccountModal from '../modal/EditAccountModal';
import {getBandTag, parsePhoneNumber} from "../../../utils/organizeData";
import {AccountDto} from "../account.interface";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";
import {DEFAULT_TABLE_SIZE} from "../../../constants";

interface Props {
  accounts: AccountDto[];
  setAccounts: React.Dispatch<React.SetStateAction<AccountDto[]>>;
}

const AdminAccountTable: VFC<Props> = ({accounts, setAccounts}) => {
  const { t } = useTranslation();
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isVisibleResetModal, setIsVisibleResetModal] = useState(false);
  const [selectAccount, setSelectAccount] = useState<AccountDto | null>(null);
  const [isVisibleModifyModal, setIsVisibleModifyModal] = useState(false);

  const showDeleteModal = (account: AccountDto) => () => {
    setSelectAccount(account);
    setIsVisibleDeleteModal(true);
  };

  const showResetModal = (account: AccountDto) => () => {
    // 패스워드 수정모달
    setSelectAccount(account);
    setIsVisibleResetModal(true);
  };

  const handleDeleteOk = async () => {
    if (selectAccount) {
      try {
        await deleteAccount(selectAccount.accountId);
        await getAccounts().then(setAccounts);
        alert(t('deleteSuccessful'));
        setSelectAccount(null);
        setIsVisibleDeleteModal(false);
      } catch (err) {
        alert(t('deleteFail'));
      }
    }
  };

  const modifyModalOpenCloseModal = () => {
    // 계정수정 open / close 모달
    setIsVisibleModifyModal((prevState) => !prevState);
  };

  const onClickModifyBtn = (account: AccountDto) => () => {
    setSelectAccount(account);
    modifyModalOpenCloseModal();
  };

  const handleResetCancel = () => {
    setIsVisibleResetModal(false);
  };

  const handleDeleteCancel = () => {
    setIsVisibleDeleteModal(false);
  };

  const columns: ColumnsType<AccountDto> = [
    columnMaker('name', t('common-name'), columnRenderer(data => data.accountName)),
    columnMaker('email', t('common-email'), columnRenderer(data => data.email)),
    columnMaker('phoneNumber', t('common-phoneNumber'), columnRenderer(data => parsePhoneNumber(data.phoneNumber))),
    columnMaker('band', t('common-band'), columnRenderer((data, idx) => getBandTag(data.band.bandName, idx))),
    columnMaker('role', t('common-auth'), columnRenderer((data) => <span>{data.role === 'ADMIN' ? t('common-operator') : t('common-basic')}</span>)),
    columnMaker('edit', t('common-edit'), columnRenderer(data => <a onClick={onClickModifyBtn(data)}>수정</a>)),
    columnMaker('updatePassword', t('changePw'), columnRenderer(data => <a onClick={showResetModal(data)}>{t('changePw')}</a>)),
    columnMaker('delete', t('common-delete'), columnRenderer(data => <a onClick={showDeleteModal(data)}>삭제</a>)),
  ];
  return (
    <>
      <Table
        columns={columns}
        bordered
        dataSource={accounts.map((a) => ({key: a.accountId, ...a}))}
        pagination={{
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
      <BaseModal
        isVisible={isVisibleDeleteModal}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
        headerTitle={t('deleteAccount')}
      >
        <p>
          {t('deleteAccount?')}
        </p>
      </BaseModal>
      <ResetPasswordModal
        isVisible={isVisibleResetModal}
        setSelectAccount={setSelectAccount}
        handleCancel={handleResetCancel}
        selectAccount={selectAccount}
      />
      <ModifyAccountModal
        isVisible={isVisibleModifyModal}
        modalHandler={modifyModalOpenCloseModal}
        account={selectAccount}
      />
    </>
  );
};

export default AdminAccountTable;
