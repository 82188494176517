import React, { FC, useState } from 'react';
import BaseModal from '../../../common/component/BaseModal';
import { putBannerOrder } from '../banner.service';
import { InputNumber } from 'antd';
import { Classes } from 'reactcss';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrderEditBannerId: number | null;
}

const BannerOrderEditModal: FC<Props> = ({ isVisible, setIsVisible, selectedOrderEditBannerId }) => {

  const [order, setOrder] = useState(1000);

  const handleSubmit = () => {
    if (selectedOrderEditBannerId && order) {
      putBannerOrder(selectedOrderEditBannerId, { displayOrder: order })
        .then(e => {
          alert('순서가 변경되었습니다');
          window.location.reload();
        })
        .catch(l => {
          alert('나중에 다시시도해주세요');
          window.location.reload();
        });
    }
  };

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={() => handleSubmit()}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'배너 순서 수정'}
      width={'80%'}
    >
      <InputNumber
        style={{ width: '100%' }}
        placeholder={'순서를 입력해주세요'}
        value={order}
        onChange={e => setOrder(e)}
      />
    </BaseModal>
  )
    ;
};

export default BannerOrderEditModal;