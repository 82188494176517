import React from 'react';
import {editAppInfo} from "../appinfo.service";
import {useTranslation} from 'react-i18next';

/* == antd == */
import {Badge, Switch} from 'antd'
import Table, {ColumnsType} from 'antd/lib/table';
import {AndroidFilled, AppleFilled} from '@ant-design/icons'
import {DEFAULT_TABLE_SIZE} from "../../../constants";
import {AppInfoDto} from "../appinfo.interface";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";

interface Props {
  appinfos: AppInfoDto[]
}

export function AppInfoTable({appinfos}: Props) {
  const {t} = useTranslation();

  const handleSwitchChange = (e: any, row: AppInfoDto) => {
    if (window.confirm(`${row.appName}의 ${row.appVersion} 버전을 ${e ? "서비스 중" : "서비스 종료"}(으)로 변경하시겠습니까?`)) {
      editAppInfo({
        appName: row.appName,
        appVersion: row.appVersion,
        isService: e
      }).then(e => {
        alert("업데이트에 성공했습니다");
        window.location.reload()
      }).catch((err) => {
        alert("업데이트에 실패하였습니다")
        window.location.reload()
        console.log(err)
      })
    } else {
      window.location.reload();
    }
  }

  const bannerColumn: ColumnsType<AppInfoDto> = [
    columnMaker('appKey', t('appKey'), columnRenderer(data => data.appKey)),
    columnMaker('secretKey', t('common-password'), columnRenderer(data => data.secretKey)),
    columnMaker('appName', t('appName'), columnRenderer(data => data.appName)),
    columnMaker('appVersion', t('appVersion'), columnRenderer(data => data.appVersion)),
    columnMaker('serviceYn', t('appService'),
      columnRenderer(data => data.service
        ? <Badge status="success" text="서비스 중"/>
        : <Badge status="error" text="서비스 종료"/>)
    ),
    columnMaker('OS', 'OS', columnRenderer(data => data.appName === "DaYRidER_aNDRoid"
      ? <AndroidFilled style={{fontSize: '20px'}}/>
      : <AppleFilled style={{fontSize: '20px'}}/>)
    ),
    columnMaker('action', '', columnRenderer(data => (
      <Switch
        defaultChecked={data.service}
        onChange={(e) => handleSwitchChange(e, data)}
      />))
    ),
  ];

  return (
    <>
      <Table
        columns={bannerColumn}
        bordered={true}
        dataSource={
          appinfos.map((r, i) => ({key: String(i + 1), ...r,}))
        }
        pagination={{
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
    </>
  );
}
