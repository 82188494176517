import React, {CSSProperties, useEffect, useState} from 'react';
import PlacementMap from '../component/PlacementMap';
import MapFilterControlPanel from "../component/MapController/MapFilterControlPanel";
import MapSearchControlPanel from "../component/MapController/MapSearchControlPanel";
import {Col, Row, Popover, Button} from 'antd';
import {MapType} from '../../../common/map/Types';
import {AddEditPlacementModal} from "../component/AddEditPlacementModal";
import MapDashboardPanel from "../component/MapController/MapDashboardPanel";
import {getAllMopeds} from "../../../services/moped";
import {getAllScooters} from "../../../services/scoot";
import {MobilityDto} from "../../mobility/mobility.interface";
import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";

export type MobilityRadioValue = 'ALL' | 'BS' | 'NIU' | 'S9' | 'NO' | 'PCX';
export type PlacementRadioValue = 'ALL' | 'A' | 'S' | 'M' | 'SP' | 'NO';
export type PlacementDisplayValue = 'ALL' | boolean;

export function PlacementMapPage() {

  const [mapType, setMapType] = useState<MapType>('KAKAO');
  const [mobilityRadio, setMobilityRadio] = useState<MobilityRadioValue>('ALL');
  const [placementRadio, setPlacementRadio] = useState<PlacementRadioValue>('ALL');
  const [placementRadio2, setPlacementRadio2] = useState<PlacementDisplayValue>('ALL');

  const [isPlacementAddModalVisible, setIsPlacementAddModalVisible] = useState<boolean>(false);
  const [isMapControllerVisible, setIsMapControllerVisible] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>('');

  const [scooterBatteryFilter, setScooterBatteryFilter] = useState<number>(100);
  const [mopedBatteryFilter, setMopedBatteryFilter] = useState<number>(100);

  const [scooters, setScooters] = useState<MobilityDto[]>([]);
  const [mopeds, setMopeds] = useState<MobilityDto[]>([]);
  const [filteredScooters, setFilteredScooters] = useState<MobilityDto[]>([]);
  const [filteredMopeds, setFilteredMopeds] = useState<MobilityDto[]>([]);

  useEffect(() => {
    getAllScooters().then(scooters => {
      setScooters(scooters.filter(e => e.mobilityStatus !== 'CARGO'));
      setFilteredScooters(scooters.filter(e => e.mobilityStatus !== 'CARGO'));
    });
    getAllMopeds().then(mopeds => {
      setMopeds(mopeds);
      setFilteredMopeds(mopeds);
    })
  }, []);

  useEffect(() => {
    setFilteredMopeds(mopeds.filter(e => e.battery <= mopedBatteryFilter));
    setFilteredScooters(scooters.filter(e => e.battery <= scooterBatteryFilter));
  }, [scooterBatteryFilter, mopedBatteryFilter]);

  const buttonStyle: CSSProperties = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    zIndex: 2
  }

  return (
    <div style={{position: 'relative'}}>
      <AddEditPlacementModal
        isVisible={isPlacementAddModalVisible}
        setIsPlacementAddModalVisible={setIsPlacementAddModalVisible}
        mapType={mapType}
      />
      <Popover
        style={{zIndex: 5}}
        placement="leftBottom"
        content={(
          <>
            <MapSearchControlPanel
              mapType={mapType}
              setMapType={setMapType}
              setIsPlacementAddModalVisible={setIsPlacementAddModalVisible}
              setSearchStr={setSearchStr}
            />
            <MapFilterControlPanel
              mobilityRadio={mobilityRadio}
              setMobilityRadio={setMobilityRadio}
              placementRadio={placementRadio}
              setPlacementRadio={setPlacementRadio}
              setScooterBatteryFilter={setScooterBatteryFilter}
              setMopedBatteryFilter={setMopedBatteryFilter}
              scooterBatteryFilter={scooterBatteryFilter}
              mopedBatteryFilter={mopedBatteryFilter}
              placementRadio2={placementRadio2}
              setPlacementRadio2={setPlacementRadio2}
            />
          </>
        )}
        trigger="click"
        visible={isMapControllerVisible}
        onVisibleChange={() => setIsMapControllerVisible(!isMapControllerVisible)}
      >
        {
          isMapControllerVisible
            ?
            <Button type={'primary'} danger={true} style={buttonStyle} size={'large'} icon={<FullscreenExitOutlined/>}/>
            : <Button type={'primary'} danger={true} style={buttonStyle} size={'large'} icon={<FullscreenOutlined/>}/>
        }
      </Popover>
      <MapDashboardPanel
        scooterCount={filteredScooters.length}
        mopedCount={filteredMopeds.length}
      />
      <Row gutter={[4, 4]} align={'middle'}>
        <Col span={24}>
          <PlacementMap
            mapType={mapType}
            setIsPlacementAddModalVisible={setIsPlacementAddModalVisible}
            query={searchStr}
            mobilityRadio={mobilityRadio}
            placementEntityVisibilityPredicate={p => placementRadio === 'ALL' || placementRadio === p.deployableMobilityType}
            placementEntityVisibilityPredicate2={p => placementRadio2 === 'ALL' || placementRadio2 === p.displayYn}
            scooterBatteryFilter={scooterBatteryFilter}
            mopedBatteryFilter={mopedBatteryFilter}
          />
        </Col>
      </Row>
    </div>

  );
}