import i18n from 'i18next';
import {languageKo} from './language/languageKo'
import {languageJp} from './language/languageJp'
import {languageEn} from './language/languageEn'
import { initReactI18next } from 'react-i18next';

const resources = {
  ko: { translation: languageKo },
  en: { translation: languageEn },
  jp: { translation: languageJp }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ko",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;