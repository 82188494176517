import {BaseMapPlugin} from "../BaseMapPlugin";
import {utils} from "../../Utils";
import {EventType} from "../../Map";
import {ArrayList} from "../../../../utils/array_list";
import {EditablePolygon} from "./EditablePolygon";
import {LocationDto} from "../../../common.interface";

export class PolygonDrawingPlugin extends BaseMapPlugin {
  private editablePolygon = this.createNewEditablePolygon();
  private editablePolygons = new ArrayList<EditablePolygon>();

  async onMapUpdated() {
    const map = await this.getMap();
    utils.addListener(map, EventType.CLICK, async (event) => {
      if (event?.location == null) return;

      const location = event.location;
      if (Array.isArray(location)) return;

      this.editablePolygon.addMarker(map, location);
    });
  }

  private onPolygonEnd() {
    this.editablePolygons.add(this.editablePolygon);
    this.editablePolygon = this.createNewEditablePolygon();
  }

  private createNewEditablePolygon() {
    return new EditablePolygon(() => this.onPolygonEnd(),
      (e) => this.editablePolygons.remove(e));
  }
}
