import {LocalAdminApi} from '../../services';
import {AppInfoCreateBody, AppInfoDto, AppInfoUpdateBody} from "./appinfo.interface";

export async function getAppInfos() {
    return await LocalAdminApi.get<AppInfoDto[]>(`/appInfos`).then((resp) => resp.data);
}

export async function addAppInfo(content: AppInfoCreateBody) {
    return await LocalAdminApi.post(`/appInfos`, content).then((resp) => resp.data);
}

export async function editAppInfo(content: AppInfoUpdateBody) {
    return await LocalAdminApi.put(`/appInfos`, content).then((resp) => resp.data);
}