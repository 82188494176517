import {LocationDto} from "../common.interface";
import {Undoable} from "../work_history/work_history";
import {ArrayList} from "../../utils/array_list";

export class AppendWork implements Undoable {
  private readonly coordinate: LocationDto;
  private readonly coordinates: ArrayList<LocationDto>;

  constructor(coordinate: LocationDto, coordinates: ArrayList<LocationDto>) {
    this.coordinate = coordinate;
    this.coordinates = coordinates;

    coordinates.add(coordinate);
  }

  undo() {
    this.coordinates.remove(this.coordinate);
  }
}
