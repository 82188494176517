import React, { useEffect } from 'react';
import { Button, Form, InputNumber, message, Modal } from 'antd';
import { EditPassModal } from './RiderDetailTab';
import { updateTransferCount } from '../../pass/pass.service';

interface Props {
  riderId: string;
  setIsEditPassModal: React.Dispatch<React.SetStateAction<EditPassModal>>;
  isEditPassModal: EditPassModal;
}

function EditRiderPassModal({ riderId, isEditPassModal, setIsEditPassModal }: Props) {
  const { row: pass, visible } = isEditPassModal;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(pass);
  }, [form, pass]);

  async function handleSubmitTransferCount() {
    if (pass) {
      const { transferCount } = form.getFieldsValue();
      try {
        await updateTransferCount(riderId, { passId: pass.passId, transferCount });
        message.success('사용가능 횟수 수정 성공');
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } catch (err) {
        console.log(err);
        message.error('사용가능 횟수 수정에 실패하였습니다');
      }
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => setIsEditPassModal({ row: null, visible: false })}
      title={`${pass?.passId} 이용권 수정`}
      footer={false}
      forceRender
    >
      <Form
        form={form}
        onFinish={handleSubmitTransferCount}
      >
        <Form.Item name={'transferCount'} label={'사용가능 횟수'}>
          <InputNumber min={0} max={99999} required />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
          <Button type={'primary'} htmlType={'submit'}>
            수정
          </Button>
        </Form.Item>
      </Form>
    </Modal>);
}

export default EditRiderPassModal;