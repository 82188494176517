import BaseModal from '../../../common/component/BaseModal';
import React, {useEffect, useState, VFC} from 'react';
import {PurchaseHistoryTable2} from '../../../domain/purchase/component/PurchaseHistoryTable2';

// import { paymentHistoriesDummy } from '../../../pages/payment/history';

interface Props {
    isVisible: boolean;
    paymentHistoryHandleOk: () => void;
    paymentHistoryHandleClose: () => void;
}

export const PurchaseHistoryModal: VFC<Props> = ({isVisible, paymentHistoryHandleOk, paymentHistoryHandleClose}) => {
    const [ridesHistory, setRidesHistory] = useState<PaymentHistory[]>([]);

    useEffect(() => {
        // setRidesHistory(paymentHistoriesDummy);
    }, []);
    return (
        <>
            <BaseModal
                isVisible={isVisible}
                headerTitle="결제이력"
                handleOk={paymentHistoryHandleOk}
                handleCancel={paymentHistoryHandleOk}
                width={1000}
            >
                <div>
                    <div>
                        <h3>결제 이력</h3>
                        <PurchaseHistoryTable2 paymentHistory={ridesHistory}/>
                    </div>
                </div>
            </BaseModal>
        </>
    );
};
