import React from 'react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { DEFAULT_TABLE_SIZE } from '../../../../constants';
import { getAmountNumberFormat, parsePhoneNumber } from '../../../../utils/organizeData';
import { columnMaker, columnRenderer } from '../../../../utils/componentUtils';
import NameALink from '../../../../common/component/NameALink';

import { RiderLendOrderDto } from '../../rental.interface';

interface Props {
  filteredOrders: RiderLendOrderDto[],
  setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOrder: React.Dispatch<React.SetStateAction<RiderLendOrderDto | null>>;
}


function LeaseRentalOrderList({ filteredOrders, setIsEditModalVisible, setSelectedOrder }: Props) {

  const columns: ColumnsType<RiderLendOrderDto> = [
    columnMaker('lendOrderId', '주문ID', columnRenderer((row, idx) => (
      <Button type={'link'} onClick={() => {
        setSelectedOrder(row);
        setIsEditModalVisible(true);
      }}>{row.lendOrderId}</Button>
    ))),
    {
      title: '주문자 정보',
      align: 'center',
      children: [
        columnMaker('riderId', '라이더ID', columnRenderer((row, idx) => row.riderId)),
        columnMaker('riderName', '라이더 이름', columnRenderer((row, idx) => (
          <NameALink urlVal={row.riderId} textVal={row.riderName} />))),
        columnMaker('phone', '핸드폰번호', columnRenderer((row, idx) => parsePhoneNumber(row.phone), true)),
        columnMaker('isConsultedYn', '상담여부', columnRenderer((row, idx) => row.isConsultedYn ? '상담완료' : '미상담'))
      ]
    },
    {
      title: '주문정보',
      align: 'center',
      children: [
        columnMaker('modelName', '모델이름', columnRenderer((row, idx) => row.modelName, true)),
        columnMaker('pcxGrade', 'PCX 등급', columnRenderer((row, idx) => row.modelName.includes('PCX') ? row.pcxGrade : '-')),
        columnMaker('lendProductName', '상품명', columnRenderer((row, idx) => row.lendProductName, true)),
        columnMaker('lendProductPrice', '상품가격', columnRenderer((row, idx) => getAmountNumberFormat(row.lendProductPrice))),
        columnMaker('lendExtraProductName', '기타상품명', columnRenderer((row, idx) => row.lendExtraProductName, true)),
        columnMaker('lendExtraProductPrice', '기타상품 가격', columnRenderer((row, idx) => getAmountNumberFormat(row.lendExtraProductPrice))),
        columnMaker('periodType', '기간제', columnRenderer((row, idx) => row.periodType)),
        columnMaker('deliveryType', '수령방법', columnRenderer((row, idx) => row.deliveryType)),
        columnMaker('isOrderCompletedYn', '수령여부', columnRenderer((row, idx) => row.isOrderCompletedYn ? '수령완료' : '미수령'))
      ]
    }
  ];

  return (
    <Table
      bordered
      size={'middle'}
      columns={columns}
      dataSource={filteredOrders.map((e, i) => ({ key: i, ...e }))}
      pagination={{
        defaultPageSize: DEFAULT_TABLE_SIZE
      }}
    />
  );
}

export default LeaseRentalOrderList;