import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, SetLoadingContext } from '../../../../context';
import AddBandProductModal from '../component/AddBandProductModal';
import BandProductTableTab from '../component/tab/BandProductTableTab';
import BandProductPreviewTab from '../component/tab/BandProductPreviewTab';

import { Button, Tabs, Card, Input, Radio, Space } from 'antd';
import { MobilityCode, ProductDto } from '../../product.interface';
import { getAllMasterProducts } from '../../product.service';
import BandOptionRadio from '../../../../common/component/BandOptionRadio';

type productViewMode = 'TABLE' | 'PREVIEW';
type SelectMobilityCode = MobilityCode | 'ALL'

const { TabPane } = Tabs;

const BandProductPage = () => {

  const bandId = useContext(AccountContext)!.band.bandId;
  const setLoading = useContext(SetLoadingContext)!;

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    getAllMasterProducts().then(setMasterProducts);
  };

  const [viewMode, setViewMode] = useState<productViewMode>('TABLE');

  const [masterProducts, setMasterProducts] = useState<ProductDto[]>([]);
  const [isAddMasterProductModalVisible, setIsAddMasterProductModalVisible] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>('');
  const [selectedBand, setSelectedBand] = useState<string>('0');
  const [selectedMobility, setSelectedMobility] = useState<SelectMobilityCode>('ALL');

  return (
    <>
      <div>
        <h2>밴드 상품 조회</h2>
        <Tabs defaultActiveKey='TABLE'>
          <TabPane tab='전체보기' key='TABLE'>
            <Card
              title={'상품검색'}
              extra={<Button onClick={() => setIsAddMasterProductModalVisible(true)}>밴드 상품 추가</Button>}
            >
              <Space style={{width:'100%'}} direction={'vertical'}>
                <Input
                  placeholder={'검색할 상품명을 입력해주세요'}
                  value={searchStr}
                  onChange={e => setSearchStr(e.target.value)}
                />
                <BandOptionRadio selectedBand={selectedBand} setSelectedBand={setSelectedBand} />
                <Radio.Group
                  style={{ width: '100%' }}
                  value={selectedMobility}
                  onChange={(e) => setSelectedMobility(e.target.value)}
                >
                  <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value={'ALL'}>전체</Radio.Button>
                  <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value={'ST'}>킥보드</Radio.Button>
                  <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value={'MC'}>오토바이</Radio.Button>
                </Radio.Group>
              </Space>
            </Card>
            <BandProductTableTab
              searchStr={searchStr}
              selectedBand={selectedBand}
              selectedMobility={selectedMobility}
            />
          </TabPane>
          <TabPane tab='밴드별 프리뷰' key='PREVIEW'>
            <BandProductPreviewTab />
          </TabPane>
        </Tabs>
      </div>

      <AddBandProductModal
        isVisible={isAddMasterProductModalVisible}
        masterProducts={masterProducts}
        setIsVisible={setIsAddMasterProductModalVisible}
      />
    </>
  );
};

export default BandProductPage;
