import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '../../../constants';
import { ScooterRidePage } from '../../../domain/ride/page/ScooterRidePage';
import { ScooterPage } from '../../../domain/mobility/scooter/page/ScooterPage';
import { ScooterDetailPage } from '../../../domain/mobility/scooter/page/ScooterDetailPage';
import { RidersPage } from '../../../domain/rider/page/RiderListPage';
import { PurchasePage } from '../../../domain/purchase/page/PurchasePage';
import {LeaseRentalPage} from '../../../domain/leaseRental/pages/LeaseRentalPage';
import AdminAccountPage from '../../../domain/account/page/AdminAccountPage';
import MasterProductPage from '../../../domain/product/master/page/MasterProductPage';
import BandProductPage from '../../../domain/product/band/page/BandProductPage';
import BandPage from '../../../domain/band/page/BandListPage';
import DashboardPage from '../../../domain/dashboard/page/PurchaseDashboard';
import RiderDashboardPage from '../../../domain/dashboard/page/RiderDashboard';
import { RiderDetailPage } from '../../../domain/rider/page/RiderDetailPage';
import BandDetailPage from '../../../domain/band/page/BandDetailPage';
import { BannerPage } from '../../../domain/banner/page/BannerPage';
import { AppInfoPage } from '../../../domain/appinfo/page/AppInfoPage';
import MopedPage from '../../../domain/mobility/moped/page/MopedPage';
import { SubscriptionPage } from '../../../domain/subscription/page/SubscriptionPage';
import { MopedRidePage } from '../../../domain/ride/page/MopedRidePage';
import { PlacementMapPage } from '../../../domain/placement/page/PlacementMapPage';
import { PlacementListPage } from '../../../domain/placement/page/PlacementListPage';
import PlacementDashboardPage from '../../../domain/dashboard/page/PlacementDashboard';
import TariffBandPage from "../../../domain/tariff/pages/TariffBandPage";
import TariffListPage from "../../../domain/tariff/pages/TariffListPage";
import NonPaymentPage from "../../../domain/ondemand/pages/NonPaymentPage";
import GeofencePage from "../../../domain/geofence/page/GeofencePage";
import ExpiredScooterRide from "../../../domain/ride/impl/ExpiredScooterRide";
import ExpiredMopedRide from "../../../domain/ride/impl/ExpiredMopedRide";
import LeaseRentalOrderList from '../../../domain/leaseRental/pages/LeaseRentalOrderList';
import LeaseRentalConsultList from '../../../domain/leaseRental/pages/LeaseRentalConsultList';

export function PrivateRouter() {
  const {
    RIDERS,
    RIDERS_DETAIL,
    BAND_PRODUCT,
    PURCHASE_HISTORY,
    ACCOUNTS,
    MASTER_PRODUCT,
    BAND,
    DASHBOARD,
    DASHBOARD_RIDER,
    SCOOTER_LIST,
    SCOOTER_DETAIL,
    SCOOTER_HISTORY,
    BAND_LIST,
    SUBSCRIPTION_HISTORY,
    BANNER,
    APP_INFO,
    SCOOTER_EXPIRED_USAGE,
    MOPED_LIST,
    MOPED_HISTORY,
    PLACEMENT_MAP,
    PLACEMENT_LIST,
    LEASE_RENTAL_LIST,
    LEASE_RENTAL_CONSULT_LIST,
    LEASE_RENTAL_ORDER_LIST,
    DASHBOARD_PLACEMENT,
    TARIFF_LIST,
    TARIFF_BAND,
    NONPAYMENT,
    MAP,
    MOPED_EXPIRED_USAGE,
  } = Routes;

  return (
    <Switch>
      <Route exact path={ACCOUNTS} component={AdminAccountPage} />
      <Route exact path={BAND} component={BandPage} />
      <Route exact path={BAND_LIST} component={BandDetailPage} />
      <Route exact path={MASTER_PRODUCT} component={MasterProductPage} />

      <Route exact path={DASHBOARD_RIDER} component={RiderDashboardPage} />
      <Route exact path={DASHBOARD_PLACEMENT} component={PlacementDashboardPage} />

      <Route exact path={RIDERS} component={RidersPage} />
      <Route exact path={RIDERS_DETAIL} component={RiderDetailPage} />
      <Route exact path={SUBSCRIPTION_HISTORY} component={SubscriptionPage} />
      <Route exact path={PURCHASE_HISTORY} component={PurchasePage} />
      <Route exact path={NONPAYMENT} component={NonPaymentPage} />

      <Route exact path={SCOOTER_LIST} component={ScooterPage} />
      <Route exact path={SCOOTER_DETAIL} component={ScooterDetailPage} />
      <Route exact path={SCOOTER_HISTORY} component={ScooterRidePage} />
      <Route exact path={SCOOTER_EXPIRED_USAGE} component={ExpiredScooterRide} />

      <Route exact path={MOPED_LIST} component={MopedPage} />
      <Route exact path={MOPED_HISTORY} component={MopedRidePage} />
      <Route exact path={MOPED_EXPIRED_USAGE} component={ExpiredMopedRide} />

      <Route exact path={PLACEMENT_LIST} component={PlacementListPage} />
      <Route exact path={PLACEMENT_MAP} component={PlacementMapPage} />

      <Route exact path={BAND_PRODUCT} component={BandProductPage} />
      <Route exact path={DASHBOARD} component={DashboardPage} />
      <Route exact path={BANNER} component={BannerPage} />
      <Route exact path={APP_INFO} component={AppInfoPage} />

      <Route exact path={LEASE_RENTAL_LIST} component={LeaseRentalPage} />
      <Route exact path={LEASE_RENTAL_ORDER_LIST} component={LeaseRentalOrderList} />
      <Route exact path={LEASE_RENTAL_CONSULT_LIST} component={LeaseRentalConsultList} />

      <Route exact path={TARIFF_LIST} component={TariffListPage} />
      <Route exact path={TARIFF_BAND} component={TariffBandPage} />
      <Route exact path={MAP} component={GeofencePage} />
    </Switch>
  );
}
