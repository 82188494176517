import React, {FC, useState} from 'react';
import BaseModal from '../../../common/component/BaseModal';
import {Row, Input} from 'antd';
import {Classes} from 'reactcss';
import {putRiderPhoneNumber} from '../rider.service';

interface Props {
  riderId?: number;
  riderPhoneNo?: string;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditRiderModal: FC<Props> = ({riderId, riderPhoneNo, isVisible, setIsVisible}) => {

  const [phoneNo, setPhoneNo] = useState(riderPhoneNo);

  const handleSubmitButton = async () => {
    if (window.confirm('수정하시겠습니까?') && riderId && phoneNo) {
      try {
        const parsingPhoneNo = phoneNo
          .replace(/[^0-9]/g, '')
          .replace(/^0/, '82');

        putRiderPhoneNumber(riderId,
          {phoneNo: parsingPhoneNo})
        alert('성공적으로 등록되었습니다');
        window.location.reload();
      } catch (err) {
        alert('등록에 실패하였습니다');
        window.location.reload();
      }
    }
  };

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={handleSubmitButton}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'라이더 휴대폰 번호 변경'}
      width={'50%'}
    >
      <Row
        gutter={[16, 16]}
        justify={'center'}
        align={'middle'}
      >
        <Input
          value={phoneNo}
          onChange={e => setPhoneNo(e.target.value)}
        />
      </Row>

    </BaseModal>
  );
};

export default EditRiderModal;