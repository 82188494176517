import React from "react";
import {Descriptions, Input, Modal, Typography} from 'antd';
import {getAmountNumberFormat, getMobilityLendTypeTag, parseDate, parseMobilityType} from "../../../utils/organizeData";
import {MopedLeaseRentalDto, ScooterLeaseRentalDto} from "../rental.interface";
import moment from "moment";
import {MobilityType} from "../../mobility/mobility.interface";

interface Props {
  tabVal: MobilityType,
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  selectedLeaseRental: ScooterLeaseRentalDto | MopedLeaseRentalDto | null,
  setSelectedLeaseRental: React.Dispatch<React.SetStateAction<ScooterLeaseRentalDto | MopedLeaseRentalDto | null>>,
}

function LeaseRentalDetailModal(
  {
    tabVal,
    isVisible,
    setIsVisible,
    selectedLeaseRental,
    setSelectedLeaseRental,
  } :Props
) {

  if (selectedLeaseRental == null) return null;

  const nowDate = moment().format("YYYY-MM-DD 23:59:59").toString();
  const isLate = new Date(selectedLeaseRental.nextAt).getTime() <= new Date(nowDate).getTime();
  const handleOk = () => {
    setSelectedLeaseRental(null);
    setIsVisible(false)
  }

  return (
    <Modal
      width={'80%'}
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleOk}
    >
      <Descriptions
        title="리스/렌탈 계약자 및 기기"
        bordered={true}
        size={'small'}
        column={3}
      >
        <Descriptions.Item label="계약명">
          {selectedLeaseRental.contractName}
        </Descriptions.Item>
        <Descriptions.Item label="리스/렌탈">
          {getMobilityLendTypeTag(selectedLeaseRental.mobilityLendType)}
        </Descriptions.Item>
        <Descriptions.Item label="라이더ID">
          {selectedLeaseRental.rider.riderId}
        </Descriptions.Item>
        <Descriptions.Item label="라이더명">
          {selectedLeaseRental.rider.riderName}
        </Descriptions.Item>
        <Descriptions.Item label="라이더번호">
          {selectedLeaseRental.rider.phoneNo}
        </Descriptions.Item>
        <Descriptions.Item label="기기">
          {parseMobilityType(selectedLeaseRental.mobility.mobilityType)}
        </Descriptions.Item>
        <Descriptions.Item label="SIMEI">
          {selectedLeaseRental.mobility.shortImei}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="리스/렌탈 계약상세"
        bordered={true}
        size={'small'}
        column={3}
        style={{marginTop: '10px'}}
      >
        <Descriptions.Item label="다음결제일" span={1}>
          {<span style={{color: isLate ? 'red' : 'black'}}>{parseDate(selectedLeaseRental.nextAt)}</span>}
        </Descriptions.Item>
        <Descriptions.Item label="계약일" span={1}>
          {parseDate(selectedLeaseRental.contractAt)}
        </Descriptions.Item>

        <Descriptions.Item label="반환일" span={1}>
          {selectedLeaseRental.returnAt
            ? parseDate(selectedLeaseRental.returnAt)
            : '-'
          }
        </Descriptions.Item>
        <Descriptions.Item label="시작일" span={1}>
          {parseDate(selectedLeaseRental.startAt)}
        </Descriptions.Item>
        <Descriptions.Item label="종료일" span={1}>
          {parseDate(selectedLeaseRental.endAt)}
        </Descriptions.Item>
        <Descriptions.Item label="월 납부액" span={1}>
          {getAmountNumberFormat(selectedLeaseRental.lendAmount)}
        </Descriptions.Item>
        <Descriptions.Item label="계약 금액" span={1}>
          {getAmountNumberFormat(selectedLeaseRental.lendTotalAmount)}
        </Descriptions.Item>
        <Descriptions.Item label="결제/총 (개월)" span={1}>
          {`${selectedLeaseRental.monthlyInstallment - selectedLeaseRental.residualMonthlyInstallment} / ${selectedLeaseRental.monthlyInstallment}`}
        </Descriptions.Item>
        <Descriptions.Item label="위약금" span={1}>
          <Typography.Text>{getAmountNumberFormat(selectedLeaseRental.penaltyAmount)}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="키 보증금" span={1}>
          {tabVal === 'MOPED'
              ? getAmountNumberFormat((selectedLeaseRental as MopedLeaseRentalDto).depositAmount)
              : null
          }
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title="리스/렌탈 메모"
        bordered={true}
        size={'small'}
        style={{marginTop: '10px'}}
      >
        <Descriptions.Item>
          <Input.Group compact>
            <div>{selectedLeaseRental.memo}</div>
          </Input.Group>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

export default LeaseRentalDetailModal;