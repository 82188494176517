import {AxiosResponse} from 'axios';
import {LocalAdminApi} from '.';
import {AccountDto} from "../domain/account/account.interface";

async function login({email, password}: { email: string; password: string }) {
    await LocalAdminApi.post<{ token: string }>('/authenticate', {username: email, password})
        .then((resp) => resp.data)
        .then((data) => {
            const {token} = data;

            if (!token) {
                throw `Cannot login with ${email}`;
            }

            localStorage.setItem('access_token', token);
        })
        .catch((err) => {
            const resp = err.response as AxiosResponse;
            const message = resp.data.message;
            if (resp.status === 401 || resp.status === 400) {
                throw message;
            }
            throw '알수 없는 문제가 발생했습니다.';
        });
}

async function getMyInfo() {
    const token = localStorage.getItem('access_token');
    LocalAdminApi.defaults.headers = {
        authorization: `Bearer ${token}`,
    };
    return await LocalAdminApi.get<AccountDto>('/me').then((resp) => {
        return resp.data;
    });
}

async function logout() {
    localStorage.removeItem('access_token');
    window.location.reload();
}

export const AuthService = {getMyInfo, login, logout};
