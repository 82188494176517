import {Descriptions, Modal, Radio, Tooltip} from 'antd';
import React, {useState, VFC} from 'react';
import moment from "moment";
import {updateSubscriptionPeriodEvent} from '../subscription.service'
import {SimpleSubscription} from "../subscription.interface";

interface Props {
  isVisible: boolean;
  modalCloseOpenHandler: () => void
  modalHandler: () => void;
  subscription: SimpleSubscription | null;
}

const SubscriptionDetailModal: VFC<Props> = ({isVisible, modalCloseOpenHandler, modalHandler, subscription}) => {

  const [additionalDay, setAdditionalDay] = useState(0);

  const onAdditionalDayChange = (day: any) => {
    setAdditionalDay(day.target.value);
  }

  const onOkay = async () => {
    if (subscription?.subscriptionId && additionalDay > 0) {
      try {
        const extendResult = await updateSubscriptionPeriodEvent(subscription?.subscriptionId, {extendDays: additionalDay});
        if (extendResult.status === 200) {
          alert("성공적으로 연장 되었습니다");
          window.location.reload();
        } else {
          alert("연장에 실패하였습니다");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("연장에 실패하였습니다");
      modalCloseOpenHandler();
    }
  }

  return (
    <Modal
      title={`구독 상세`}
      visible={isVisible}
      onCancel={modalHandler}
      width={800}
      onOk={onOkay}
    >
      <Descriptions
        bordered
        column={3}
        layout="vertical"
      >
        <Descriptions.Item label="이름" span={1}>{subscription?.rider.riderName}</Descriptions.Item>
        <Descriptions.Item label="핸드폰번호" span={1}>{subscription?.rider.phoneNo}</Descriptions.Item>
        <Descriptions.Item
          label="구독ID"
          span={1}
        >
          {subscription?.subscriptionId}
        </Descriptions.Item>
        <Descriptions.Item
          label="구독 시작일"
          span={1}
        >
          {moment(subscription?.startAt).format("YYYY-MM-DD HH:mm")}
        </Descriptions.Item>
        <Descriptions.Item label="다음 결제일" span={1}>
          {subscription?.nextAt ? moment(subscription?.nextAt).format("YYYY-MM-DD HH:mm") : '구독 취소'}
        </Descriptions.Item>
        <Descriptions.Item label="구독여부" span={1}>
          {subscription?.cancelAt ? `구독취소 (${moment(subscription?.cancelAt).format("YYYY-MM-DD HH:mm")})` : '구독중'}
        </Descriptions.Item>
        <Descriptions.Item label="추가 일" span={2}>
          <Tooltip title="구독을 취소한 경우 연장이 불가합니다">
            <Radio.Group
              onChange={onAdditionalDayChange}
              disabled={!!subscription?.cancelAt}
            >
              <Radio.Button value={1}>1일</Radio.Button>
              <Radio.Button value={3}>3일</Radio.Button>
              <Radio.Button value={7}>7일</Radio.Button>
              <Radio.Button value={14}>14일</Radio.Button>
              <Radio.Button value={30}>30일</Radio.Button>
            </Radio.Group>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="변경된 다음 결제일" span={1}>
          {
            subscription?.cancelAt ? '-' :
              additionalDay === 0 ? moment(subscription?.nextAt).format("YYYY-MM-DD HH:mm") : moment(subscription?.nextAt).add(additionalDay, 'days').format("YYYY-MM-DD HH:mm")
          }
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default SubscriptionDetailModal;
