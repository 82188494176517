import React, {useState} from 'react';
import {parseDateTime} from '../../../utils/organizeData';
import {Geocode} from '../../../common/unit/geocode';
import moment from 'moment';
import {antdTableFilterProperty} from '../../../common/unit/antd/FilterDropdown';
import {forceEndRide} from '../ride.service';
import Table, {ColumnsType} from 'antd/lib/table';
import {Button, Tag, Typography} from 'antd';
import {EnvironmentOutlined, SearchOutlined} from '@ant-design/icons';
import {HistoryDto} from "../ride.interface";
import Copyable from "../../../common/component/Copyable";
import NameALink from "../../../common/component/NameALink";

export function MopedRideHistoryTable({rides, type = 'ALL'}: { rides: HistoryDto[]; type?: 'ALL' | 'MEMBER' }) {

  const [searchEndAtText, setSearchEndAtText] = useState<any>('');
  const [searchedEndAtColumn, setSearchedEndAtColumn] = useState<any>(null);

  const handleForceEndRide = (rideId: number) => {
    console.log(rideId);
    forceEndRide(rideId).then(() => {
      alert("성공적으로 종료되었습니다");
      window.location.reload();
    }).catch((err) => {
      alert("강제종료에 실패하였습니다" + err)
    })
  }

  const ridesColumns: ColumnsType<HistoryDto> = [
    {
      key: 'shortImei',
      dataIndex: 'shortImei',
      title: 'SIMEI',
      align: 'center',
      render: (_, history) => <Copyable textVal={history.mobility.shortImei}/>,
    },
    {
      key: 'rider',
      dataIndex: ['rider', 'name'],
      title: '사용자',
      align: 'center',
      render: (_, ride) => {
        return <NameALink urlVal={ride.rider.riderId} textVal={ride.rider.riderName}/>;
      },
    },
    {
      key: 'startAt',
      dataIndex: 'startAt',
      title: '시작 시간',
      align: 'center',
      render: (time) => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {parseDateTime(moment(time).unix() * Math.pow(10, 3))}
        </Typography.Text>
      ),
    },
    {
      key: 'endAt',
      dataIndex: 'endAt',
      title: '종료 시간',
      align: 'center',
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) =>
        antdTableFilterProperty(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          'endAt',
          setSearchEndAtText,
          setSearchedEndAtColumn
        ),
      filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
      onFilter: (value, record) => {
        if (record['endAt']) {
          const str = value.toString();
          return record['endAt'].toString().includes(str);
        } else if (record['endAt'] === undefined) {
          return true;
        } else return false;
      },
      render: (time) => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {time ? parseDateTime(moment(time).unix() * Math.pow(10, 3)) : '현재 이용중'}
        </Typography.Text>
      ),
    },
    {
      key: 'startPoint',
      title: '시작 위치',
      align: 'center',
      render: (val, row) => {
        if (row.startPlacement) {
          return (<Tag icon={<EnvironmentOutlined/>} color="processing">
            {row.startPlacement.placementName}
          </Tag>)
        } else {
          if (row.startLocation) {
            const {startLocation} = row;
            return <Geocode lat={startLocation.latitude} lng={startLocation.longitude}/>
          } else {
            return '-'
          }
        }
      },
    },
    {
      key: 'endLocation',
      title: '종료 위치',
      align: 'center',
      render: (val, row) => {
        if (row.endPlacement) {
          return (<Tag icon={<EnvironmentOutlined/>} color="processing">
            {row.endPlacement.placementName}
          </Tag>)
        } else {
          if (row.endLocation) {
            const {endLocation: {latitude, longitude}} = row;
            return <Geocode lat={latitude} lng={longitude}/>
          } else {
            return '-'
          }
        }
      }
    },
    {
      key: 'actionButtons',
      title: '강제종료',
      align: 'center',
      render: (val, row) => {
        return row.endAt ? <span> - </span> : <Button onClick={() => handleForceEndRide(row.id)}>강제종료</Button>
      }
    }
  ];

  return (
    <Table
      bordered
      columns={ridesColumns}
      dataSource={
        rides.sort((p, n) => {
          return moment(n.startAt).unix() - moment(p.startAt).unix();
        }).map((e, i) => {
          return {
            key: i,
            ...e
          }
        })}
    />
  );
}