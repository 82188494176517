import MARKER_RIDING_SCOOTER from '../../images/is_riding.png';
import MARKER_DEFAULT_SCOOTER from '../../images/kick_sellect.png';
import { getScooter } from '../../services/scoot';

// only one scooter
export const drawOneScooterOnMapUtil = async (id: number) => {
  const scooter = await getScooter(id);
  const image = scooter.mobilityStatus === 'AVAILABLE' ? MARKER_DEFAULT_SCOOTER : MARKER_RIDING_SCOOTER;
  const { latitude: lat, longitude: lng } = scooter.location;
  return [{ lat, lng, image }];
};
