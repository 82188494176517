import React from 'react';
import {parseDateTime} from '../../../utils/organizeData';
import {Geocode} from '../../../common/unit/geocode';
import moment from 'moment';
import {forceEndRide} from '../../ride/ride.service';

/* == ant design == */
import Table, {ColumnsType} from 'antd/lib/table';
import {Button, Tag, Typography} from 'antd';
import {EnvironmentOutlined} from '@ant-design/icons';
import {HistoryDto} from "../../ride/ride.interface";
import {MobilityType} from "../../../domain/mobility/mobility.interface";
import NameALink from '../../../common/component/NameALink';

export function RiderRideTable({rides, type = 'ALL'}: { rides: HistoryDto[]; type?: 'ALL' | 'MEMBER' }) {

  const handleForceEndRide = (rideId: number) => {
    forceEndRide(rideId).then(() => {
      alert("성공적으로 종료되었습니다");
      window.location.reload();
    }).catch((err) => {
      alert("강제종료에 실패하였습니다" + err)
    })
  }

  const ridesColumns: ColumnsType<HistoryDto> = [
    {
      key: 'shortImei',
      dataIndex: 'shortImei',
      title: '스쿠터ID',
      align: 'center',
      render: (val, row) => {
        const el: Record<MobilityType, JSX.Element> = {
          SCOOTER: <span>{row.mobility.scooterId!}</span>,
          MOPED: <span>{row.mobility.mopedId!}</span>,
        }
        return el[row.mobility.mobilityType];
      }
    },
    {
      key: 'riderId',
      title: '라이더ID',
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderId}/>
    },
    {
      key: 'rider',
      title: '사용자',
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName} />,
    },
    {
      key: 'startAt',
      dataIndex: 'startAt',
      title: '시작 시간',
      align: 'center',
      render: (time) => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {parseDateTime(moment(time).unix() * Math.pow(10, 3))}
        </Typography.Text>
      ),
    },
    {
      key: 'endAt',
      dataIndex: 'endAt',
      title: '종료 시간',
      align: 'center',
      render: (time) => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {time ? parseDateTime(moment(time).unix() * Math.pow(10, 3)) : '현재 이용중'}
        </Typography.Text>
      ),
    },
    {
      key: 'startPoint',
      title: '시작 위치',
      align: 'center',
      render: (val, row) => {
        if (row.startPlacement) {
          return (<Tag icon={<EnvironmentOutlined/>} color="processing">
            {row.startPlacement.placementName}
          </Tag>)
        } else {
          if (row.startLocation) {
            const {startLocation: {latitude, longitude}} = row;
            return <Geocode lat={latitude} lng={longitude}/>
          } else {
            return '-'
          }
        }
      },
    },
    {
      key: 'endLocation',
      title: '종료 위치',
      align: 'center',
      render: (val, row) => {
        if (row.endPlacement) {
          return (<Tag icon={<EnvironmentOutlined/>} color="processing">
            {row.endPlacement.placementName}
          </Tag>)
        } else {
          if (row.endLocation) {
            const {endLocation: {latitude, longitude}} = row;
            return <Geocode lat={latitude} lng={longitude}/>
          } else {
            return '-'
          }
        }
      }
    },
    {
      key: 'actionButtons',
      title: '강제종료',
      align: 'center',
      render: (val, row) => {
        return row.endAt ? <span> - </span> : <Button onClick={() => handleForceEndRide(row.id)}>강제종료</Button>
      }
    }
  ];

  return (
    <Table
      bordered
      columns={ridesColumns}
      dataSource={
        rides.sort((p, n) => {
          return moment(n.startAt).unix() - moment(p.startAt).unix();
        }).map((e, i) => {
          return {
            key: i,
            ...e
          }
        })}
    />
  );
}