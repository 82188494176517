export const languageEn = {
  'common-all': 'all',
  'common-confirm' : 'confirm',
  'common-cancel' : 'cancel',
  'common-battery' : 'battery',
  'common-none': 'none',
  'common-price': 'price',
  'common-submit': 'submit',
  'common-type': 'type',
  'common-kind': 'kind',
  'common-status': 'status',
  'common-rider': 'rider',
  'common-license': 'license',
  'common-area': 'area',
  'common-list': 'list',
  'common-year': 'year',
  'common-month': 'month',
  'common-day' : 'day',
  'common-hour': 'hour',
  'common-minute': 'minute',
  'common-second': 'second',

  'header-language': 'Language',
  'header-language-kor': 'Korean',
  'header-language-eng': 'English',
  'header-language-jpn': 'Japanese',
  'header-language-logout': 'Logout',

  'side-bar-operator': 'Operator',
  'side-bar-operator-account': 'Account',
  'side-bar-operator-band': 'Band',
  'side-bar-operator-master-product': 'Master Product',

  'side-bar-dashboard': 'Dashboard',
  'side-bar-dashboard-signup': 'Sign-Up',
  'side-bar-dashboard-purchase': 'Sales',
  'side-bar-dashboard-placement': 'Placement',

  'side-bar-rider': 'Rider',
  'side-bar-rider-list': 'List',
  'side-bar-rider-subscription': 'Subscription',
  'side-bar-rider-purchase': 'Purchase',
  'side-bar-rider-nonpayment': 'Nonpayment',

  'side-bar-scooter': 'Scooter',
  'side-bar-scooter-list': 'List',
  'side-bar-scooter-ride': 'Ride',
  'side-bar-scooter-expired': 'Expired',
  'side-bar-scooter-nonpayment': 'Nonpayment',

  'side-bar-moped': 'Moped',
  'side-bar-moped-list': 'List',
  'side-bar-moped-ride': 'Ride',
  'side-bar-moped-rental': 'Lease / Rental',
  'side-bar-moped-expired': 'Expired',
  'side-bar-moped-nonpayment': 'Nonpayment',

  'side-bar-placement': 'Placement',
  'side-bar-placement-list': 'List',
  'side-bar-placement-map': 'Map',

  'side-bar-product': 'Product',
  'side-bar-product-list': 'List',

  'side-bar-tariff' : 'Tariff',
  'side-bar-tariff-list' : 'List',
  'side-bar-tariff-band' : 'Band',

  'side-bar-application': 'Application',
  'side-bar-application-app': 'Info',
  'side-bar-application-banner': 'Banner',
};