import moment from 'moment';
import { Tag } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import React from 'react';
import { MobilityLendType, MobilityStatus, MobilityType } from '../domain/mobility/mobility.interface';
import { TariffDayType, TariffTimeType } from '../domain/tariff/tariff.interface';

export function parseBirthday(birthday?: string) {
  if (birthday) {
    const year = birthday.slice(0, 4);
    const month = birthday.slice(4, 6);
    const day = birthday.slice(6, 8);

    return `${year}년 ${month}월 ${day}일`;
  } else {
    return '-';
  }
}

export function parseUsagePeriodUnit(unit: string) {
  switch (unit) {
    case 'M':
      return '월';
    case 'D':
      return '일';
    case 'H':
      return '시';
    case 'I':
      return '분';
    case 'S':
      return '초';
  }
}

export function parseDateTime(timestamp: number | string | null): string {
  if (!timestamp) {
    return `-`;
  }
  return moment(timestamp).format('YY.MM.DD HH:mm');
}

export function parseDate(timestamp: number | string | null): string {
  if (!timestamp) {
    return `-`;
  }
  return moment(timestamp).format('YY.MM.DD');
}

export function parseTime(timestamp: number | string | null): string {
  if (!timestamp) {
    return `-`;
  }
  return moment(timestamp).format('HH:mm:SS');
}

export function parsePhoneNumber(phone_number: string) {
  if (phone_number) {
    return phone_number
      .replace(/[^0-9]/g, '')
      .replace(/^8[0-9]/, '0')
      .replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
  } else return '-';
}

export function parseSearchPhoneNumber(phone_number: string) {
  if (phone_number) {
    return phone_number
      .replace(/-/g, '')
      .replace(/^8[0-9]/, '0')
  } else return '';
}

export function getAmountNumberFormat(amount: number) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseSecondsToDays(miliSeconds: number) {
  const seconds = miliSeconds / 1000;
  if (seconds < 60) return Math.floor(seconds) + '초';

  const minutes = seconds / 60;
  if (minutes < 60) return Math.floor(minutes) + '분';

  const hours = minutes / 60;
  if (hours < 24) return Math.floor(hours) + '시간';

  return Math.floor(hours / 24) + '일';
}

export function getDeployableMobilityTag(mobilityType: string) {
  switch (mobilityType) {
    case 'A':
      return <Tag color={'purple'}>오킥</Tag>;
    case 'S':
      return <Tag color={'geekblue'}>킥보드</Tag>;
    case 'M':
      return <Tag color={'green'}>오토바이</Tag>;
  }
}

export function getBandTag(bandName: string, idx: number) {
  return <Tag key={'band-tag-' + idx}>{bandName}</Tag>;
}

export function getBandTagById(bandId: number, idx: number) {
  switch (bandId) {
    case 1:
      return <Tag key={'band-tag-' + idx}>직영</Tag>;
    case 2:
      return <Tag key={'band-tag-' + idx}>부천바로고</Tag>;
    case 7:
      return <Tag key={'band-tag-' + idx}>일산</Tag>;
    case 8:
      return <Tag key={'band-tag-' + idx}>송도</Tag>;
    case 9:
      return <Tag key={'band-tag-' + idx}>대전칸</Tag>;
    case 10:
      return <Tag key={'band-tag-' + idx}>동대문DMC</Tag>;
    case 11:
      return <Tag key={'band-tag-' + idx}>천안리드콜</Tag>;
    case 12:
      return <Tag key={'band-tag-' + idx}>나주배달꾼</Tag>;
    default:
      return <Tag key={'band-tag-' + idx}>등록대기중</Tag>;
  }

}

export function getMobilityLendTypeTag(lendType: MobilityLendType) {
  switch (lendType) {
    case 'ALL':
      return <Tag color='black'>전체</Tag>;
    case 'LEASE':
      return <Tag color='lime'>리스</Tag>;
    case 'RENTAL':
      return <Tag color='volcano'>렌탈</Tag>;
    case 'NONE':
      return <Tag color='blue'>오라</Tag>;
  }
}

export function getPlacementTag(placementName: string) {
  return <Tag icon={<EnvironmentOutlined />} color='processing'>{placementName}</Tag>;
}

export function parseSearchString(str: string) {

  if (isPhoneNumber(str)) {
    let trimmedNumber = '';

    if (str[0] === '0') {
      trimmedNumber = str.slice(1);
    } else {
      trimmedNumber = str;
    }

    let newNumber = '';

    trimmedNumber.split('').forEach((e, i) => {
      if (parseInt(e) >= 0) {
        newNumber += e;
      }
    });

    return newNumber;
  } else {
    return str;
  }
}

export function isPhoneNumber(str: string) {
  let count = 0;
  str.split('').forEach(e => {
    const number = parseInt(e);
    if (number >= 0 && number <= 9) {
      count++;
    }
  });
  return count !== 0;
}

//#cf1322

export function parseTariffDayType(value: TariffDayType) {
  switch (value) {
    case 'WD' :
      return <Tag color={'blue'}>평일</Tag>;
    case 'WE' :
      return <Tag color={'red'}>주말</Tag>;
  }
}

export function parseTariffTimeType(value: TariffTimeType) {
  switch (value) {
    case 'DT' :
      return <Tag color={'green'}>주간</Tag>;
    case 'NT' :
      return <Tag color={'purple'}>야간</Tag>;
  }
}

export function parseMobilityType(mobilityType: MobilityType) {
  return mobilityType === 'MOPED' ? <Tag color={'blue'}>오토바이</Tag> : <Tag color={'orange'}>스쿠터</Tag>;
}

export function parseMobilityStatus(mobilityStatus: MobilityStatus) {
  switch (mobilityStatus) {
    case 'AVAILABLE':
      return '이용가능';
    case 'CARGO':
      return '창고';
    case 'RIDING':
      return '탑승중';
    default:
      return '알수없는 상태값';
  }
}
