import { Spin, Typography } from "antd";
import React, { useContext } from "react";
import "./loading.css";

import { LoadingContext } from "../../context";
export function LoadingPage() {
  const isLoading = useContext(LoadingContext);
  if (isLoading) {
    return (
      <div className="loading-page">
        <Spin size="large" />
        <br />
        <Typography.Title level={3}>잠시 기다려주세요</Typography.Title>
      </div>
    );
  } else {
    return <></>;
  }
}
