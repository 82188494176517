import {ArrayList} from "../../../../utils/array_list";
import {MapWrapper, MarkerWrapper, PolygonWrapper} from "../../Types";
import {Polygon} from "../../../polygon/polygon";
import {compareLocationDto, LocationDto} from "../../../common.interface";
import {utils} from "../../Utils";
import {EventType, MarkerImage} from "../../Map";
import {red_dot} from "../../marker_constants";
import {PolygonDragBehavior} from "./PolygonDragBehavior";
import {MarkerDragBehavior} from "./MarkerDragBehavior";
import {SubMarkerDragBehavior} from "./SubMarkerDragBehavior";

const MARKER_SIZE = 10;
const SUB_MARKER_SIZE = 8;

const RED_DOT_MARKER_IMAGE: MarkerImage = {
  src: red_dot,
  size: {
    width: MARKER_SIZE,
    height: MARKER_SIZE,
  },
  zIndex: 2,
  anchor: {
    x: MARKER_SIZE / 2,
    y: MARKER_SIZE / 2,
  },
};

const SUB_MARKER_IMAGE: MarkerImage = {
  src: red_dot,
  size: {
    width: SUB_MARKER_SIZE,
    height: SUB_MARKER_SIZE,
  },
  zIndex: 2,
  anchor: {
    x: SUB_MARKER_SIZE / 2,
    y: SUB_MARKER_SIZE / 2,
  },
};

export class EditablePolygon {
  private readonly markers: ArrayList<MarkerWrapper>;
  private readonly subMarkers: ArrayList<MarkerWrapper>;
  private readonly polygonBuilder = new Polygon.Builder();

  private polygon?: PolygonWrapper

  private readonly markerDragBehaviors = new ArrayList<MarkerDragBehavior>();

  private readonly onPolygonEndCallback: () => void;
  private readonly onPolygonDeleteCallback: (e: EditablePolygon) => void;

  constructor(onPolygonEnd: () => void, onPolygonDelete: (e: EditablePolygon) => void) {
    this.markers = new ArrayList<MarkerWrapper>();
    this.subMarkers = new ArrayList<MarkerWrapper>();
    this.onPolygonEndCallback = onPolygonEnd;
    this.onPolygonDeleteCallback = onPolygonDelete;
  }

  addMarker(map: MapWrapper, location: LocationDto) {
    const marker = this.createMarker(map, location);
    this.markers.add(marker);
    this.polygonBuilder.append(location);
  }

  getPath() {
    return this.polygonBuilder.build().getCoordinates();
  }

  private createMarker(map: MapWrapper, location: LocationDto): MarkerWrapper {
    const marker = utils.createMarker(map, {
      position: location,
      image: RED_DOT_MARKER_IMAGE,
    }, location);

    utils.addListener(marker, EventType.CLICK, () => this.onPolygonEnd(map, location));
    utils.addListener(marker, EventType.RIGHTCLICK, () => this.onMarkerDelete(map, marker));
    this.markerDragBehaviors.add(new MarkerDragBehavior(map, marker, this.polygonBuilder, this.polygon));

    return marker;
  }

  private onPolygonEnd(map: MapWrapper, start: LocationDto) {
    if (this.polygon) return;

    const path = this.polygonBuilder.build().getCoordinates();
    const arrayList = ArrayList.from(path, compareLocationDto);
    const index = arrayList.indexOf(start);

    const viableCount = arrayList.size() - index;
    if (viableCount < 3) return;

    this.buildPolygon(map);
  }

  private buildPolygon(map: MapWrapper): boolean {
    const polygons = this.polygonBuilder.build().getCoordinates();
    polygons.push(polygons[0]);

    const polygon = utils.createPolygon(map, polygons);
    this.polygon = polygon;
    new PolygonDragBehavior(map, polygon, this.markers, this.subMarkers, this.polygonBuilder);
    this.markerDragBehaviors.forEach(b => b.setPolygon(polygon));

    for (let i = 0; i < polygons.length - 1; i++) {
      this.createSubMarker(map, i);
    }

    this.onPolygonEndCallback();
    return true;
  }

  private onMarkerDelete(map: MapWrapper, marker: MarkerWrapper) {
    const location = marker.data;
    const markerIndex = this.markers.findIndex(m => compareLocationDto(m.data, location));
    if (markerIndex === -1) return;

    utils.setMap(marker, null);
    const index = this.markers.remove(marker);
    this.polygonBuilder.delete(location);
    this.markerDragBehaviors.removeAt(index);

    if (this.polygon) {
      const path = this.polygonBuilder.build().getCoordinates();
      if (path.length < 3) {
        this.markers.forEach(m => utils.setMap(m, null));
        this.subMarkers.forEach(m => utils.setMap(m, null));
        utils.setMap(this.polygon, null);
        this.onPolygonDeleteCallback(this);
        return;
      }

      path.push(path[0]);
      this.polygon.setPath(path);

      const prev = this.subMarkers.get((index - 1 + this.subMarkers.size()) % this.subMarkers.size());
      const next = this.subMarkers.get(index);
      utils.setMap(prev, null);
      utils.setMap(next, null);
      this.subMarkers.remove(prev);
      this.subMarkers.remove(next);

      this.createSubMarker(map, (index - 1 + this.markers.size()) % this.markers.size());
    }
  }

  private onMarkerCreateFromSubMarker(map: MapWrapper, location: LocationDto, prev: MarkerWrapper, next: MarkerWrapper): MarkerWrapper {
    const index = this.markers.indexOf(prev) + 1;

    const marker = utils.createMarker(map, {
      position: location,
      image: RED_DOT_MARKER_IMAGE,
    }, location);
    utils.addListener(marker, EventType.RIGHTCLICK, () => this.onMarkerDelete(map, marker));
    const dragBehavior = new MarkerDragBehavior(map, marker, this.polygonBuilder, this.polygon);
    dragBehavior.forceDrag();

    if (this.markers.size() <= index) {
      this.markers.add(marker);
      this.markerDragBehaviors.add(dragBehavior);
      this.polygonBuilder.append(location);
    } else {
      this.markers.insert(index, marker);
      this.markerDragBehaviors.insert(index, dragBehavior);
      this.polygonBuilder.insert(index, location);
    }

    this.createSubMarker(map, index - 1);
    this.createSubMarker(map, index % this.markers.size());

    return marker;
  }

  private onSubMarkerDestroyed(subMarker: MarkerWrapper, prev: MarkerWrapper, next: MarkerWrapper) {
    this.subMarkers.remove(subMarker);
    this.markerDragBehaviors.get(this.markers.indexOf(prev)).removeSubMarker(subMarker);
    this.markerDragBehaviors.get(this.markers.indexOf(next)).removeSubMarker(subMarker);
  }

  private createSubMarker(map: MapWrapper, prevMarkerIndex: number) {
    const a = this.markers.get(prevMarkerIndex).data;
    const b = this.markers.get((prevMarkerIndex + 1) % this.markers.size()).data;
    const position: LocationDto = {
      latitude: (a.latitude + b.latitude) / 2,
      longitude: (a.longitude + b.longitude) / 2,
    };

    const subMarker = utils.createMarker(map, {position, image: SUB_MARKER_IMAGE}, position);
    if (prevMarkerIndex >= this.subMarkers.size()) {
      this.subMarkers.add(subMarker);
    } else {
      this.subMarkers.insert(prevMarkerIndex, subMarker);
    }
    new SubMarkerDragBehavior(map, subMarker, this.markers.get(prevMarkerIndex), this.markers.get((prevMarkerIndex + 1) % this.markers.size()),
      (m, l, p, n) => this.onMarkerCreateFromSubMarker(m, l, p, n),
      (s, p, n) => this.onSubMarkerDestroyed(s, p, n));

    this.markerDragBehaviors.get(prevMarkerIndex).addSubMarker(subMarker);
    this.markerDragBehaviors.get((prevMarkerIndex + 1) % this.markerDragBehaviors.size()).addSubMarker(subMarker);
  }
}
