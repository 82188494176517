import {Form, Input, Modal} from 'antd';
import React, {useEffect, useState, VFC} from 'react';
import useInput from '../../../hooks/useInput';
import {updateAccount} from '../account.service';
import {AccountDto, AccountUpdateBody} from "../account.interface";

interface Props {
  isVisible: boolean;
  modalHandler: () => void;
  account: AccountDto | null;
}

const ModifyAccountModal: VFC<Props> = ({isVisible, modalHandler, account}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, onChangeEmail, setEmail] = useInput('');
  const [phoneNumber, onChangePhoneNumber, setPhoneNumber] = useInput('');

  const handleOk = async () => {
    if (account) {
      const body: AccountUpdateBody = {
        ...account,
        bandId: account.band.bandId,
        email: email,
        phoneNumber: phoneNumber,
      }
      await updateAccount(
        account.accountId,
        body
      )
        .then(() => alert('수정에 성공했습니다.'))
        .catch((err) => alert(`수정에 실패했습니다. ${err}`));
      window.location.reload();
    }
    modalHandler();
  };
  const {Item} = Form;

  useEffect(() => {
    if (account) {
      setEmail(account.email);
      setPhoneNumber(account.phoneNumber);
    }
  }, [JSON.stringify(account)]);

  return (
    <>
      <Modal title="계정수정" visible={isVisible} onOk={handleOk} onCancel={modalHandler}>
        <Form>
          <Item label="이메일">
            <Input value={email} onChange={onChangeEmail}/>
          </Item>
          <Item label="휴대폰 번호">
            <Input value={phoneNumber} onChange={onChangePhoneNumber}/>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModifyAccountModal;
