import {Button, Input, Typography} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import React, {useContext, useState} from 'react';
import './login.css';
import {AuthService} from '../../services/auth';
import {useHistory} from 'react-router';
import {SetAdminContext, SetLoadingContext} from '../../context';

export function LoginPage() {
    const [message, setMessage] = useState('이메일로 로그인');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value);
    };
    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value);
    };

    const history = useHistory();

    function goMain() {
        history.push('/riders');
    }

    const setAccount = useContext(SetAdminContext)!;
    const setLoading = useContext(SetLoadingContext)!;

    function handleLogin() {
        setLoading(true);

        AuthService.login({
            email,
            password,
        })
            .then(AuthService.getMyInfo)
            .then((info) => {
                console.log(info);
                setAccount({
                    ...info
                });
            })
            .then(goMain)
            .catch((err) => {
                console.log(err, 'err');
                alert('아이디, 비밀번호를 확인해주세요');
                setMessage(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className="login-page">
            <div className="login-wrap">
                <Typography.Title level={4} type="secondary">
                    {message}
                </Typography.Title>
                <Input
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="dayrider@theswing.co.kr"
                    prefix={<UserOutlined/>}
                />
                <Input.Password
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Your password"
                    prefix={<LockOutlined/>}
                    onPressEnter={(e) => {
                        handleLogin();
                    }}
                />
                <Button className="btn-login" type="link" onClick={handleLogin}>
                    로그인
                </Button>
            </div>
        </div>
    );
}
