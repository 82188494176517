import React, {useContext, useEffect, useState} from 'react';
import {AccountContext, SetLoadingContext} from '../../../context';
import {Card, Col, Row, Statistic} from 'antd';
import {getDashboardSignUpListByDate} from '../dashboard.service'
import moment from "moment";
import {TeamOutlined, UserOutlined} from "@ant-design/icons";
import 'antd/dist/antd.css';
import {DualAxes} from '@ant-design/charts';
import {RiderList} from "../../rider/rider.interface";

const RiderDashboardPage = () => {

  const admin = useContext(AccountContext);
  const [riders, setRiders] = useState<RiderList[]>([]);
  const [purchases, setPurchases] = useState<BasicPurchase[]>([]);

  /* == graph data == */

  const [riderlineGraphData, setRiderLineGraphData] = useState<RiderDualAxisGraph[]>([]);
  const [purchaselineGraphData, setPurchaseLineGraphData] = useState<PurchaseLineGraph[]>([]);

  useEffect(() => {
    bootstrap();
  }, [admin]);

  useEffect(() => {
    if (riders.length) {
      const ridersByDate = riders.map(e => moment(e.createdAt).add(9, 'hours').format("YY.MM.DD"));
      const riderDateMap = new Map();
      ridersByDate.forEach(e => {
        if (riderDateMap.has(e)) {
          const c = riderDateMap.get(e);
          riderDateMap.set(e, c + 1);
        } else {
          riderDateMap.set(e, 1);
        }
      });

      const sortedRiderDateMap = Array.from(riderDateMap).sort((a, b) => a[0].localeCompare(b[0]));
      const arr2 = [];
      let acc = 0;
      for (const rdm of sortedRiderDateMap) {
        acc += rdm[1]
        arr2.push({
          date: rdm[0],
          daily: rdm[1],
          acc: acc
        });
      }
      setRiderLineGraphData(arr2);
    }
  }, [riders]);

  useEffect(() => {
    if (purchases.length && admin) {
      const purchasesByDate = purchases.filter(e => (!e.cancelYn) && (e.bandId.toString() === admin.band.bandId.toString())).map(e => {
        return {
          date: moment(e.purchaseAt).format("YY.MM.DD"),
          amount: e.purchaseAmount
        }
      });
      const purchaseDateMap = new Map();
      purchasesByDate.forEach(e => {
        if (purchaseDateMap.has(e.date)) {
          const c = purchaseDateMap.get(e.date);
          purchaseDateMap.set(e.date, c + e.amount);
        } else {
          purchaseDateMap.set(e.date, e.amount);
        }
      });
      const arr = Array.from(purchaseDateMap).sort((a, b) => {
        return a[0].localeCompare(b[0]);
      }).map(e => {
        return {
          date: e[0],
          purchase: e[1]
        }
      });
      setPurchaseLineGraphData(arr);
    }
  }, [purchases]);

  const setLoading = useContext(SetLoadingContext)!;

  async function bootstrap() {
    setLoading(true);
    if (admin) {
      getDashboardSignUpListByDate().then(list => {
        const organizedList = [];
        for (const [k, v] of Object.entries(list)) {
          organizedList.push({
            date: k,
            count: v
          })
        }
        let acc = 0;
        const signupData = organizedList.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map(e => {
          acc += e.count;
          return {
            date: e.date,
            daily: e.count,
            acc: acc
          }
        });
        setRiderLineGraphData(signupData);
      });
    }
    setLoading(false);
  }

  const riderDualAxisConfig = {
    data: [riderlineGraphData.slice(0, riderlineGraphData.length - 1), riderlineGraphData.slice(0, riderlineGraphData.length - 1)],
    xField: 'date',
    yField: ['daily', 'acc'],
    limitInPlot: false,
    padding: [5, 20, 60, 20],
    geometryOptions: [
      {geometry: 'column'},
      {
        geometry: 'line',
        lineStyle: {lineWidth: 2}
      }
    ],
    meta: {
      date: {sync: false}
    },
    slider: {start: 0.7},
  }

  return (
    <>
      <Row
        gutter={[12, 12]}
      >
        <Col span={24}>
          <Card
            title={`오늘의 대쉬보드`}
            style={{width: '100%'}}
          >
            <Row>
              <Col span={12}>
                <Statistic
                  prefix={<UserOutlined/>}
                  suffix={riderlineGraphData.length ? riderlineGraphData[riderlineGraphData.length - 1]['daily'] : 0}
                  title="가입자수"
                  valueStyle={{
                    color: '#252196',
                    fontSize: 36
                  }}
                  value={' '}
                />
              </Col>

              <Col span={12}>
                <Statistic
                  prefix={<TeamOutlined/>}
                  suffix={riderlineGraphData.length ? riderlineGraphData[riderlineGraphData.length - 1]['acc'] : 0}
                  title="누적 가입자수"
                  valueStyle={{
                    color: '#962125',
                    fontSize: 36
                  }}
                  value={' '}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="일별 가입자 추이"
            style={{width: '100%'}}
          >
            <DualAxes {...riderDualAxisConfig} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RiderDashboardPage;