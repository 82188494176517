import {getAllPlacements} from "../../../domain/placement/placement.service";
import {utils} from "../Utils";
import {CircleWrapper} from "../Types";
import {PartialVisibilityChangeableMapPlugin} from "./PartialVisibilityChangeableMapPlugin";
import {PlacementDto} from "../../../domain/placement/placement.interface";

export class PlacementCirclePlugin extends PartialVisibilityChangeableMapPlugin<CircleWrapper> {
    private placements: PlacementDto[] = [];
    private circles: CircleWrapper[] = [];
    constructor() {
        super();
    }

    async onInitialize() {
        this.placements = await getAllPlacements();
        this.onMapUpdated();
    }

    async onMapUpdated() {
        const map = await this.getMap();
        this.circles = this.placements.map(p => utils.createCircle(map, p));
    }

    getEntities(): CircleWrapper[] {
        return this.circles;
    }

    show(entity: CircleWrapper) {
        this.getMap().then(map => utils.setMap(entity, map));
    }

    hide(entity: CircleWrapper) {
        utils.setMap(entity, null);
    }
}