export const languageJp = {
  'common-all': '全体',
  'common-confirm' : '確認',
  'common-cancel' : '取り消し',
  'common-battery' : 'バッテリー',
  'common-none': 'なし',
  'common-price': '価格',
  'common-submit': '提出',
  'common-type': 'タイプ',
  'common-kind': '種類',
  'common-status': '状態',
  'common-rider': 'ライダー',
  'common-license': '免許',
  'common-no-license': '無免許',
  'common-area': '地域',
  'common-list': 'ターリスト',
  'common-year': '年',
  'common-month': '月',
  'common-day' : '日',
  'common-hour': '時',
  'common-minute': '分',
  'common-second': '秒',
  'common-operator': '管理者',
  'common-basic': '基本',
  'common-name': '名前',
  'common-email': 'Eメール',
  'common-phoneNumber': '携帯電話番号',
  'common-password': 'パスワード',
  'common-band': 'バンド',
  'common-auth': '権限',
  'common-edit' : '修正',
  'common-delete': '削除',
  'common-pass-all': 'すべてのパス',
  'common-pass-scooter': 'キックボードパス',
  'common-pass-moped': 'オートバイパス',
  'common-pass-expired': '満了したパス',
  'common-male': '男性',
  'common-female': '女性',
  'common-gender': '性別',
  'common-birthday': '誕生日',
  'common-age': '馬齢',
  'common-product-name': '商品名',
  'common-search': '照会',
  'common-purchase': '購入',
  'common-cancelYn': '取り消し可否',
  'common-product': '商品',
  'common-purhcase-amount': '金額',
  'common-scooter': 'スクーター',
  'common-riding': '運行中',
  'common-current-position': '現在位置',
  'common-start-at': '開始時間',
  'common-end-at': '終了時間',
  'common-start-location': 'スタート位置',
  'common-end-location': '終了位置',
  'common-force-end': '強制終了',

  'header-language': '言語管理・設定',
  'header-language-kor': '韓国語',
  'header-language-eng': '英語',
  'header-language-jpn': '日本語',
  'header-language-logout': 'ログアウト',

  'side-bar-operator': '管理者',
  'side-bar-operator-account': 'アカウント管理',
  'side-bar-operator-band': 'バンド管理',
  'side-bar-operator-master-product': 'マスター商品管理',

  'side-bar-dashboard': 'ダッシュボード',
  'side-bar-dashboard-signup': '加入者',
  'side-bar-dashboard-purchase': '購入',
  'side-bar-dashboard-placement': 'ピックアップゾーン',

  'side-bar-rider': 'ライダー',
  'side-bar-rider-list': 'ライダーリスト',
  'side-bar-rider-subscription': '利用登録履歴',
  'side-bar-rider-purchase': '購入履歴',
  'side-bar-rider-nonpayment': '未決済履歴',

  'side-bar-scooter': 'スクーター',
  'side-bar-scooter-list': 'スクーターリスト',
  'side-bar-scooter-ride': '利用内訳',
  'side-bar-scooter-expired': '満了した利用',
  'side-bar-scooter-nonpayment': '未決済履歴',

  'side-bar-moped': 'オートバイ',
  'side-bar-moped-list': 'オートバイリスト',
  'side-bar-moped-ride': '利用内訳',
  'side-bar-moped-rental': 'Lease / Rental',
  'side-bar-moped-expired': '満了した利用',
  'side-bar-moped-nonpayment': '未決済履歴',

  'side-bar-placement': 'ピックアップゾーン',
  'side-bar-placement-list': 'ピックアップゾーンリスト',
  'side-bar-placement-map': 'ピックアップゾーン地図',

  'side-bar-product': '商品',
  'side-bar-product-list': '商品リスト',

  'side-bar-tariff' : '料金表',
  'side-bar-tariff-list' : '料金表リスト',
  'side-bar-tariff-band' : 'バンド料金表',

  'side-bar-application': 'アプリケーション',
  'side-bar-application-app': 'アプリ情報',
  'side-bar-application-banner': 'バナー',

  //account
  'operatorAccountCreated': 'アカウントを作成しました',
  'operatorAccountFailedToCreate': 'アカウント作成ができませんでした',
  'operatorAccount': '管理者アカウント',
  'createOperatorAccount': 'アカウント作成',
  'selectOperatorAuth': '権限選択',
  'pleaseSelectOperatorAuth': '権限選択',
  'selectOperatorBand': '所属バンド設定',
  'pleaseSelectOperatorBand': '所属バンドを選択してください',
  'pleaseFillName': 'お名前をご入力ください',
  'pleasePutValidEmail': 'Eメール形式を合わせてください',
  'pleasePutPhoneNumber': '番号をご入力ください',
  'pleasePutPassword': 'パスワードをご入力ください',
  'confirmPassword': 'パスワード確認',
  'pleaseConfirmPassword': 'パスワード再入力',
  'passwordNotMatch' : 'パースワードが正しくありません',
  'changePw': 'パスワード修正',
  'deleteSuccessful' : '正常に削除されました',
  'deleteFail': '削除できませんでした',
  'deleteAccount': 'アカウント削除',
  'deleteAccount?': 'アカウントを削除しますか？（確認）',

  //appinfo
  'appInfoList': 'アプリ情報リスト',
  'appKey': 'App Key',
  'appName': 'App 名前',
  'appVersion': 'App Version',
  'addAppInfo': '情報追加',
  'appService': 'サービスの可否',

  //riderListPage
  'riderList': 'ライダーリスト',
  'searchRider': 'ライダー検索',
  'pleasePutNamePhoneNumberID': 'お名前/携帯番号/IDをご入力ください',
  'riderId': 'ライダー ID',
  'usingPass': '使用パス',
  'registerAt': '加入日',

  //riderSubscriptionPage
  'purchaseDate': '購入日',
  'nextPurchaseDate': '次回お支払日',
  'subscriptionYn': '利用登録有無',
  'subscriptionCancel': '利用登録キャンセル',
  'subscriptionProgress': '利用登録中',

  //purchaseList
  'purchaseListSearchDownload': '購入履歴検索・ダウンロード',
  'purchaseCanceled': '取り消されました',

  //unpaid
  'unpaid': '未決済履歴',
  'pay': '決済する',

  //scooter-riding-history
  'scooterRidingHistory': 'スクーター利用内訳',
  'pleasePutNameSIMEI': 'お名前 / SIMEIをご入力ください',

  //expired-ride
  'expiredRide': '満了した利用内訳',
};