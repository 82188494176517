import React from 'react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { DEFAULT_TABLE_SIZE } from '../../../../constants';
import { columnMaker, columnRenderer } from '../../../../utils/componentUtils';
import NameALink from '../../../../common/component/NameALink';
import { parsePhoneNumber } from '../../../../utils/organizeData';

import { RiderLendConsultDto } from '../../rental.interface';

interface Props {
  filteredConsults: RiderLendConsultDto[];
  setIsDetailModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedConsult: React.Dispatch<React.SetStateAction<RiderLendConsultDto | null>>;
}

function LeaseRentalConsultTable({ filteredConsults, setIsDetailModalVisible, setSelectedConsult }: Props) {

  const columns: ColumnsType<RiderLendConsultDto> = [
    columnMaker('consultId', '상담ID', columnRenderer((row, idx) => (
      <Button type={'link'} onClick={() => {
        setSelectedConsult(row);
        setIsDetailModalVisible(true);
      }}>{row.consultId}</Button>
    ))),
    {
      title: '고객',
      children: [
        columnMaker('riderId', '라이더ID', columnRenderer((row, idx) => row.riderId)),
        columnMaker('clientName', '고객 이름', columnRenderer((row, idx) => (
          <NameALink urlVal={row.riderId} textVal={row.clientName} />))),
        columnMaker('clientPhoneNo', '핸드폰번호', columnRenderer((row, idx) => parsePhoneNumber(row.clientPhoneNo), true)),
        columnMaker('contactTime', '연락가능시간', columnRenderer((row, idx) => row.contactTime)),
        columnMaker('contractType', '계약형태', columnRenderer((row, idx) => row.contractType, true)),
      ]
    },
    {
      title: '상담',
      children: [
        columnMaker('consultant', '상담자', columnRenderer((row, idx) => row.consultant ?? '-', true)),
        columnMaker('consultAt', '상담일시', columnRenderer((row, idx) => row.consultAt ?? '-')),
        columnMaker('consultStatus', '상담현황', columnRenderer((row, idx) => row.consultStatus))
      ]
    }
  ];

  return (
    <Table
      bordered
      size={'middle'}
      columns={columns}
      dataSource={filteredConsults.map((e, i) => ({ key: i, ...e }))}
      pagination={{
        defaultPageSize: DEFAULT_TABLE_SIZE
      }}
    />
  );
}

export default LeaseRentalConsultTable;