import React from 'react';
import {Button, Table, Typography} from 'antd';
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";
import {ScooterLeaseRentalDto} from "../rental.interface";
import {getMobilityLendTypeTag, parseDate, parseDateTime, parseMobilityType} from "../../../utils/organizeData";
import NameALink from "../../../common/component/NameALink";
import moment from "moment";
import {
  DollarCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  SyncOutlined,
  EditOutlined
} from "@ant-design/icons";
import {
  pauseLeaseRentalRiderPass,
  putLeaseRentalPurchase,
  recoverLeaseRentalRiderPass,
  returnLeaseRental
} from "../leaseRental.service";
import {MobilityType} from "../../mobility/mobility.interface";
import {DEFAULT_TABLE_SIZE} from "../../../constants";

const {Link, Paragraph} = Typography;

interface Props {
  tabVal: MobilityType,
  setSelectedLeaseRental: React.Dispatch<React.SetStateAction<ScooterLeaseRentalDto | null>>,
  setIsLeaseRentalDetailModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLeaseRentalEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  leaseRentals: ScooterLeaseRentalDto[],
}

function LeaseRentalTable(
  {
    tabVal,
    setSelectedLeaseRental,
    setIsLeaseRentalDetailModalVisible,
    setIsLeaseRentalEditModalVisible,
    leaseRentals,
  }: Props
) {

  const columns = [
    columnMaker<ScooterLeaseRentalDto>('contractName', '계약명', columnRenderer(data => {
      return <Link onClick={() => {
        setSelectedLeaseRental(data);
        setIsLeaseRentalDetailModalVisible(true);
      }}>{data.contractName}</Link>
    })),
    columnMaker<ScooterLeaseRentalDto>('lendType', '리스/렌탈', columnRenderer(data => getMobilityLendTypeTag(data.mobilityLendType))),
    columnMaker<ScooterLeaseRentalDto>('riderId', '라이더ID', columnRenderer(data => (<NameALink urlVal={data.rider.riderId} textVal={data.rider.riderId}/>))),
    columnMaker<ScooterLeaseRentalDto>('riderName', '라이더명', columnRenderer(data => (
      <NameALink urlVal={data.rider.riderId} textVal={data.rider.riderName}/>
    ))),
    columnMaker<ScooterLeaseRentalDto>('simei', 'SIMEI', columnRenderer(data => <Paragraph copyable={true} style={{margin: 0}}>{data.mobility.shortImei}</Paragraph>)),
    columnMaker<ScooterLeaseRentalDto>('nextAt', '다음결제일', columnRenderer(data => {
      const nowDate = moment().format("YYYY-MM-DD 23:59:59").toString();
      const isLate = new Date(data.nextAt).getTime() <= new Date(nowDate).getTime();
      return <span style={{color: isLate ? 'red' : 'black'}}>{parseDate(data.nextAt)}</span>
    })),
    columnMaker<ScooterLeaseRentalDto>('returnAt', '반환일', columnRenderer(data => parseDate(data.returnAt))),
    columnMaker<ScooterLeaseRentalDto>('monthlyInstallment', '결제/총 (개월)', columnRenderer(data => `${data.monthlyInstallment - data.residualMonthlyInstallment} / ${data.monthlyInstallment}`)),
    columnMaker<ScooterLeaseRentalDto>('applyEndAt', '이용권만료일', columnRenderer(data => {
      return data.pass ? parseDateTime(data.pass.applyEndAt) : '반환 또는 이용권 중지'
    })),
    columnMaker<ScooterLeaseRentalDto>('resumePass', '이용권복구', columnRenderer(data => (
      <Button
        icon={<PlayCircleOutlined/>}
        onClick={() => recoverLeaseRentalRiderPass(data.rider.riderId, data.pass.passId, tabVal)
          .then(e => {
            window.alert("복구되었습니다");
            window.location.reload();
          })}
      />
    ))),
    columnMaker<ScooterLeaseRentalDto>('endPass', '이용권정지', columnRenderer(data => (
      <Button
        icon={<PauseCircleOutlined/>}
        onClick={() => pauseLeaseRentalRiderPass(
          data.rider.riderId,
          data.pass.passId,
          tabVal
        ).then(e => {
          window.alert("정지되었습니다");
          window.location.reload();
        })}
      />
    ))),
    columnMaker<ScooterLeaseRentalDto>('purchase', '결제', columnRenderer(data => (
      <Button
        icon={<DollarCircleOutlined/>}
        onClick={() => {
          putLeaseRentalPurchase({riderId: data.rider.riderId}, tabVal)
            .then(e => {
              alert('결제처리 되었습니다');
              window.location.reload()
            })
            .catch(err => {
              alert('이미 종료된 계약입니다');
              window.location.reload();
            })
        }}
      />
    ))),
    columnMaker<ScooterLeaseRentalDto>('edit', '수정', columnRenderer(data => (
      <Button
        icon={<EditOutlined/>}
        onClick={() => {
          setSelectedLeaseRental(data);
          setIsLeaseRentalEditModalVisible(true);
        }}
      />
    ))),
    columnMaker<ScooterLeaseRentalDto>('return', '반환', columnRenderer(data => (
      <Button
        icon={<SyncOutlined/>}
        onClick={() => {
          if (window.confirm("정말 반환 하시겠습니까?")) {
            returnLeaseRental(data.rider.riderId, tabVal).then(e => {
              alert('반환 되었습니다');
              window.location.reload();
            });
          }
        }}
      />
    ))),
  ]

  return (
    <Table
      columns={columns}
      dataSource={leaseRentals.map((e, i) => ({key: i, ...e}))}
      pagination={{
        defaultPageSize: DEFAULT_TABLE_SIZE
      }}
    />
  )
}

export default LeaseRentalTable;