import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { getBands } from '../../domain/band/band.service';
import { BandSimpleDto } from '../../domain/band/band.interface';

interface Prop {
  selectedBand: string,
  setSelectedBand: React.Dispatch<React.SetStateAction<string>>
  isAll?: boolean
}

const BandOptionRadio = ({ selectedBand, setSelectedBand, isAll = true }: Prop) => {
  const [bands, setBands] = useState<BandSimpleDto[]>([]);

  useEffect(() => {
    getBands().then(setBands);
  }, []);

  return (
    <Select
      showSearch style={{ width: '100%' }}
      value={selectedBand}
      filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
      onChange={(value: any) => setSelectedBand(value)}
    >
      {isAll ? <Select.Option value={'0'}>전체</Select.Option> : null}
      {bands && bands
        .map(band => (
          <Select.Option key={band.bandId} value={String(band.bandId)}>{band.bandName}</Select.Option>
        ))}
    </Select>
  );
};

export default BandOptionRadio;
