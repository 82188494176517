import React, {useState} from 'react';
import {Modal, Tabs, Input, Row, Col, DatePicker} from 'antd';
import {MopedLeaseRentalDto, ScooterLeaseRentalDto} from "../rental.interface";
import {MobilityType} from "../../mobility/mobility.interface";
import {putLeaseRentalMemo, putLeaseRentalPenalty, putLeaseRentalPeriod} from "../leaseRental.service";
import {parseDate} from "../../../utils/organizeData";
import moment, {Moment} from "moment";

interface Props {
  tabVal: MobilityType,
  selectedLeaseRental: ScooterLeaseRentalDto | MopedLeaseRentalDto | null,
  setSelectedLeaseRental: React.Dispatch<React.SetStateAction<ScooterLeaseRentalDto | MopedLeaseRentalDto | null>>,
  isLeaseRentalEditModalVisible: boolean,
  setIsLeaseRentalEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const {TabPane} = Tabs;
const {TextArea} = Input;

type EditTabName = 'DATE' | 'PENALTY' | 'MEMO';

function EditLeaseContractModal(props: Props) {

  const [tabVal2, setTabVal2] = useState<EditTabName>("DATE");
  const [newPenalty, setNewPenalty] = useState('');
  const [newStartDate, setNewStartDate] = useState<Moment | null>(moment());
  const [newEndDate, setNewEndDate] = useState<Moment | null>(moment());
  const [newMemo, setNewMemo] = useState(
    props.selectedLeaseRental
      ? props.selectedLeaseRental.memo
      : ''
  );

  const handleOk = () => {
    if (window.confirm('정말 수정하시겠습니까?') && props.selectedLeaseRental) {
      switch (tabVal2) {
        case 'DATE' :
          putLeaseRentalPeriod(props.selectedLeaseRental.rider.riderId, {
            startAt: newStartDate ? newStartDate.format('YYYY-MM-DDT00:00:00') : '-',
            endAt: newEndDate ? newEndDate.format('YYYY-MM-DDT23:59:59') : '-'
          }, props.tabVal).then(e => {
            window.alert('수정되었습니다');
            window.location.reload();
          })
          break;
        case 'PENALTY' :
          putLeaseRentalPenalty(
            props.selectedLeaseRental.rider.riderId,
            {penaltyAmount: newPenalty.replace(/[^0-9]/g, "")}, props.tabVal)
            .then(e => {
              window.alert("수정되었습니다");
              window.location.reload();
            })
          break;
        case 'MEMO' :
          putLeaseRentalMemo(
            props.selectedLeaseRental.rider.riderId, {
              memo: newMemo
            }, props.tabVal).then(e => {
            window.alert("수정되었습니다");
            window.location.reload()
          });
          break;
        default:
          break;
      }
    }
  }

  return (
    <Modal
      visible={props.isLeaseRentalEditModalVisible}
      closable={false}
      width={'80%'}
      okText={'제출'}
      cancelText={'취소'}
      onOk={handleOk}
      onCancel={() => {
        props.setSelectedLeaseRental(null);
        props.setIsLeaseRentalEditModalVisible(false);
      }}
    >
      <Tabs defaultActiveKey="DATE" onChange={e => setTabVal2(e as EditTabName)}>
        <TabPane tab="시작/종료일" key="DATE">
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Input
                addonBefore={'시작일'}
                disabled={true}
                value={props.selectedLeaseRental ? parseDate(props.selectedLeaseRental.startAt) : '-'}
              />
            </Col>
            <Col span={12}>
              <Input
                addonBefore={'종료일'}
                disabled={true}
                value={props.selectedLeaseRental ? parseDate(props.selectedLeaseRental.endAt) : '-'}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                style={{width: '100%'}}
                showTime={false}
                onChange={(e) => setNewStartDate(e)}
              />
            </Col>
            <Col span={12}>
              <DatePicker
                style={{width: '100%'}}
                showTime={false}
                onChange={(e) => setNewEndDate(e)}
              />
            </Col>
          </Row>

        </TabPane>
        <TabPane tab="위약금" key="PENALTY">
          <Input
            addonBefore={'위약금'}
            disabled={true}
            value={props.selectedLeaseRental?.penaltyAmount}
            style={{marginBottom: '12px'}}
          />
          <Input
            addonBefore={'위약금'}
            onChange={e => setNewPenalty(e.target.value)}
            value={newPenalty}
            placeholder={'새로운 위약금을 입력해주세요'}
          />
        </TabPane>

        <TabPane tab="메모" key="MEMO">
          <Input
            addonBefore={'메모'}
            disabled={true}
            value={props.selectedLeaseRental?.memo}
            style={{marginBottom: '12px'}}
          />
          <TextArea
            onChange={e => setNewMemo(e.target.value)}
            value={newMemo}
          />
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export default EditLeaseContractModal;
