import React, {FC} from 'react';
import {Col, Row, Radio} from 'antd';
import BaseModal from '../../../common/component/BaseModal';
import {RiderCardDto} from "../rider.interface";
import {editPrimaryCard} from "../rider.service";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  riderId: string;
  riderCards: RiderCardDto[] | undefined;
}

const EditPrimaryCardModal: FC<Props> = ({isVisible, setIsVisible, riderId, riderCards}) => {

  const riderCardCards = riderCards ? riderCards.map((e, i) => (
    <Col span={24 / riderCards.length} key={i}>
      <Radio.Button
        style={{width: '100%', textAlign: 'center'}}
        value={e.riderPaymentMethodId}
        checked={e.isPrimary}
      >{`${e.cardName} / ${e.cardNumber ?? '-'}`}</Radio.Button>
    </Col>
  )) : [];

  const submitModal = () => {

  }

  const [primaryCard] = riderCards ? riderCards.filter(e => e.isPrimary).map(l => l.riderPaymentMethodId) : [];

  return (
    <>
      <BaseModal
        isVisible={isVisible}
        headerTitle={'주결제 카드 변경'}
        handleOk={submitModal}
        handleCancel={() => setIsVisible(false)}
        width={'70%'}
      >
        <Radio.Group
          style={{width: '100%'}}
          value={primaryCard}
          onChange={(e) => {
            editPrimaryCard(riderId, e.target.value).then(e => window.location.reload());
          }}
        >
          <Row
            gutter={[8, 8]}
          >
            {riderCardCards}
          </Row>
        </Radio.Group>
      </BaseModal>
    </>
  );
};

export default EditPrimaryCardModal;
