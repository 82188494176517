import React, {FC, useEffect, useState} from 'react';
import BaseModal from '../../../common/component/BaseModal';
import {Col, DatePicker, Divider, Input, Radio, Row, Select, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import {Classes} from "reactcss";
import {postBanner} from "../banner.service";
import {getBands} from "../../band/band.service";
import {RcFile} from "antd/lib/upload";

const {Option} = Select;
const {RangePicker} = DatePicker
const {Dragger} = Upload;

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

type actionType = "APPIN" | "APPOUT";

const BannerAddModal: FC<Props> = ({isVisible, setIsVisible}) => {

  const [bands, setBands] = useState<{ bandId: number, bandName: string }[]>([])
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerStartDate, setBannerStartDate] = useState('');
  const [bannerEndDate, setBannerEndDate] = useState('');
  const [bannerBands, setBannerBands] = useState<number[]>([]);
  const [bannerButtonText, setBannerButtonText] = useState('');
  const [bannerAction, setBannerAction] = useState<actionType>('APPIN');
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [detailImageFile, setDetailImageFile] = useState<File | null>(null);
  const [actionValue, setActionValue] = useState<string>('');

  const [showBannerImg, setShowBannerImg] = useState<string>('');
  const [showBannerDetailImg, setShowBannerDetailImg] = useState<string>('');

  useEffect(() => {
    getBands().then(e => {
      setBands(e.map(l => {
        return {
          bandId: l.bandId,
          bandName: l.bandName
        }
      }));
    })
  }, [])

  const handleBannerImage = {
    beforeUpload: (file: RcFile) => {
      setImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          setShowBannerImg(reader.result as string);
        }
      };
    },
    showUploadList: false,
  };
  const handleBannerDetailImage = {
    beforeUpload: (file: RcFile) => {
      setDetailImageFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          setShowBannerDetailImg(reader.result as string);
        }
      };
    },
    showUploadList: false
  };

  const handleSubmitButton = () => {

    if (
      bannerTitle === ''
      || bannerStartDate === ''
      || bannerEndDate === ''
      || bannerBands.length === 0
      || imageFile == null
    ) {
      alert("입력값을 확인해주세요")
    } else {
      const formData = new FormData();

      formData.append("image", imageFile!);
      if (!!detailImageFile || detailImageFile != null) formData.append("detailImage", detailImageFile!);
      formData.append("title", bannerTitle);
      formData.append("displayStartAt", bannerStartDate);
      formData.append("displayEndAt", bannerEndDate,);
      formData.append("displayOrder", '1000');
      formData.append("actionTitle", bannerButtonText);
      formData.append("actionType", bannerAction);
      formData.append("actionValue", actionValue);
      formData.append("bandIds", bannerBands.join(','));
      formData.append("displayBackgroundColor", '#FFF');

      console.log(formData.entries());

      postBanner(formData).then(e => {
        alert("성공적으로 등록되었습니다");
        window.location.reload();
      }).catch(() => {
        alert("등록에 실패하였습니다");
        // window.location.rel/oad();
      })
    }
  }

  const bandOptions = bands.map((e, i) => {
    return (
      <Option key={'band-options-' + i} value={e.bandId}>{e.bandName}</Option>
    )
  })

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={handleSubmitButton}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'배너 추가'}
      width={'80%'}
    >
      <Row
        gutter={[16, 16]}
        justify={'center'}
        align={'middle'}
      >
        <Col span={24}>
          <Input
            onChange={(e) => setBannerTitle(e.target.value)}
            placeholder={"배너 제목을 입력해주세요 (앱에는 노출되지 않습니다)"}
          />
        </Col>
        <Col span={24}>
          <RangePicker
            showTime
            placeholder={["배너 노출 시작", "배너 노출 종료"]}
            style={{width: '100%'}}
            onChange={e => {
              if (e && e[0] && e[1]) {
                const startDateString = e[0].format('YYYY-MM-DDTHH:mm:ss')
                const endDateString = e[1].format('YYYY-MM-DDTHH:mm:ss');
                setBannerStartDate(startDateString);
                setBannerEndDate(endDateString);
              }
            }}
          />
        </Col>
        <Col span={24}>
          <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            placeholder="배너를 노출할 밴드를 선택해주세요"
            onChange={(e: number[]) => setBannerBands(e)}
          >
            {bandOptions}
          </Select>
        </Col>
      </Row>
      <Divider plain>배너 이미지</Divider>
      <Row gutter={[8, 0]}>
        <Col span={6}>
          <div
            style={{
              height: '100%',
              border: '1px solid lightgray',
              backgroundColor: '#f2f2f2'
            }}
          >
            {showBannerImg ? <img src={showBannerImg} style={{width: '100%'}}/> : <></>}
          </div>
        </Col>
        <Col span={18}>
          <Dragger {...handleBannerImage}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined/>
            </p>
            <p className="ant-upload-text">클릭하여 배너 이미지를 추가해주세요</p>
          </Dragger>
        </Col>
      </Row>
      <Divider plain>배너 상세 이미지</Divider>
      <Row gutter={[8, 0]}>
        <Col span={6}>
          <div
            style={{
              padding: '5px',
              height: '100%',
              border: '1px solid lightgray',
              backgroundColor: '#FFF'
            }}
          >
            {showBannerDetailImg ? <img src={showBannerDetailImg} style={{width: '100%'}}/> : <></>}
          </div>
        </Col>
        <Col span={18}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Dragger {...handleBannerDetailImage} style={{width: '100%'}}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined/>
                </p>
                <p className="ant-upload-text">클릭하여 상세 이미지를 추가해주세요</p>
              </Dragger>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider plain>배너 버튼</Divider>
      <Row gutter={[8, 0]}>
        <Col span={6}>
          <div
            style={{
              display: 'flex',
              height: '100%',
              border: '1px solid lightgray',
              backgroundColor: '#f2f2f2',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {bannerButtonText}
          </div>
        </Col>
        <Col span={18}>
          <Row align={'middle'} justify={'center'} gutter={[4, 4]}>
            <Col span={24}>
              <Input
                placeholder={"버튼문구를 입력해주세요"}
                onChange={(e) => setBannerButtonText(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Radio.Group
                style={{width: '100%'}}
                onChange={e => setBannerAction(e.target.value)}
              >
                <Radio.Button style={{width: '50%', textAlign: 'center'}} value={"APPIN"}>앱 내
                  이동</Radio.Button>
                <Radio.Button style={{width: '50%', textAlign: 'center'}} value={"APPOUT"}>앱 외
                  이동</Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={24}>
              <Input
                placeholder={"버튼 클릭시 이동할 주소를 입력해주세요"}
                onChange={(e) => setActionValue(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </BaseModal>
  )
    ;
};

export default BannerAddModal;