import { LocalAdminApi } from '../../services';
import { HistoryDto } from '../ride/ride.interface';
import { PurchaseCreateBody, PurchaseDto } from '../purchase/purchase.interface';
import { PassCreateBody } from '../pass/pass.interface';
import { LicenseCreateDto, RiderDetailDto, RiderList, RiderPenaltyHistory, RiderTableList } from './rider.interface';
import { SimpleSubscription } from '../subscription/subscription.interface';
import { Pagination } from '../../common/url/pagination';
import { buildUrl } from '../../common/url/utils';

export async function getRiders(): Promise<RiderTableList[]> {
  return await LocalAdminApi.get<RiderTableList[]>(`/riders`).then((resp) => resp.data);
}

export async function getRidersPagination(pagination: Pagination) {
  return (await LocalAdminApi.get(buildUrl('/riders', pagination.getParams()))).data;
}

export async function getRiderDetail(riderId: string) {
  return await LocalAdminApi.get<RiderDetailDto>(`/riders/${riderId}`).then((resp) => resp.data);
}

export async function postRiderPass(riderId: string) {
  return await LocalAdminApi.post<RiderList>(`/riders/${riderId}/purchases`).then((resp) => resp.data);
}

export async function putRider(riderId: number, content: { band: { id: string } }) {
  return await LocalAdminApi.put(`/riders/${riderId}`, content).then((resp) => resp.data);
}

export async function putRiderPhoneNumber(riderId: number, content: { phoneNo: string }) {
  return await LocalAdminApi.put(`/riders/${riderId}/phoneNo`, content).then((resp) => resp.data);
}

// 라이더 스쿠터 이용권 등록
export async function postRegisterRiderPass(content: PurchaseCreateBody) {
  // Todo : Change this
  return await LocalAdminApi.post(`/purchases`, content).then((resp) => resp.data);
}

// 라이더 스쿠터 무료 이용권 등록
export async function postRegisterFreeRiderPass(riderId: string, content: PassCreateBody) {
  return await LocalAdminApi.post(`/riders/${riderId}/passes`, content).then((resp) => resp.data);
}

// 라이더 면허 정보 삭제
export async function deleteRiderLicense(riderId: string) {
  return await LocalAdminApi.delete(`/riders/${riderId}/license`).then((resp) => resp.data);
}

export async function putRiderWithdrawal(riderId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/withdraw`).then((resp) => resp.data);
}

// 라이더 상품결제 내역
export async function getRiderSubscription(riderId: string) {
  return await LocalAdminApi.get<SimpleSubscription[]>(`/riders/${riderId}/subscriptions`).then((resp) => resp.data);
}

// 라이더 결제이력
export async function getRiderPurchase(riderId: string) {
  return await LocalAdminApi.get<PurchaseDto[]>(`/purchases?riderId=${riderId}`).then((resp) => resp.data);
}

export async function getRiderMopedRideHistory(riderId: string, page: number = 0, size: number = 20): Promise<{
  page: number,
  totalPage: number,
  totalItem: number,
  items: HistoryDto[]
}> {
  return await LocalAdminApi.get(`/riders/${riderId}/rides?mobility=MOPED&page=${page}&size=${size}`).then((resp) => resp.data);
}

export async function getRiderPenaltyHistory(riderId: string) {
  return await LocalAdminApi.get<RiderPenaltyHistory[]>(`/riders/${riderId}/penalties`).then((resp) => resp.data);
}

export async function refundRiderPenalty(riderId: string, purchaseId: string, message: string) {
  return await LocalAdminApi.post(`/riders/${riderId}/purchases/${purchaseId}/cancel?ptype=Penalty`, {
    comment: message
  });
}

export async function cancelPenaltyHistory(riderId: string, penaltyId: string) {
  return await LocalAdminApi.delete(`/riders/${riderId}/penalties/${penaltyId}/cancel`);
}

///riders/:id/band/:bandId
export async function putRiderBand(riderId: number, bandId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/bands/${bandId}`);
}

export async function putRiderUnsubscribe(riderId: string, subscriptionId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/subscriptions/${subscriptionId}/unsubscribe`);
}

export async function putRiderTerminateSubscription(riderId: string, subscriptionId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/subscriptions/${subscriptionId}/terminate`);
}

export async function putRiderRecoverSubscription(riderId: string, subscriptionId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/subscriptions/${subscriptionId}/recover`);
}

export async function terminatePass(riderId: string, passId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/passes/${passId}/terminate`);
}

export async function registerLicense(riderId: string, body: LicenseCreateDto) {
  return await LocalAdminApi.post(`/riders/${riderId}/license`, body);
}

//riders/:id/payments/:paymentId
export async function editPrimaryCard(riderId: string, paymentId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/payments/${paymentId}`,);
}

export async function getRiderNonpayment(riderId: string) {
  return await LocalAdminApi.get(`/riders/${riderId}/nonpayments`).then(e => e.data);
}

export async function terminateDevice(riderId: number) {
  return await LocalAdminApi.put(`/riders/${riderId}/device`).then(e => e.data);
}


