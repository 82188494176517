import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getBandTag, parseDateTime } from '../../../../utils/organizeData';
import { deleteBanner, getBannerList } from '../../banner.service';
import { SimpleBanner } from '../../banner.interface';
import BannerTable from '../BannerTable';
import BannerAddModal from '../BannerAddModal';
import BannerOrderEditModal from '../BannerOrderEditModal';
import BannerDurationEditModal from '../BannerDurationEditModal';

/* == antd == */
import { Button } from 'antd';
import { AccountContext, SetLoadingContext } from '../../../../context';
import BannerDetailViewModal from '../BannerDetailViewModal';


export function BannerTableTab() {

  const admin = useContext(AccountContext);
  const history = useHistory();

  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditOrderModalVisible, setIsEditOrderModalVisible] = useState(false);
  const [isEditDurationModalVisible, setIsEditDurationModalVisible] = useState(false);

  const [selectedOrderEditBannerId, setSelectedOrderEditBannerId] = useState<number | null>(null);
  const [selectedDurationEditBanner, setSelectedDurationEditBanner] = useState<SimpleBanner | null>(null);
  const [selectedBannerIdx, setSelectedBannerIdx] = useState<number | null>(null);
  const [selectedViewBanner, setSelectedViewBanner] = useState<SimpleBanner | null>(null);
  const [banners, setBanners] = useState<SimpleBanner[]>([]);

  // add member modal

  useEffect(() => {
    bootstrap();
  }, [admin]);

  const setLoading = useContext(SetLoadingContext)!;

  async function bootstrap() {
    if (admin) {
      setLoading(true);
      await getBannerList().then((res) => setBanners(res.sort((a, b) => a.displayOrder - b.displayOrder))).then(() => setLoading(false));
    }
  }

  const goDetailPage = (id: number) => () => {
    history.push(`/riders/${id}`);
  };

  const handleDeleteBanner = (bannerId: number) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      deleteBanner(bannerId)
        .then(e => {
          alert('성공적으로 삭제하였습니다');
          window.location.reload();
        })
        .catch((err) => {
          alert('배너 삭제에 실패했습니다');
          window.location.reload();
          console.log(err);
        });
    }
  };

  return (
    <>
      <div>
        <Button
          onClick={() => setIsAddModalVisible(true)}
        >배너 추가하기</Button>
      </div>
      <BannerTable
        banners={banners}
        handleDeleteBanner={handleDeleteBanner}
        isViewModalVisible={isViewModalVisible}
        setIsViewModalVisible={setIsViewModalVisible}
        setSelectedViewBanner={setSelectedViewBanner}
        setSelectedBannerIdx={setSelectedBannerIdx}
        isEditOrderModalVisible={isEditOrderModalVisible}
        setIsEditOrderModalVisible={setIsEditOrderModalVisible}
        setSelectedOrderEditBannerId={setSelectedOrderEditBannerId}
        setIsEditDurationModalVisible={setIsEditDurationModalVisible}
        setSelectedDurationEditBanner={setSelectedDurationEditBanner}
      />
      <BannerAddModal
        isVisible={isAddModalVisible}
        setIsVisible={setIsAddModalVisible}
      />
      <BannerDetailViewModal
        isVisible={isViewModalVisible}
        setIsVisible={setIsViewModalVisible}
        selectedViewBanner={selectedViewBanner}
      />
      <BannerOrderEditModal
        isVisible={isEditOrderModalVisible}
        setIsVisible={setIsEditOrderModalVisible}
        selectedOrderEditBannerId={selectedOrderEditBannerId}
      />
      <BannerDurationEditModal
        isVisible={isEditDurationModalVisible}
        setIsVisible={setIsEditDurationModalVisible}
        selectedDurationEditBanner={selectedDurationEditBanner}
      />
    </>
  );
}
