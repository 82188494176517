import React from 'react';
import { Card, Col, Row, Input } from 'antd';
import { SearchConditions } from '../../pages/LeaseRentalConsultList';
import LeaseRentalConsultRangePicker from './LeaseRentalConsultRangePicker';

const { Search } = Input;

interface Props {
  setSearchConditions: React.Dispatch<React.SetStateAction<SearchConditions>>;
}

function LeaseRentalConsultSearchCard({ setSearchConditions }: Props) {

  return (
    <>
      <Row gutter={[8, 8]} justify={'center'}>
        <Col flex={1}>
          <Card
            className={'floating-card'}
            title={'주문자 검색'}
          >
            <Search
              placeholder={'라이더 이름, 핸드폰 번호'}
              onChange={(e) => setSearchConditions(prev => ({ ...prev, riderSearch: e.target.value }))}
            />
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            className={'floating-card'}
            title={'계약검색'}
          >
            <Search
              placeholder={'계약형태'}
              onChange={(e) => setSearchConditions(prev => ({ ...prev, contractSearch: e.target.value }))}
            />
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            className={'floating-card'}
            title={'상담검색'}
          >
            <Row gutter={[8, 8]} justify={'center'}>
              <Col flex={1}>
                <Search
                  placeholder={'상담자, 상담현황'}
                  onChange={(e) => setSearchConditions(prev => ({ ...prev, consultSearch: e.target.value }))}
                />
              </Col>
              <Col flex={1}>
                <LeaseRentalConsultRangePicker setSearchConditions={setSearchConditions} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LeaseRentalConsultSearchCard;