import React, {FC, useEffect, useState} from 'react';
import BaseModal from '../../../common/component/BaseModal';
import {Col, DatePicker, Form, Row, Select} from 'antd';
import moment from 'moment';
import {postSubscription} from "../subscription.service";
import {getAllBandProducts} from "../../product/product.service";
import {ProductBandDto} from "../../product/product.interface";
import {AddSubscriptionForm} from "../subscription.interface";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  riderId: string
}

const AddSubscriptionModal: FC<Props> = ({isVisible, setIsVisible, riderId}) => {

  const {Option} = Select;
  const [formValues, setFormValues] = useState<AddSubscriptionForm | null>(null);
  const [formInstance] = Form.useForm();
  const [bandSubscriptionProduct, setBandSubscriptionProduct] = useState<ProductBandDto[]>([])

  useEffect(() => {
    getAllBandProducts().then(e => setBandSubscriptionProduct(e.filter(l => l.baseProduct.productType === 'SUBSCRIPTION')));
  }, []);

  const selectBandProductOptions = bandSubscriptionProduct.map((e, i) => {
    return (
      <Option
        value={e.baseProduct.productId}
        key={'band-product-' + i}
      >
        {e.baseProduct.title + ' / ' + e.baseProduct.productSubType + ' / ' + e.baseProduct.price}
      </Option>)
  });

  const onSubmitForm = async (formValues: AddSubscriptionForm) => {

    if (window.confirm("정말 생성하시겠습니까?")) {

      const newSubscription: AddSubscriptionForm = {
        riderId: riderId,
        productId: formValues.productId,
        startAt: moment(formValues.startAt).format("YYYY-MM-DDTHH:mm:SS")
      }

      try {
        await postSubscription(newSubscription).then(e => {
          alert("성공적으로 생성 되었습니다");
          window.location.reload();
        });
      } catch (err) {
        alert("생성에 실패하였습니다 : " + err);
        window.location.reload();
      }
    }
  }

  const submitModal = async () => {
    if (formValues) { // 변경이 있는 경우
      formInstance.submit();
    } else { // 변경이 없는 경우
      setIsVisible(false);
    }
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label}은 숫자만 입력해주세요',
    },
  };

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={submitModal}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'구독권 부여'}
      width={'70%'}
    >
      <Form
        name="subscriptionForm"
        onValuesChange={(changedVal, allVal) => {
          setFormValues(allVal);
        }}
        validateMessages={validateMessages}
        form={formInstance}
        onFinish={onSubmitForm}
      >
        <Row gutter={[16, 16]}
             justify={'center'}
        >
          <Col span={24}>
            <Form.Item
              label="구독 상품"
              name="productId"
              rules={[{required: true}]}
            >
              <Select
                style={{width: '100%'}}
                placeholder="구독할 상품을 선택해주세요"
              >
                {selectBandProductOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="구독 시작일"
              name="startAt"
              rules={[{required: true}]}
            >
              <DatePicker
                style={{width: '100%'}}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
};

export default AddSubscriptionModal;
