import { LocalAdminApi } from '../../services';
import {BandCreateDto, BandDto} from './band.interface';

// 모든밴드 가져오기
export async function getBands() {
  return LocalAdminApi.get<BandDto[]>('/bands').then((resp) => resp.data);
}

// 밴드상품 가져오기

// 밴드 추가하기
export async function addBand(body: BandCreateDto) {
  return LocalAdminApi.post('/bands', body).then((resp) => resp.data);
}

// 밴드 삭제하기
export async function deleteBand(bandId: number) {
  return LocalAdminApi.delete(`/bands/${bandId}`);
}

// 특정 밴드 가져오기
export async function getBand(id: number) {
  return LocalAdminApi.get<BandDto>(`/bands/${id}`).then((resp) => resp.data);
}

// 특정 밴드 수정하기
export async function updateBandLicenseCheckStatus(id: number, scooterStatus: boolean, mopedStatus: boolean) {
  ///band/:id/license?scooter={true | false}&moped={true | false}
  return LocalAdminApi.put(`/bands/${id}/license?scooter=${scooterStatus}&moped=${mopedStatus}`);
}

export async function putBandDisplayYn(bandId: number, displayYn: boolean) {
  return LocalAdminApi.put(`/bands/${bandId}/display`, {
      displayYn: displayYn
  });
}

export async function putBandProperty(bandId: number, domain: string, data: any) {
  return LocalAdminApi.put(`/bands/${bandId}/${domain}`, data);
}
