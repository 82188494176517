import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import {
  AppstoreOutlined, AuditOutlined,
  DingdingOutlined, DollarOutlined,
  LineChartOutlined,
  RocketOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';

import { useHistory, useLocation } from 'react-router';
import { Routes } from '../../constants';
import React, { useContext } from 'react';
import { AccountContext } from '../../context';
import { useTranslation } from 'react-i18next';

export function SideBarComponent() {
  const { t } = useTranslation();
  const {
    RIDERS,
    BAND_PRODUCT,
    PURCHASE_HISTORY,
    SUBSCRIPTION_HISTORY,
    ACCOUNTS,
    MASTER_PRODUCT,
    BAND,
    DASHBOARD,
    DASHBOARD_RIDER,
    SCOOTER_LIST,
    SCOOTER_HISTORY,
    BANNER,
    APP_INFO,
    MOPED_LIST,
    MOPED_HISTORY,
    SCOOTER_EXPIRED_USAGE,
    MOPED_EXPIRED_USAGE,
    MOPED_RENTAL,
    PLACEMENT_MAP,
    PLACEMENT_LIST,
    DASHBOARD_PLACEMENT,
    TARIFF_LIST,
    TARIFF_BAND,
    // SCOOTER_NONPAYMENT,
    // MOPED_NONPAYMENT,
    NONPAYMENT,
    MAP,
    LEASE_RENTAL_LIST,
    LEASE_RENTAL_ORDER_LIST,
    LEASE_RENTAL_CONSULT_LIST
  } = Routes;

  const account = useContext(AccountContext)!;
  const { role } = account;
  const isAdmin = role === 'ADMIN';

  const history = useHistory();
  const path = '/' + useLocation().pathname.split('/')[1];

  function goScooters() {
    history.push(SCOOTER_LIST);
  }

  function goRides() {
    history.push(SCOOTER_HISTORY);
  }

  function goRiders() {
    history.push(RIDERS);
  }

  function redirectTo(path: string) {
    return () => {
      history.push(path);
    };
  }

  return (
    <Menu
      defaultOpenKeys={['dashboard']}
      className='side-bar'
      mode='inline'
      selectedKeys={[path]}
      style={{ height: '100%', borderRight: 0 }}
    >
      {isAdmin &&
        <>
          <SubMenu
            key='payment'
            icon={<TeamOutlined />}
            title={t('side-bar-operator')}
          >
            <Menu.Item key={ACCOUNTS} onClick={redirectTo(ACCOUNTS)}>
              {t('side-bar-operator-account')}
            </Menu.Item>
            <Menu.Item key={BAND} onClick={redirectTo(BAND)}>
              {t('side-bar-operator-band')}
            </Menu.Item>
            <Menu.Item key={MASTER_PRODUCT} onClick={redirectTo(MASTER_PRODUCT)}>
              {t('side-bar-operator-master-product')}
            </Menu.Item>
          </SubMenu>
        </>}
      {isAdmin && (
        <SubMenu
          key='dashboard'
          title={t('side-bar-dashboard')}
          icon={<LineChartOutlined />}
        >
          <Menu.Item key={DASHBOARD_RIDER} onClick={redirectTo(DASHBOARD_RIDER)}>
            {t('side-bar-dashboard-signup')}
          </Menu.Item>
          <Menu.Item key={DASHBOARD} onClick={redirectTo(DASHBOARD)}>
            {t('side-bar-dashboard-purchase')}
          </Menu.Item>
          <Menu.Item key={DASHBOARD_PLACEMENT} onClick={redirectTo(DASHBOARD_PLACEMENT)}>
            {t('side-bar-dashboard-placement')}
          </Menu.Item>
        </SubMenu>
      )}

      <SubMenu
        key='rider'
        icon={<UserOutlined />}
        title={t('side-bar-rider')}
      >
        <Menu.Item key={RIDERS} onClick={goRiders}>
          {t('side-bar-rider-list')}
        </Menu.Item>
        <Menu.Item key={SUBSCRIPTION_HISTORY} onClick={redirectTo(SUBSCRIPTION_HISTORY)}>
          {t('side-bar-rider-subscription')}
        </Menu.Item>
        <Menu.Item key={PURCHASE_HISTORY} onClick={redirectTo(PURCHASE_HISTORY)}>
          {t('side-bar-rider-purchase')}
        </Menu.Item>
        <Menu.Item key={NONPAYMENT} onClick={redirectTo(NONPAYMENT)}>
          {t('side-bar-rider-nonpayment')}
        </Menu.Item>
        {/*<Menu.Item key={LEASE_RENTAL_LIST} onClick={redirectTo(LEASE_RENTAL_LIST)}>*/}
        {/*  리스/렌탈*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item key={LEASE_LIST} onClick={redirectTo(LEASE_LIST)}>*/}
        {/*  리스 이력*/}
        {/*</Menu.Item>*/}
      </SubMenu>

      <SubMenu
        key='scooter'
        icon={<DingdingOutlined />}
        title={t('side-bar-scooter')}
      >
        <Menu.Item key={SCOOTER_LIST} onClick={goScooters}>
          {t('side-bar-scooter-list')}
        </Menu.Item>
        <Menu.Item key={SCOOTER_HISTORY} onClick={goRides}>
          {t('side-bar-scooter-ride')}
        </Menu.Item>
        <Menu.Item key={SCOOTER_EXPIRED_USAGE} onClick={redirectTo(SCOOTER_EXPIRED_USAGE)}>
          {t('side-bar-scooter-expired')}
        </Menu.Item>
        {/*<Menu.Item key={SCOOTER_NONPAYMENT} onClick={redirectTo(SCOOTER_NONPAYMENT)}>*/}
        {/*  {t('side-bar-scooter-nonpayment')}*/}
        {/*</Menu.Item>*/}
      </SubMenu>

      <SubMenu
        key='moped'
        icon={<RocketOutlined />}
        title={t('side-bar-moped')}
      >
        <Menu.Item key={MOPED_LIST} onClick={redirectTo(MOPED_LIST)}>
          {t('side-bar-moped-list')}
        </Menu.Item>
        <Menu.Item key={MOPED_HISTORY} onClick={redirectTo(MOPED_HISTORY)}>
          {t('side-bar-moped-ride')}
        </Menu.Item>
        <Menu.Item key={MOPED_EXPIRED_USAGE} onClick={redirectTo(MOPED_EXPIRED_USAGE)}>
          {t('side-bar-moped-expired')}
        </Menu.Item>
        {/*<Menu.Item key={MOPED_NONPAYMENT} onClick={redirectTo(MOPED_NONPAYMENT)}>*/}
        {/*  {t('side-bar-moped-nonpayment')}*/}
        {/*</Menu.Item>*/}
      </SubMenu>

      <SubMenu
        key='placement'
        icon={<ShopOutlined />}
        title={t('side-bar-placement')}
      >
        <Menu.Item key={PLACEMENT_LIST} onClick={redirectTo(PLACEMENT_LIST)}>
          {t('side-bar-placement-list')}
        </Menu.Item>
        <Menu.Item key={PLACEMENT_MAP} onClick={redirectTo(PLACEMENT_MAP)}>
          {t('side-bar-placement-map')}
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key='product'
        icon={<ShoppingOutlined />}
        title={t('side-bar-product')}
      >
        <Menu.Item key={BAND_PRODUCT} onClick={redirectTo(BAND_PRODUCT)}>
          {t('side-bar-product-list')}
        </Menu.Item>
      </SubMenu>

      {account.band.ondemandYn
        ? (
          <SubMenu
            key={'tariff'}
            icon={<DollarOutlined />}
            title={t('side-bar-tariff')}
          >
            <Menu.Item key={TARIFF_LIST} onClick={redirectTo(TARIFF_LIST)}>
              {t('side-bar-tariff-list')}
            </Menu.Item>
            <Menu.Item key={TARIFF_BAND} onClick={redirectTo(TARIFF_BAND)}>
              {t('side-bar-tariff-band')}
            </Menu.Item>
          </SubMenu>
        ) : null}

      {isAdmin && (
        <SubMenu
          key='leaseRental'
          icon={<AuditOutlined />}
          title={'리스/렌탈'}
        >
          <Menu.Item key={LEASE_RENTAL_CONSULT_LIST} onClick={redirectTo(LEASE_RENTAL_CONSULT_LIST)}>
            상담내역
          </Menu.Item>
          <Menu.Item key={LEASE_RENTAL_ORDER_LIST} onClick={redirectTo(LEASE_RENTAL_ORDER_LIST)}>
            주문내역
          </Menu.Item>
          <Menu.Item key={LEASE_RENTAL_LIST} onClick={redirectTo(LEASE_RENTAL_LIST)}>
            계약
          </Menu.Item>
        </SubMenu>
      )}

      {isAdmin ? (
        <>
          <SubMenu
            key='application'
            icon={<AppstoreOutlined />}
            title={t('side-bar-application')}
          >
            <Menu.Item key={APP_INFO} onClick={redirectTo(APP_INFO)}>
              {t('side-bar-application-app')}
            </Menu.Item>
            <Menu.Item key={BANNER} onClick={redirectTo(BANNER)}>
              {t('side-bar-application-banner')}
            </Menu.Item>
          </SubMenu>
        </>
      ) : null}
    </Menu>
  );
}
