import {LocalAdminApi} from '../../services';
import {HistoryDto} from "./ride.interface";
import {PaginationResDto} from "../../common/common.interface";
import {MobilityType} from "../mobility/mobility.interface";

export async function getRidesHistories(): Promise<HistoryDto[]> {
  return await LocalAdminApi.get<HistoryDto[]>(`/rides`).then((resp) => resp.data);
}

export async function getScooterRidesFromRider(riderId: string): Promise<HistoryDto[]> {
  return await LocalAdminApi.get<HistoryDto[]>(`/rides?riderId=${riderId}&mobility=SCOOTER`)
    .then((resp) => resp.data);
}

export async function getMopedRidesFromRider(riderId: string): Promise<HistoryDto[]> {
  return await LocalAdminApi.get<HistoryDto[]>(`/rides?riderId=${riderId}&mobility=MOPED`)
    .then((resp) => resp.data);
}

export async function getScooterRides(page: number, size: number, keyword: string, bandId: string): Promise<PaginationResDto<HistoryDto>> {
  return await LocalAdminApi.get(`/rides?page=${page}&size=${size}&mobility=SCOOTER&keyword=${keyword}&bandId=${bandId}`)
    .then((resp) => resp.data);
}

export async function getMopedRideHistoryPagination(page: number, size: number, keyword: string, bandId: string): Promise<PaginationResDto<HistoryDto>> {
  return await LocalAdminApi.get(`/rides?mobility=MOPED&page=${page}&size=${size}&keyword=${keyword}&bandId=${bandId}`).then((resp) => resp.data);
}

export async function forceEndRide(rideId: number) {
  return await LocalAdminApi.put(`/rides/${rideId}/end`).then((resp) => resp.data);
}

export async function getAllExpiredUsageRides(): Promise<HistoryDto[]> {
  return await LocalAdminApi.get<HistoryDto[]>(`/rides?expired=true`).then((resp) => resp.data)
}

export async function getExpiredMobilityRides(mobilityType: MobilityType): Promise<HistoryDto[]> {
  switch (mobilityType) {
    case "MOPED":
      return await LocalAdminApi.get<HistoryDto[]>(`/rides?expired=true&mobility=${mobilityType}`).then((resp) => resp.data)
    case 'SCOOTER':
      return await LocalAdminApi.get<HistoryDto[]>(`/rides?expired=true&mobility=${mobilityType}`).then((resp) => resp.data)
  }
}

export async function payExpiredAmount(rideId: number) {
  return await LocalAdminApi.put(`/rides/${rideId}/end/expired-pass`).then(resp => resp.data);
}