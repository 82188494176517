import React from 'react';
import CustomPlacementOverlay from '../CustomPlacementOverlay';
import CustomPlacementDefaultOverlay from './CustomPlacementDefaultOverlay';
import * as marker from '../marker_constants';
import { DeployableMobilityType, PlacementDto } from '../../../domain/placement/placement.interface';
import { MarkerImage, MarkerProps, Size } from '../Map';
import { LocationDto } from '../../common.interface';

const lounge = marker.lounge;
const lounge_partner = marker.lounge_partner;
const pickup_scooter = marker.pickup_default;
const pickup_moped = marker.pickup_moped;
const pickup_all = marker.pickup_all;

const imageUrls: Record<DeployableMobilityType, string> = {
  A: pickup_all,
  M: pickup_moped,
  S: pickup_scooter
};

const ImageUrl = (placement: PlacementDto): string => {
  if (placement.placementType == 'LOUNGE') return lounge;
  return imageUrls[placement.deployableMobilityType];
};

const MARKER_IMAGE_SIZE: Size = {
  width: 40,
  height: 45
};

export const PlacementMarker = (placement: PlacementDto): MarkerProps => {

  const image: MarkerImage = {
    src: ImageUrl(placement),
    size: MARKER_IMAGE_SIZE,
    zIndex: 2
  };

  const infoWindowHTML = CustomPlacementOverlay(placement);

  const position: LocationDto = {
    latitude: placement.location.latitude,
    longitude: placement.location.longitude
  };

  return {
    identifier: 'P',
    infoWindowHTML,
    image,
    position
  };
};
