import { LocalAdminApi } from '../../services';
import {
  SimpleBanner,
  BannerDetailPutReq,
  BannerUpdateOrderDto,
  BannerUpdateDurationDto
} from './banner.interface';

export async function getBannerList() {
  return await LocalAdminApi.get<SimpleBanner[]>(`/banners`).then((resp) => resp.data);
}

export async function getBannerByBandId(bandId: string) {
  return await LocalAdminApi.get<SimpleBanner[]>(`/banners?bandId=${bandId}`).then((resp) => resp.data);
}

export async function getBannerByBannerId(bannerId: number) {
  return await LocalAdminApi.get<SimpleBanner>(`/banners/${bannerId}`).then((resp) => resp.data);
}

export async function deleteBanner(bannerId: number) {
  return await LocalAdminApi.delete(`/banners/${bannerId}`).then((resp) => resp.data);
}

export async function putBannerDetail(bannerId: number, content: BannerDetailPutReq) {
  return await LocalAdminApi.put(`/banners/${bannerId}`, content).then((resp) => resp.data);
}

export async function putBannerOrder(bannerId: number, content: BannerUpdateOrderDto) {
  return await LocalAdminApi.put(`/banners/${bannerId}/display-order`, content).then((resp) => resp.data);
}

export async function putBannerDuration(bannerId: number, content: BannerUpdateDurationDto) {
  return await LocalAdminApi.put(`/banners/${bannerId}/display-duration`, content).then((resp) => resp.data);
}


export async function postBanner(data: any) {
  return await LocalAdminApi.post(`/banners`, data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }).then((resp) => resp.data);
}

export async function cancelPenaltyHistory(riderId: string, penaltyId: string) {
  return await LocalAdminApi.delete(`/riders/${riderId}/penalties/${penaltyId}/cancel`);
}
