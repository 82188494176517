import { LocationDto } from '../common/common.interface';

export function getPolygonCenter(pts: LocationDto[]): LocationDto {
  let first = pts[0];
  let last = pts[pts.length - 1];

  if (first.latitude != last.latitude || first.longitude != last.longitude) pts.push(first);

  let twicearea = 0;
  let x = 0;
  let y = 0;
  let nPts = pts.length, p1, p2, f;

  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i];
    p2 = pts[j];
    f = p1.latitude * p2.longitude - p2.latitude * p1.longitude;
    twicearea += f;
    x += (p1.latitude + p2.latitude) * f;
    y += (p1.longitude + p2.longitude) * f;
  }
  f = twicearea * 3;
  return { latitude: x / f, longitude: y / f };
}

export function isInsidePolygon(point: LocationDto, polygon: LocationDto[]) {

  let x = point.latitude
  let y = point.longitude;

  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i].latitude, yi = polygon[i].longitude;
    let xj = polygon[j].latitude, yj = polygon[j].longitude;

    let intersect = ((yi > y) != (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
};