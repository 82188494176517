import React from "react";

interface Prop {
  headingTitle: string
  row: number
}

export function TableHeading({ headingTitle, row }: Prop) {
  return <h2>{`${headingTitle} (${row})`}</h2>
}
