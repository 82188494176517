import React, {useContext, useEffect, useState} from 'react';
import {SetLoadingContext} from '../../../context';
import Table, {ColumnsType} from 'antd/lib/table';
import AddSubscriptionModal from '../../subscription/component/AddSubscriptionModal'
import {
  getRiderSubscription,
  putRiderRecoverSubscription,
  putRiderTerminateSubscription,
  putRiderUnsubscribe
} from "../rider.service";
import {getBandTag, parseDateTime} from "../../../utils/organizeData";
import moment from "moment";
import {Button, Space} from 'antd';
import NameALink from "../../../common/component/NameALink";
import {SimpleSubscription} from "../../subscription/subscription.interface";

export function RiderSubscriptionHistoryTable({riderId}: { riderId: string }) {

  const setLoading = useContext(SetLoadingContext)!;
  const [riderSubscription, setRiderSubscription] = useState<SimpleSubscription[]>([]);
  const [isAddSubscriptionModalVisible, setIsAddSubscriptionModalVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getRiderSubscription(riderId)
      .then((e) => {
        setRiderSubscription(e.sort((a, b) => b.subscriptionId - a.subscriptionId))
      })
      .then(() => setLoading(false))
  }

  const riderColumn: ColumnsType<SimpleSubscription> = [
    {
      key: 'id',
      title: '구독 ID',
      align: 'center',
      render: (val, row) => row.subscriptionId
    },
    {
      key: 'riderName',
      title: '라이더 이름',
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName}/>
    },
    {
      key: 'bandName',
      title: '밴드명',
      align: 'center',
      render: (val, row, i) => {
        return row.rider ? getBandTag(row.rider.band.bandName, i) : '-'
      }
    },
    {
      key: 'productName',
      title: '상품명',
      align: 'center',
      render: (val, row) => {
        return row.product.productName
      }
    },
    {
      key: 'mobilityCode',
      title: '기기',
      align: 'center',
      render: (val, row) => {
        return row.product.mobilityCode === 'ST' ? '킥보드' : '오토바이'
      }
    },
    {
      key: 'startAt',
      title: '시작일',
      dataIndex: 'startAt',
      align: 'center',
      render: (val) => parseDateTime(moment(val).unix() * 1000)
    },
    {
      key: 'endAt',
      title: '종료일',
      dataIndex: 'endAt',
      align: 'center',
      render: (val) => parseDateTime(moment(val).unix() * 1000)
    },
    {
      key: 'nextAt',
      title: '다음결제일',
      align: 'center',
      render: (val, row) => {
        if (row.nextAt) {
          return parseDateTime(moment(row.nextAt).unix() * 1000)
        } else {
          return '-'
        }
      }
    },
    {
      key: 'action',
      title: '동작',
      align: 'center',
      render: (val, row) => {
        return (
          <>
            <Space size={'small'}>
              <Button disabled={row.nextAt == null || row.cancelAt != null}
                      onClick={() => {
                        putRiderUnsubscribe(riderId, row.subscriptionId);
                        window.location.reload()
                      }}>
                구독해지
              </Button>
              <Button
                disabled={(row.cancelAt != null) && (new Date(row.endAt).getTime() <= new Date().getTime())}
                onClick={() => {
                  putRiderTerminateSubscription(riderId, row.subscriptionId)
                  window.location.reload()
                }}>
                구독종료
              </Button>
              <Button disabled={true}>
                구독복구
              </Button>
            </Space>
          </>
        )
      }
    }
  ];

  return (
    <>
      <div
        style={{textAlign: 'right'}}
      >
        <Button
          onClick={() => setIsAddSubscriptionModalVisible(true)}
          style={{marginRight: '8px'}}
        >
          구독생성
        </Button>
      </div>
      <AddSubscriptionModal
        isVisible={isAddSubscriptionModalVisible}
        setIsVisible={setIsAddSubscriptionModalVisible}
        riderId={riderId}
      />
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={
          riderSubscription.map((r, i) => ({key: String(i + 1), ...r,}))
        }
      />
    </>
  );
}
