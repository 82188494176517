import {Card, Row, Statistic} from 'antd';
import Title from 'antd/lib/typography/Title';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {convertGeocode} from '../../../../common/unit/geocode';
import {getScooter} from '../../../../services/scoot';
import {PageHeader} from '../../../../components/layout/PageHeader';
import {SetLoadingContext} from '../../../../context';
import {Routes} from '../../../../constants';
import {FilledButton, LinedButton} from '../../../../components/layout/Button';
import {useValidation} from '../../../../hooks/useValidation';
import {RiderRideTable} from "../../../rider/table/RiderRideTable";
import {HistoryDto} from "../../../ride/ride.interface";
import {MobilityDto} from "../../mobility.interface";


export function ScooterDetailPage() {
  const setLoading = useContext(SetLoadingContext)!;
  const {scootId} = useParams<{ scootId: string }>();

  const [scoot, setScoot] = useState<MobilityDto>();
  const [rides, setRides] = useState<HistoryDto[]>([]);
  const [scootLocation, setScootLocation] = useState('');
  const checkToken = useValidation;

  checkToken();

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    if (scootId) {
      // await getScootDetail(scootId).then(setScoot);
      // await getScootRides(scootId).then(setRides);
      await getScooter(parseInt(scootId)).then(setScoot);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (scoot) {
      convertGeocode(scoot.location.latitude, scoot.location.longitude).then(setScootLocation);
    }
  }, [scoot]);

  const history = useHistory();

  function goScoots() {
    history.push(Routes.SCOOTER_LIST);
  }

  return (
    <div className="scoot-detail">
      <PageHeader title={(scoot?.shortImei || '킥보드') + ' 상세 정보'} backTitle="킥보드 목록" onBack={goScoots}/>
      {scoot && (
        <Row>
          <LinedButton size="sm">이용 강제 종료</LinedButton>
          <FilledButton size="sm">배터리 커버 열기</FilledButton>
        </Row>
      )}
      {scoot && (
        <Card className="scoot-card">
          <Row className="scoot-status">
            <Statistic title="배터리" value={`${scoot.battery}%`}/>
            <Statistic title="잠금 상태" value="잠금"/>
            <Statistic title="현재 위치" value={scootLocation}/>
          </Row>
        </Card>
      )}
      <div className="ride">
        <Title level={5}>이용 내역</Title>
        <RiderRideTable rides={rides}/>
      </div>
    </div>
  );
}
