import {Button, Modal} from 'antd';
import React, {VFC} from 'react';
import {putRiderWithdrawal} from '../rider.service'

interface Props {
  isModalVisible: boolean;
  handleCancel: React.Dispatch<React.SetStateAction<boolean>>;
  riderId: number | undefined
}

const WithdrawRiderModal: VFC<Props> = ({isModalVisible, handleCancel, riderId}) => {

  const handleWithdrawal = () => {
    if (riderId && window.confirm("정말 탈퇴처리 하시겠습니까?")) {
      putRiderWithdrawal(riderId).then(e => {
        alert("탈퇴처리 되었습니다");
        window.location.reload();
      })
    }
  }

  return (
    <Modal
      visible={isModalVisible}
      onCancel={e => handleCancel(false)}
      title={'회원 탈퇴'}
      onOk={() => handleCancel(false)}
    >
      <Button size={'large'} style={{width: '100%'}} danger={true} onClick={handleWithdrawal}>탈퇴 처리</Button>
    </Modal>
  );
};

export default WithdrawRiderModal;
