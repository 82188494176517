import React, {useContext, useEffect, useState} from 'react';
import {SetLoadingContext} from '../../../context';

import Table, {ColumnsType} from 'antd/lib/table';

import {getBandTag, getPlacementTag, parseDateTime} from "../../../utils/organizeData";
import moment from "moment";
import {Geocode} from "../../../common/unit/geocode";
import {getMopedRidesFromRider} from "../../ride/ride.service";
import {HistoryDto} from "../../ride/ride.interface";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";
import NameALink from "../../../common/component/NameALink";
import Copyable from "../../../common/component/Copyable";

export function RiderMopedRideHistoryTable({riderId}: { riderId: string }) {

  const setLoading = useContext(SetLoadingContext)!;
  const [histories, setHistories] = useState<HistoryDto[]>([]);

  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getMopedRidesFromRider(riderId)
      .then(setHistories)
      .then(() => setLoading(false))
  }

  const riderColumn: ColumnsType<HistoryDto> = [
    columnMaker('id', '라이드 ID', columnRenderer(data => data.id)),
    columnMaker('riderName', '라이더 이름', columnRenderer(data => data
      ? data.rider
        ? <NameALink urlVal={data.rider.riderId} textVal={data.rider.riderName}/>
        : ''
      : ''
    )),
    columnMaker('mopedShortImei', 'SIMEI', columnRenderer(data => data
      ? data.mobility.shortImei
        ? <Copyable textVal={data.mobility.shortImei}/>
        : ''
      : ''
    )),
    columnMaker('bandName', '밴드명', columnRenderer((data, idx) => data
      ? data.rider.band
        ? getBandTag(data.rider.band.bandName, idx)
        : '-'
      : '-'
    )),
    columnMaker('startAt', '시작일', columnRenderer(data => parseDateTime(moment(data.startAt).unix() * 1000))),
    columnMaker('endAt', '종료일', columnRenderer(data => data.endAt ? parseDateTime(moment(data.endAt).unix() * 1000) : '현재 이용중')),
    columnMaker('startLoc', '시작 주소', columnRenderer(data => data.startPlacement
      ? getPlacementTag(data.startPlacement.placementName)
      : <Geocode lat={data.startLocation.latitude} lng={data.startLocation.longitude}/>)
    ),
    columnMaker('endLoc', '종료 주소', columnRenderer(data => data.endPlacement
      ? getPlacementTag(data.endPlacement.placementName)
      : data.endLocation
        ? <Geocode lat={data.endLocation.latitude} lng={data.endLocation.longitude}/>
        : '-'
      )
    ),
  ];

  return (
    <Table
      columns={riderColumn}
      bordered={true}
      dataSource={
        histories.map((r, i) => ({key: String(i + 1), ...r,}))
      }
    />
  );
}
