import React from 'react';
import {BandTariffDto} from "../tariff.interface";
import {ColumnsType} from "antd/es/table";
import {Table} from 'antd';
import {getBandTag, parseTariffDayType, parseTariffTimeType} from '../../../utils/organizeData';
import {columnMaker, columnRenderer, columnWithChildren} from "../../../utils/componentUtils";

interface Props {
  bandTariffs: BandTariffDto[]
}

export function BandTariffListTable({bandTariffs}: Props) {

  const tariffColumn: ColumnsType<BandTariffDto> = [
    columnMaker('band', '밴드', columnRenderer((data, idx) => getBandTag(data.band.bandName, idx))),
    columnMaker('tariffId', '요금표ID', columnRenderer(data => data.tariffId)),
    columnMaker('modelType', '적용 기기 모델',
      columnRenderer(data => data.modelType === 'R1' ? '오토바이-' + data.modelType : '킥보드-' + data.modelType)
    ),
    columnMaker('dayType', '주일', columnRenderer(data => parseTariffDayType(data.dayType))),
    columnMaker('timeType', '시간', columnRenderer(data => parseTariffTimeType(data.timeType))),
    columnWithChildren(
      'fare',
      '요금',
      [
        columnMaker('startFare', '시작요금', columnRenderer(data => data.startFare)),
        columnMaker('rideFare', '운행요금', columnRenderer(data => data.rideFare)),
      ]
    ),
    columnMaker('freeTime', '무료시간(분)', columnRenderer(data => data.freeTime)),
  ];

  return (
    <>
      <Table
        columns={tariffColumn}
        bordered={true}
        dataSource={bandTariffs.map((r, i) => ({key: String(i + 1), ...r,}))}
      />
    </>
  );
}
