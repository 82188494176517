import React, {useState} from 'react';
import BaseModal from "../../common/component/BaseModal";
import {Row, Col, Select, Tabs, Button} from 'antd'

import * as mobilityController from '../../domain/mobility/mobility.service'
import {MobilityDto} from "../../domain/mobility/mobility.interface";

const {Option} = Select;
const {TabPane} = Tabs;

interface Props {
  isMobilityRemoteControlVisible: boolean
  setIsMobilityRemoteControlVisible: React.Dispatch<React.SetStateAction<boolean>>
}

type TabTitle = 'scooter' | 'moped';

export function MobilityRemoteControl({isMobilityRemoteControlVisible, setIsMobilityRemoteControlVisible}: Props) {

  const [scooters, setScooters] = useState<MobilityDto[]>([]);
  const [mopeds, setMopeds] = useState<MobilityDto[]>([]);
  const [tabTitle, setTabTitle] = useState<TabTitle>('scooter');

  const [selectedScooter, setSelectedScooter] = useState<number[]>([]);
  const [selectedMoped, setSelectedMoped] = useState<number[]>([]);

  // useEffect(() => {
  //   getAllScooters()
  //     .then(e => {
  //       setScooters(e);
  //       getAllMopeds()
  //         .then(l => {
  //           setMopeds(l)
  //         })
  //     })
  // }, []);

  const scooterOptions = scooters.map((e, i) => {
    return (
      <Option key={'scooter-option-' + i} value={e.scooterId!}>{e.shortImei}</Option>
    )
  })

  const mopedOptions = mopeds.map((e, i) => {
    return (
      <Option key={'moped-option-' + i} value={e.mopedId!}>{e.shortImei}</Option>
    )
  })

  const handleScooterSelect = (e: any) => {
    setSelectedScooter(e);
  }

  const handleMopedSelect = (e: any) => {
    setSelectedMoped(e);
  }

  const handleScooterLock = async () => {
    const promiseLockAll = selectedScooter.map(e => {
      return mobilityController.putScooterLock(e)
    });
    const result = await Promise.all(promiseLockAll);
    console.log(result);
  }

  const handleScooterUnlock = async () => {
    const promiseUnlockAll = selectedScooter.map(e => {
      return mobilityController.putScooterUnlock(e)
    });
    const result = await Promise.all(promiseUnlockAll);
    console.log(result);
  }

  const handleScooterRing = async () => {
    const promiseRingAll = selectedScooter.map(e => {
      return mobilityController.putScooterRing(e)
    });
    const result = await Promise.all(promiseRingAll);
    console.log(result);
  }

  const handleScooterOpenCover = async () => {
    const promiseOpenCoverAll = selectedScooter.map(e => {
      return mobilityController.putScooterOpenCover(e)
    });
    const result = await Promise.all(promiseOpenCoverAll);
    console.log(result);
  }

  const handleMopedLock = async () => {
    const promiseLockAll = selectedMoped.map(e => {
      return mobilityController.putMopedLock(e)
    });
    const result = await Promise.all(promiseLockAll);
    console.log(result);
  }

  const handleMopedUnlock = async () => {
    const promiseUnlockAll = selectedMoped.map(e => {
      return mobilityController.putMopedUnlock(e)
    });
    const result = await Promise.all(promiseUnlockAll);
    console.log(result);
  }

  const handleMopedRing = async () => {
    const promiseRingAll = selectedMoped.map(e => {
      return mobilityController.putMopedRing(e)
    });
    const result = await Promise.all(promiseRingAll);
    console.log(result);
  }

  const handleMopedOpenCover = async () => {
    const promiseOpenCoverAll = selectedMoped.map(e => {
      return mobilityController.putMopedOpenCover(e)
    });
    const result = await Promise.all(promiseOpenCoverAll);
    console.log(result);
  }

  return (
    <BaseModal
      isVisible={isMobilityRemoteControlVisible}
      handleOk={() => setIsMobilityRemoteControlVisible}
      handleCancel={() => setIsMobilityRemoteControlVisible(!isMobilityRemoteControlVisible)}
      headerTitle={"모빌리티 리모트 컨트롤"}
      width={'90%'}
    >
      <Tabs defaultActiveKey={tabTitle} centered>
        <TabPane tab="킥보드" key={'scooter'}>
          <Select
            showSearch
            style={{width: '100%'}}
            placeholder="킥보드 simei를 입력해주세요"
            optionFilterProp="children"
            mode="tags"
            size={'large'}
            onChange={(e) => handleScooterSelect(e)}
          >
            {scooterOptions}
          </Select>
          <div style={{width: '50%', margin: '25px auto'}}>
            <Row
              gutter={[16, 16]}
              justify="space-around" align="middle"
            >
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}} onClick={handleScooterLock}>락</Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}}
                        onClick={handleScooterUnlock}>언락</Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}} onClick={handleScooterRing}>경고음</Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}}
                        onClick={handleScooterOpenCover}>배터리커버</Button>
              </Col>
            </Row>
          </div>

        </TabPane>
        <TabPane tab="오토바이" key={'moped'}>
          <Select
            showSearch
            style={{width: '100%'}}
            placeholder="오토바이 simei를 입력해주세요"
            optionFilterProp="children"
            mode="tags"
            size={'large'}
            onChange={e => handleMopedSelect(e)}
          >
            {mopedOptions}
          </Select>
          <div style={{width: '50%', margin: '25px auto'}}>
            <Row
              gutter={[16, 16]}
              justify="space-around" align="middle"
            >
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}} onClick={handleMopedLock}>락</Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}} onClick={handleMopedUnlock}>언락</Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}} onClick={handleMopedRing}>경고음</Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button shape={'round'} style={{width: '100%', height: '75px'}}
                        onClick={handleMopedOpenCover}>배터리커버</Button>
              </Col>
            </Row>
          </div>
        </TabPane>
      </Tabs>
    </BaseModal>
  )
}