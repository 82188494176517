import {Button, Form, Input, Modal, Radio} from 'antd';
import React, {VFC} from 'react';
import {addBand} from '../band.service';
import {BandCreateDto} from "../band.interface";

interface Props {
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

export const AddBandModal: VFC<Props> = ({isModalVisible, handleOk, handleCancel}) => {

  const onFinish = async (values: BandCreateDto) => {

    try {
      values.swingPid = 'ckrrawxfdz0eh0878ls7b6u5j';
      await addBand(values);
      alert('밴드추가에 성공했습니다.');
      window.location.reload();
    } catch (err) {
      alert(`밴드추가에 실패하였습니다.${err}`);
      console.log(err);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Modal title="밴드 추가하기" visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <Form
          name="basic"
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          initialValues={{
            remember: true,
            type: 'DIRECT',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="밴드이름" name="bandName" rules={[{required: true, message: '입력해주세요.'}]}>
            <Input/>
          </Form.Item>

          <Form.Item label="밴드ID" name="swingPid" rules={[{required: true, message: '입력해주세요.'}]}>
            <Input/>
          </Form.Item>

          <Form.Item label="잠금대기시간" name="waitableSec" rules={[{required: true, message: '입력해주세요.'}]}>
            <Input/>
          </Form.Item>

          <Form.Item label="운영타입" name="type" rules={[{required: true, message: '입력해주새요.'}]}>
            <Radio.Group>
              <Radio value="DIRECT">직영점</Radio>
              <Radio value="PARTNER">파트너사</Radio>
              <Radio value="DELIVERY">배달사</Radio>
            </Radio.Group>
          </Form.Item>


          <Form.Item wrapperCol={{offset: 10, span: 16}}>
            <Button type="primary" htmlType="submit">
              추가하기
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};