import { Button, Typography } from "antd";
import React from "react";

interface ButtonProps {
  children?: React.ReactNode;
  size?: "bg" | "md" | "sm";
  onClick?: () => void;
}

export function FilledButton({ children, onClick, size = "md" }: ButtonProps) {
  return (
    <Button onClick={onClick} className={`button filled-button ${size}`}>
      {children}
    </Button>
  );
}

export function LinedButton({ children, onClick, size = "md" }: ButtonProps) {
  return (
    <Button onClick={onClick} className={`button lined-button ${size}`}>
      {children}
    </Button>
  );
}

export function UnderlineButton({ children, onClick, size }: ButtonProps) {
  return (
    <Typography.Text
      className={`button underline-button ${size}`}
      onClick={onClick}
      underline={true}
    >
      {children}
    </Typography.Text>
  );
}

export function TextButton({ children, onClick, size }: ButtonProps) {
  return (
    <a className={`button text-button ${size}`} onClick={onClick}>
      {children}
    </a>
  );
}
