import React from 'react';
import { Card, Col, Input, PageHeader, Row, Slider } from 'antd';

import { DeviceCount, FilterCondition } from '../page/MopedPage';
import { MobilityFilterButton } from '../../common/MobilityFilterButton';

const { Search } = Input;

interface Props {
  filteredMopedLength: number,
  setFilterCondition: React.Dispatch<React.SetStateAction<FilterCondition>>,
  filterCondition: FilterCondition,
  deviceCount: Map<string, DeviceCount>;
}

function MopedSearchCard(
  {
    filteredMopedLength,
    setFilterCondition,
    filterCondition,
    deviceCount
  }: Props) {

  const firmwareColumnMaker = () => {
    if (deviceCount && deviceCount.has(filterCondition.deviceModel)) {

      const firmwareMap = deviceCount.get(filterCondition.deviceModel)!.firmware;
      const firmwareArr = firmwareMap && Array.from(firmwareMap);
      const firmwareColumn = firmwareArr.map(e => [{ text: e[0], count: e[1], value: e[0] }]);

      return [[{ text: '전체', value: 'ALL' }], ...firmwareColumn];
    }
  };

  const deviceColumnMaker = () => {
    if (deviceCount) {
      const deviceColumn = Array.from(deviceCount).map(ele => {
        const [deviceModel, value] = ele;
        return (
          [{ text: deviceModel, count: value.all, value: deviceModel },
            { text: '운행중', count: value.riding, value: deviceModel }]
        );
      });
      return deviceColumn;
    }
  };

  return (
    <PageHeader
      title={'오토바이'}
      subTitle={filteredMopedLength + '대'}
      style={{ backgroundColor: 'white' }}
    >

      <Row
        gutter={[12, 12]}
        justify={'center'}
      >
        <Col span={6} style={{ marginTop: '13px' }}>
          {deviceCount && (
            <MobilityFilterButton
              size={'middle'}
              style={{ borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
              arrow={true}
              buttonStyle={'solid'}
              column={deviceColumnMaker()!}
              radioGroupValue={filterCondition.deviceModel}
              onRadioChange={(e) => {
                const deviceModelVal = e.target.value;
                setFilterCondition(prev => ({
                  ...prev,
                  deviceModel: deviceModelVal,
                  firmware: prev.deviceModel !== deviceModelVal ? 'ALL' : prev.firmware
                }));
              }}
            />
          )}
        </Col>

        <Col span={18}>
          <Card title={filterCondition.deviceModel}>
            {filterCondition.deviceModel !== 'ALL' &&
              <MobilityFilterButton
                size={'small'}
                btnSpan={8}
                textAlign={'center'}
                column={firmwareColumnMaker()!}
                radioGroupValue={filterCondition.firmware}
                onRadioChange={(e) => setFilterCondition(prev => ({ ...prev, firmware: e.target.value }))}
              />
            }
          </Card>
        </Col>
      </Row>

      <br />

      <Row
        gutter={[12, 12]}
        align={'middle'}
      >
        <Col span={24}>
          {deviceCount && (
            <MobilityFilterButton
              textAlign={'center'}
              btnSpan={8}
              column={[
                [{ text: '전체', value: 'ALL', count: deviceCount.get(filterCondition.deviceModel)?.all }],
                [{ text: '탑승가능', value: 'AVAILABLE', count: deviceCount.get(filterCondition.deviceModel)?.available }],
                [{ text: '탑승중', value: 'RIDING', count: deviceCount.get(filterCondition.deviceModel)?.riding }]
              ]}
              radioGroupValue={filterCondition.available}
              onRadioChange={e => setFilterCondition(prev => ({ ...prev, available: e.target.value }))}
            />
          )}
        </Col>
        <Col span={24}>
          {deviceCount && (
            <MobilityFilterButton
              textAlign={'center'}
              btnSpan={6}
              column={[
                [{ text: '전체', value: 'ALL', count: deviceCount.get(filterCondition.deviceModel)?.all }],
                [{ text: '오라', value: 'NONE', count: deviceCount.get(filterCondition.deviceModel)?.dayRider }],
                [{ text: '리스', value: 'LEASE', count: deviceCount.get(filterCondition.deviceModel)?.lease }],
                [{ text: '렌탈', value: 'RENTAL', count: deviceCount.get(filterCondition.deviceModel)?.rental }]
              ]}
              radioGroupValue={filterCondition.lendType}
              onRadioChange={e => setFilterCondition(prev => ({ ...prev, lendType: e.target.value }))}
            />
          )}
        </Col>
        <Col span={12}>
          <Search
            placeholder='차대번호, SIMEI, IMEI 를 입력해주세요'
            size={'large'}
            onChange={(e) => setFilterCondition(prev => ({ ...prev, simei: e.target.value, imei: e.target.value }))}
          />
        </Col>
        <Col span={12}>
          <Slider
            range={{ draggableTrack: true }}
            min={-1}
            defaultValue={filterCondition.battery}
            onAfterChange={(e: any) => setFilterCondition(prev => ({ ...prev, battery: e }))}
          />
        </Col>
      </Row>
    </PageHeader>
  );
}

export default MopedSearchCard;