import React, { useContext, useEffect, useState } from 'react';
import { message, PageHeader } from 'antd';

import LeaseRentalConsultTable from '../components/consult/LeaseRentalConsultTable';
import { RiderLendConsultDto } from '../rental.interface';
import { getAllLendConsults } from '../leaseRental.service';
import LeaseRentalConsultDetailModal from '../components/consult/LeaseRentalConsultDetailModal';
import LeaseRentalConsultSearchCard from '../components/consult/LeaseRentalConsultSearchCard';
import { parseSearchPhoneNumber } from '../../../utils/organizeData';
import moment from 'moment';
import { AccountContext } from '../../../context';
import { useHistory } from 'react-router-dom';

export interface SearchConditions {
  riderSearch: string;
  consultSearch: string;
  contractSearch: string;
  consultPeriod: [string, string],
}

function LeaseRentalConsultList() {
  const history = useHistory();
  const admin = useContext(AccountContext);
  if (admin?.role !== 'ADMIN') {
    history.push('/riders');
  }

  const [consults, setConsults] = useState<RiderLendConsultDto[]>([]);
  const [filteredConsults, setFilteredConsults] = useState<RiderLendConsultDto[]>([]);
  const [selectedConsult, setSelectedConsult] = useState<RiderLendConsultDto | null>(null);
  const [forceRenderer, setForceRenderer] = useState(false);

  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [searchConditions, setSearchConditions] = useState<SearchConditions>({
    riderSearch: '',
    consultSearch: '',
    contractSearch: '',
    consultPeriod: ['2022-01-01', moment().format('YYYY-MM-DD')]
  });

  useEffect(() => {
    bootstrap();
  }, [forceRenderer]);

  async function bootstrap() {
    try {
      const consults = await getAllLendConsults();
      setConsults(consults.data);
      setFilteredConsults(consults.data);
    } catch (err) {
      message.error('정보를 불러오지 못했습니다,');
    }
  }

  useEffect(() => {
    setFilteredConsults(
      consults
        .filter(ele => (
          (searchConditions.riderSearch === '' && true)
          || ele.clientName.includes(searchConditions.riderSearch)
          || parseSearchPhoneNumber(ele.clientPhoneNo).includes(parseSearchPhoneNumber(searchConditions.riderSearch))
        ))
        .filter(ele => (
          (searchConditions.contractSearch === '' && true)
          || ele.contractType.includes(searchConditions.contractSearch)
          // || ele.contactTime.includes(searchConditions.contractSearch)
        ))
        .filter(ele => (
          (searchConditions.consultSearch === '' && true)
          || (ele.consultant && ele.consultant.includes(searchConditions.consultSearch))
          || ele.consultStatus.includes(searchConditions.consultSearch)
        ))
        .filter(ele => {
          const start = searchConditions.consultPeriod[0];
          const end = searchConditions.consultPeriod[1];
          const consultAt = ele.consultAt ? moment(ele.consultAt).format('YYYY-MM-DD') : '2022-01-01';
          return (start <= consultAt) && (end >= consultAt);
        })
    );
  }, [searchConditions]);

  return (<>
    <PageHeader
      title={'상담내역'}
      subTitle={
        `${filteredConsults.length}명`
      }
      style={{ padding: 0 }}
    />
    <LeaseRentalConsultDetailModal
      selectedConsult={selectedConsult}
      isDetailModalVisible={isDetailModalVisible}
      setIsDetailModalVisible={setIsDetailModalVisible}
      setForceRenderer={setForceRenderer}
    />

    <LeaseRentalConsultSearchCard
      setSearchConditions={setSearchConditions}
    />
    <LeaseRentalConsultTable
      filteredConsults={filteredConsults}
      setSelectedConsult={setSelectedConsult}
      setIsDetailModalVisible={setIsDetailModalVisible}
    />

  </>);
}

export default LeaseRentalConsultList;