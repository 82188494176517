import {BaseMapPlugin} from "./BaseMapPlugin";
import {utils} from "../Utils";
import {DrawingManagerWrapper, MarkerWrapper, PolygonWrapper} from "../Types";
import {LocationDto} from "../../common.interface";
import {EventType, MarkerImage} from "../Map";
import {red_dot} from "../marker_constants";

export class CustomDrawingPlugin extends BaseMapPlugin {
  private drawingManager: DrawingManagerWrapper | null = null;
  private polygons: PolygonWrapper[] = [];
  private coords: LocationDto[] = [];
  private coordMarkers: MarkerWrapper[] = [];

  constructor() {
    super();
  }

  async onMapUpdated() {
    const map = await this.getMap();
    utils.addListener(map, EventType.CLICK, (event) => {
      if (!event) return;

      const location = event.location;
      if (!location) return;

      const redDotMarkerImage: MarkerImage = {
        src: red_dot,
        size: {
          width: 10,
          height: 10,
        },
        zIndex: 2
      }
      if (!Array.isArray(location)) {
        this.coords.push(location);

        const newMarker = utils.createMarker(map, {position: location, image: redDotMarkerImage}, location);
        const CLICK = map.mapType === 'GOOGLE' ? 'click' : 'click';
        utils.addListener(newMarker, CLICK,() => {
          if (this.coords.length < 3) {
            console.log('점 3개 이상은 선택해야 합니다');
          } else {
            let from  = 0;
            let to = 0;
            const {latitude, longitude} = newMarker.getPosition();
            this.coords.forEach((e, i) => {
              if (e.latitude === latitude && e.longitude === longitude) {
                from = i;
                to = this.coords.length;
                if (to - from > 2) {
                  this.createPolygon(from, to);
                }
              }
            });
          }
        });
        this.coordMarkers.push(newMarker);
        if (this.coords.length > 1) {
          // this.drawPolyline();
        }
      }
    });

    utils.addListener(map, EventType.RIGHTCLICK, () => {
      if (this.coords.length === 0) {
        return;
      } else {
        this.coords.pop();
        const removeMarker = this.coordMarkers.pop();
        removeMarker!.marker.setVisible(false);
        // this.drawPolyline();
      }
    });
  }

  async registerDrawingManager() {
    const map = await this.getMap();
    if (this.drawingManager) {
      const drawend = map.mapType === 'GOOGLE' ? 'polygoncomplete' : 'drawend';
      utils.addListener(this.drawingManager, drawend, (event => {
        console.log(this.drawingManager);
      //   if (event && Array.isArray(event.location)) {
      //     this.polygons.push(event.location);
      //   }
      }))
      utils.registerDrawingManager(map, this.drawingManager);
    }
  }

  async createPolygon(from: number, to: number) {
    const map = await this.getMap();
    const polygonPaths = this.coords.slice(from, to);
    polygonPaths.push(this.coords[from]);
    const newPolygon = utils.createPolygon(map, polygonPaths);
    this.polygons.push(newPolygon);
    this.initializeMarkers();
  }

  async finishDrawing() {
    console.log('finishDrawing');
    const map = await this.getMap();
    const drawingManager = utils.createDrawingManager(map, true);
    console.log(drawingManager);
    this.drawingManager = drawingManager;
    this.registerDrawingManager();
  }

  initializeMarkers() {
    this.coordMarkers.forEach(e => e.marker.setVisible(false));
    this.coords = [];
    this.coordMarkers = [];
  }
}