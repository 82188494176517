import React from 'react';
import ExpiredMobilityPage from "../page/ExpiredMobilityPage";
import {getExpiredMobilityRides} from "../ride.service";

function ExpiredScooterRide() {
  function getExpiredScooterRides() {
    return getExpiredMobilityRides('SCOOTER')
  }

  return (
    <ExpiredMobilityPage
      getExpiredRides={getExpiredScooterRides}
    />
  );
}


export default ExpiredScooterRide;