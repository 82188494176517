import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountContext, SetLoadingContext } from '../../../context';
import { RiderTable } from '../table/RiderTable';
import { Card, Input, Row, Col, Radio } from 'antd';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import { TableHeading } from '../../../common/component/TableHeading';
import { getRidersPagination } from '../rider.service';
import { RiderTableList } from '../rider.interface';
import { PaginationRes } from '../../../common/common.interface';
import BandOptionRadio from '../../../common/component/BandOptionRadio';
import { parseSearchString } from '../../../utils/organizeData';
import { move } from '../../../common/url/utils';
import { Pagination } from '../../../common/url/pagination';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const BASE_URL = '/admin/riders';

export function RidersPage() {
  const { t } = useTranslation();
  const admin = useContext(AccountContext);
  const setLoading = useContext(SetLoadingContext)!;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const pagination = Pagination.rider(
    query.get('page') ? parseInt(query.get('page')!) : 0,
    query.get('size') ? parseInt(query.get('size')!) : DEFAULT_TABLE_SIZE,
    query.get('keyword') ?? '',
    query.get('band') ?? '',
    query.get('mobility') ?? '',
    query.get('license') ?? ''
  );

  useEffect(() => {
    bootstrap();
  }, []);

  const [riders, setRiders] = useState<RiderTableList[]>([]);
  const [paginationRes, setPaginationRes] = useState<PaginationRes>({
    page: 0,
    totalPage: 0,
    totalItem: 0
  });
  const [selectedBand, setSelectedBand] = useState('');

  function bootstrap() {
    if (admin) {
      setLoading(true);
      getRidersPagination(pagination).then((res) => {
        setPaginationRes({
          page: res.page,
          totalItem: res.totalItem,
          totalPage: res.totalPage
        });
        setRiders(res.items.sort((a: { riderId: number; }, b: { riderId: number; }) => b.riderId - a.riderId));
      }).then(() => setLoading(false));
    }
  }

  useEffect(() => {
    if (selectedBand !== '') {
      const newPagination = Pagination.riderFrom(pagination, {
        band: selectedBand === '0' ? '' : selectedBand
      });
      move(BASE_URL, newPagination);
    }
  }, [selectedBand]);

  const handleSearch = (param: string, val: any) => {
    switch (param) {
      case 'KEYWORD':
        move(BASE_URL, Pagination.riderFrom(pagination, { keyword: parseSearchString(val), page: 0 }));
        break;
      case 'PASS':
        move(BASE_URL, Pagination.riderFrom(pagination, { mobility: val }));
        break;
      case 'LICENSE':
        move(BASE_URL, Pagination.riderFrom(pagination, { license: val }));
        break;
    }
  };

  return (
    <div>
      <TableHeading
        headingTitle={t('riderList')}
        row={paginationRes.totalItem}
      />
      <Card title={t('searchRider')}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Search
              style={{ width: '100%' }}
              placeholder={t('pleasePutNamePhoneNumberID')}
              size={'large'}
              defaultValue={pagination.fields.keyword}
              onSearch={e => handleSearch('KEYWORD', e)}
            />
          </Col>
          <Col span={24}>
            <BandOptionRadio
              selectedBand={pagination.fields.band ? pagination.fields.band : '0'}
              setSelectedBand={setSelectedBand}
            />
          </Col>
          <Col span={24}>
            <Radio.Group style={{ width: '100%' }} value={pagination.fields.license}
                         onChange={e => handleSearch('LICENSE', e.target.value)}>
              <Radio.Button style={{ width: '33%', textAlign: 'center' }} value={''}>{t('common-all')}</Radio.Button>
              <Radio.Button style={{ width: '33%', textAlign: 'center' }}
                            value={'1'}>{t('common-license')}</Radio.Button>
              <Radio.Button style={{ width: '33%', textAlign: 'center' }}
                            value={'0'}>{t('common-no-license')}</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Radio.Group style={{ width: '100%' }} value={pagination.fields.mobility}
                         onChange={e => handleSearch('PASS', e.target.value)}>
              <Radio.Button style={{ width: '20%', textAlign: 'center' }} value={''}>{t('common-all')}</Radio.Button>
              <Radio.Button style={{ width: '20%', textAlign: 'center' }}
                            value={'ALL'}>{t('common-pass-all')}</Radio.Button>
              <Radio.Button style={{ width: '20%', textAlign: 'center' }}
                            value={'SCOOTER'}>{t('common-pass-scooter')}</Radio.Button>
              <Radio.Button style={{ width: '20%', textAlign: 'center' }}
                            value={'MOPED'}>{t('common-pass-moped')}</Radio.Button>
              <Radio.Button style={{ width: '20%', textAlign: 'center' }}
                            value={'NONE'}>{t('common-pass-expired')}</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Card>
      <RiderTable
        riders={riders}
        pagination={pagination}
        paginationRes={paginationRes}
      />
    </div>
  );
}
