import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Form, Radio, Row, Select, Input, Divider } from 'antd';
import BaseModal from '../../../common/component/BaseModal';
import { AccountContext, SetLoadingContext } from '../../../context';
import { getAllBandProducts } from '../../product/product.service';
import { postRegisterFreeRiderPass } from '../rider.service';
import { MobilityCode, ProductBandDto } from '../../product/product.interface';
import { FreePassCreateBody } from '../../pass/pass.interface';

const { Option } = Select;

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  registerModalHandleOk: () => void;
  registerModalHandleClose: () => void;
  riderId: string;
}

const RegisterFreePassModal: FC<Props> = ({ isVisible, setIsVisible, registerModalHandleClose, riderId }) => {

  const userBandId = useContext(AccountContext)?.band.bandId;
  const setLoading = useContext(SetLoadingContext)!;
  const handleModalCancel = () => {
    registerModalHandleClose();
  };

  const bandId = useContext(AccountContext)!.band.bandId;
  const [formValues, setFormValues] = useState<FreePassCreateBody | null>(null);
  const [bandProducts, setBandProducts] = useState<ProductBandDto[]>([]);
  const [filteredBandProducts, setFilteredBandProducts] = useState<ProductBandDto[]>([]);
  const [selectedBandProduct, setSelectedBandProduct] = useState<ProductBandDto | null>(null);

  const [passFilter, setPassFilter] = useState<MobilityCode>('ST')

  const [formInstance] = Form.useForm();

  const submitModal = async () => {
    if (formValues) { // 변경이 있는 경우
      formInstance.submit();
    } else { // 변경이 없는 경우
      setIsVisible(false);
    }
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label}은 숫자만 입력해주세요'
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    if (bandId) {
      await getAllBandProducts().then(res => {
        const freePasses = res.filter(e => e.price === 0 && e.band.bandId === userBandId);
        setBandProducts(freePasses);
        setFilteredBandProducts(freePasses.filter(e => e.baseProduct.mobilityCode === 'ST'));
      }).then(() => setLoading(false));
    }
  }

  const handleSelectChange = (value: any) => {
    for (const content of bandProducts) {
      if (content.baseProduct.productId === value) {
        setSelectedBandProduct(content);
      }
    }
  };

  const onSubmitForm = async (formValues: FreePassCreateBody) => {
    if (bandId && selectedBandProduct) {
      const registrableProduct: FreePassCreateBody = {
        productId: formValues.productId,
        activated: formValues.activated,
        duration: formValues.duration
      };
      if (window.confirm('정말 등록하시겠습니까?')) {
        try {
          await postRegisterFreeRiderPass(riderId, registrableProduct).then(e => {
            alert('성공적으로 등록 되었습니다');
            window.location.reload();
          });
        } catch (err) {
          alert('등록에 실패하였습니다 : ' + err);
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    setFilteredBandProducts(bandProducts.filter(e => e.baseProduct.mobilityCode === passFilter));
  }, [passFilter])

  const bandProductionOptions = filteredBandProducts.map((e, i) => {
    return (
      <Option key={'bandProduction' + i} value={e.baseProduct.productId} label={e.baseProduct.title}>
        <div className='demo-option-label-item'>
          {e.baseProduct.title} ({e.price}원 / {e.baseProduct.title})
        </div>
      </Option>
    );
  });

  return (
    <>
      <BaseModal
        isVisible={isVisible}
        headerTitle={'무료 이용권 부여'}
        handleOk={submitModal}
        handleCancel={handleModalCancel}
        width={'70%'}
      >
        <Divider>프리 이용권 필터</Divider>
        <Radio.Group
          style={{ width: '100%' }}
          value={passFilter}
          onChange={e => setPassFilter(e.target.value)}
        >
          <Radio.Button value={'ST'} style={{ width: '50%', textAlign: 'center' }}>킥보드</Radio.Button>
          <Radio.Button value={'MC'} style={{ width: '50%', textAlign: 'center' }}>오토바이</Radio.Button>
        </Radio.Group>
        <Divider>프리 이용권 선택</Divider>
        <Form
          name='freeProductForm'
          onValuesChange={(changedVal, allVal) => {
            setFormValues(allVal);
          }}
          validateMessages={validateMessages}
          form={formInstance}
          onFinish={onSubmitForm}
        >
          <Row gutter={[32, 32]}
               justify={'center'}
          >
            <Col span={24}>
              <Form.Item
                style={{ marginBottom: '0' }}
                name='productId'
                required={true}
              >
                <Select
                  showSearch={true}
                  style={{ width: '100%' }}
                  placeholder='부여할 상품을 선택해주세요'
                  optionFilterProp={'value'}
                  filterOption={(input, option) => {
                    const labelStr = option?.label;
                    if (labelStr) {
                      return labelStr.toString().indexOf(input) !== -1;
                    } else {
                      return false;
                    }
                  }}
                  onChange={handleSelectChange}
                >
                  {bandProductionOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='activated'
                required={true}
                style={{ width: '100%' }}
              >
                <Radio.Group style={{ width: '100%' }}>
                  <Radio.Button style={{ width: '50%', textAlign: 'center' }}
                                value={true}>활성화</Radio.Button>
                  <Radio.Button style={{ width: '50%', textAlign: 'center' }}
                                value={false}>비활성화</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='duration'
                required={true}
                style={{ width: '100%' }}
              >
                <Input placeholder={'유효기간을 입력해주세요 예) 1'} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </BaseModal>
    </>
  )
    ;
};

export default RegisterFreePassModal;
