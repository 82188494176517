import {BaseMapPlugin} from "./BaseMapPlugin";

export abstract class PartialVisibilityChangeableMapPlugin<T> extends BaseMapPlugin {
  private visibilityPredicate: (entity: T) => boolean = () => true;

  setVisibility(isVisible: boolean) {
    this.setVisibilityPredicate(() => isVisible);
  }

  setVisibilityPredicate(visibilityPredicate: (entity: T) => boolean) {
    this.visibilityPredicate = visibilityPredicate
    this.update();
  }

  abstract getEntities(): T[];

  abstract show(entity: T): void;

  abstract hide(entity: T): void;

  protected update() {
    this.getEntities().forEach(e => {
      if (this.visibilityPredicate(e)) {
        this.show(e);
      } else {
        this.hide(e);
      }
    });
  }
}