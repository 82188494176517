import {LocalAdminApi} from '../../services';
import {AccountCreateBody, AccountDto, AccountUpdateBody, AccountUpdatePassword} from "./account.interface";

export async function getAccounts() {
    return LocalAdminApi.get<AccountDto[]>('/accounts').then((resp) => resp.data);
}

export async function addAccount(body: AccountCreateBody) {
    return LocalAdminApi.post('/accounts', body).then((resp) => resp.data);
}

export async function resetAdminPassword(id: number, body: AccountUpdatePassword) {
    return LocalAdminApi.put(`/accounts/${id}/password`, body);
}

// 어드민 계정 삭제
export async function deleteAccount(id: number) {
    return LocalAdminApi.delete(`/accounts/${id}`);
}

// 어드민 계정 수정
export async function updateAccount(id: number, body: AccountUpdateBody) {
    return LocalAdminApi.put(`/accounts/${id}`, body);
}
