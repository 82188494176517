import React, {useState, useEffect} from 'react';
import {BandTariffDto} from "../tariff.interface";
import {getAllBandTariffs} from "../tariff.service";
import {PageHeader, Descriptions, Card} from'antd'
import {TableHeading} from "../../../common/component/TableHeading";
import {BandTariffListTable} from "../components/BandTarrifListTable";
import BandOptionRadio from "../../../common/component/BandOptionRadio";

function TariffBandPage() {

  const [bandTariffs, setBandTariffs] = useState<BandTariffDto[]>([]);
  const [filteredBandTariffs, setFilteredBandTariffs] = useState<BandTariffDto[]>([]);

  const [selectedBand, setSelectedBand] = useState('0')

  useEffect(() => {
    getAllBandTariffs().then(bandTariffs => {
      setBandTariffs(bandTariffs);
      setFilteredBandTariffs(bandTariffs);
    })
  }, []);

  useEffect(() => {
    setFilteredBandTariffs(bandTariffs.filter(e => {
      if (selectedBand === '0') return true;
      else return e.band.bandId.toString() === selectedBand;
    }));

  }, [selectedBand])

  return (
    <>
      <TableHeading headingTitle={'밴드별 요금표'} row={filteredBandTariffs.length}/>
      <Card
        title={'밴드 요금표 필터'}
      >
        <BandOptionRadio selectedBand={selectedBand} setSelectedBand={setSelectedBand}/>
      </Card>
      <BandTariffListTable bandTariffs={filteredBandTariffs}/>
    </>
  )

}

export default TariffBandPage