import React, {FC} from 'react';
import BaseModal from '../../../common/component/BaseModal';
import {Col, Descriptions, Divider, Row} from 'antd';
import {parseDateTime} from '../../../utils/organizeData'
import {SimpleBanner} from "../banner.interface";


interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedViewBanner: SimpleBanner | null
}

const BannerDetailViewModal: FC<Props> = ({isVisible, setIsVisible, selectedViewBanner}: Props) => {

  if (selectedViewBanner) {
    return (
      <BaseModal
        isVisible={isVisible}
        handleOk={() => {
        }}
        handleCancel={() => setIsVisible(false)}
        headerTitle={'배너 상세보기'}
        width={'70%'}
      >
        <Row
          gutter={[16, 16]}
          align={'middle'}
          justify={'center'}
        >
          <Col span={8}>
            <Divider>배너 이미지</Divider>
            <div style={{textAlign: 'center', marginBottom: '10px'}}>
              <img src={selectedViewBanner!.imageUrl} style={{margin: '0 auto', width: '100%'}}/>
            </div>
            <Divider>배너 상세 이미지</Divider>
            <div style={{textAlign: 'center', marginBottom: '10px'}}>
              <img src={selectedViewBanner!.detailImageUrl} style={{margin: '0 auto', width: '100%'}}/>
            </div>
            <Divider>배너 버튼</Divider>
            <div style={{
              textAlign: 'center',
              border: '1px solid black',
              padding: '8px'
            }}>{selectedViewBanner!.actionTitle}</div>
          </Col>
          <Col span={16}>
            <Descriptions layout="vertical" bordered column={2}>
              <Descriptions.Item label="배너 제목" span={2}>{selectedViewBanner.title}</Descriptions.Item>
              <Descriptions.Item
                label="배너 노출 시작">{parseDateTime(selectedViewBanner!.displayStartAt)}</Descriptions.Item>
              <Descriptions.Item
                label="배너 노출 종료">{parseDateTime(selectedViewBanner!.displayEndAt)}</Descriptions.Item>
              <Descriptions.Item label="배너 버튼 액션">{selectedViewBanner.actionType}</Descriptions.Item>
              <Descriptions.Item label="배너 버튼 도착지">{selectedViewBanner.actionValue}</Descriptions.Item>
            </Descriptions>,
          </Col>
        </Row>

      </BaseModal>
    );
  } else {
    return (<></>)
  }

};

export default BannerDetailViewModal;
