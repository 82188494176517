import React from 'react';
import CustomScooterOverlay from '../CustomScooterOverlay';
import * as marker from '../marker_constants';
import { MobilityDto } from '../../../domain/mobility/mobility.interface';
import { MarkerProps, MarkerImage, Size } from '../Map';
import { LocationDto } from '../../common.interface';

const s7_ready = marker.s7_ready;
const s7_riding = marker.s7_riding;
const s9_ready = marker.s9_ready;
const s9_riding = marker.s9_riding;
const s7_partner_ready = marker.s7_partner_ready;
const s9_partner_ready = marker.s9_partner_ready;

type ScooterModel = 'S9' | 'S7'

interface ScooterMarkerType {
  AVAILABLE: string,
  RIDING: string,
  CARGO: string,
  PARTNER: string
}

type ScooterList = {
  [k in ScooterModel]: ScooterMarkerType;
}

const MARKER_IMAGE_SIZE: Size = {
  width: 30,
  height: 30
};

export function ScooterMarker(scooter: MobilityDto): MarkerProps {
  const scooterList: ScooterList = {
    S9: {
      AVAILABLE: s9_ready,
      RIDING: s9_riding,
      CARGO: s9_ready,
      PARTNER: s9_partner_ready
    },
    S7: {
      AVAILABLE: s7_ready,
      RIDING: s7_riding,
      CARGO: s7_ready,
      PARTNER: s7_partner_ready
    }
  };

  let imageUrl: string | ScooterMarkerType = scooterList[scooter.modelType as ScooterModel];
  if (scooter.band.bandId !== 1) {
    imageUrl = imageUrl['PARTNER'];
  } else {
    imageUrl = imageUrl[scooter.mobilityStatus];
  }

  const position: LocationDto = {
    latitude: scooter.location.latitude,
    longitude: scooter.location.longitude
  };

  const image: MarkerImage = {
    src: imageUrl,
    size: MARKER_IMAGE_SIZE,
    zIndex: 1
  };

  const infoWindowHTML = CustomScooterOverlay(scooter);

  return {
    identifier: 'S',
    image,
    position,
    infoWindowHTML
  };
}
