import { LocalAdminApi } from '../../services';
import { MobilityDto } from '../mobility/mobility.interface';
import { PlacementCreateDto, PlacementDto, PlacementUpdateDto } from './placement.interface';
import { Pagination } from '../../common/url/pagination';
import { buildUrl } from '../../common/url/utils';
import { cache } from '../../utils/cache';

// 모든 라운지(캠프), 픽업존 메타데이터 가쟈오기
export async function getAllPlacements() {
  return await cache.get<PlacementDto[]>(`/placements`);
}

// 모든 라운지(캠프), 픽업존 메타데이터 가쟈오기
export async function getPlacementPagination(pagination: Pagination): Promise<{
  page: number,
  totalItem: number,
  totalPage: number,
  items: PlacementDto[],
}> {
  let newDisplay = '';
  if (pagination.fields.display !== 'all') {
    newDisplay = pagination.fields.display ?? '';
  }
  const newPagination = Pagination.placementFrom(pagination, { display: newDisplay });
  return await cache.get(buildUrl('/placements', newPagination.getParams()));
}

// 특정 라운지 정보 가져오기
export async function getPlacement(id: number) {
  return await cache.get<PlacementDto>(`/placements/${id}`);
}

// 라운지 픽업존 추가
export async function addPlacement(body: PlacementCreateDto) {
  return await LocalAdminApi.post('/placements', body);
}

export async function getPlacementDescription(): Promise<{
  description: string,
}[]> {
  return await cache.get('/placements/description');
}

// 라운지, 픽업존 지우기
export async function deletePlacement(id: number) {
  return await LocalAdminApi.delete(`/placements/${id}`);
}


// 특정 라운지에 속한 픽업존 가져오기
export async function getChildrenOfPlacement(id: number) {
  return await cache.get<PlacementDto[]>(`/placements/${id}/children`);
}

// 특정 라운지 수정하기
export async function updatePlacement(id: number, body: PlacementCreateDto) {
  return await LocalAdminApi.put(`/placements/${id}`, body);
}

// 특정 placement 위치 수정하기
export async function updatePlacementLocation(id: number, body: PlacementUpdateDto) {
  return await LocalAdminApi.put(`/placements/${id}/location`, body);
}

// 이미지 업로드
export async function uploadImage(formData: FormData) {
  return await LocalAdminApi.post('/placements/upload', formData).then((resp) => {
    return resp.data;
  });
}

//특정 placement mobility 가져오기
export async function getPlacementMobilities(id: number) {
  return await cache.get<MobilityDto[]>(`/placements/${id}/mobilities`);
}

export async function putPlacementDisplayYn(placementId: number, displayYn: boolean) {
  return await LocalAdminApi.put<MobilityDto[]>(`/placements/${placementId}/display`, {
    displayYn: displayYn
  }).then((resp) => resp.data);
}
