import { LocalAdminApi } from '.';
import { HistoryDto } from '../domain/ride/ride.interface';
import { MobilityDto } from '../domain/mobility/mobility.interface';
import { cache } from '../utils/cache';

//모든 moped 가져오기

export async function getAllMopeds() {
  return await cache.get<MobilityDto[]>(`/mopeds`, 5);
}

// 특정 moped 가져오기
export async function getMoped(id: number) {
  return await cache.get<MobilityDto>(`/mopeds/${id}`, 5 * 60);
}

// 특정 moped rides history 가져오기
export async function getMopedRideHistory(id: number) {
  return await cache.get<HistoryDto[]>(`/rides?mopedId=${id}`, 5 * 60);
}