import React from 'react';
import { Card, Col, Row, Input } from 'antd';

import { SearchConditions } from '../../pages/LeaseRentalOrderList';

const { Search } = Input;

interface Props {
  setSearchConditions: React.Dispatch<React.SetStateAction<SearchConditions>>;
}

function LeaseRentalOrderSearchCard({ setSearchConditions }: Props) {

  return (
    <>
      <Row gutter={[8, 8]} justify={'center'}>
        <Col flex={1}>
          <Card
            className={'floating-card'}
            title={'주문자 검색'}
          >
            <Search
              placeholder={'라이더 이름, 핸드폰 번호'}
              onChange={(e) => setSearchConditions(prev => ({ ...prev, riderSearch: e.target.value }))}
            />
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            className={'floating-card'}
            title={'주문정보 검색'}
          >
            <Search
              placeholder={'모델이름, 상품명, 기타상품명'}
              onChange={(e) => setSearchConditions(prev => ({ ...prev, productSearch: e.target.value }))}
            />
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            className={'floating-card'}
            title={'배송정보 검색'}
          >
            <Search
              placeholder={'수령방법, 탁송주소'}
              onChange={(e) => setSearchConditions(prev => ({ ...prev, deliverySearch: e.target.value }))}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LeaseRentalOrderSearchCard;