import React, {CSSProperties} from 'react';
import {Card, Divider} from "antd";
import {TFunctionResult} from "i18next";

interface Props {
  title: TFunctionResult;
  value: string | number;
  valueUnit: string;
}

export default function MobilityCardStatistic({title, value, valueUnit}: Props) {
  const titleStyle: CSSProperties = {fontSize: '18px'}

  const valueStyle: CSSProperties = {
    fontSize: '16px'
  }

  const valueUnitStyle: CSSProperties = {
    marginLeft: '5px',
    fontSize: '16px'
  }

  return (
    <Card
      size={'small'}
      hoverable={true}
      style={{marginBottom: '4px'}}
    >
      <span style={titleStyle}>{title}</span>
      <Divider
        type={'vertical'}
      />
      <span style={valueStyle}>{value}</span>
      <span style={valueUnitStyle}>{valueUnit}</span>
    </Card>
  )
}