import React from 'react';
import {useTranslation} from "react-i18next";
import {ColumnsType} from 'antd/lib/table';
import {AimOutlined, ControlOutlined, EditOutlined, LockTwoTone, UnlockTwoTone} from '@ant-design/icons';
import BaseTable from "../../../../common/component/BaseTable";
import {getBandTag, getMobilityLendTypeTag} from "../../../../utils/organizeData";
import {MobilityDto, MobilityType} from "../../mobility.interface";
import {use} from "i18next";
import {columnMaker, columnRenderer} from "../../../../utils/componentUtils";
import {Button, Typography} from "antd";
import Copyable from "../../../../common/component/Copyable";

const {Paragraph} = Typography;

interface Props {
  scooters: MobilityDto[],
  scooterIdOnClickHandler: (id: number) => () => Promise<void>,
  handleSelectedScooterPosition: (row: MobilityDto) => void,
  handleScooterEdit: (row: MobilityDto) => void,
  setIsScooterRentalModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedRentalChangeScooter: React.Dispatch<React.SetStateAction<MobilityDto | null>>,
}

export function ScooterListTable(
  {
    scooters,
    scooterIdOnClickHandler,
    handleSelectedScooterPosition,
    handleScooterEdit,
    setIsScooterRentalModalVisible,
    setSelectedRentalChangeScooter
  }: Props
) {
  const {t} = useTranslation();
  const scooterColumns: ColumnsType<MobilityDto> = [
    columnMaker('id', t('common-scooter') + 'ID', columnRenderer(data => {
      const el: Record<MobilityType, JSX.Element> = {
        SCOOTER: <a onClick={scooterIdOnClickHandler(data.scooterId!)}>{data.scooterId}</a>,
        MOPED: <a onClick={scooterIdOnClickHandler(data.mopedId!)}>{data.mopedId}</a>
      }
      return el[data.mobilityType];
    })),
    columnMaker('band', t('common-band'), columnRenderer((data, idx) => getBandTag(data.band.bandName, idx))),
    columnMaker('modelType', '모델', columnRenderer(data => data.modelType)),
    columnMaker('shortImei', 'SIMEI', columnRenderer(data => <Copyable textVal={data.shortImei}/>)),
    columnMaker('imei', 'IMEI', columnRenderer(data => data.imei)),
    columnMaker('rental', '렌탈/리스', columnRenderer(data => (
      data.mobilityLendType ? getMobilityLendTypeTag(data.mobilityLendType) : '-'))
    ),
    columnMaker('battery', t('common-battery'),
      columnRenderer(data => data.battery + '%'),
      'center',
      (prev, next) => prev.battery - next.battery,
    ),
    columnMaker('mobilityStatus', '사용가능 여부',
      columnRenderer(data => data.mobilityStatus === 'AVAILABLE' ? "탑승 가능" : '탑승 불가')
    ),
    columnMaker('mobilityLocation', t('common-current-position'), columnRenderer(data => {
      return <AimOutlined onClick={() => handleSelectedScooterPosition(data)}/>
    })),
    columnMaker('action', '', columnRenderer(data => <EditOutlined onClick={() => handleScooterEdit(data)}/>)),
    columnMaker('moped-rental', '리스/렌탈 변경', columnRenderer(data => (
        <Button
          icon={<ControlOutlined/>}
          onClick={() => {
            setSelectedRentalChangeScooter(data);
            setIsScooterRentalModalVisible(true);
          }}
        />
      ))
    ),
  ];

  return (
    <BaseTable
      datasource={scooters.map((e, i) => {
        return {key: 'scooter-' + i, ...e}
      })}
      columnType={scooterColumns}
    />
  );
}
