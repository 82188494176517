import React, {useContext, useEffect, useState} from 'react';
import {AccountContext, SetLoadingContext} from '../../../../../context';
import {Col, Row, Card, Tabs} from 'antd'
import {ProductPreviewDto, MobilityCode} from "../../../product.interface";
import {getBandProductPreview} from "../../../product.service";
import ProductPreviewCard from "../ProductPreviewCard";
import BandOptionRadio from "../../../../../common/component/BandOptionRadio";

const {TabPane} = Tabs;

const BandProductPreviewTab = () => {

  const bandId = useContext(AccountContext)!.band.bandId;
  const setLoading = useContext(SetLoadingContext)!;

  const [selectedBand, setSelectedBand] = useState<string>(bandId.toString());
  const [bandProductPreview, setBandProductPreview] = useState<ProductPreviewDto[]>([]);
  const [mobilityPreview, setMobilityPreview] = useState<MobilityCode[]>([]);
  const [selectedMobilityPreview, setSelectedMobilityPreview] = useState<MobilityCode>('ST')

  useEffect(() => {
    getBandProductPreview(parseInt(selectedBand))
      .then(e => {
        const mobilityTypeSet: Set<MobilityCode> = new Set();

        e.forEach(product => {
          mobilityTypeSet.add(product.mobilityCode);
        });

        setMobilityPreview(Array.from(mobilityTypeSet));
        setBandProductPreview(e);
      })
      .catch(e => console.log(e));
  }, [selectedBand]);

  return (
    <Card
      title={'소속된 밴드의 노출되는 상품입니다'}
    >
      <BandOptionRadio selectedBand={selectedBand} setSelectedBand={setSelectedBand} isAll={false}/>
      <Tabs defaultActiveKey={selectedMobilityPreview}>
        <TabPane tab="킥보드" key="ST">
          <Row
            gutter={[16, 16]}
            justify={'center'}
            align={'middle'}
            style={{marginTop: '20px'}}
          >
            <Col
              span={12}>{bandProductPreview.filter(e => e.isLockLimit === false && e.mobilityCode === 'ST').map((e, i) =>
              <ProductPreviewCard product={e} key={'product-preview-limit' + i}/>)}</Col>
            <Col
              span={12}>{bandProductPreview.filter(e => e.isLockLimit === true && e.mobilityCode === 'ST').map((e, i) =>
              <ProductPreviewCard product={e} key={'product-preview-unlimit' + i}/>)}</Col>
          </Row>
        </TabPane>
        <TabPane tab="오토바이" key="MC">
          <Row
            gutter={[16, 16]}
            justify={'center'}
            align={'middle'}
            style={{marginTop: '20px'}}
          >
            <Col
              span={12}>{bandProductPreview.filter(e => e.isLockLimit === false && e.mobilityCode === 'MC').map((e, i) =>
              <ProductPreviewCard product={e} key={'product-preview-limit' + i}/>)}</Col>
            <Col
              span={12}>{bandProductPreview.filter(e => e.isLockLimit === true && e.mobilityCode === 'MC').map((e, i) =>
              <ProductPreviewCard product={e} key={'product-preview-unlimit' + i}/>)}</Col>
          </Row>
        </TabPane>
      </Tabs>

    </Card>
  );
};

export default BandProductPreviewTab;
