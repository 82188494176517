import React from 'react';
import {MobilityDto} from "../../domain/mobility/mobility.interface";


const CustomScooterOverlay = (scooter: MobilityDto) => {
  return `
    <div style='padding: 5px'>
      <div>
        <span style={{fontWeight: 'bold'}}>SIMEI:</span> ${scooter.shortImei}
      </div>
      <div><span style={{fontWeight: 'bold'}}>배터리:</span> ${scooter.battery}%
      </div>
      <div><span style={{fontWeight: 'bold'}}>상태: </span>${scooter.mobilityStatus === 'AVAILABLE' ? '사용가능' : '탑승중'}
      </div>
    </div>
  `
};

export default CustomScooterOverlay;
