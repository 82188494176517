import {MapWrapper} from "../Types";

export abstract class BaseMapPlugin {
    private map?: MapWrapper;
    private mapPromise?: Promise<MapWrapper>;
    private mapResolve?: (value: (MapWrapper | PromiseLike<MapWrapper>)) => void;

    private rerender?: () => void;

    protected getMap(): Promise<MapWrapper> {
        if (this.map) {
            return Promise.resolve(this.map);
        }

        if (this.mapPromise) {
            return this.mapPromise;
        }

        return this.mapPromise =  new Promise<MapWrapper>((res, _) => this.mapResolve = res);
    }

    protected refresh() {
        if (this.rerender) {
            this.rerender();
        }
    }

    onMapCreated(map: MapWrapper): void {
        // Manage Map
        if (this.mapResolve) {
            this.mapResolve(map);
        }

        this.map = map;
        this.onMapUpdated();
    }

    onMapUpdated() {}
    onDestroy() {}
    onInitialize() {}

    onRerender(rerender: () => void) {
        this.rerender = rerender;
    }

    render(): JSX.Element | null {
        return null;
    }
}
