import React from 'react';
import { AbstractMarkerPlugin } from './AbstractMarkerPlugin';
import { MarkerProps } from '../Map';
import { PlacementDto } from '../../../domain/placement/placement.interface';
import { getAllPlacements } from '../../../domain/placement/placement.service';
import { PlacementMarker } from '../markers/PickupMarker';
import { AddEditPlacementModal } from '../../../domain/placement/component/AddEditPlacementModal';
import { PlacementDetailModal } from '../../../domain/placement/component/PlacementDetailModal';
import { MapType } from '../Types';

export class PlacementMarkerPlugin extends AbstractMarkerPlugin<PlacementDto> {
  private mapType: MapType;

  private isEditing: boolean = false;

  constructor(initialMapType: MapType) {
    super();
    this.mapType = initialMapType;
  }

  setMapType(mapType: MapType) {
    this.mapType = mapType;
    this.refresh();
  }

  protected async getData(): Promise<PlacementDto[]> {
    return await getAllPlacements();
  }

  protected convertToMarkerProps(data: PlacementDto[]): { entity: PlacementDto; props: MarkerProps }[] {
    return data.map(s => ({
      entity: s,
      props: PlacementMarker(s)
    }));
  }

  protected renderSelectedComponent(selectedData: PlacementDto): JSX.Element {
    return <>
      <AddEditPlacementModal
        isVisible={this.isEditing}
        setIsPlacementAddModalVisible={isEditDone => {
          this.isEditing = false;
          if (isEditDone) {
            this.refresh();
          } else {
            this.setSelectedData(null);
          }
        }}
        mapType={this.mapType}
      />
      <PlacementDetailModal
        mapType={this.mapType}
        isVisible={!this.isEditing}
        handleClose={() => {
          this.setSelectedData(null);
        }}
        editModalHandler={() => {
          this.isEditing = true;
          this.refresh();
        }}
        placement={selectedData}
      />
    </>;
  }
}
