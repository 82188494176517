import {MapWrapper} from "./Types";
import {Point} from "./Map";

type Listener = (position: Point) => void;

class CursorTracker {
  private readonly maps = new Map<HTMLElement, Point>();
  private readonly listeners = new Map<HTMLElement, Listener[]>();

  register(map: MapWrapper) {
    if (this.maps.has(map.htmlElement)) return;

    map.htmlElement.addEventListener('mousemove', ({clientX, clientY}) => {
      const {left, top} = map.htmlElement.getBoundingClientRect();
      const point = {x: clientX - left, y: clientY - top};
      this.maps.set(map.htmlElement, point);
      for (const listener of this.listeners?.get(map.htmlElement) ?? []) {
        listener(point);
      }
    });
  }

  getCursorPosition(map: MapWrapper): Point | undefined {
    return this.maps.get(map.htmlElement);
  }

  addListener(map: MapWrapper, callback: Listener) {
    if (!this.listeners.has(map.htmlElement)) {
      this.listeners.set(map.htmlElement, []);
    }

    this.listeners.get(map.htmlElement)?.push(callback);
  }
}

export const cursorTracker = new CursorTracker();
