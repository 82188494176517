import { MapWrapper } from '../Types';
import { getAllScooters } from '../../../services/scoot';
import { getAllMopeds } from '../../../services/moped';
import { getAllPlacements } from '../../../domain/placement/placement.service';
import { LocationDto } from '../../common.interface';
import {BaseMapPlugin} from "./BaseMapPlugin";

export class SearchPlugin extends BaseMapPlugin {
  constructor() {
    super();
  }

  async search(query: string) {
    const scootersPromise = getAllScooters();
    const mopedsPromise = getAllMopeds();
    const placementPromise = getAllPlacements();

    const scooters = await scootersPromise;
    const mopeds = await mopedsPromise;
    const placements = await placementPromise;

    const mobility = [...scooters, ...mopeds].find(m => m.shortImei === query);
    if (mobility) {
      this.center(mobility.location);
      return;
    }

    const placement = placements.find(p => p.placementName === query);
    if (placement) {
      this.center(placement.location);
    }
  }

  private center(location: LocationDto) {
    this.getMap().then(map => map.move(location, 1));
  }
}

/*
useEffect(() => {
    let searchedLocation: LocationDto = {
      latitude: 0,
      longitude: 0
    };
    scooters.forEach(e => {
      if (e.shortImei === searchStr) {
        searchedLocation.latitude = e.location.latitude;
        searchedLocation.longitude = e.location.longitude;
      }
    });
    mopeds.forEach(e => {
      if (e.shortImei === searchStr) {
        searchedLocation.latitude = e.location.latitude;
        searchedLocation.longitude = e.location.longitude;
      }
    });
    placements.forEach(e => {
      if (e.placementName === searchStr) {
        searchedLocation.latitude = e.location.latitude;
        searchedLocation.longitude = e.location.longitude;
      }
    });
    if (searchedLocation.longitude !== 0 && searchedLocation.latitude !== 0) {
      setFocusedElementLocation({
        location: searchedLocation,
        level: 5
      });
    }
  }, [searchStr]);
 */