import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Descriptions, message, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { deleteRiderLicense, getRiderDetail, terminateDevice, terminatePass } from '../rider.service';
import RegisterFreeProductModal from './RegisterFreePass';
import BaseModal from '../../../common/component/BaseModal';
import { getBandTag, parseBirthday, parseDateTime, parsePhoneNumber } from '../../../utils/organizeData';
import { AccountContext, SetLoadingContext } from '../../../context';
import {
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined, EnterOutlined,
  HighlightOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import UpdateRiderBandModal from './UpdateRiderBandModal';
import WithdrawRiderModal from './WithdrawRiderModal';
import moment from 'moment';
import { PurchaseHistoryModal } from '../../../components/modal/payment/PaymentHistory';
import { RiderCardDto, RiderDetailDto } from '../rider.interface';
import { Pass } from '../../pass/pass.interface';
import RegisterLicenseModal from './RegisterLicenseModal';
import EditRiderModal from './EditRiderModal';
import EditPrimaryCardModal from './EditPrimaryCardModal';
import { updateTransferCount } from '../../pass/pass.service';
import EditRiderPassModal from './EditRiderPassModal';

const { Paragraph } = Typography;

interface Props {
  riderId: string;
}

export interface EditPassModal {
  row: Pass | null,
  visible: boolean
}

export const RiderDetailTab: FC<Props> = ({ riderId }) => {

  const account = useContext(AccountContext);

  const [isExpireModalVisible, setIsExpireModalVisible] = useState(false);
  const [isRegisterFreePassVisible, setIsRegisterFreePassVisible] = useState(false);
  const [isPaymentHistoryVisible, setIsPaymentHistoryVisible] = useState(false);
  const [isEditRiderModalVisible, setIsEditRiderModalVisible] = useState(false);
  const [isUpdateRiderBandModalVisible, setIsUpdateRiderBandModalVisible] = useState(false);
  const [isWithdrawRiderBandModalVisible, setIsWithdrawRiderBandModalVisible] = useState(false);
  const [isRegisterLicenseModalVisible, setIsRegisterLicenseModalVisible] = useState(false);
  const [isEditPrimaryCardModalVisible, setIsEditPrimaryCardModalVisible] = useState(false);
  const [isEditPassModal, setIsEditPassModal] = useState<EditPassModal>({ row: null, visible: false });

  const setLoading = useContext(SetLoadingContext)!;

  const [riderDetail, setRiderDetail] = useState<RiderDetailDto | null>(null);
  const [deleteProductId, setDeleteProductId] = useState('');


  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getRiderDetail(riderId)
      .then(setRiderDetail)
      .then(() => setLoading(false));
  }

  const expireModalHandleOk = () => {
    setIsExpireModalVisible(false);
  };

  const expireModalHandleClose = () => {
    setIsExpireModalVisible(false);
  };

  const showRegisterModal = () => {
    setIsRegisterFreePassVisible(true);
  };

  const registerModalHandleOk = () => {
    setIsRegisterFreePassVisible(false);
  };

  const registerModalHandleClose = () => {
    setIsRegisterFreePassVisible(false);
  };

  const paymentHistoryHandleOk = () => {
    setIsPaymentHistoryVisible(false);
  };

  const paymentHistoryHandleClose = () => {
    setIsPaymentHistoryVisible(false);
  };

  const handleDeleteLicense = () => {
    if (window.confirm('면허 정보를 삭제하시겠습니까?')) {
      deleteRiderLicense(riderId).then(() => {
        alert('면허정보가 삭제 되었습니다');
        window.location.reload();
      });
    }
  };

  const paymentColumns: ColumnsType<RiderCardDto> = [
    {
      title: '카드명',
      dataIndex: 'cardName',
      key: 'cardName',
      align: 'center'
    },
    {
      title: '카드번호',
      dataIndex: 'cardNumber',
      key: 'cardNumber',
      align: 'center'
    },
    {
      title: '주결제카드',
      dataIndex: 'isPrimary',
      key: 'isPrimary',
      align: 'center',
      render: isPrimary => isPrimary ?
        <Tag color='success'>주결제</Tag> :
        <Tag color='default'>보조용</Tag>
    },
    {
      title: '등록일자',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (val) => parseDateTime(moment(val).unix() * 1000)
    }
  ];

  const riderProductHistoryColumns: ColumnsType<Pass> = [
    {
      key: 'productType',
      title: '이용권 번호',
      align: 'center',
      render: (val, row) => row.passId
    },
    {
      key: 'productType',
      title: '자동연장여부',
      align: 'center',
      render: (val, row) => row.product.productType === 'SUBSCRIPTION' ? 'O' : 'X'
    },
    {
      key: 'mobilityCode',
      title: '기기',
      align: 'center',
      render: (val, row) => row.product.mobilityCode === 'ST' ? '킥보드' : '오토바이'
    },
    {
      key: 'productName',
      title: '사용이용권',
      align: 'center',
      render: (val, row) => {
        if (row.product) {
          let period = row.product.period;
          let tabColor = period > 1 ? period > 30 ? 'red' : 'green' : 'blue';
          return <Tag color={tabColor}>{row.product.productName}</Tag>;
        } else {
          return <Tag color='gray'>없음</Tag>;
        }
      }
    },
    {
      key: 'validAt',
      title: '이용권 사용 가능 기간',
      align: 'center',
      render: (val, row) => row.validStartAt && row.validEndAt
        ? `${parseDateTime(row.validStartAt)} ~ ${parseDateTime(row.validEndAt)}`
        : '-'
    },
    {
      key: 'applyStartAt',
      title: '이용권 활성화 시작일',
      dataIndex: 'applyStartAt',
      align: 'center',
      render: (sub) => {
        return (
          sub == null ? '-' : parseDateTime(sub)
        );
      }
    },
    {
      key: 'applyEndAt',
      title: '이용권 만료일',
      dataIndex: 'applyEndAt',
      align: 'center',
      render: (sub) => {
        return (
          sub == null ? '-' : parseDateTime(sub)
        );
      }
    },
    {
      key: 'transferCount',
      title: '사용가능 횟수',
      align: 'center',
      render: (_, row) => row.transferCount > 90000 ? '-' : row.transferCount
    },
    {
      key: 'passEdit',
      title: '수정',
      align: 'center',
      render: (_, row) => (
        <Button disabled={row.transferCount>90000} icon={<EditOutlined />} onClick={() => setIsEditPassModal({ row, visible: true })} />
      )
    },
    {
      key: 'action',
      title: '동작',
      align: 'center',
      render: (val, row) => {
        return row.product.productType === 'TRANSACTION'
          ? (<Button disabled={new Date(row.applyEndAt).getTime() <= new Date().getTime()} onClick={() => {
            terminatePass(riderId, row.passId);
            window.location.reload();
          }}>이용권만료</Button>)
          : '-';
      }
    }
  ];

  const riderCard = riderDetail ? riderDetail.payments ? riderDetail.payments.map((e, i) => {
    return { key: String(i), ...e };
  }) : [] : [];

  const riderProductHistoryData = riderDetail
    ? riderDetail.passes
      ? riderDetail.passes.map((e, i) => ({ key: String(i + 1), ...e })).sort((a, b) => b.passId - a.passId)
      : []
    : [];

  const handleUpdateRider = () => {
    setIsEditRiderModalVisible(true);
  };

  const handleRegiserLicense = () => {
    setIsRegisterLicenseModalVisible(true);
  };

  return (
    <>
      <EditPrimaryCardModal
        isVisible={isEditPrimaryCardModalVisible}
        setIsVisible={setIsEditPrimaryCardModalVisible}
        riderId={riderId}
        riderCards={riderDetail?.payments}
      />
      <UpdateRiderBandModal
        isModalVisible={isUpdateRiderBandModalVisible}
        handleCancel={setIsUpdateRiderBandModalVisible}
        bandId={riderDetail?.band.bandId}
        riderId={riderDetail?.riderId}
      />
      <WithdrawRiderModal
        isModalVisible={isWithdrawRiderBandModalVisible}
        handleCancel={setIsWithdrawRiderBandModalVisible}
        riderId={riderDetail?.riderId}
      />
      <RegisterLicenseModal riderId={riderId} isVisible={isRegisterLicenseModalVisible}
                            setIsVisible={setIsRegisterLicenseModalVisible} />
      <EditRiderModal
        riderId={riderDetail?.riderId}
        riderPhoneNo={riderDetail?.phoneNo}
        isVisible={isEditRiderModalVisible}
        setIsVisible={setIsEditRiderModalVisible}
      />
      <EditRiderPassModal
        riderId={riderId}
        setIsEditPassModal={setIsEditPassModal}
        isEditPassModal={isEditPassModal}
      />

      <Descriptions
        title={'라이더 기본정보'}
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        style={{ marginBottom: '30px' }}
        contentStyle={{ backgroundColor: 'white' }}
        labelStyle={{ backgroundColor: '#f5f5f5' }}
        extra={account?.band.bandId === 1 ? (
          <>
            <Button icon={<EditOutlined />} onClick={() => setIsEditRiderModalVisible(true)} />
            <Button icon={<UserSwitchOutlined />} onClick={() => setIsUpdateRiderBandModalVisible(true)} />
            <Button icon={<UserDeleteOutlined />} onClick={() => setIsWithdrawRiderBandModalVisible(true)}
                    danger={true} />
          </>
        ) : null}>
        <Descriptions.Item label='이름'>{riderDetail?.riderName}</Descriptions.Item>
        <Descriptions.Item label='연락처'>{parsePhoneNumber(riderDetail?.phoneNo!)}</Descriptions.Item>
        <Descriptions.Item label='성별'>{riderDetail?.gender === 'M' ? '남자' : '여자'}</Descriptions.Item>
        <Descriptions.Item label='생일'>{parseBirthday(riderDetail?.birth)}</Descriptions.Item>
        <Descriptions.Item
          label='가입일'>{parseDateTime(moment(riderDetail?.createdAt).unix() * 1000)}</Descriptions.Item>
        <Descriptions.Item
          label='소속밴드'>{riderDetail ? getBandTag(riderDetail.band.bandName, 1) : '-'}</Descriptions.Item>
        <Descriptions.Item label='등록 핸드폰 초기화'>{<Button
          onClick={() => {
            terminateDevice(riderDetail!.riderId).then(e => {
              alert('등록 핸드폰이 초기화 되었습니다');
              window.location.reload();
            });
          }}>핸드폰초기화</Button>}</Descriptions.Item>
        <Descriptions.Item label='이용중인 상품'>
          {riderDetail ? riderDetail.usingPasses ? riderDetail.usingPasses[riderDetail.usingPasses.length - 1].product.productName : '없음' : '없음'}
        </Descriptions.Item>

      </Descriptions>
      <Descriptions
        title={'라이더 면허정보'}
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        style={{ marginBottom: '30px' }}
        contentStyle={{ backgroundColor: 'white' }}
        labelStyle={{ backgroundColor: '#f5f5f5' }}
        extra={(<>
          <Button onClick={() => handleRegiserLicense()}>등록</Button>
          <Button onClick={() => handleDeleteLicense()}>
            삭제
          </Button>
        </>)}
      >
        <Descriptions.Item
          label='지역'>{riderDetail?.license ? riderDetail.license.region : '등록된 면허가 없습니다'}</Descriptions.Item>
        <Descriptions.Item
          label='면허번호'>{riderDetail?.license ? riderDetail.license.licenseNo : '등록된 면허가 없습니다'}</Descriptions.Item>
      </Descriptions>

      <div style={{ marginBottom: '30px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>등록한 카드정보</h3>
          <Button onClick={() => setIsEditPrimaryCardModalVisible(true)}>주결제 카드 변경</Button>
        </div>
        <Table
          pagination={false}
          columns={paymentColumns}
          dataSource={riderCard}
        />
      </div>

      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>사용 이용권 이력</h3>
          <Button onClick={showRegisterModal}>프리 이용권 부여</Button>
        </div>
        <Table
          columns={riderProductHistoryColumns}
          dataSource={riderProductHistoryData.map((ele, idx) => ({
            ...ele,
            key: `riderProductHistory-${idx}`
          }))}
        />
      </div>
      <RegisterFreeProductModal
        isVisible={isRegisterFreePassVisible}
        setIsVisible={setIsRegisterFreePassVisible}
        registerModalHandleOk={registerModalHandleOk}
        registerModalHandleClose={registerModalHandleClose}
        riderId={riderId}
      />
      <PurchaseHistoryModal
        isVisible={isPaymentHistoryVisible}
        paymentHistoryHandleOk={paymentHistoryHandleOk}
        paymentHistoryHandleClose={paymentHistoryHandleClose}
      />
      <BaseModal
        isVisible={isExpireModalVisible}
        handleOk={expireModalHandleOk}
        handleCancel={expireModalHandleClose}
        headerTitle={'기간 강제 만료하기'}
        okText={'만료하기'}
      >
        <p style={{ fontSize: '20px' }}>
          정말로 <strong style={{ color: '#e03131' }}>만료처리</strong> 하시겠습니까?
          <br />
          <br />
          <strong>상품의 만료여부 상태가 만료됨으로 변경됩니다.</strong>
          <div>
            <strong>test용 ProductId: {deleteProductId}</strong>
          </div>
        </p>
      </BaseModal>
    </>
  );
};
