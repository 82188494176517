import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Input, message, Modal, Row, Switch } from 'antd';
import { EditOutlined, RollbackOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';

import { getAmountNumberFormat, parsePhoneNumber } from '../../../../utils/organizeData';
import { RiderLendOrderDto, UpdateDeliveryDto } from '../../rental.interface';
import {
  putRiderLendOrderComplete,
  putRiderLendOrderConsult,
  putRiderLendOrderDelivery
} from '../../leaseRental.service';

const { Item } = Descriptions;

interface Props {
  isEditModalVisible: boolean;
  setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrder: RiderLendOrderDto;
  setForceRenderer: React.Dispatch<React.SetStateAction<boolean>>;
}

function LeaseRentalOrderEditModal({
                                     isEditModalVisible,
                                     setIsEditModalVisible,
                                     selectedOrder,
                                     setForceRenderer
                                   }: Props) {
  const [isEdit, setIsEdit] = useState(false);
  const [updateBody, setUpdateBody] = useState<UpdateDeliveryDto>({
    deliveryType: '',
    deliveryPrice: 0,
    deliveryAddress: ''
  });

  useEffect(() => {
    if (selectedOrder) {
      setIsEdit(false);
      setUpdateBody({
        deliveryType: selectedOrder.deliveryType,
        deliveryPrice: selectedOrder.deliveryPrice,
        deliveryAddress: selectedOrder.deliveryAddress ?? '-'
      });
    }
  }, [selectedOrder]);

  async function putRiderLendOrderInfo<T>(api: (id: number, body: T) => Promise<AxiosResponse<T>>, body: T, text: string) {
    try {
      await api(selectedOrder.lendOrderId, body);
      setForceRenderer(prev => !prev);
      message.success(`${text}가 성공적으로 변경되었습니다.`);
    } catch (err) {
      message.error(`${text} 변경에 실패하였습니다.`);
    }
  }

  return (
    <Modal
      width={'80%'}
      title={`${selectedOrder?.lendOrderId}번 주문내역`}
      visible={isEditModalVisible}
      onCancel={() => setIsEditModalVisible(false)}
      footer={false}
    >
      <Row style={{ marginTop: '30px' }} gutter={[12, 12]} justify={'center'}>
        <Col span={24}>
          <Descriptions
            title='리스/렌탈 주문고객'
            bordered={true}
            size={'small'}
            column={4}
          >
            <Item label={'라이더ID'}>{selectedOrder?.riderId}</Item>
            <Item label={'이름'}>{selectedOrder?.riderName}</Item>
            <Item label={'핸드폰 번호'}>{parsePhoneNumber(selectedOrder?.phone ?? '-')}</Item>
            <Item label={'상담여부'}>
              <Switch
                checkedChildren={'상담완료'}
                unCheckedChildren={'미상담'}
                defaultChecked={selectedOrder?.isConsultedYn}
                onChange={(e) => {
                  putRiderLendOrderInfo(putRiderLendOrderConsult, { isConsultedYn: e }, '상담여부');
                }}
              />
            </Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Descriptions
            title='주문정보'
            bordered={true}
            size={'small'}
            column={4}
          >
            <Item span={1} label={'모델명'}>{selectedOrder?.modelName}</Item>
            <Item span={1} label={'PCX 등급'}>{selectedOrder?.modelName === 'PCX' ? selectedOrder?.pcxGrade : '-'}</Item>
            <Item span={2} label={'수령여부'}>
              <Switch
                checkedChildren={'수령완료'}
                unCheckedChildren={'미수령'}
                defaultChecked={selectedOrder?.isOrderCompletedYn}
                onClick={(e) => {
                  putRiderLendOrderInfo(putRiderLendOrderComplete, { isOrderCompletedYn: e }, '수령상태');
                }}
              />
            </Item>
            <Item label={'상품명'}>{selectedOrder?.lendProductName}</Item>
            <Item label={'상품가격'}>{getAmountNumberFormat(selectedOrder?.lendProductPrice ?? '-')}</Item>
            <Item label={'기타상품명'}>{selectedOrder?.lendExtraProductName}</Item>
            <Item label={'기타상품가격'}>{getAmountNumberFormat(selectedOrder?.lendExtraProductPrice ?? '-')}</Item>
            <Item span={2} label={'기간'}>{selectedOrder?.periodType}</Item>
            <Item span={2} label={'보험'}>{selectedOrder?.insuranceType}</Item>
          </Descriptions>
        </Col>

        <Col span={24}>
          <Descriptions
            title='배송정보'
            bordered={true}
            size={'small'}
            column={2}
            extra={<>
              {isEdit && <Button style={{ marginRight: '10px' }} type={'primary'} onClick={() => {
                if (window.confirm('수정하시겠습니까?')) {
                  putRiderLendOrderInfo(putRiderLendOrderDelivery, updateBody, '배송정보').then(() => setIsEdit(false));
                }
              }}>수정</Button>}
              <Button
                icon={isEdit ? <RollbackOutlined /> : <EditOutlined />}
                onClick={() => setIsEdit(prev => !prev)}
              />
            </>}
          >
            <Item label={'수령방법'}>
              {isEdit
                ? <Input value={updateBody.deliveryType} onChange={(e) => setUpdateBody(prev => (
                  { ...prev, deliveryType: e.target.value }))} />
                : selectedOrder?.deliveryType !== updateBody.deliveryType
                  ? updateBody.deliveryType
                  : selectedOrder?.deliveryType}
            </Item>
            <Item label={'탁송비'}>
              {isEdit
                ? <Input value={updateBody.deliveryPrice} type={'number'} onChange={(e) => setUpdateBody(prev => (
                  { ...prev, deliveryPrice: Number(e.target.value) }))} />
                : selectedOrder?.deliveryPrice !== updateBody.deliveryPrice
                  ? getAmountNumberFormat(updateBody.deliveryPrice)
                  : getAmountNumberFormat(selectedOrder?.deliveryPrice ?? '-')
              }
            </Item>
            <Item label={'탁송 요청 주소'}>
              {isEdit
                ? <Input value={updateBody.deliveryAddress} onChange={(e) => setUpdateBody(prev => (
                  { ...prev, deliveryAddress: e.target.value }))} />
                : selectedOrder?.deliveryAddress !== updateBody.deliveryAddress
                  ? updateBody.deliveryAddress
                  : selectedOrder?.deliveryAddress ?? '-'}
            </Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>);
}

export default LeaseRentalOrderEditModal;