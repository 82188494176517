import React, { CSSProperties } from 'react';
import { Col, Divider, Radio, RadioChangeEvent, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';

type buttonColumn = { text: string, count?: number, value?: string }[];

interface Prop {
  column: buttonColumn[],
  radioGroupValue: string,
  onRadioChange: (e: RadioChangeEvent) => void,
  arrow?: boolean,
  size?: 'small' | 'middle' | 'large',
  textAlign?: 'start' | 'center' | 'end',
  btnSpan?: number,
  buttonStyle?: 'solid' | 'outline',
  style?: CSSProperties
}

export function MobilityFilterButton(
  {
    column,
    radioGroupValue,
    onRadioChange,
    arrow,
    size = 'middle',
    textAlign = 'start',
    btnSpan = 24,
    buttonStyle = 'outline',
    style
  }: Prop) {

  const fontSz = size === 'large' ? '16px' : size === 'middle' ? '0.7vw' : '0.5vw';
  const paddingSz = size === 'large' ? '10px' : size === 'middle' ? '0.4vw' : '0.1vw';

  return (
    <Radio.Group value={radioGroupValue} style={{ width: '100%' }} onChange={onRadioChange} buttonStyle={buttonStyle}>

      <Row gutter={[8, 8]} wrap>
        {column.map((buttonColumn, btnColIdx) => (
          <Col
            style={{ minWidth: '200px' }}
            span={btnSpan}
            key={`mobility-filter-radio-btn-${btnColIdx}`}>
            <Radio.Button
              value={buttonColumn[0].value}
              style={{ width: '100%', height: '100%', padding: `${paddingSz} 10px`, ...style }}
            >
              {buttonColumn[0].count === 0 || buttonColumn[0].count ?
                <Row style={{ textAlign: textAlign }} align={'middle'} justify={'space-between'} gutter={8}>
                  {buttonColumn.map((column, idx) => (
                    <Col span={arrow ? 22 / buttonColumn.length : 24} key={`mobility-filter-btn-${idx}`}>
                      <span style={{ fontSize: fontSz }}>{column.text}</span>
                      <Divider type={'vertical'} />
                      <span style={{ fontSize: fontSz }}>{column.count}</span>
                      <span style={{ marginLeft: '5px', fontSize: fontSz }}>대</span>
                    </Col>
                  ))}
                  {arrow &&
                    <Col span={2} style={{ textAlign: 'center' }}>
                      <RightOutlined />
                    </Col>
                  }
                </Row>
                : <Col flex={1} style={{ textAlign: textAlign }}>
                  <span style={{ fontSize: fontSz }}>{buttonColumn[0].text}</span>
                </Col>}
            </Radio.Button>
          </Col>
        ))}
      </Row>

    </Radio.Group>);
}