import React, { useContext, useEffect, useState } from 'react';
import { message, PageHeader } from 'antd';

import { parseSearchPhoneNumber } from '../../../utils/organizeData';
import { getAllLendOrders } from '../leaseRental.service';
import { RiderLendOrderDto } from '../rental.interface';

import LeaseRentalOrderTable from '../components/order/LeaseRentalOrderTable';
import LeaseRentalOrderSearchCard from '../components/order/LeaseRentalOrderSearchCard';
import LeaseRentalOrderEditModal from '../components/order/LeaseRentalOrderEditModal';
import { AccountContext } from '../../../context';
import { useHistory } from 'react-router-dom';


export interface SearchConditions {
  riderSearch: string;
  productSearch: string;
  deliverySearch: string;
}

function LeaseRentalOrderList() {
  const history = useHistory();
  const admin = useContext(AccountContext);
  if (admin?.role !== 'ADMIN') {
    history.push('/riders');
  }

  const [orders, setOrders] = useState<RiderLendOrderDto[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<RiderLendOrderDto[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<RiderLendOrderDto | null>(null);
  const [forceRenderer, setForceRenderer] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const [searchConditions, setSearchConditions] = useState<SearchConditions>({
    riderSearch: '',
    productSearch: '',
    deliverySearch: ''
  });

  useEffect(() => {
    bootstrap();
  }, [forceRenderer]);

  async function bootstrap() {
    try {
      const orders = await getAllLendOrders();
      setOrders(orders.data);
      setFilteredOrders(orders.data);
    } catch (err) {
      message.error('정보를 불러오지 못했습니다,');
    }
  }

  useEffect(() => {
    setFilteredOrders(
      orders
        .filter(ele => {
          return (
            (searchConditions.riderSearch === '' && true)
            || parseSearchPhoneNumber(ele.phone).includes(parseSearchPhoneNumber(searchConditions.riderSearch))
            || ele.riderName.includes(searchConditions.riderSearch)
          );
        })
        .filter(ele => (
          (searchConditions.productSearch === '' && true)
          || ele.modelName.includes(searchConditions.productSearch)
          || ele.lendProductName.includes(searchConditions.productSearch)
          || ele.lendExtraProductName.includes(searchConditions.productSearch)
          || ele.pcxGrade?.includes(searchConditions.productSearch)
        ))
        .filter(ele => (
          (searchConditions.deliverySearch === '' && true)
          || ele.deliveryAddress?.includes(searchConditions.deliverySearch)
          || ele.deliveryType.includes(searchConditions.deliverySearch)
        ))
    );
  }, [orders, searchConditions]);

  return (
    <>
      <PageHeader
        title={'주문내역'}
        subTitle={
          `${filteredOrders.length}명`
        }
        style={{ padding: 0 }}
      />
      <LeaseRentalOrderEditModal
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisible={setIsEditModalVisible}
        selectedOrder={selectedOrder!}
        setForceRenderer={setForceRenderer}
      />
      <LeaseRentalOrderSearchCard
        setSearchConditions={setSearchConditions}
      />
      <LeaseRentalOrderTable
        filteredOrders={filteredOrders}
        setIsEditModalVisible={setIsEditModalVisible}
        setSelectedOrder={setSelectedOrder}
      />
    </>
  );
}

export default LeaseRentalOrderList;