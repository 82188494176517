import CustomMopedOverlay from '../CustomMopedOverlay';
import {
  moped_bs_ready,
  moped_bs_riding,
  moped_bs_lease_rental,
  moped_niu_ready,
  moped_niu_riding,
  moped_niu_lease_rental,
  moped_pcx_lease_rental,
  moped_pcx_ready,
  moped_pcx_riding
} from '../marker_constants';
import { MobilityDto } from '../../../domain/mobility/mobility.interface';
import { MarkerImage, MarkerProps, Size } from '../Map';
import { LocationDto } from '../../common.interface';

const MARKER_IMAGE_SIZE: Size = {
  width: 30,
  height: 30
};

type MopedModel = 'R1-Lite' | 'NIU' | 'PCX';

interface MopedMarkerType {
  LEASE_RENTAL: string,
  AVAILABLE: string,
  RIDING: string
}

type MopedList = {
  [k in MopedModel]: MopedMarkerType;
}

export const MopedMarker = (moped: MobilityDto): MarkerProps => {
  const mopedList: MopedList = {
    'R1-Lite': {
      LEASE_RENTAL: moped_bs_lease_rental,
      AVAILABLE: moped_bs_ready,
      RIDING: moped_bs_riding
    },
    NIU: {
      LEASE_RENTAL: moped_niu_lease_rental,
      AVAILABLE: moped_niu_ready,
      RIDING: moped_niu_riding
    },
    PCX: {
      LEASE_RENTAL: moped_pcx_lease_rental,
      AVAILABLE: moped_pcx_ready,
      RIDING: moped_pcx_riding
    }
  };

  let imageUrl: string | MopedMarkerType = mopedList[moped.modelType as MopedModel];
  if (moped.mobilityLendType === 'LEASE' || moped.mobilityLendType === 'RENTAL') {
    imageUrl = imageUrl['LEASE_RENTAL'];
  } else if (moped.mobilityStatus === 'AVAILABLE') {
    imageUrl = imageUrl['AVAILABLE'];
  } else {
    imageUrl = imageUrl['RIDING'];
  }

  const image: MarkerImage = {
    src: imageUrl,
    size: MARKER_IMAGE_SIZE,
    zIndex: 1
  };

  const position: LocationDto = {
    latitude: moped.location?.latitude,
    longitude: moped.location?.longitude
  };

  const infoWindowHTML = CustomMopedOverlay(moped);

  return {
    identifier: 'M',
    position,
    image,
    infoWindowHTML
  };
};