import {compareLocationDto, LocationDto} from "../common.interface";
import {WorkHistory} from "../work_history/work_history";
import {AppendWork} from "./append_work";
import {ArrayList} from "../../utils/array_list";
import {DeleteWork} from "./delete_work";
import {EditWork} from "./edit_work";

export class Polygon {
  private readonly coordinates: LocationDto[] = []

  private constructor(coordinates: LocationDto[]) {
    this.coordinates = coordinates;
  }

  getCoordinates(): LocationDto[] {
    return this.coordinates;
  }

  static Builder = class Builder extends WorkHistory {
    private readonly coordinates: ArrayList<LocationDto> = new ArrayList<LocationDto>(compareLocationDto);

    append(coordinate: LocationDto): Builder {
      this.appendHistory(new AppendWork(coordinate, this.coordinates));
      return this;
    }

    insert(index: number, coordinate: LocationDto): Builder {
      this.coordinates.insert(index, coordinate);
      return this;
    }

    delete(coordinate: LocationDto) {
      try {
        this.appendHistory(new DeleteWork(coordinate, this.coordinates));
      } catch (unused) {}
      return this;
    }

    edit(original: LocationDto, target: LocationDto) {
      try {
        this.appendHistory(new EditWork(original, target, this.coordinates));
      } catch (unused) {}
      return this;
    }

    popFirst(count: number = 1) {
      this.coordinates.removeAt(0, count);
      return this;
    }

    clear() {
      this.coordinates.clear();
    }

    move(lat: number, lng: number) {
      this.coordinates.forEach(c => {
        c.latitude += lat;
        c.longitude += lng;
      });
    }

    build(): Polygon {
      return new Polygon(this.coordinates.getArray());
    }
  }
}

export declare namespace Polygon {
  type Builder = typeof Polygon.Builder.prototype;
}

