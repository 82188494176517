import React, { useContext, useEffect, useState } from 'react';
import {Button, Card, Divider, Input, PageHeader, Tabs, Tag} from "antd";
import {MobilityType} from "../../mobility/mobility.interface";
import {MopedLeaseRentalDto, ScooterLeaseRentalDto} from "../rental.interface";
import {getAllMopedLeaseRental, getAllScooterLeaseRental} from "../leaseRental.service";
import moment from "moment";
import {PlusOutlined} from "@ant-design/icons";
import AddLeaseContractModal from "../components/AddLeaseContractModal";
import LeaseRentalTable from "../components/LeaseRentalTable";
import LeaseRentalDetailModal from "../components/LeaseRentalDetailModal";
import EditLeaseContractModal from "../components/EditLeaseContractModal";
import { AccountContext } from '../../../context';
import { useHistory } from 'react-router-dom';

const {TabPane} = Tabs;

export function LeaseRentalPage() {
  const history = useHistory();
  const admin = useContext(AccountContext);
  if (admin?.role !== 'ADMIN') {
    history.push('/riders');
  }

  const [tabVal, setTabVal] = useState<MobilityType>("SCOOTER");
  const [isAddLeaseContractModalVisible, setIsAddLeaseContractModalVisible] = useState(false);
  const [isLeaseRentalDetailModalVisible, setIsLeaseRentalDetailModalVisible] = useState(false);
  const [isLeaseRentalEditModalVisible, setIsLeaseRentalEditModalVisible] = useState(false);
  const [selectedLeaseRental, setSelectedLeaseRental] = useState<ScooterLeaseRentalDto | MopedLeaseRentalDto | null>(null);
  const [searchStr, setSearchStr] = useState('');
  const [leaseRentals, setLeaseRentals] = useState<ScooterLeaseRentalDto[]>([]);

  useEffect(() => {
    if (tabVal === 'SCOOTER') {
      getAllScooterLeaseRental().then(setLeaseRentals);
    } else {
      getAllMopedLeaseRental().then(setLeaseRentals);
    }
  }, [tabVal]);

  const filteredLeaseRentals = leaseRentals.filter(e => e.rider.riderName.indexOf(searchStr) !== -1);
  const overdues = leaseRentals.filter(e => {
    const nowDate = moment().format("YYYY-MM-DD 23:59:59").toString();
    const now = new Date(nowDate).getTime();
    const nextAt = new Date(e.nextAt).getTime();
    return nextAt <= now && e.returnAt == null;
  });
  const overdueTags = overdues.map((e, i) => (
    <Tag
      key={'overdue-tag-' + i} color={'red'}
      onClick={() => setSearchStr(e.rider.riderName)}
    >
      {e.rider.riderName}
    </Tag>
  ));

  const leaseCount = filteredLeaseRentals.filter(e => e.mobilityLendType === 'LEASE').length;
  const rentalCount = filteredLeaseRentals.filter(e => e.mobilityLendType === 'RENTAL').length;

  return (
    <div>
      <Tabs defaultActiveKey="SCOOTER" onChange={e => setTabVal(e as MobilityType)}>
        <TabPane tab="킥보드" key="SCOOTER"/>
        <TabPane tab="오토바이" key="MOPED"/>
      </Tabs>
      <LeaseRentalDetailModal
        tabVal={tabVal}
        isVisible={isLeaseRentalDetailModalVisible}
        setIsVisible={setIsLeaseRentalDetailModalVisible}
        selectedLeaseRental={selectedLeaseRental}
        setSelectedLeaseRental={setSelectedLeaseRental}
      />
      <EditLeaseContractModal
        tabVal={tabVal}
        selectedLeaseRental={selectedLeaseRental}
        setSelectedLeaseRental={setSelectedLeaseRental}
        isLeaseRentalEditModalVisible={isLeaseRentalEditModalVisible}
        setIsLeaseRentalEditModalVisible={setIsLeaseRentalEditModalVisible}
      />
      <AddLeaseContractModal
        tabVal={tabVal}
        isVisible={isAddLeaseContractModalVisible}
        setIsVisible={setIsAddLeaseContractModalVisible}
      />
      <PageHeader
        title={'리스/렌탈'}
        subTitle={
          `${filteredLeaseRentals.length}명 (${leaseCount} / ${rentalCount})`
        }
        style={{padding: 0}}
        extra={[
          <Button
            key={'lease-rental-add-button'}
            icon={<PlusOutlined/>}
            onClick={() => setIsAddLeaseContractModalVisible(true)}
          >
            리스계약 추가
          </Button>
        ]}
      />
      <Card
        title={'고객 필터'}
      >
        <Input
          value={searchStr}
          onChange={e => setSearchStr(e.target.value)}
          placeholder={'고객님의 성함을 입력해주세요'}
        />
        <Divider>미결제 고객</Divider>
        {overdueTags}
      </Card>
      <LeaseRentalTable
        tabVal={tabVal}
        setSelectedLeaseRental={setSelectedLeaseRental}
        setIsLeaseRentalDetailModalVisible={setIsLeaseRentalDetailModalVisible}
        setIsLeaseRentalEditModalVisible={setIsLeaseRentalEditModalVisible}
        leaseRentals={filteredLeaseRentals}
      />
    </div>
  );
}

