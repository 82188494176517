import marker_lounge from '../../images/pickup/lounge.png'
import marker_lounge_partner from '../../images/pickup/partner/lounge_partner.png';

import marker_pickup_all from '../../images/pickup/pickup_scooter_moped.png'
import marker_pickup_default from '../../images/pickup/pickup_default.png';
import marker_pickup_scooter from '../../images/pickup/pickup_scooter.png'
import marker_pickup_moped from '../../images/pickup/pickup_moped.png'

import marker_pickup_partner_all from '../../images/pickup/partner/pickup_partner_scooter_moped.png'
import marker_pickup_partner_default from '../../images/pickup/partner/pickup_partner_default.png'
import marker_pickup_partner_scooter from '../../images/pickup/partner/pickup_partner_scooter.png'
import marker_pickup_partner_moped from '../../images/pickup/partner/pickup_partner_moped.png'

import marker_s7_ready from '../../images/scooter/s7_ready.png';
import marker_s7_riding from '../../images/scooter/s7_riding.png';
import marker_s9_ready from '../../images/scooter/s9_ready.png';
import marker_s9_riding from '../../images/scooter/s9_riding.png';
import marker_S9_partner_ready from '../../images/scooter/partner/s9_ready_partner.png';
import marker_S7_partner_riding from '../../images/scooter/partner/s7_ready_partner.png';

import marker_dl_ready from '../../images/moped/dl_ready.png'
import marker_dl_riding from '../../images/moped/dl_riding.png'
import marker_bs_ready from '../../images/moped/bs_ready.png'
import marker_bs_riding from '../../images/moped/bs_riding.png'
import marker_bs_lease_rental from '../../images/moped/partner/bs_ready_lease.png'
import marker_niu_ready from '../../images/moped/niu_ready.png'
import marker_niu_riding from '../../images/moped/niu_riding.png'
import marker_niu_lease_rental from '../../images/moped/partner/niu_ready_lease.png'
import marker_pcx_ready from '../../images/moped/pcx_ready.png'
import marker_pcx_riding from '../../images/moped/pcx_riding.png'
import marker_pcx_lease_rental from '../../images/moped/partner/pcx_ready_lease.png'

import marker_red_dot from '../../images/red_dot.png';
import marker_path_start from '../../images/path/path_start.png'
import marker_path_riding from '../../images/path/path_riding.png'
import marker_path_end from '../../images/path/path_end.png'

export const red_dot = marker_red_dot;

// lounge image
export const lounge = marker_lounge;
export const lounge_partner = marker_lounge_partner;

// pickup image
export const pickup_default = marker_pickup_default;
export const pickup_all = marker_pickup_all
export const pickup_scooter = marker_pickup_scooter
export const pickup_moped = marker_pickup_moped

// partner pickup image
export const pickup_partner_default = marker_pickup_partner_default;
export const pickup_partner_all = marker_pickup_partner_all
export const pickup_partner_scooter = marker_pickup_partner_scooter
export const pickup_partner_moped = marker_pickup_partner_moped

// scooter image
export const s7_ready = marker_s7_ready;
export const s7_riding = marker_s7_riding;
export const s9_ready = marker_s9_ready;
export const s9_riding = marker_s9_riding;
export const s7_partner_ready = marker_S7_partner_riding;
export const s9_partner_ready = marker_S9_partner_ready;

// moped image
export const moped_dl_ready = marker_dl_ready;
export const moped_dl_riding = marker_dl_riding;
export const moped_bs_ready = marker_bs_ready;
export const moped_bs_riding = marker_bs_riding;
export const moped_bs_lease_rental = marker_bs_lease_rental;
export const moped_niu_ready = marker_niu_ready;
export const moped_niu_riding = marker_niu_riding;
export const moped_niu_lease_rental = marker_niu_lease_rental;
export const moped_pcx_ready = marker_pcx_ready;
export const moped_pcx_riding = marker_pcx_riding;
export const moped_pcx_lease_rental = marker_pcx_lease_rental;

// path image
export const path_start = marker_path_start;
export const path_riding = marker_path_riding;
export const path_end = marker_path_end;
