import React, {FC, useState} from 'react';
import {Badge, Space, Table} from 'antd';
import BaseModal from '../../../../common/component/BaseModal';
import {ColumnsType} from 'antd/es/table';
import AddMasterProductModal from "./AddMasterProductModal";
import {deleteMasterProduct} from "../../product.service";
import {DEFAULT_TABLE_SIZE} from "../../../../constants";
import {ProductDto} from "../../product.interface";
import { parseUsagePeriodUnit } from '../../../../utils/organizeData';
import {columnMaker, columnRenderer, domainModalDisplayer} from "../../../../utils/componentUtils";

interface Props {
  datasource: ProductDto[];
}

const MasterProductTable: FC<Props> = ({datasource}) => {

  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [editMasterProduct, setEditMasterProduct] = useState<ProductDto | null>(null);
  const [delMasterProduct, setDelMasterProduct] = useState<ProductDto | null>(null);

  const modalClose = () => {
    setIsEditModalVisible(false);
    setIsDeleteModalVisible(false);
  };

  const handleDelete = async () => {
    if (delMasterProduct?.productId) {
      try {
        await deleteMasterProduct(delMasterProduct.productId.toString())
        alert("성공적으로 삭제하였습니다");
        window.location.reload();
      } catch (err) {
        alert("삭제에 실패하였습니다 : " + err);
      }
    }
  }

  const columns: ColumnsType<ProductDto> = [
    columnMaker('id', '상품 ID', columnRenderer(data => data.productId)),
    columnMaker('productType', '요금제', columnRenderer(data => data.productType === 'TRANSACTION' ? '종량제' : '정액제')),
    columnMaker('mobilityCode', '요금기기제', columnRenderer(data => data.mobilityCode === 'MC' ? '오토바이' : '킥보드')),
    columnMaker('productName', '마스터상품명', columnRenderer(data => data.title), 'left'),
    columnMaker('period', '유효기간', columnRenderer(data => data.period)),
    columnMaker('unit', '단위', columnRenderer(data => parseUsagePeriodUnit(data.unit))),
    columnMaker('displaying', '노출상태', columnRenderer(data => data.displaying
      ? <Badge status="success" text="노출중"/>
      : <Badge status="default" text="미노출"/>
    )),
    columnMaker('saleStatus', '판매상태', columnRenderer(data => data.saleStatus === 'ONSALE'
      ? <Badge status="success" text="판매중"/>
      : <Badge status="default" text="미판매"/>)
    ),
    columnMaker('updateProduct', '수정 및 삭제', columnRenderer(data => {
      return (
        <Space size="middle">
          <a onClick={domainModalDisplayer(
            setEditMasterProduct,
            setIsEditModalVisible,
            data,
            true
          )}>수정</a>
          <a onClick={domainModalDisplayer(
            setDelMasterProduct,
            setIsDeleteModalVisible,
            data,
            true
          )}>삭제</a>
        </Space>
      )
    })),
  ];



  return (
    <>
      <Table
        bordered={true}
        rowKey={'key'}
        columns={columns}
        dataSource={
          datasource.map((r, i) => ({key: String(i + 1), ...r,}))
        }
        expandable={{
          expandedRowRender: record => <p style={{margin: 0}}>{
            record.description
          }</p>
        }}
        pagination={{
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
      <BaseModal
        headerTitle={'상품 삭제'}
        isVisible={isDeleteModalVisible}
        handleOk={() => handleDelete()}
        handleCancel={() => modalClose()}
      >
        <p style={{fontSize: '16px'}}>
          정말 <strong style={{color: '#f03e3e'}}>삭제</strong> 하시겠습니까?
        </p>
      </BaseModal>
      <AddMasterProductModal
        isVisible={isEditModalVisible}
        setIsVisible={setIsEditModalVisible}
        editMasterProduct={editMasterProduct}
      />
    </>
  );
};

export default MasterProductTable;
