import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, SetLoadingContext } from '../../../context';
import { Card, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { getDashboardPurchaseList } from '../dashboard.service';
import { DashboardSalesTable } from '../DashboardSalesTable';
import { DashboardPurchase, OrganizedPurchaseDashboard } from '../dashboard.interface';
import PurchaseDashboardDailyChart from '../component/PurchaseDashboardDailyChart';
import 'antd/dist/antd.css';
import * as purchaseDashboardFilters from '../dashboard_utils/purchase_dashboard/filters';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

function organizePurchaseData(purchases: DashboardPurchase[]) {
  const bandIdSet = new Set<number>();
  purchases.forEach(e => bandIdSet.add(e.bandId));
  const bandIds = Array.from(bandIdSet).sort((a, b) => a - b);

  const organizedPurchasesByBand: OrganizedPurchaseDashboard[] = [];

  bandIds.forEach(e => {
    const purchasesByBand = purchaseDashboardFilters.getPurchaseDataByBand(purchases, e);
    const scooterPassPurchase = purchaseDashboardFilters.getScooterPassPurchases(purchasesByBand);
    const mopedPassPurchase = purchaseDashboardFilters.getMopedPassPurchases(purchasesByBand);
    organizedPurchasesByBand.push({
      bandId: e,
      passes: {
        scooter: {
          hour: purchaseDashboardFilters.getPassPurchaseByPeriod(scooterPassPurchase, 1, 'H'),
          one: purchaseDashboardFilters.getPassPurchaseByPeriod(scooterPassPurchase, 1, 'D'),
          seven: purchaseDashboardFilters.getPassPurchaseByPeriod(scooterPassPurchase, 7, 'D'),
          thirty: purchaseDashboardFilters.getPassPurchaseByPeriod(scooterPassPurchase, 30, 'D'),
          ninety: purchaseDashboardFilters.getPassPurchaseByPeriod(scooterPassPurchase, 90, 'D'),
        },
        moped: {
          hour: purchaseDashboardFilters.getPassPurchaseByPeriod(mopedPassPurchase, 1, 'H'),
          one: purchaseDashboardFilters.getPassPurchaseByPeriod(mopedPassPurchase, 1, 'D'),
          seven: purchaseDashboardFilters.getPassPurchaseByPeriod(mopedPassPurchase, 7, 'D'),
          thirty: purchaseDashboardFilters.getPassPurchaseByPeriod(mopedPassPurchase, 30, 'D'),
          ninety: purchaseDashboardFilters.getPassPurchaseByPeriod(mopedPassPurchase, 90, 'D'),
        }
      },
      penalties: {
        return: {
          scooter: purchaseDashboardFilters.getScooterReturnPenaltyPurchases(purchasesByBand),
          moped: purchaseDashboardFilters.getMopedReturnPenaltyPurchases(purchasesByBand)
        },
        other: purchaseDashboardFilters.getPenaltyPurchases(purchasesByBand)
      },
      ondemands: {
        scooter: purchaseDashboardFilters.getScooterOndemandPurchases(purchasesByBand),
        moped: purchaseDashboardFilters.getMopedOndemandPurchases(purchasesByBand),
      },
      lease: {
        scooter: purchaseDashboardFilters.getLeasePurchaseByMobilityType(purchasesByBand, 'ST'),
        moped: purchaseDashboardFilters.getLeasePurchaseByMobilityType(purchasesByBand, 'MC')
      },
      rental: {
        scooter: purchaseDashboardFilters.getRentalPurchaseByMobilityType(purchasesByBand, 'ST'),
        moped: purchaseDashboardFilters.getRentalPurchaseByMobilityType(purchasesByBand, 'MC')
      },
      others: purchaseDashboardFilters.getOtherPurchases(purchasesByBand)
    });
  });
  return organizedPurchasesByBand;
}

const DashboardPage = () => {

  const admin = useContext(AccountContext);
  const setLoading = useContext(SetLoadingContext)!;

  const [purchases, setPurchases] = useState<DashboardPurchase[]>([]);
  const [filteredPurchases, setFilteredPurchases] = useState<DashboardPurchase[]>([]);

  const [salesPeriod, setSalesPeriod] = useState<string[]>([]);

  const [organizedData, setOrganizedData] = useState<OrganizedPurchaseDashboard[]>([]);

  useEffect(() => {
    bootstrap();
  }, [admin]);

  async function bootstrap() {
    setLoading(true);
    getDashboardPurchaseList().then(e => {
      setPurchases(e);
      setFilteredPurchases(e);
    }).then(() => setLoading(false));
  }

  const handleSalesPeriod = (e: any) => {
    const from = e[0].format(dateFormat + ' 00:00:00');
    const to = e[1].format(dateFormat + ' 23:59:59');
    setSalesPeriod([from, to]);
  };

  useEffect(() => {
    const [from, to] = salesPeriod;
    const fromTime = new Date(from).getTime();
    const toTime = new Date(to).getTime();
    const newFilteredPurchases = purchases.filter(e => {
      const purchaseAtTime = new Date(e.purchaseAt).getTime();
      return purchaseAtTime >= fromTime && purchaseAtTime <= toTime;
    });
    setFilteredPurchases(newFilteredPurchases);
  }, [salesPeriod]);

  useEffect(() => {
    const tempPurchases = organizePurchaseData(filteredPurchases);
    setOrganizedData(tempPurchases);
  }, [filteredPurchases]);

  return (
    <>
      <Card
        title={'매출'}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <RangePicker
              style={{ width: '100%' }}
              format={dateFormat}
              disabledDate={(cur) => cur && cur < moment('2021-08-23').endOf('day')}
              onChange={(e) => handleSalesPeriod(e)}
            />
          </Col>
          <Col span={24}>
            <DashboardSalesTable
              organizedData={organizedData}
            />
          </Col>
        </Row>
        <PurchaseDashboardDailyChart />
      </Card>
    </>
  );
};

export default DashboardPage;