import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Input, message, Modal, Row, Select } from 'antd';
import { EditOutlined, RollbackOutlined } from '@ant-design/icons';

import { RiderLendConsultDto } from '../../rental.interface';
import { parsePhoneNumber } from '../../../../utils/organizeData';
import { putRiderLendConsult } from '../../leaseRental.service';

const { Item } = Descriptions;
const { TextArea } = Input;

interface Props {
  selectedConsult: RiderLendConsultDto | null;
  isDetailModalVisible: boolean;
  setIsDetailModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setForceRenderer: React.Dispatch<React.SetStateAction<boolean>>;
}

function LeaseRentalConsultDetailModal({
                                         selectedConsult,
                                         isDetailModalVisible,
                                         setIsDetailModalVisible,
                                         setForceRenderer
                                       }: Props) {

  const [isEdit, setIsEdit] = useState(false);
  const [updateBody, setUpdateBody] = useState({
    consultant: '',
    consultContent: '',
    consultStatus: ''
  });

  useEffect(() => {
    if (selectedConsult) {
      setUpdateBody({
        consultant: selectedConsult.consultant ?? '-',
        consultContent: selectedConsult.consultContent ?? '-',
        consultStatus: selectedConsult.consultStatus
      });
    }
    setIsEdit(false);
  }, [selectedConsult]);

  async function onSubmit() {
    if (window.confirm('수정하시겠습니까?')) {
      try {
        await putRiderLendConsult(selectedConsult?.consultId!, updateBody);
        message.success('성공적으로 수정하였습니다.');
        setForceRenderer(prev => !prev);
        setIsDetailModalVisible(false);
      } catch (err) {
        message.error('수정에 실패하였습니다.');
      }
    }
  }

  return (
    <Modal
      width={'80%'}
      title={`${selectedConsult?.consultId}번 상담내역`}
      visible={isDetailModalVisible}
      onCancel={() => setIsDetailModalVisible(false)}
      footer={isEdit
        && <Button type={'primary'} onClick={() => onSubmit()}>수정</Button>
      }
    >
      <Row style={{ marginTop: '30px' }} gutter={[12, 12]} justify={'center'}>
        <Col span={24}>
          <Descriptions
            title='리스/렌탈 상담고객'
            bordered={true}
            size={'small'}
            column={3}
            extra={<Button
              icon={isEdit ? <RollbackOutlined /> : <EditOutlined />}
              onClick={() => setIsEdit(prev => !prev)} />}
          >
            <Item label={'라이더ID'}>{selectedConsult?.riderId}</Item>
            <Item label={'이름'}>{selectedConsult?.clientName}</Item>
            <Item label={'핸드폰 번호'}>{parsePhoneNumber(selectedConsult?.clientPhoneNo ?? '-')}</Item>
            <Item label={'계약형태'}>{selectedConsult?.contractType}</Item>
            <Item span={2} label={'연락가능시간'}>{selectedConsult?.contactTime}</Item>
            <Item label={'개인정보수집동의'}>{selectedConsult?.agreePrivacy ? '동의' : '미동의'}</Item>
            <Item label={'마케팅활용동의'}>{selectedConsult?.agreeMarketing ? '동의' : '미동의'}</Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Descriptions
            title='리스/렌탈 상담정보'
            bordered={true}
            size={'small'}
            column={3}
          >
            <Item label={'상담자'}>
              {isEdit
                ? <Input
                  value={updateBody.consultant}
                  onChange={(e) => {
                    setUpdateBody(prev => ({ ...prev, consultant: e.target.value }));
                  }}
                />
                : selectedConsult?.consultant ?? '-'}
            </Item>
            <Item label={'상담일시'}>{selectedConsult?.consultAt ?? '-'}</Item>
            <Item label={'상담현황'}>
              {isEdit
                ? (
                  <Select
                    defaultValue={selectedConsult?.consultStatus}
                    onSelect={(e) => setUpdateBody(prev => ({ ...prev, consultStatus: e }))}>
                    <Select.Option value={'상담전'}>상담전</Select.Option>
                    <Select.Option value={'상담중'}>상담중</Select.Option>
                    <Select.Option value={'상담완료'}>상담완료</Select.Option>
                  </Select>)
                : selectedConsult?.consultStatus ?? '-'}
            </Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Descriptions
            bordered={true}
            size={'small'}
            layout={'vertical'}
            column={1}
            labelStyle={{ display: 'flex', justifyContent: 'center' }}
          >
            <Item label={'상담내용'}>
              {isEdit
                ? <TextArea
                  autoSize={{ minRows: 4 }}
                  showCount
                  maxLength={500}
                  value={updateBody.consultContent}
                  onChange={(e) => setUpdateBody(prev => ({ ...prev, consultContent: e.target.value }))}
                />
                : selectedConsult?.consultContent ?? '-'}
            </Item>
            <Item label={'예약내용'}>{selectedConsult?.reserveContent ?? '-'}</Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>);

}

export default LeaseRentalConsultDetailModal;