import { Button, Col, Descriptions, Modal, Row } from 'antd';
import React, { useEffect, useState, VFC } from 'react';
import { Geocode } from '../../../../common/unit/geocode';
import { getMopedRideHistory } from '../../../../services/moped';
import { MopedRideHistoryTable } from '../../../ride/component/MopedRideHistoryTable';
import { HistoryDto } from '../../../ride/ride.interface';
import { MobilityDto } from '../../mobility.interface';

interface Props {
  isVisible: boolean;
  modalHandler: () => void;
  moped: MobilityDto | null;
}

const MopedDetailModal: VFC<Props> = ({ isVisible, modalHandler, moped }) => {
  // const [mopedMetaData, setMopedMetaData] = useState<MarkerProp[]>([]);
  const [mopedHistory, setMopedHistory] = useState<HistoryDto[]>([]);

  useEffect(() => {
    bootstrap();
  }, [JSON.stringify(moped)]);

  const bootstrap = async () => {
    if (moped && moped.mopedId) {
      // await drawOneMopedOnMapUtil(moped.id).then(setMopedMetaData);
      await getMopedRideHistory(moped.mopedId).then(setMopedHistory);
    }
  };

  return (
    <Modal
      title={`${moped?.shortImei} 상세`}
      visible={isVisible}
      onCancel={modalHandler}
      width={'90%'}
      footer={
        <Button onClick={modalHandler}>
          닫기
        </Button>
      }
    >
      <Row>
        <Col span={8}>
          <Descriptions
            bordered
            column={1}
          >
            <Descriptions.Item label='배터리'>{moped?.battery}%</Descriptions.Item>
            <Descriptions.Item label='현재위치'>
              <Geocode
                lat={moped && moped?.location.latitude}
                lng={moped && moped?.location.longitude}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label='이용여부'>{moped?.mobilityStatus === 'AVAILABLE' ? '없음' : '사용중'}
            </Descriptions.Item>
            {/*<Descriptions.Item*/}
            {/*  label='총 주행거리(km)'>{moped?.totalDistance}*/}
            {/*</Descriptions.Item>*/}
          </Descriptions>
        </Col>
        <Col span={16}>
          <MopedRideHistoryTable
            rides={mopedHistory}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default MopedDetailModal;
