import React, { useContext, useEffect, useState } from 'react';

import { AccountContext, SetLoadingContext } from '../../../../context';
import { useValidation } from '../../../../hooks/useValidation';
import { ModalMap } from '../../../../common/map/ModalMap';

import { getAllMopeds, getMoped } from '../../../../services/moped';
import { MobilityStatus, MobilityDto } from '../../mobility.interface';

import MopedDetailModal from '../component/MopedDetailModal';
import MopedRentalModal from '../component/MopedRentalModal';
import MopedListTable from '../component/MopedListTable';
import MopedSearchCard from '../component/MopedSearchCard';

export type MopedStatus = MobilityStatus | 'ALL';

export interface FilterCondition {
  imei: string,
  simei: string,
  deviceModel: string,
  firmware: string,
  battery: [number, number],
  available: string,
  lendType: string,
}

export interface DeviceCount {
  all: number,
  available: number,
  riding: number,
  locked: number,
  seaterLocked: number,
  lease: number,
  rental: number,
  dayRider: number,
  firmware: Map<string, number>
}

const MopedPage = () => {
  const admin = useContext(AccountContext);

  const [mopeds, setMopeds] = useState<MobilityDto[]>([]);
  const [filteredMopeds, setFilteredMopeds] = useState<MobilityDto[]>([]);

  const checkToken = useValidation;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [clickMoped, setClickMoped] = useState<MobilityDto | null>(null);
  const [isMopedPositionMapModalVisible, setIsMopedPositionMapModalVisible] = useState(false);
  const [isMopedRentalModalVisible, setIsMopedRentalModalVisible] = useState(false);
  const [selectedPositionMoped, setSelectedPositionMoped] = useState<MobilityDto | null>(null);
  const [selectedRentalChangeMoped, setSelectedRentalChangeMoped] = useState<MobilityDto | null>(null);
  const [deviceCount, setDeviceCount] = useState<Map<string, DeviceCount> | null>(null);
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({
    simei: '',
    imei: '',
    deviceModel: 'ALL',
    firmware: 'ALL',
    battery: [-1, 100],
    available: 'ALL',
    lendType: 'ALL'
  });

  const modalOpenCloseHandler = () => {
    setIsVisibleModal((prevState) => !prevState);
  };

  const mopedIdOnClickHandler = (id: number) => async () => {
    await getMoped(id).then(setClickMoped);
    modalOpenCloseHandler();
  };

  const handleSelectedMopedPosition = (row: MobilityDto) => {
    setSelectedPositionMoped(row);
    setIsMopedPositionMapModalVisible(true);
  };

  checkToken();

  useEffect(() => {
    bootstrap();
  }, [admin]);

  const setLoading = useContext(SetLoadingContext)!;

  async function bootstrap() {
    if (admin) {
      setLoading(true);
      await getAllMopeds().then((data) => {
        setMopeds(data);
        setFilteredMopeds(data);

        const deviceCountMap: Map<string, DeviceCount> = new Map();

        /** deviceCountMap's default value: ALL */
        deviceCountMap.set('ALL', {
          all: data.length,
          available: data.filter(e => e.mobilityStatus === 'AVAILABLE').length,
          riding: data.filter(e => e.mobilityStatus === 'RIDING').length,
          seaterLocked: data.filter(e => e.seaterLocked).length,
          locked: data.filter(e => e.locked).length,
          lease: data.filter(e => e.mobilityLendType === 'LEASE').length,
          rental: data.filter(e => e.mobilityLendType === 'RENTAL').length,
          dayRider: data.filter(e => e.mobilityLendType === 'NONE').length,
          firmware: new Map()
        });

        /** create deviceCount */
        data
          .forEach(e => {
            if (!deviceCountMap.has(e.modelType)) {
              /** initialize */
              deviceCountMap.set(e.modelType, {
                all: 0,
                available: 0,
                riding: 0,
                seaterLocked: 0,
                locked: 0,
                lease: 0,
                rental: 0,
                dayRider: 0,
                firmware: new Map()
              });
            }
            /** set Data */
            const countData: DeviceCount = deviceCountMap.get(e.modelType)!;

            const firmwareCountMap = countData.firmware;
            const isFirmware = firmwareCountMap.has(e.firmwareVersion);
            const firmwareCount = isFirmware ? firmwareCountMap.get(e.firmwareVersion) : 0;

            deviceCountMap.set(e.modelType, {
              all: countData.all + 1,
              available: countData.available + (e.mobilityStatus === 'AVAILABLE' ? 1 : 0),
              riding: countData.riding + (e.mobilityStatus === 'RIDING' ? 1 : 0),
              seaterLocked: countData.seaterLocked + 1,
              locked: countData.locked + 1,
              lease: countData.lease + (e.mobilityLendType === 'LEASE' ? 1 : 0),
              rental: countData.rental + (e.mobilityLendType === 'RENTAL' ? 1 : 0),
              dayRider: countData.dayRider + (e.mobilityLendType === 'NONE' ? 1 : 0),
              firmware: isFirmware
                ? firmwareCountMap.set(e.firmwareVersion, firmwareCount! + 1)
                : firmwareCountMap.set(e.firmwareVersion, 1)
            });
          });

        setDeviceCount(deviceCountMap);
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    setFilteredMopeds(
      mopeds
        .filter(e => {
          if (filterCondition.deviceModel === 'ALL') return true;
          else return e.modelType === filterCondition.deviceModel;
        })
        .filter(e => (e.shortImei.indexOf(filterCondition.simei.toUpperCase()) !== -1) || (e.imei.indexOf(filterCondition.imei.toUpperCase()) !== -1)
          || e.mopedVin.includes(filterCondition.simei)
        )
        .filter(e => {
          const [batteryMin, batteryMax] = filterCondition.battery;
          return e.battery >= batteryMin && e.battery <= batteryMax;
        })
        .filter(e => {
          if (filterCondition.firmware === 'ALL') return true;
          else return e.firmwareVersion === filterCondition.firmware;
        })
        .filter(e => {
          if (filterCondition.available === 'ALL') return true;
          else return e.mobilityStatus === filterCondition.available;
        })
        .filter(e => {
          if (filterCondition.lendType === 'ALL') return true;
          else return e.mobilityLendType === filterCondition.lendType;
        })
    );

  }, [filterCondition]);

  return (
    <div>
      <ModalMap
        isVisible={isMopedPositionMapModalVisible}
        setIsVisible={setIsMopedPositionMapModalVisible}
        selectedDomain={selectedPositionMoped}
      />
      <MopedRentalModal
        isVisible={isMopedRentalModalVisible}
        setIsVisible={setIsMopedRentalModalVisible}
        moped={selectedRentalChangeMoped}
      />

      {deviceCount &&
        <MopedSearchCard
          filteredMopedLength={filteredMopeds.length}
          setFilterCondition={setFilterCondition}
          filterCondition={filterCondition}
          deviceCount={deviceCount}
        />
      }

      <MopedListTable
        mopeds={filteredMopeds}
        mopedIdOnClickHandler={mopedIdOnClickHandler}
        handleSelectedMopedPosition={handleSelectedMopedPosition}
        setIsMopedRentalModalVisible={setIsMopedRentalModalVisible}
        setSelectedRentalChangeMoped={setSelectedRentalChangeMoped}
        filterCondition={filterCondition}
      />
      <MopedDetailModal isVisible={isVisibleModal} modalHandler={modalOpenCloseHandler} moped={clickMoped} />
    </div>
  );
};

export default MopedPage;
