import React from 'react';
import { AbstractMarkerPlugin } from './AbstractMarkerPlugin';
import { MobilityDto } from '../../../domain/mobility/mobility.interface';
import { MopedMarker } from '../markers/MopedMarker';
import { getAllMopeds } from '../../../services/moped';
import MopedDetailModal from '../../../domain/mobility/moped/component/MopedDetailModal';
import { MarkerProps } from '../Map';

// 1 day
const REFRESH_SECONDS = 24 * 60 * 60;

export class MopedMarkerPlugin extends AbstractMarkerPlugin<MobilityDto> {
  constructor() {
    super(REFRESH_SECONDS);
  }

  protected async getData(): Promise<MobilityDto[]> {
    return await getAllMopeds();
  }

  protected convertToMarkerProps(data: MobilityDto[]): { entity: MobilityDto; props: MarkerProps }[] {
    return data.map(s => ({
      entity: s,
      props: MopedMarker(s)
    }));
  }

  protected renderSelectedComponent(selectedData: MobilityDto): JSX.Element {
    return <MopedDetailModal
      isVisible={true}
      modalHandler={() => this.setSelectedData(null)}
      moped={selectedData}
    />;
  }
}
