import React, { useContext } from 'react';

export const CustomPolygonOverlay = (placement: number, scooter: number, moped: number) => {

  return `
      <div style='padding: 8px 4px'>
        <div>픽업존 : ${placement}개</div>
        <div>킥보드 : ${scooter}대</div>
        <div>오토바이 : ${moped}대</div>
      </div>
    `;
};

//        <div>배터리부족 킥보드 : ${point.placementCount.lowBatteryScooters}대 / ${point.placementCount.scooters}대</div>
//         <div>배터리부족 오토바이 : ${point.placementCount.lowBatteryMopeds}대 / ${point.placementCount.mopeds}대</div>