import {Button, Layout} from 'antd';

import React from 'react';
import { AccountInfo } from '../../domain/account/component/AccountInfo';
import './header.css';
import RIDER_LOGO from '../../images/rider_logo.png';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {ControlOutlined} from "@ant-design/icons";

interface Props {
  isMobilityRemoteControlVisible: boolean;
  setIsMobilityRemoteControlVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export function HeaderCompoonent({ isMobilityRemoteControlVisible, setIsMobilityRemoteControlVisible }: Props) {

  const history = useHistory();
  const goMain = () => {
    history.push('/riders');
  };

  return (
    <Layout.Header className='header'>
      <img src={RIDER_LOGO} style={{ display: 'block', height: '32px', cursor: 'pointer', marginLeft: '-20px' }} onClick={goMain} />
      {/*<div>*/}
      {/*  <Button icon={<ControlOutlined/>} onClick={() => setIsMobilityRemoteControlVisible(!isMobilityRemoteControlVisible)}/>*/}
      {/*</div>*/}
      <AccountInfo />
    </Layout.Header>
  );
}
