import {ColumnsType} from 'antd/lib/table';
import {parseDateTime} from '../../../utils/organizeData';
import {Button, Table} from 'antd';
import React, {VFC} from 'react';
import moment from 'moment';

interface Props {
    paymentHistory: PaymentHistory[];
    type?: 'ALL' | 'MEMBER';
    isVisibleRefundBtn?: boolean;
}

export const PurchaseHistoryTable2: VFC<Props> = ({paymentHistory, isVisibleRefundBtn = false, type = 'ALL'}) => {
    const alignCenter = 'center' as 'center';
    const isMember = type === 'MEMBER';
    const onClickRefundBtn = (id: string) => () => {
        alert(`${id}번째 상품 환불`);
    };
    console.log(isMember, '---<<<<<<<');
    const columns: ColumnsType<PaymentHistory> = [
        {
            key: 'name',
            dataIndex: isMember ? '' : 'name',
            title: isMember ? null : '사용자 이름',
        },

        {
            key: 'paymentType',
            dataIndex: 'paymentType',
            title: '결제구분', // 탑승결제, 패널티결제, 상품결제
        },
        {
            key: 'productName',
            dataIndex: 'productName',
            title: '상품이름',
        },
        {
            key: 'price',
            dataIndex: 'price',
            title: '결제금액',
            render: (_, product) => <span>{product.price}</span>,
        },
        {
            key: 'paymentAt',
            title: '결제일자',
            dataIndex: 'paymentAt',
            render: (_, product) => <span>{parseDateTime(product.paymentAt)}</span>,
        },
        {
            key: 'paymentResult',
            title: '결제결과',
            dataIndex: 'paymentResult',
            align: alignCenter,
        },
        {
            key: 'reasonFailure',
            title: '실패사유',
            dataIndex: 'reasonFailure',
            align: alignCenter,
        },
        {
            key: 'manager',
            title: '담당자(처리자)',
            dataIndex: 'manager',
            align: alignCenter,
        },
        {
            key: 'refund',
            title: isVisibleRefundBtn ? '환불' : null,
            render: (_, payment) =>
                isVisibleRefundBtn ? (
                    <Button style={{background: '#ffa94d', color: '#fff'}}
                            onClick={onClickRefundBtn(payment.paymentId)}>
                        환불하기
                    </Button>
                ) : null,
        },
    ];
    return (
        <div>
            <Table
                columns={columns}
                dataSource={paymentHistory
                    .sort((p, n) => moment(n.paymentAt).unix() - moment(p.paymentAt).unix())
                    .map((r, i) => ({key: i.toString(), ...r}))
                }
                pagination={{
                    defaultPageSize: 20,
                    showSizeChanger: false
                }}
            />
        </div>
    );
};