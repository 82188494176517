import React from 'react';
import { PlacementDto } from '../../domain/placement/placement.interface';


const CustomPlacementOverlay = (point: PlacementDto) => {

  const placementNameStyle = 'color: #51cf66; display: flex; justify-content: center; width: 200px; text-align: center;';

  return `
      <div style='${placementNameStyle}'>[${point.placementName}]</div>
      <div style='padding: 8px 4px'>
        <div>킥보드 : ${point.placementCount.scooters}대</div>
        <div>오토바이 : ${point.placementCount.mopeds}대</div>
      </div>
    `;
};

export default CustomPlacementOverlay;

//        <div>배터리부족 킥보드 : ${point.placementCount.lowBatteryScooters}대 / ${point.placementCount.scooters}대</div>
//         <div>배터리부족 오토바이 : ${point.placementCount.lowBatteryMopeds}대 / ${point.placementCount.mopeds}대</div>