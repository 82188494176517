import {ListenerEventData, MapUtils} from '../Utils';
import {LocationDto} from '../../common.interface';
import {
  MapWrapper,
  MapType,
  MarkerWrapper,
  CircleWrapper,
  DrawingManagerWrapper,
  PolygonWrapper,
  PolylineWrapper
} from '../Types';
import {EventType, MarkerProps, Point} from '../Map';
import {PlacementDto} from "../../../domain/placement/placement.interface";

function levelToZoom(level: number) {
  return Math.sqrt(level) + 11;
}

function createMap(mapElement: HTMLDivElement, level: number, center: LocationDto, mapType: MapType) {
  if (mapType !== 'GOOGLE') {
    throw new Error();
  }

  const options: google.maps.MapOptions = {
    center: new google.maps.LatLng(center.latitude, center.longitude),
    zoom: levelToZoom(level)
  };

  return new MapWrapper(new google.maps.Map(mapElement, options), mapElement);
}

function createMarker(target: MapWrapper, {
  position,
  image,
  infoWindowHTML
}: MarkerProps, data: any, draggable: boolean = false) {
  const options = {
    map: target.map as google.maps.Map,
    position: new google.maps.LatLng(position.latitude, position.longitude),
    icon: image ? {
      url: image.src,
      scaledSize: new google.maps.Size(image.size.width, image.size.height),
      origin: new google.maps.Point(0, 0),
      anchor: image.anchor ? new google.maps.Point(image.anchor.x, image.anchor.y) : new google.maps.Point(image.size.width / 2, image.size.height),
    } : undefined,
    clickable: true,
    visible: true,
    draggable: draggable,
  };

  return new MarkerWrapper(new google.maps.Marker(options), infoWindowHTML, data);
}

function createCircle(target: MapWrapper, placement: PlacementDto): CircleWrapper {
  if (target.mapType !== 'GOOGLE') {
    throw new Error();
  }

  const center = placement.location;
  return new CircleWrapper(new google.maps.Circle({
    center: new google.maps.LatLng(center.latitude, center.longitude),
    clickable: false,
    draggable: false,
    editable: false,
    map: target.map as google.maps.Map,
    radius: 25,
    zIndex: 2
  }), placement);
}

function createDrawingManager(map: MapWrapper, customDrawing?: boolean): DrawingManagerWrapper {
  if (map.mapType !== 'GOOGLE') {
    throw new Error();
  }

  const controlOptions: google.maps.drawing.DrawingControlOptions = {
    drawingModes: [
      google.maps.drawing.OverlayType.POLYGON,
      // google.maps.drawing.OverlayType.CIRCLE,
      // google.maps.drawing.OverlayType.RECTANGLE,
    ],
    position: google.maps.ControlPosition.TOP_CENTER,
  };

  const manager = new google.maps.drawing.DrawingManager({
    drawingControlOptions: controlOptions,
    markerOptions: {
      draggable: true,
      clickable: true,
    },
    polygonOptions: {
      strokeColor: '#39f',
      fillColor: '#39f',
      fillOpacity: 0.5,
      editable: customDrawing ? customDrawing : false,
      draggable: customDrawing ? customDrawing : false,
      clickable: customDrawing ? customDrawing : false,
    }
  });

  return new DrawingManagerWrapper(manager);
}

function createInfoWindow(map: MapWrapper, content: string, position: LocationDto) {
  const polygonInfoWindowContent = new google.maps.InfoWindow({
    content: content,
    position: new google.maps.LatLng(position.latitude, position.longitude),
    zIndex: 2
  });
  polygonInfoWindowContent.open(map.map as google.maps.Map);
}

function createPolygon(map: MapWrapper, paths: LocationDto[]): PolygonWrapper {
  const polygon = new google.maps.Polygon({
    map: map.map as google.maps.Map,
    strokeWeight: 2,
    strokeColor: '#0732cb',
    strokeOpacity: 0.5,
    fillColor: '#00EEEE',
    fillOpacity: 0.3,
    paths: paths.map(e => new google.maps.LatLng(e.latitude, e.longitude)),
  });

  return new PolygonWrapper(polygon, paths);
}

function createPolyline(map: MapWrapper, paths: LocationDto[]): PolylineWrapper {
  const options = {
    path: paths.map(e => new google.maps.LatLng(e.latitude, e.longitude)),
    strokeWeight: 3,
    strokeColor: '#FA6800',
    strokeOpacity: 1,
    strokeStyle: 'solid',
  }

  return new PolylineWrapper(new google.maps.Polyline(options));
}

function registerDrawingManager(map: MapWrapper, manager: DrawingManagerWrapper) {
  if (map.mapType !== 'GOOGLE') {
    throw new Error();
  }

  manager.setMap(map);
}

function addListener(
  target: MapWrapper | MarkerWrapper | DrawingManagerWrapper | PolygonWrapper,
  event: EventType | keyof kakao.maps.EventHandlerMap | string,
  handler: (event?: ListenerEventData) => void) {
  if (target.mapType !== 'GOOGLE') {
    throw new Error();
  }

  google.maps.event.addListener(
    target.target as google.maps.Map | google.maps.Marker | google.maps.drawing.DrawingManager | google.maps.Polygon,
    event,
    handlerParser(handler),
  );
}

function setMap(target: MarkerWrapper | CircleWrapper | PolygonWrapper, map: MapWrapper | null) {
  if (target.mapType !== 'GOOGLE' || (map && map.mapType !== 'GOOGLE')) {
    throw new Error();
  }

  (target.target as google.maps.Marker | google.maps.Circle | google.maps.Polygon).setMap(map?.map as google.maps.Map);
}


function handlerParser(handler: (event?: ListenerEventData) => void) {
  return (e: google.maps.MapMouseEvent | google.maps.Polygon) => {
    if (e) {
      if (e instanceof google.maps.Polygon) {
        const polygonCoords: LocationDto[] = [];
        e.getPath().getArray().forEach(e => {
          polygonCoords.push({
            latitude: e.lat(),
            longitude: e.lng(),
          });
        });
        handler({
          location: polygonCoords
        })
      } else {
        handler({
          location: {
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng()
          },
        });
      }
    } else {
      handler();
    }
  };
}

function setDraggable(target: MapWrapper, isDraggable: boolean) {
  const map = target.map as google.maps.Map;
  map.setOptions({draggable: isDraggable});
}

function pointToLocation(map: MapWrapper, point: Point): LocationDto {
  const gMap = map.map as google.maps.Map;

  const projection = gMap.getProjection()!;

  const {y} = projection.fromLatLngToPoint(gMap.getBounds()!.getNorthEast());
  const {x} = projection.fromLatLngToPoint(gMap.getBounds()!.getSouthWest());

  const scale = 1 << gMap.getZoom();

  const location = projection.fromPointToLatLng(new google.maps.Point(point.x / scale + x, point.y / scale + y));
  return {
    latitude: location.lat(),
    longitude: location.lng(),
  };
}


export const utils: MapUtils = {
  createMap,
  createMarker,
  createCircle,
  createDrawingManager,
  createInfoWindow,
  createPolygon,
  createPolyline,
  registerDrawingManager,
  addListener,
  setMap,
  setDraggable,
  pointToLocation
};