import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { LocationDto } from '../common.interface';
import { MapWrapper, MapType } from './Types';
import { utils } from './Utils';
import {BaseMapPlugin} from "./plugins/BaseMapPlugin";
import {cursorTracker} from "./CursorTracker";

export interface CommonMapProps {
  center?: LocationDto | null,
  level?: number | null
  markers?: MarkerProps[]
  setNewPlacementCoord?: React.Dispatch<React.SetStateAction<LocationDto | undefined>>
  setIsPlacementAddModalVisible?: React.Dispatch<React.SetStateAction<boolean>>
  style?: CSSProperties
  drawMode?: 'MARKER'
  onMapClick?: (position: LocationDto) => void
  showToolBox?: boolean,
  addMode?: boolean
}

export enum EventType {
  CLICK = 'click',
  RIGHTCLICK = 'rightclick',
  MOUSEOVER = 'mouseover',
  MOUSEOUT = 'mouseout',
  DRAGEND= 'dragend',
  MOUSEDOWN = 'mousedown',
  MOUSEUP = 'mouseup',
  MOUSEMOVE = 'mousemove',
}

export interface Size {
  width: number,
  height: number,
}

export interface Point {
  x: number,
  y: number,
}

export interface MarkerImage {
  src: string,
  size: Size,
  zIndex: number,
  anchor?: Point,
}

export interface MarkerProps {
  identifier?: string;
  position: LocationDto;
  image?: MarkerImage;
  infoWindowHTML?: string;

  onClick?: () => void;
}

declare global {
  interface Window {
    marker: any;
    map: any;
    rvClient: any;
    rv: any;
    overlayOn: boolean;
    geocoder: any;
  }
}

const defaultOptions = {
  center: { latitude: 37.565706, longitude: 126.999531 },
  level: 8
};

const defaultStyle: CSSProperties = {
  width: '100%',
  height: '90vh',
  position: 'sticky'
};

interface MapProps extends CommonMapProps {
  mapType: MapType,
  plugins?: BaseMapPlugin[],
}

export function Map(props: MapProps) {
  const [map, setMap] = useState<MapWrapper | null>(null);
  const [rerenderSwitch, setRerenderSwitch] = useState<number>(0);

  const mapRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (mapRef?.current) {
      const map = utils.createMap(
        mapRef.current,
        props.level ?? defaultOptions.level,
        props.center ?? defaultOptions.center,
        props.mapType);
      setMap(map);
      cursorTracker.register(map);
    }
  }, [mapRef, props.mapType]);

  useEffect(() => {
    for (const plugin of props.plugins ?? []) {
      plugin.onInitialize();
    }

    return () => {
      for (const plugin of props.plugins ?? []) {
        plugin.onDestroy();
      }
    };
  }, [setRerenderSwitch]);

  useEffect(() => {
    // map 이 생성되었을때 plugin들의 lifecycle을 호출
    if (map == null) return;

    for (const plugin of props.plugins ?? []) {
      plugin.onMapCreated(map);
    }
  }, [map]);

  for (const plugin of props.plugins ?? []) {
    plugin.onRerender(() => setRerenderSwitch(rerenderSwitch + 1));
  }

  return (
    <>
      <div ref={mapRef} style={{ ...defaultStyle, ...props.style }} />
      {props.plugins?.map(l => l.render())}
    </>
  );
}

// useEffect(() => {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition((position) => {
//       setUserCenter(position.coords);
//     });
//   }
// }, []);