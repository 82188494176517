export interface Undoable {
  undo: () => void;
}

export abstract class WorkHistory {
  private readonly history: Undoable[] = [];

  protected appendHistory(work: Undoable) {
    this.history.push(work);
  }

  undoHistory() {
    if (this.history.length === 0) {
      return;
    }

    const work = this.history.pop();
    if (work == null) {
      return;
    }

    work.undo();
  }
}
