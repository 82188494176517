import { useContext, useState, VFC } from 'react';
import { Input, Modal, Tabs } from 'antd';
import React from 'react';
import { AccountContext } from '../../../../context';
import { putScooterImei, putScooterSimei } from '../../mobility.service';
import { MobilityDto } from '../../mobility.interface';

type Props = {
  isEditModalVisible: boolean;
  setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedScooter: MobilityDto | null;
  setSelectedScooter: React.Dispatch<React.SetStateAction<MobilityDto | null>>
};

const { TabPane } = Tabs;

const EditScootModal: VFC<Props> = ({
                                      isEditModalVisible,
                                      setIsEditModalVisible,
                                      selectedScooter,
                                      setSelectedScooter
                                    }) => {

  const account = useContext(AccountContext)!;

  const [mode, setMode] = useState<string>('');
  const [newImei, setNewImei] = useState(selectedScooter?.imei);
  const [newSimei, setNewSimei] = useState('');

  const handleOk = async () => {
    if (selectedScooter) {
      if (newImei) {
        putScooterImei(selectedScooter.scooterId!.toString(), {
          imei: newImei
        }).then(e => {
          alert('업데이트 되었습니다');
          window.location.reload();
        }).catch((err) => {
          alert('업데이트에 실패하였습니다');
          window.location.reload();
        });
      } else if (newSimei) {
        putScooterSimei(selectedScooter.scooterId!.toString(), {
          shortImei: newSimei
        }).then(e => {
          alert('업데이트 되었습니다');
          window.location.reload();
        }).catch((err) => {
          alert('업데이트에 실패하였습니다');
          window.location.reload();
        });
      } else {
        alert('다시시도해주세요');
      }
    }


    // if (newImei && selectedScooter) {
    //   putScooter(selectedScooter.scooterId!.toString(), {
    //     imei: newImei
    //   }).then(e => {
    //     alert('업데이트 되었습니다');
    //     window.location.reload();
    //   }).catch((err) => {
    //     alert('업데이트에 실패하였습니다');
    //     window.location.reload();
    //   });
    // }
  };

  const handleIMEIChange = (e: any) => {
    setNewImei(e.target.value);
  };

  const handleSIMEIChange = (e: any) => {
    setNewSimei(e.target.value);
  };

  const handleCancel = () => {
    setSelectedScooter(null);
    setIsEditModalVisible(false);
  };

  return (
    <Modal
      title='스쿠터 수정'
      visible={isEditModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Tabs defaultActiveKey='IMEI' onChange={(e) => setMode(e)}>
        <TabPane tab='IMEI' key='IMEI'>
          <Input
            addonBefore='SIMEI'
            value={selectedScooter?.shortImei}
            disabled={true}
            style={{ marginBottom: '20px' }}
          />
          <Input
            addonBefore='IMEI'
            value={newImei}
            onChange={(e) => handleIMEIChange(e)}
          />
        </TabPane>
        <TabPane tab='SIMEI' key='SIMEI'>
          <Input
            addonBefore='IMEI'
            value={selectedScooter?.imei}
            disabled={true}
            style={{ marginBottom: '20px' }}
          />
          <Input
            addonBefore='SIMEI'
            onChange={(e) => handleSIMEIChange(e)}
          />
        </TabPane>
      </Tabs>

    </Modal>
  );
};

export default EditScootModal;
