import { DashboardPurchase } from '../../dashboard.interface';
import { MobilityCode } from '../../../product/product.interface';
import {ProductUnit} from "../../../product/product.interface";

export function getLeasePurchaseByMobilityType(purcahses: DashboardPurchase[], mobilityCode: MobilityCode) {
  return purcahses.filter(e => e.productSubType === 'LEASE' && e.mobilityCode === mobilityCode);
}

export function getRentalPurchaseByMobilityType(purcahses: DashboardPurchase[], mobilityCode: MobilityCode) {
  return purcahses.filter(e => e.productSubType === 'RENTAL' && e.mobilityCode === mobilityCode);
}

export function getPurchaseDataByBand(purchases: DashboardPurchase[], bandId: number) {
  return purchases.filter(e => e.bandId === bandId);
}

export function getScooterPassPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'PASS' && e.mobilityCode === 'ST');
}

export function getMopedPassPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'PASS' && e.mobilityCode === 'MC');
}

export function getPenaltyPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'PENALTY');
}

export function getScooterReturnPenaltyPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'RETURN_PENALTY' && e.mobilityCode === 'ST');
}

export function getMopedReturnPenaltyPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'RETURN_PENALTY' && e.mobilityCode === 'MC');
}

export function getOtherPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'OTHER');
}

export function getPassPurchaseByPeriod(purchases: DashboardPurchase[], day: number, unit: ProductUnit) {
  return purchases.filter(e => e.period === day && e.unit === unit && e.purchaseAmount > 0);
}

export function getScooterOndemandPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'ONDEMAND' && e.mobilityCode === 'ST');
}

export function getMopedOndemandPurchases(purchases: DashboardPurchase[]) {
  return purchases.filter(e => e.productSubType === 'ONDEMAND' && e.mobilityCode === 'MC');
}