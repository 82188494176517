import { LocalAdminApi } from '../../services';
import { MobilityLendType, MobilityType } from './mobility.interface';

//scooter lock
export async function putScooterLock(scooterId: number) {
  return await LocalAdminApi.put(`/scooters/${scooterId}/lock`).then((resp) => resp.data);
}

//scooter unlock
export async function putScooterUnlock(scooterId: number) {
  return await LocalAdminApi.put(`/scooters/${scooterId}/unlock`).then((resp) => resp.data);
}

//scooter riding
export async function putScooterRing(scooterId: number) {
  return await LocalAdminApi.put(`/scooters/${scooterId}/ring`).then((resp) => resp.data);
}

//scooter cover
export async function putScooterOpenCover(scooterId: number) {
  return await LocalAdminApi.put(`/scooters/${scooterId}/cover`).then((resp) => resp.data);
}

//moped lock
export async function putMopedLock(mopedId: number) {
  return await LocalAdminApi.put(`/mopeds/${mopedId}/lock`).then((resp) => resp.data);
}

//moped unlock
export async function putMopedUnlock(mopedId: number) {
  return await LocalAdminApi.put(`/mopeds/${mopedId}/unlock`).then((resp) => resp.data);
}

//moped riding
export async function putMopedRing(mopedId: number) {
  return await LocalAdminApi.put(`/mopeds/${mopedId}/ring`).then((resp) => resp.data);
}

//moped cover
export async function putMopedOpenCover(mopedId: number) {
  return await LocalAdminApi.put(`/mopeds/${mopedId}/open`).then((resp) => resp.data);
}

export async function putScooterImei(scooterId: string, content: { imei: string }) {
  return await LocalAdminApi.put(`/scooters/${scooterId}`, content).then((resp) => resp.data);
}

export async function putScooterSimei(scooterId: string, content: { shortImei: string }) {
  return await LocalAdminApi.put(`/scooters/${scooterId}`, content).then((resp) => resp.data);
}

// 특정 moped 리스/렌탈로 변경
export async function putMobilityLeaseRental(
  id: number,
  lendType: MobilityLendType,
  mobilityType: MobilityType
) {
  if (mobilityType === 'SCOOTER') {
    return await LocalAdminApi.put(`/scooters/${id}/lendType`, {
      lendType: lendType
    }).then((resp) => resp.data);
  } else {
    return await LocalAdminApi.put(`/mopeds/${id}/lendType`, {
      lendType: lendType
    }).then((resp) => resp.data);
  }
}

export async function putMopedBand(mopedId: number, body: { bandId: number }) {
  return await LocalAdminApi.put(`mopeds/${mopedId}/band`, body);
}

export async function putPCXSimei(id: number, shortImei: string) {
  return await LocalAdminApi.put(`mopeds/${id}/short-imei`, { shortImei });
}