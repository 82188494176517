import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, SetLoadingContext } from '../../../../context';
import { getAllScooters, getScooter } from '../../../../services/scoot';
import AddScootModal from '../component/AddScootModal';
import ScooterDetailModal from '../component/ScooterDetailModal';
import { useValidation } from '../../../../hooks/useValidation';
import EditScootModal from '../component/EditScootModal';
import { ModalMap } from '../../../../common/map/ModalMap';
import { ScooterListTable } from '../component/ScooterListTable';
import MobilityCardStatistic from '../../MobilityCardStatistic';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Input, PageHeader, Radio, Row, Slider } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { MobilityDto, MobilityLendType, MobilityStatus, ModelType } from '../../mobility.interface';
import ScooterRentalModal from '../component/ScooterRentalModal';
import RemoveScooterModal from '../component/RemoveScootModal';
import BandOptionRadio from '../../../../common/component/BandOptionRadio';

const { Search } = Input;

type ScooterModel = ModelType | 'All'
type ScooterStatus = MobilityStatus | 'All'

export function ScooterPage() {
  const admin = useContext(AccountContext);
  const { t } = useTranslation();

  const [scooters, setScooters] = useState<MobilityDto[]>([]);
  const [filteredScooters, setFilteredScooters] = useState<MobilityDto[]>([]);
  const [isAddScooterModalVisible, setIsAddScooterModalVisible] = useState<boolean>(false);
  const [isRemoveScooterModalVisible, setIsRemoveScooterModalVisible] = useState<boolean>(false);
  const checkToken = useValidation;

  const [searchText, setSearchText] = useState<any>('');
  const [searchBand, setSearchBand] = useState<string>('0');
  const [searchModel, setSearchModel] = useState<ScooterModel>('All');
  const [searchBatteryCapacity, setSearchBatteryCapacity] = useState<number[]>([0, 100]);
  const [searchLock, setSearchLock] = useState<ScooterStatus>('All');

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [clickScooter, setClickScooter] = useState<MobilityDto | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isScooterPositionMapModalVisible, setIsScooterPositionMapModalVisible] = useState(false);
  const [selectedScooter, setSelectedScooter] = useState<MobilityDto | null>(null);
  const [selectedPositionScooter, setSelectedPositionScooter] = useState<MobilityDto | null>(null);
  const [searchLeaseRental, setSearchLeaseRental] = useState<MobilityLendType>('ALL');
  const [selectedRentalChangeScooter, setSelectedRentalChangeScooter] = useState<MobilityDto | null>(null);
  const [isScooterRentalModalVisible, setIsScooterRentalModalVisible] = useState(false);

  const modalOpenCloseHandler = () => {
    setIsVisibleModal((prevState) => !prevState);
  };

  const scooterIdOnClickHandler = (id: number) => async () => {
    getScooter(id).then(setClickScooter);
    modalOpenCloseHandler();
  };

  const handleScooterEdit = (row: MobilityDto) => {
    setSelectedScooter(row);
    setIsEditModalVisible(true);
  };

  const handleSelectedScooterPosition = (row: MobilityDto) => {
    setSelectedPositionScooter(row);
    setIsScooterPositionMapModalVisible(true);
  };

  checkToken();

  useEffect(() => {
    bootstrap();
  }, [admin]);

  const setLoading = useContext(SetLoadingContext)!;

  async function bootstrap() {
    if (admin) {
      setLoading(true);
      await getAllScooters().then(e => {
        setScooters(e.filter(scoot => scoot.mobilityStatus !== 'CARGO'));
        setFilteredScooters(e.filter(scoot => scoot.mobilityStatus !== 'CARGO'));
      });
      setLoading(false);
    }
  }

  const openAndCloseModalHandler = () => {
    setIsAddScooterModalVisible(!isAddScooterModalVisible);
  };

  useEffect(() => {
    setFilteredScooters(
      scooters
        .filter(e => (e.shortImei.indexOf(searchText.toUpperCase()) !== -1) || e.imei.indexOf(searchText.toUpperCase()) !== -1)
        .filter(e => e.battery >= searchBatteryCapacity[0] && e.battery <= searchBatteryCapacity[1])
        .filter(e => {
          if (searchBand === '0') return true;
          else return e.band.bandId.toString() === searchBand;
        })
        .filter(e => {
          if (searchLock === 'All') return true;
          else return e.mobilityStatus === searchLock;
        })
        .filter(e => {
          if (searchModel === 'All') return true;
          else return e.modelType === searchModel;
        })
        .filter(e => {
          if (searchLeaseRental === 'ALL') return true;
          else return e.mobilityLendType === searchLeaseRental;
        })
    );
  }, [searchText, searchModel, searchBatteryCapacity, searchLock, searchBand, searchLeaseRental]);

  return (
    <div className='scoots-page'>
      <EditScootModal
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisible={setIsEditModalVisible}
        selectedScooter={selectedScooter}
        setSelectedScooter={setSelectedScooter}
      />
      <ModalMap
        isVisible={isScooterPositionMapModalVisible}
        setIsVisible={setIsScooterPositionMapModalVisible}
        selectedDomain={selectedPositionScooter}
      />
      <ScooterRentalModal
        isVisible={isScooterRentalModalVisible}
        setIsVisible={setIsScooterRentalModalVisible}
        scooter={selectedRentalChangeScooter}
      />
      <PageHeader
        title={t('common-scooter')}
        subTitle={filteredScooters.length + '대'}
        style={{ backgroundColor: 'white' }}
        extra={<>
          <Button icon={<PlusOutlined />} onClick={openAndCloseModalHandler} />,
          <Button icon={<MinusOutlined />} onClick={() => setIsRemoveScooterModalVisible(true)} />
        </>}
      >
        <Row
          gutter={[12, 12]}
          align={'middle'}
          justify={'center'}
        >
          <Col span={12}>
            <MobilityCardStatistic
              title={'S9'}
              value={scooters.filter(e => e.modelType === 'S9').length}
              valueUnit={'대'}
            />
          </Col>
          <Col span={12}>
            <MobilityCardStatistic
              title={t('common-riding')}
              value={scooters.filter(e => e.mobilityStatus === 'RIDING').length}
              valueUnit={'대'}
            />
          </Col>
        </Row>
        <Divider>킥보드 검색 및 필터</Divider>
        <Row align={'middle'} gutter={[8, 8]}>
          <Col span={24}>
            <Radio.Group style={{ width: '100%' }} value={searchLock}
                         onChange={e => setSearchLock(e.target.value)}>
              <Radio.Button style={{ width: '33.3%', textAlign: 'center' }} value={'All'}>전체</Radio.Button>
              <Radio.Button style={{ width: '33.3%', textAlign: 'center' }} value={'AVAILABLE'}>탑승
                가능</Radio.Button>
              <Radio.Button style={{ width: '33.3%', textAlign: 'center' }} value={'RIDING'}>탑승
                불가</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Radio.Group
              style={{ width: '100%' }}
              value={searchLeaseRental}
              onChange={e => setSearchLeaseRental(e.target.value)}
            >
              <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'ALL'}>전체</Radio.Button>
              <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'NONE'}>오라</Radio.Button>
              <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'LEASE'}>리스</Radio.Button>
              <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'RENTAL'}>렌탈</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <BandOptionRadio selectedBand={searchBand} setSelectedBand={setSearchBand} />
          </Col>

          <Col span={12}>
            <Search
              placeholder='SIMEI, IMEI 를 입력해주세요'
              size={'large'}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Slider
              range={{ draggableTrack: true }}
              defaultValue={[0, 100]}
              onAfterChange={(e: any) => setSearchBatteryCapacity(e)}
            />
          </Col>
        </Row>

      </PageHeader>
      <ScooterListTable
        scooters={filteredScooters}
        scooterIdOnClickHandler={scooterIdOnClickHandler}
        handleSelectedScooterPosition={handleSelectedScooterPosition}
        handleScooterEdit={handleScooterEdit}
        setSelectedRentalChangeScooter={setSelectedRentalChangeScooter}
        setIsScooterRentalModalVisible={setIsScooterRentalModalVisible}
      />
      <AddScootModal open={isAddScooterModalVisible} openAndCloseModalHandler={openAndCloseModalHandler} />
      <RemoveScooterModal open={isRemoveScooterModalVisible} close={setIsRemoveScooterModalVisible} />
      <ScooterDetailModal isVisible={isVisibleModal} handleClose={modalOpenCloseHandler} scooter={clickScooter} />
    </div>
  );
}
