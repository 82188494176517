import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Tabs} from 'antd';

import {PageHeader} from '../../../components/layout/PageHeader';
import {Routes} from '../../../constants';
import {AccountContext, SetLoadingContext} from '../../../context';
import {getBand} from '../band.service';
import {getAllBandProducts} from '../../product/product.service';
import {BandSimpleDto} from "../band.interface";
import {ProductBandDto} from "../../product/product.interface";

const BandDetailPage = () => {
  const history = useHistory();
  const setLoading = useContext(SetLoadingContext)!;
  const contextBandId = useContext(AccountContext)!.band.bandId;

  const [bandInfo, setBandInfo] = useState<BandSimpleDto | null>(null);
  const [bandProducts, setBandProducts] = useState<ProductBandDto[]>([]);

  const {bandId} = useParams<{ bandId: string }>();

  const goBandListPage = () => {
    history.push(Routes.BAND);
  };

  const {TabPane} = Tabs;

  useEffect(() => {
    setLoading(true);
    bootstrap();
    setLoading(false);
  }, []);

  const bootstrap = async () => {
    if (bandId && contextBandId) {
      await getBand(parseInt(bandId)).then(setBandInfo);
      await getAllBandProducts().then(setBandProducts);
    }
  };

  return (
    <>
      <div>
        <PageHeader
          title={bandInfo ? bandInfo?.bandName : '밴드 상세정보'}
          backTitle="밴드 리스트"
          onBack={goBandListPage}
        />
      </div>
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="라운지 / 픽업존" key="1">
            라운지리스트
          </TabPane>

          <TabPane tab="스쿠터목록" key="2">
            스쿠터목록
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default BandDetailPage;
