import React, {CSSProperties} from 'react';

interface Props {
  scooterCount: number,
  mopedCount: number,
}

const MapDashboardPanel = ({scooterCount, mopedCount} : Props) => {
  const floatingStyle: CSSProperties = {
    position: 'absolute',
    width: '180px',
    top: '10px',
    left: '10px',
    backgroundColor: '#FFF',
    borderRadius: '15px',
    boxShadow: '2px 2px 3px #999',
    zIndex: 3,
    padding: '8px 12px',
    border: '2px solid #F36F21',
  }

  const spanTagStyle: CSSProperties = {
    fontSize: '16px'
  }

  return (
    <div style={floatingStyle}>
      <h3 style={{width: '100%', textAlign: 'center'}}>대시보드</h3>
      <h4 style={{width: '100%'}}>킥보드 : <span style={spanTagStyle}>{scooterCount}</span> 대</h4>
      <h4 style={{width: '100%'}}>오토바이 : <span style={spanTagStyle}>{mopedCount}</span> 대</h4>
    </div>
  );
};

export default MapDashboardPanel;
