import React, {useContext, useEffect, useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {Layout} from 'antd';
import {HeaderCompoonent} from './components/layout/Header';
import {AuthService} from './services/auth';
import './app.css';
import {LoadingPage} from './components/layout/Loading';
import {PrivateRouter} from './components/layout/router/PrivateRouter';
import {SideBarComponent} from './components/layout/SideBar';
import {AccountContext, SetAdminContext, SetLoadingContext} from './context';
import {LoginPage} from './domain/login';
import {AlertComponent} from './common/alert/Alert';
import {useLocation} from 'react-router';
import {MobilityRemoteControl} from "./components/modal/MobilityRemoteControl";

const {Sider} = Layout;

export function App() {
  const setLoading = useContext(SetLoadingContext)!;
  const {pathname} = useLocation();

  const account = useContext(AccountContext);
  const setAccountContext = useContext(SetAdminContext)!;
  const [isMobilityRemoteControlVisible, setIsMobilityRemoteControlVisible] = useState(false);

  const history = useHistory();

  async function bootstrap() {
    try {
      const account = await AuthService.getMyInfo();
      if (account.accountName === 'anonymousUser') {
        history.push('/login');
        return;
      }

      if (pathname === '/login' && account && account.accountName) {
        history.push('/riders');
      }

      setAccountContext(account);
    } catch (err) {
      history.push('/login');
    }
  }

  useEffect(() => {
    setLoading(true);
    bootstrap().finally(() => {
      setLoading(false);
    });
  }, []);

  if (!account) {
    return (
      <Layout className="app">
        <LoadingPage/>
        <Switch>
          <Route path="/login" component={LoginPage}/>
        </Switch>
      </Layout>
    );
  }

  if (account) {
    return (
      <Layout className="app">
        <LoadingPage/>
        <AlertComponent/>
        <HeaderCompoonent
          isMobilityRemoteControlVisible={isMobilityRemoteControlVisible}
          setIsMobilityRemoteControlVisible={setIsMobilityRemoteControlVisible}
        />
        <MobilityRemoteControl
          isMobilityRemoteControlVisible={isMobilityRemoteControlVisible}
          setIsMobilityRemoteControlVisible={setIsMobilityRemoteControlVisible}
        />
        <Layout className="content">
          <Sider
            className="site-layout-background"
            width={180}
            collapsible={true}
            collapsedWidth={50}
            breakpoint="lg"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
          >
            <SideBarComponent/>
          </Sider>
          <Layout style={{padding: '24px 12px', backgroundColor: '#ededed'}}>
            <PrivateRouter/>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  return <></>;
}
