import React from 'react';
import {getBandTag, getPlacementTag, parseDateTime} from '../../../utils/organizeData';
import moment from 'moment';
import {forceEndRide} from '../ride.service';
import Table, {ColumnsType} from 'antd/lib/table';
import {Button, Typography} from 'antd';
import {Geocode} from "../../../common/unit/geocode";
import NameALink from "../../../common/component/NameALink";
import {NodeIndexOutlined} from "@ant-design/icons";
import {DEFAULT_TABLE_SIZE} from "../../../constants";
import {HistoryDto, ScooterRidePaginationReq} from "../ride.interface";
import {PaginationRes} from "../../../common/common.interface";
import {MobilityType, PathDomain} from "../../mobility/mobility.interface";
import Copyable from "../../../common/component/Copyable";

interface Props {
  rides: HistoryDto[],
  pagination: ScooterRidePaginationReq,
  paginationRes: PaginationRes
  type?: 'ALL' | 'MEMBER',
  setIsPathModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedMobilityAndPath: React.Dispatch<React.SetStateAction<PathDomain | null>>
}

export function MopedRideTable(
  {rides,
    type,
    pagination,
    paginationRes,
    setIsPathModalVisible,
    setSelectedMobilityAndPath,
  }: Props) {

  const handleForceEndRide = (rideId: number) => {
    forceEndRide(rideId).then(() => {
      alert("성공적으로 종료되었습니다");
      window.location.reload();
    }).catch((err) => {
      alert("강제종료에 실패하였습니다" + err)
    })
  }

  const mopedRidesColumns: ColumnsType<HistoryDto> = [
    {
      key: 'band',
      title: '밴드',
      align: 'center',
      render: (val, row, i) => {
        return getBandTag(row.rider.band.bandName, i);
      }
    },
    {
      key: 'shortImei',
      dataIndex: 'shortImei',
      title: '오토바이 이름',
      align: 'center',
      render: (val, row) => <Copyable textVal={row.mobility.shortImei}/>
    },
    {
      key: 'member',
      dataIndex: ['rider', 'name'],
      title: '사용자',
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName}/>
    },
    {
      key: 'startAt',
      dataIndex: 'startAt',
      title: '시작 시간',
      align: 'center',
      render: (time) => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {parseDateTime(moment(time).unix() * Math.pow(10, 3))}
        </Typography.Text>
      ),
    },
    {
      key: 'endAt',
      dataIndex: 'endAt',
      title: '종료 시간',
      align: 'center',
      render: (time) => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {time ? parseDateTime(moment(time).unix() * Math.pow(10, 3)) : '현재 이용중'}
        </Typography.Text>
      ),
    },
    {
      key: 'startLocation',
      title: '시작 위치',
      align: 'center',
      render: (val, row) => {
        if (row.startPlacement) {
          return getPlacementTag(row.startPlacement.placementName);
        } else {
          if (row.startLocation) {
            const {startLocation: {latitude, longitude}} = row;
            return <Geocode lat={latitude} lng={longitude}/>
          } else {
            return '-'
          }
        }
      },
    },
    {
      key: 'endLocation',
      title: '종료 위치',
      align: 'center',
      render: (val, row) => {
        if (row.endPlacement) {
          return getPlacementTag(row.endPlacement.placementName)
        } else {
          if (row.endLocation) {
            const {endLocation: {latitude, longitude}} = row;
            return <Geocode lat={latitude} lng={longitude}/>
          } else {
            return '-'
          }
        }
      }
    },
    {
      key: 'paths',
      title: '경로',
      align:'center',
      render: (val, row) => <Button
        icon={<NodeIndexOutlined/>}
        disabled={!row.path || row.path.length === 2}
        onClick={() => {
          setIsPathModalVisible(true);
          setSelectedMobilityAndPath({
            mobilityType: row.mobility.mobilityType,
            path: row.path
          })
        }
        }
      />
    },
    {
      key: 'actionButtons',
      title: '강제종료',
      align: 'center',
      render: (val, row) => {
        return row.endAt ? <span> - </span> : <Button onClick={() => handleForceEndRide(row.id)}>강제종료</Button>
      }
    }
  ];

  const handleTableChange = (pgn: any) => {
    window.location.href = `/admin/moped-history?page=${pgn.current - 1}&size=${pagination.size}&keyword=${pagination.keyword}`
  }

  return (
    <Table
      bordered
      columns={mopedRidesColumns}
      dataSource={
        rides.sort((p, n) => {
          return moment(n.startAt).unix() - moment(p.startAt).unix();
        }).map((e, i) => {
          return {key: i, ...e}
        })
      }
      onChange={handleTableChange}
      pagination={{
        current: paginationRes.page + 1,
        total: paginationRes.totalItem,
        defaultPageSize: DEFAULT_TABLE_SIZE,
        showSizeChanger: false
      }}
    />
  );
}
