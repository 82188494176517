import {LocationDto} from '../common.interface';

export type MapType = 'GOOGLE' | 'KAKAO';

abstract class Wrapper<T> {
  readonly target: T;

  readonly mapType: MapType;

  protected constructor(target: T) {
    this.target = target;
    this.mapType = this.getMapType(target);
  }

  protected abstract getMapType(target: T): MapType
}

export class MapWrapper extends Wrapper<kakao.maps.Map | google.maps.Map> {
  readonly map: kakao.maps.Map | google.maps.Map = this.target;
  readonly htmlElement: HTMLElement;

  constructor(map: kakao.maps.Map | google.maps.Map, htmlElement: HTMLElement) {
    super(map);
    this.htmlElement = htmlElement;
  }

  protected getMapType(target: kakao.maps.Map | google.maps.Map): MapType {
    return target instanceof kakao.maps.Map ? 'KAKAO' : 'GOOGLE';
  }

  move(center: LocationDto, level?: number) {
    switch (this.mapType) {
      case 'KAKAO':
        const kMap = this.target as kakao.maps.Map;
        const kCenter = new kakao.maps.LatLng(center.latitude, center.longitude);
        kMap.setCenter(kCenter);
        if (level) {
          kMap.setLevel(level);
        }
        break;
      case 'GOOGLE':
        const gMap = this.target as google.maps.Map;

        const gCenter = new google.maps.LatLng(center.latitude, center.longitude);
        gMap.setCenter(gCenter);

        if (level) {
          if (level < 5) {
            level = level * 10 + 8;
          } else {
            level += 10;
          }
          gMap.setZoom(level);
        }
        break;
    }
  }
}

export class MarkerWrapper extends Wrapper<kakao.maps.Marker | google.maps.Marker> {
  readonly marker: kakao.maps.Marker | google.maps.Marker = this.target;
  data: any;
  private readonly infoWindow: kakao.maps.InfoWindow | google.maps.InfoWindow | undefined;

  constructor(marker: kakao.maps.Marker | google.maps.Marker, infoWindowHTML: string | undefined, data: any) {
    super(marker);
    this.data = data;

    if (infoWindowHTML) {
      const options = {
        content: infoWindowHTML,
        zIndex: 3
      };
      switch (this.mapType) {
        case 'KAKAO':
          this.infoWindow = new kakao.maps.InfoWindow(options);
          break;
        case 'GOOGLE':
          this.infoWindow = new google.maps.InfoWindow(options);
          break;
      }
    }
  }

  protected getMapType(target: kakao.maps.Marker | google.maps.Marker): MapType {
    return target instanceof kakao.maps.Marker ? 'KAKAO' : 'GOOGLE';
  }

  setData(data: any) {
    this.data = data;
  }

  setZIndex(index: number) {
    switch (this.mapType) {
      case "KAKAO":
        (this.marker as kakao.maps.Marker).setZIndex(index);
        break;
      case "GOOGLE":
        (this.marker as google.maps.Marker).setZIndex(index);
        break;
    }
  }

  openInfoWindow(map: MapWrapper) {
    if (map.mapType !== this.mapType) {
      throw new Error('Map type mismatch!');
    }

    this.infoWindow?.open(map.target as any, this.target as any);
  }

  closeInfoWindow() {
    this.infoWindow?.close();
  }

  getPosition() {
    switch (this.mapType) {
      case 'KAKAO':
        const {La, Ma} = this.marker.getPosition();
        return {
          latitude: Ma,
          longitude: La
        };
      case 'GOOGLE':
        const lat = this.marker.getPosition()?.lat();
        const lng = this.marker.getPosition()?.lng();
        return {
          latitude: lat,
          longitude: lng
        };
    }
  }

  setPosition({latitude, longitude}: LocationDto) {
    switch (this.mapType) {
      case 'KAKAO':
        (this.marker as kakao.maps.Marker).setPosition(new kakao.maps.LatLng(latitude, longitude));
        break;
      case 'GOOGLE':
        (this.marker as google.maps.Marker).setPosition(new google.maps.LatLng(latitude, longitude));
    }
  }
}

type CircleWrapperType = kakao.maps.Circle | google.maps.Circle;

export class CircleWrapper extends Wrapper<CircleWrapperType> {
  readonly data: any;

  constructor(circle: CircleWrapperType, data: any) {
    super(circle);
    this.data = data;
  }

  protected getMapType(target: CircleWrapperType): MapType {
    return target instanceof kakao.maps.Circle ? 'KAKAO' : 'GOOGLE';
  }
}

type DrawingManagerWrapperType = kakao.maps.Drawing.DrawingManager | google.maps.drawing.DrawingManager;

export class DrawingManagerWrapper extends Wrapper<DrawingManagerWrapperType> {
  readonly manager: kakao.maps.Drawing.DrawingManager | google.maps.drawing.DrawingManager = this.target;
  private tb: kakao.maps.Drawing.Toolbox | null = null;

  constructor(manager: DrawingManagerWrapperType) {
    super(manager);
    this.manager = manager;
  }

  protected getMapType(target: DrawingManagerWrapperType): MapType {
    return target instanceof kakao.maps.Drawing.DrawingManager ? 'KAKAO' : 'GOOGLE';
  }

  setMap(target: MapWrapper) {
    switch (this.mapType) {
      case 'KAKAO':
        const toolbox = new kakao.maps.Drawing.Toolbox({
          drawingManager: this.manager as kakao.maps.Drawing.DrawingManager
        });
        this.tb = toolbox;
        (target.map as kakao.maps.Map).addControl(toolbox.getElement(), kakao.maps.ControlPosition.TOP);
        break;
      case 'GOOGLE':
        (this.manager as google.maps.drawing.DrawingManager).setMap(target.map as google.maps.Map);
        break;
    }
  }

  removeFromMap(target: MapWrapper) {
    if (this.mapType === 'KAKAO' && this.tb) {
      (target.map as kakao.maps.Map).removeControl(this.tb.getElement());
    } else {
      (this.manager as google.maps.drawing.DrawingManager).setMap(null);
    }
  }
}

type PolygonWrapperType = google.maps.Polygon | kakao.maps.Polygon;

export class PolygonWrapper extends Wrapper<PolygonWrapperType> {
  readonly polygon: google.maps.Polygon | kakao.maps.Polygon = this.target;
  private path: LocationDto[];

  constructor(polygon: PolygonWrapperType, path: LocationDto[]) {
    super(polygon);
    this.polygon = polygon
    this.path = path;
  }

  protected getMapType(target: PolygonWrapperType): MapType {
    return target instanceof kakao.maps.Polygon ? 'KAKAO' : 'GOOGLE';
  }

  setMap(target: MapWrapper) {
    switch (this.mapType) {
      case 'KAKAO':
        (this.polygon as kakao.maps.Polygon).setMap(target.map as kakao.maps.Map);
        break;
      case 'GOOGLE':
        (this.polygon as google.maps.Polygon).setMap(target.map as google.maps.Map);
        break;
    }
  }

  setPath(path: LocationDto[]) {
    this.path = path;
    switch (this.mapType) {
      case 'KAKAO':
        (this.polygon as kakao.maps.Polygon).setPath(path.map(e => new kakao.maps.LatLng(e.latitude, e.longitude)));
        break;
      case 'GOOGLE':
        (this.polygon as google.maps.Polygon).setPath(path.map(e => new google.maps.LatLng(e.latitude, e.longitude)));
        break;
    }
  }

  getPath() {
    return [...this.path];
  }
}

type PolylineWrapperType = google.maps.Polyline | kakao.maps.Polyline;

export class PolylineWrapper extends Wrapper<PolylineWrapperType> {
  readonly polyline: google.maps.Polyline | kakao.maps.Polyline = this.target;

  constructor(polyline: PolylineWrapperType) {
    super(polyline);
    this.polyline = polyline;
  }

  protected getMapType(target: PolygonWrapperType): MapType {
    return target instanceof kakao.maps.Polygon ? 'KAKAO' : 'GOOGLE';
  }

  setMap(target: MapWrapper | null) {
    switch (this.mapType) {
      case 'KAKAO':
        (this.polyline as kakao.maps.Polyline)
          .setMap(target ? target.map as kakao.maps.Map : null);
        break;
      case 'GOOGLE':
        (this.polyline as google.maps.Polyline)
          .setMap(target ? target.map as google.maps.Map : null);
        break;
    }
  }
}
