export type OperationType = 'DIRECT' | 'PARTNER' | 'DELIVERY' | '';
export type OperationStatus = 'ONLINE' | 'OFFLINE' | '';

export interface PaginationReqDto {
  page: number,
  size: number
}

export interface PaginationRes {
  page: number;
  totalPage: number;
  totalItem: number;
}

export interface PaginationResDto<T> {
  page: number;
  totalPage: number;
  totalItem: number;
  items: T[];
}

export interface LocationDto {
  latitude: number,
  longitude: number
}

export function compareLocationDto(a: LocationDto, b: LocationDto): boolean {
  return a.latitude === b.latitude && a.longitude === b.longitude;
}

export type TooltipType = 'draw' | 'drag' | 'edit';