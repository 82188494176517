import React, {FC, useEffect, useState,} from 'react';
import {Space, Table, Tag, Typography} from 'antd';
import {ColumnsType} from 'antd/es/table';
import AddBandProductModal from "./AddBandProductModal";
import {getBandTag, parseDate} from "../../../../utils/organizeData";
import {DEFAULT_TABLE_SIZE} from "../../../../constants";
import {ProductBandDto, ProductDto} from "../../product.interface";
import {columnMaker, columnRenderer} from "../../../../utils/componentUtils";

const {Text} = Typography;

interface Props {
  datasource: ProductBandDto[];
  masterProducts: ProductDto[]
}

const BandProductTable: FC<Props> = ({datasource, masterProducts}) => {

  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<ProductBandDto[]>([]);
  const [editBandProduct, setEditBandProduct] = useState<ProductBandDto | null>(null);
  const [delBandProduct, setDelBandProduct] = useState<ProductBandDto | null>(null);

  useEffect(() => {
    setDataSource(datasource);
  }, [])

  const modalClose = () => {
    setIsEditModalVisible(false);
    setIsDeleteModalVisible(false);
  };

  const columns: ColumnsType<ProductBandDto> = [
    columnMaker('id', '상품 ID', columnRenderer(data => data.baseProduct.productId)),
    columnMaker('bandName', '밴드명', columnRenderer((data, idx) => getBandTag(data.band.bandName, idx))),
    columnMaker('productType', '요금제', columnRenderer((data, idx) => {
      return (
        data.baseProduct.productType === 'TRANSACTION' ? <Tag color="red">종량제</Tag> :
          <Tag color="blue">정액제</Tag>
      )
    })),
    columnMaker('productName', '마스터 상품명',
      columnRenderer(data => <Text>{data.baseProduct.title}</Text>),
      'left',
    ),
    columnMaker('mobilityType', '기기', columnRenderer(data => data.baseProduct.mobilityCode === 'ST' ? '킥보드' : '오토바이')),
    columnMaker('price', '밴드 가격(원)', columnRenderer(data => data.price
        ? data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : '0'
      )
    ),
    columnMaker('masterPrice', '마스터 가격(원)', columnRenderer(data => data.baseProduct.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))),
    columnMaker('displayYn', '앱노출여부', columnRenderer(data => data.displaying ? "노출중" : "미노출")),
    columnMaker('saleStatus', '판매여부', columnRenderer(data => data.baseProduct.saleStatus === 'ONSALE' ? "판매중" : "미판매")),
    columnMaker('isAppExpose', '판매시작일', columnRenderer(data => parseDate(data.saleStartAt))),
    columnMaker('saleEndAt', '판매종료일', columnRenderer(data => parseDate(data.saleEndAt))),
    columnMaker('updateProduct', '수정 및 삭제', columnRenderer(data => {
      return (
        <Space size="middle">
          <a onClick={() => {
            setEditBandProduct(data);
            setIsEditModalVisible(true)
          }}>수정</a>
          <a onClick={() => {
            setDelBandProduct(data);
            setIsDeleteModalVisible(true)
          }}>삭제</a>
        </Space>
      )
    })),
  ];

  return (
    <>
      <Table
        bordered={true}
        rowKey={'key'}
        columns={columns}
        dataSource={
          datasource.map((r, i) => ({key: String(i + 1), ...r,}))
        }
        pagination={{
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
      <AddBandProductModal
        isVisible={isEditModalVisible}
        setIsVisible={setIsEditModalVisible}
        editBandProduct={editBandProduct}
        masterProducts={masterProducts}
      />
    </>
  );
};

export default BandProductTable;
