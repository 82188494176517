// 모든 마스터상품 가져오기
import {LocalAdminApi} from "../../services";
import {
  ProductBandCreateDto,
  ProductBandDto,
  ProductCreateDto,
  ProductDto,
  ProductPreviewDto
} from "./product.interface";

export async function getAllMasterProducts(): Promise<ProductDto[]> {
  return await LocalAdminApi.get<ProductDto[]>(`/products`).then((resp) => resp.data);
}

// 마스터 상품 추가하기
export async function addMasterProduct(body: ProductCreateDto): Promise<any> {
  await LocalAdminApi.post<ProductCreateDto>(`/products`, body).then((resp) => resp.data);
}

// 마스터 상품 수정하기
// Todo : 김환진추가필요
export async function updateMasterProduct(productId: string, body: any): Promise<any> {
  await LocalAdminApi.put<any>(`/products/${productId}`, body).then((resp) => resp.data);
}

// 마스터 상품 삭제하기
export async function deleteMasterProduct(productId: string): Promise<any> {
  await LocalAdminApi.delete(`/products/${productId}`).then((resp) => resp.data);
}

// 밴드 상품 가져오기
export async function getAllBandProducts(): Promise<ProductBandDto[]> {
  return await LocalAdminApi.get<ProductBandDto[]>(`/product-band`).then((resp) => resp.data);
}

// 밴드 상품 추가하기
export async function addBandProduct(body: ProductBandCreateDto): Promise<any> {
  await LocalAdminApi.post<ProductBandCreateDto>(`/product-band/`, body).then((resp) => resp.data);
}

// 밴드 상품 수정하기
// Todo : 김환진 추가 필요
export async function updateBandProduct(productId: number, body: any): Promise<any> {
  await LocalAdminApi.put<any>(`/product-band/${productId}`, body).then((resp) => resp.data);
}

// 밴드 상품 삭제하기
export async function deleteBandProduct(productId: string): Promise<any> {
  await LocalAdminApi.delete(`/product-band/${productId}`).then((resp) => resp.data);
}

export async function getBandProductPreview(bandId: number): Promise<ProductPreviewDto[]> {
  return await LocalAdminApi.get(`/products/preview?bandId=${bandId}`).then(resp => resp.data);
}
