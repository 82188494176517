import React from 'react';
import {MobilityDto} from "../../domain/mobility/mobility.interface";
import {parseMobilityStatus} from "../../utils/organizeData";

const CustomMopedOverlay = (moped: MobilityDto) => {
  const mobilityStatus = parseMobilityStatus(moped.mobilityStatus)
  return `
    <div style='width: 160px; padding: 5px'>
      <div><span style={{fontWeight: 'bold'}}>
        SIMEI:</span> ${moped.shortImei}
      </div>
      <div><span style={{fontWeight: 'bold'}}>
        배터리:</span> ${moped.battery}%
      </div>
      <div><span style={{fontWeight: 'bold'}}>
        상태: </span>${mobilityStatus}
      </div>
    </div>
  `
};

export default CustomMopedOverlay;
