import React from 'react';
import {MobilityType} from '../../../domain/mobility/mobility.interface';
import {BaseMapPlugin} from "./BaseMapPlugin";
import {PathDomain} from "../../../domain/mobility/mobility.interface";
import {LocationDto} from "../../common.interface";
import {utils} from "../Utils";
import {MarkerWrapper, PolylineWrapper} from "../Types";
import {PathMarker} from "../markers/PathMarker";

export class MobilityPathMarkerPlugin extends BaseMapPlugin {
  private mobilityType : MobilityType;
  private path: LocationDto[];
  private polyline: PolylineWrapper | undefined;
  private pathMarkers: MarkerWrapper[];

  constructor() {
    super();
    this.mobilityType = 'SCOOTER';
    this.path = [];
    this.pathMarkers = []
  }

  async drawPolyline() {
    if (this.polyline) {
      this.polyline.setMap(null);
      this.polyline = undefined;
    }

    if (this.pathMarkers.length > 0) {
      this.pathMarkers?.forEach(e => e.marker.setMap(null));
    }

    if (this.path.length > 0) {
      const map = await this.getMap();
      this.polyline = utils.createPolyline(map, this.path);
      this.polyline.setMap(map);

      for (let i = 0; i < this.path.length; i++) {
        const location = this.path[i];

        if (i === 0) {
          this.pathMarkers.push(utils.createMarker(map, PathMarker(location, 'start'), "", false));
          continue;
        }

        if (i === this.path.length - 1) {
          this.pathMarkers.push(utils.createMarker(map, PathMarker(location, 'end'), "", false));
          continue;
        }

        if (this.path.length >= 4 && this.path.length < 60 && i % 3 === 0) {
          this.pathMarkers.push(utils.createMarker(map, PathMarker(location, 'riding'), "", false));
          continue;
        }

        if (this.path.length >= 60 && i % 5 === 0) {
          this.pathMarkers.push(utils.createMarker(map, PathMarker(location, 'riding'), "", false));
        }
      }
      map.move(this.path[this.path.length - 1], 1);
    }
  }

  updateMobilityAndPath(data: PathDomain | null) {
    this.mobilityType = data?.mobilityType ?? 'SCOOTER';
    this.path = data?.path ? [...data.path] : [];
    this.drawPolyline().then(r => {});
  }
}
