import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.less';
import './index.less';
import './i18n';
import { ContextProvider } from './context';

ReactDOM.render(
  <ContextProvider>
    <BrowserRouter basename="/admin">
      <App />
    </BrowserRouter>
  </ContextProvider>,
  document.getElementById('root')
);
