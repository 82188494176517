import { LocalAdminApi } from '../../services';
import { MobilityDto } from '../mobility/mobility.interface';
import { LocationDto } from '../../common/common.interface';
import { DashboardPurchase, PlacementDashboardDto } from './dashboard.interface';

export async function getDashboardPurchaseList() {
  return await LocalAdminApi.get<DashboardPurchase[]>(`/dashboard/purchases`).then((resp) => resp.data);
}

export async function getDashboardSignUpListByDate() {
  return await LocalAdminApi.get<number[]>(`/dashboard/signups_by_date`).then((resp) => resp.data);
}


// 모든 라운지(캠프), 픽업존 메타데이터 가쟈오기
export async function getPlacementDashboard(from: string, to: string) {
  return await LocalAdminApi.get<PlacementDashboardDto[]>(`/dashboard/placement?from=${from}&to=${to}`).then((resp) => resp.data);
}

export async function getPlacementDashboardExcel(from: string, to: string) {
  return await LocalAdminApi.get(`/rides/excel/list?from=${from}&to=${to}`, {
    responseType: 'blob'
  }).then(resp => {
    const url = window.URL.createObjectURL(new Blob([resp.data], { type: resp.headers['content-type'] }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${from}-${to} 매출.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}

// 프리패스 사용 후 며칠 이내에 상품을 구매했는지
export async function getPurchaseDateAfterFreePass(date: string, interval: number) {
  return await LocalAdminApi.get(`/dashboard/free_to_paid?date=${date}&interval=${interval}`).then(resp => resp.data);
}

// 프리패스 사용 후 어떤 상품을 구매했는지
export async function getProductAfterFreePass(date: string, interval: number) {
  return await LocalAdminApi.get(`/dashboard/free_to_paid/products?date=${date}&interval=${interval}`).then(resp => resp.data);
}

// 상품별 일별 매출
export async function getProductSales(productId: number) {
  return await LocalAdminApi.get(`/dashboard/${productId}/product_sales_by_date`).then(resp => resp.data);
}

export async function getOnSaleProducts() {
  return await LocalAdminApi.get(`/dashboard/products_on_sale`).then(resp => resp.data);
}
