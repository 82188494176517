import {BaseMapPlugin} from "./BaseMapPlugin";
import {utils} from "../Utils";
import {DrawingManagerWrapper} from "../Types";
import {LocationDto} from "../../common.interface";
import {getPolygonCenter, isInsidePolygon} from "../../../utils/algorithm";
import {getAllScooters} from "../../../services/scoot";
import {getAllMopeds} from "../../../services/moped";
import {getAllPlacements} from "../../../domain/placement/placement.service";
import {CustomPolygonOverlay} from "../CustomPolygonOverlay";

export class DrawingManagerPlugin extends BaseMapPlugin {
  private drawingManager: DrawingManagerWrapper | null = null;
  private scooterBatteryFilter: number = 100;
  private mopedBatteryFilter: number = 100;

  constructor() {
    super();
  }

  updateBatteryFilter(sbf: number, mbf: number) {
    this.scooterBatteryFilter = sbf;
    this.mopedBatteryFilter = mbf;
  }

  async onMapUpdated() {
    const map = await this.getMap();
    this.drawingManager = utils.createDrawingManager(map);
    this.registerDrawingManager();
  }

  async registerDrawingManager() {
    const map = await this.getMap();
    if (this.drawingManager) {
      const drawend = map.mapType === 'GOOGLE' ? 'polygoncomplete' : 'drawend';
      utils.addListener(this.drawingManager, drawend, (event => {
        if (event && Array.isArray(event.location)) {
          const polygonCoords: LocationDto[] = event.location;
          const polygonCenterLocation: LocationDto = getPolygonCenter(polygonCoords);
          let scooterInsidePolygon = 0;
          let mopedInsidePolygon = 0;
          let placementInsidePolygon = 0;

          getAllScooters().then(scooters => {
            scooterInsidePolygon = scooters
              .filter(e => e.mobilityStatus !== 'CARGO')
              .filter(e => {
              return isInsidePolygon(e.location, polygonCoords) && e.battery <= this.scooterBatteryFilter;
            }).length;
            getAllMopeds().then(mopeds => {
              mopedInsidePolygon = mopeds.filter(e => {
                return isInsidePolygon(e.location, polygonCoords) && e.battery <= this.mopedBatteryFilter;
              }).length;
              getAllPlacements().then(placemen => {
                placementInsidePolygon = placemen.filter(e => {
                  return isInsidePolygon(e.location, polygonCoords)
                }).length;
                utils.createInfoWindow(map, CustomPolygonOverlay(placementInsidePolygon, scooterInsidePolygon, mopedInsidePolygon), polygonCenterLocation);
              })
            })
          });
        }
      }))
      utils.registerDrawingManager(map, this.drawingManager);
    }
  }
}