import { useState, useEffect } from 'react';
import Typography from 'antd/lib/typography';

export const convertGeocode = async (lat, lng) => {
  const naver = window.naver;
  var tm128 = naver.maps.TransCoord.fromLatLngToTM128(new naver.maps.LatLng(lat, lng));
  return new Promise((res) => {
    naver.maps.Service.reverseGeocode(
      {
        location: tm128,
        coordType: naver.maps.Service.CoordType.TM128,
      },
      function (status, response) {
        try {
          res(response.result.items[0].address);
        } catch (e) {
          console.log(e);
        }
      }
    );
  });
};

export const Geocode = ({ lat, lng }) => {
  const [geocode, setGeocode] = useState('');
  useEffect(() => {
    const naver = window.naver;
    var tm128 = naver.maps.TransCoord.fromLatLngToTM128(new naver.maps.LatLng(lat, lng));
    naver.maps.Service.reverseGeocode(
      {
        location: tm128,
        coordType: naver.maps.Service.CoordType.TM128,
      },
      function (status, response) {
        try {
          setGeocode(response.result.items[0].address);
        } catch (e) {
          console.log(e);
        }
      }
    );
  }, [lat, lng]);
  return <Typography.Text>{geocode}</Typography.Text>;
};
