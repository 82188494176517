import React from 'react';
import {ReactElement, ReactNode} from "react";
import {AlignType, FixedType} from 'rc-table/lib/interface';
import {TFunctionResult} from "i18next";
import {ColumnType} from "antd/es/table";
import { Typography } from 'antd';

const {Paragraph} = Typography

export function columnWithChildren<T>(
  key: string,
  title: string,
  children: ColumnType<T>[]
) {
  return {
    key: key,
    title: title,
    children: children,
  }
}

export function columnMaker<T>(
  key: string | number,
  title: string | number | TFunctionResult,
  render: (val: any, row: T, idx: number) => string | number | ReactNode | ReactElement | Element,
  align: AlignType = 'center',
  sorter?: (a: T, b: T) => number,
  fixed?: FixedType,
): ColumnType<T> {
  return {
    key: key,
    title: title,
    render: render,
    align: align,
    sorter: sorter,
    fixed: fixed,
  }
}

// export function columnRenderer<T>(render: (data: T, idx: number) => string | number | ReactNode | ReactElement | Element) {
//   return (val: any, row: T, idx: number) => render(row, idx);
// }

export function columnRenderer<T>(
  render: (data: T, idx: number) => string | number | ReactNode | ReactElement | Element, copy?: boolean) {
  if (copy) {
    return (val: any, row: T, idx: number) => (
      <Paragraph style={{margin: 'auto'}} copyable>{render(row, idx)}</Paragraph>
    );
  }
  return (val: any, row: T, idx: number) => render(row, idx);
}


export function domainModalDisplayer<T>(
  setTarget: React.Dispatch<React.SetStateAction<T | null>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  data: T | null,
  isVisible: boolean,
) {
  return () => {
    setTarget(data);
    setIsModalVisible(isVisible);
  }
}
