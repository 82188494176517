import {Button, Input, Modal} from 'antd';
import React, {VFC} from 'react';
import useInput from '../../../hooks/useInput';
import {resetAdminPassword} from '../account.service';
import {AccountDto} from "../account.interface";

interface Props {
  isVisible: boolean;
  setSelectAccount: React.Dispatch<React.SetStateAction<AccountDto | null>>;
  handleCancel: () => void;
  selectAccount: AccountDto | null;
}

const ResetPasswordModal: VFC<Props> = ({isVisible, setSelectAccount, handleCancel, selectAccount}) => {
  const [currentPassword, onChangeCurrentPassword, setCurrentPassword] = useInput('');
  const [newPassword, onChangeNewPassword, setNewPassword] = useInput('');

  const submitHandler = async () => {
    if (selectAccount) {
      try {
        await resetAdminPassword(selectAccount.accountId, {
          newPw: newPassword,
          currentPw: currentPassword,
        });
        alert('정상적으로 패스워드가 수정되었습니다.');
        setSelectAccount(null);
        setCurrentPassword('');
        setNewPassword('');
        handleCancel();
      } catch (err) {
        alert(`패스워드가 수정 되지 않았습니다. ${err}`);
      }
    }
  };

  const cancelModal = () => {
    setCurrentPassword('');
    setNewPassword('');
    handleCancel();
  };

  return (
    <>
      <Modal
        title={'계정수정'}
        visible={isVisible}
        onCancel={cancelModal}
        footer={[<Button onClick={handleCancel}>취소</Button>, <Button onClick={submitHandler}>수정하기</Button>]}
      >
        <div style={{display: 'flex', marginBottom: '30px'}}>
          <span style={{width: '120px'}}>현재 비밀번호</span>
          <Input.Password value={currentPassword} onChange={onChangeCurrentPassword}/>
        </div>

        <div style={{display: 'flex'}}>
          <span style={{width: '120px'}}>변경할 비밀번호</span>
          <Input.Password value={newPassword} onChange={onChangeNewPassword}/>
        </div>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;
