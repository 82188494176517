import { LocalAdminApi } from '.';
import { HistoryDto } from '../domain/ride/ride.interface';
import { MobilityDto } from '../domain/mobility/mobility.interface';
import { cache } from '../utils/cache';


// 모든 scooter 가져오기
export async function getAllScooters() {
  return await cache.get<MobilityDto[]>('/scooters', 5);
  // return await LocalAdminApi.get<MobilityDto[]>(`/scooters`).then((resp) => resp.data);
}

// 스쿠터 추가하기
export async function addScooter(body: { shortImei: string, bandId: number }) {
  return await LocalAdminApi.post('/scooters', body);
}

export async function removeScooter(body: { shortImeiList: string[] }) {
  return await LocalAdminApi.put('/scooters/delete', body);
}

// 특정 scooter 가져오기
export async function getScooter(id: number) {
  return await cache.get<MobilityDto>(`/scooters/${id}`, 5 * 60);
}

// 특정 scooter rides history 가져오기
export async function getScooterRidesHistory(id: number) {
  return await cache.get<HistoryDto[]>(`/scooters/${id}/rides`, 5 * 60);
}
