import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, SetLoadingContext } from '../../../../../context';
import { MobilityCode, ProductBandDto, ProductDto } from '../../../product.interface';
import { getAllBandProducts, getAllMasterProducts } from '../../../product.service';
import BandProductTable from '../BandProductTable';

type SelectMobilityCode = MobilityCode | 'ALL'

const BandProductTableTab = (
  {
    searchStr,
    selectedBand,
    selectedMobility
  }: {
    searchStr: string,
    selectedBand: string,
    selectedMobility: SelectMobilityCode
  }) => {

  const bandId = useContext(AccountContext)!.band.bandId;
  const setLoading = useContext(SetLoadingContext)!;

  const [masterProducts, setMasterProducts] = useState<ProductDto[]>([]);

  const [bandProducts, setBandProducts] = useState<ProductBandDto[]>([]);
  const [filteredBandProducts, setFilteredBandProducts] = useState<ProductBandDto[]>([]);

  useEffect(() => {
    setLoading(true);
    getAllBandProducts().then(res => {
      const sortedFilteredBandProducts = res
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .filter(e => e.baseProduct.saleStatus !== 'TERMINATION');
      setBandProducts(sortedFilteredBandProducts);
      setFilteredBandProducts(sortedFilteredBandProducts);
    }).then(e => setLoading(false));
    getAllMasterProducts().then(setMasterProducts);
  }, []);

  useEffect(() => {
      setFilteredBandProducts(bandProducts
        .filter(e => {
          if (searchStr === '') return true;
          else return e.baseProduct.title.indexOf(searchStr) !== -1;
        })
        .filter(e => {
          if (selectedBand === '0') return true;
          else return e.band.bandId.toString() === selectedBand
        })
        .filter(e => {
          if (selectedMobility === 'ALL') return true;
          else return e.baseProduct.mobilityCode === selectedMobility
        })
      );
  }, [searchStr, selectedBand, selectedMobility]);

  return (
    <div>
      <BandProductTable
        datasource={filteredBandProducts}
        masterProducts={masterProducts}
      />
    </div>
  );
};

export default BandProductTableTab;
