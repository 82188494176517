import React, {CSSProperties} from 'react';
import {Typography} from "antd";

interface Props {
  urlVal: number;
  textVal: string | number;
}

const {Paragraph} = Typography;

export default function NameALink({urlVal, textVal}: Props) {

  const style: CSSProperties = {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: '#aa5717'
  }

  const copyableName = <Paragraph style={{color: '#aa5717', margin: 0}} copyable={true}>{textVal}</Paragraph>;

  return <a style={style} target={'_blank'} href={`/admin/riders/${urlVal}`}>{copyableName}</a>;
}