import React, {useContext, useEffect, useState} from 'react';
import {ScooterRideTable} from '../component/ScooterRideTable';
import {AccountContext, SetLoadingContext} from '../../../context';
import {getScooterRides} from '../ride.service';
import {useValidation} from '../../../hooks/useValidation';
import {useLocation} from "react-router-dom";
import {Card} from "antd";
import Search from "antd/es/input/Search";
import {DEFAULT_TABLE_SIZE} from '../../../constants'
import {PaginationRes} from "../../../common/common.interface";
import {HistoryDto, ScooterRidePaginationReq} from "../ride.interface";
import {useTranslation} from "react-i18next";
import {PathModalMap} from "../../../common/map/PathModalMap";
import {PathDomain} from "../../mobility/mobility.interface";

export function ScooterRidePage() {
  const {t} = useTranslation();
  const admin = useContext(AccountContext);
  const [rides, setRides] = useState<HistoryDto[]>([]);
  const setLoading = useContext(SetLoadingContext)!;
  const checkToken = useValidation;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [pagination, setPagination] = useState<ScooterRidePaginationReq>({
    page: query.get("page") ? parseInt(query.get("page")!) : 0,
    size: query.get("size") ? parseInt(query.get("size")!) : DEFAULT_TABLE_SIZE,
    keyword: query.get("keyword") ?? '',
    battery: query.get("battery") ?? '100',
    band: query.get("band") ?? ''
  });

  const [paginationRes, setPaginationRes] = useState<PaginationRes>({
    page: 0,
    totalPage: 0,
    totalItem: 0
  });

  const [isPathModalVisible, setIsPathModalVisible] = useState<boolean>(false);
  const [selectedMobilityAndPath, setSelectedMobilityAndPath] = useState<PathDomain | null>(null);

  checkToken();

  useEffect(() => {
    bootstrap();
  }, [admin]);

  async function bootstrap() {
    setLoading(true);
    await getScooterRides(pagination.page, pagination.size, pagination.keyword, pagination.band)
      .then((res) => {
        setPaginationRes({
          page: res.page,
          totalItem: res.totalItem,
          totalPage: res.totalPage
        });
        setRides(res.items)
      });
    setLoading(false);
  }

  const handleSearch = (val: any) => {
    window.location.href = `/admin/scooters-history?page=${0}&size=${DEFAULT_TABLE_SIZE}&keyword=${val}`
  }

  return (
    <div>
      <PathModalMap
        isVisible={isPathModalVisible}
        setIsVisible={setIsPathModalVisible}
        selectedMobilityAndPath={selectedMobilityAndPath}
      />
      <div>
        <div>
          <h2>{t('scooterRidingHistory')}({paginationRes.totalItem})</h2>
        </div>
        <Card title={t('searchRider')}>
          <Search
            placeholder={t('pleasePutNameSIMEI')}
            size={'large'}
            defaultValue={pagination.keyword}
            onSearch={handleSearch}
          />
        </Card>
      </div>
      <ScooterRideTable
        rides={rides}
        pagination={pagination}
        paginationRes={paginationRes}
        setIsPathModalVisible={setIsPathModalVisible}
        setSelectedMobilityAndPath={setSelectedMobilityAndPath}
      />
    </div>
  );
}
