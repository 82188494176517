import React, {useState, useEffect} from 'react';
import {getNonPaymentList} from "../ondemand.service";
import {NonPaymentDto} from "../ondemand.interface";
import {TableHeading} from "../../../common/component/TableHeading";
import {NonPaymentListTable} from "../components/NonPaymentListTable";
import {useTranslation} from "react-i18next";
import {Card, Input, Radio} from 'antd';
import FilterRadioButton from "../../../common/component/FilterRadioButton";

function NonPaymentPage() {
  const [scooterNonPayment, setScooterNonPayment] = useState<NonPaymentDto[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const [isPaid, setIsPaid] = useState(2)
  const {t} = useTranslation();

  useEffect(() => {
    getNonPaymentList().then(setScooterNonPayment);
  },[]);

  const filteredScooterNonpayment = scooterNonPayment
    .filter(e => e.rider.riderName.indexOf(searchStr) !== -1)
    .filter(e => {
      if (isPaid === 2) return true;
      else if (isPaid === 1) return e.paidAt
      else return !e.paidAt
    });

  return (
    <>
      <TableHeading headingTitle={t('unpaid')} row={filteredScooterNonpayment.length}/>
      <Card>
        <Input
          placeholder={'라이더 이름을 입력해주세요'}
          value={searchStr}
          onChange={e => setSearchStr(e.target.value)}
        />
        <Radio.Group style={{display: 'flex', marginTop: '10px'}} value={isPaid} onChange={e => setIsPaid(e.target.value)}>
          <Radio.Button style={{flex: '1', textAlign: 'center'}} value={2}>전체</Radio.Button>
          <Radio.Button style={{flex: '1', textAlign: 'center'}} value={1}>결제완료</Radio.Button>
          <Radio.Button style={{flex: '1', textAlign: 'center'}} value={0}>미결제</Radio.Button>
        </Radio.Group>
      </Card>
      <NonPaymentListTable nonPaymentList={filteredScooterNonpayment}/>
    </>
  )
}

export default NonPaymentPage;