import {Alert} from "antd";
import React, {useContext, useEffect} from "react";
import {AlertContext, SetAlertContext} from "../../context";

export function AlertComponent() {
    const alert = useContext(AlertContext)!;
    const setAlert = useContext(SetAlertContext)!;
    useEffect(() => {
        setTimeout(() => {
            setAlert(null);
        }, 2000);
    }, [alert]);

    if (!alert) {
        return <></>;
    }

    const {type, message} = alert;
    return (
        <Alert
            type={type}
            message={message}
            afterClose={() => setAlert(null)}
            banner
            closable
            showIcon
        />
    );
}
