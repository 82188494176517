import React, {CSSProperties} from 'react';
import {Col, Radio, Row, Divider, Slider, InputNumber} from 'antd';
import {MobilityRadioValue, PlacementDisplayValue, PlacementRadioValue} from "../../page/PlacementMapPage";

// type MobilityRadioValue = 'ALL' | 'BS' | 'S9' | 'NO'
// type PlacementRadioValue = 'ALL' | 'A' | 'S' | 'M' | 'SP' | 'NO'

interface Props {
  mobilityRadio: MobilityRadioValue;
  setMobilityRadio: React.Dispatch<React.SetStateAction<MobilityRadioValue>>;
  placementRadio: PlacementRadioValue;
  setPlacementRadio: React.Dispatch<React.SetStateAction<PlacementRadioValue>>;
  setScooterBatteryFilter: React.Dispatch<React.SetStateAction<number>>;
  setMopedBatteryFilter: React.Dispatch<React.SetStateAction<number>>;
  scooterBatteryFilter: number,
  mopedBatteryFilter: number,
  placementRadio2: PlacementDisplayValue,
  setPlacementRadio2: React.Dispatch<React.SetStateAction<PlacementDisplayValue>>,
}

const MapFilterControlPanel = (
  {
    mobilityRadio,
    setMobilityRadio,
    placementRadio,
    setPlacementRadio,
    setScooterBatteryFilter,
    setMopedBatteryFilter,
    scooterBatteryFilter,
    mopedBatteryFilter,
    placementRadio2,
    setPlacementRadio2,
  }: Props) => {

  const floatingStyle: CSSProperties = {
    position: 'absolute',
    width: '350px',
    top: '230px',
    right: '10px',
    backgroundColor: '#FFF',
    color: '#FFF',
    borderRadius: '15px',
    boxShadow: '2px 2px 3px #999',
    zIndex: 3,
    padding: '8px 12px',
    border: '2px solid #F36F21'
  }

  const dividerStyle: CSSProperties = {margin: '3px 0px'};
  const mobilityRadioStyle: CSSProperties = {width: '20%', textAlign: 'center', fontSize: '11px'};
  const placementRadioStyle: CSSProperties = {width: '20%', textAlign: 'center', fontSize: '11px', padding: '0 5px'};
  const placementRadioStyle2: CSSProperties = {width: '33.333%', textAlign: 'center', fontSize: '11px', padding: '0 5px'};

  return (
    <div style={{width: '350px', marginTop: '20px'}}>
      <Divider
        plain={true}
        style={dividerStyle}
        orientation={'left'}
      >스쿠터 배터리 필터</Divider>
      <Row>
        <Col span={18}>
          <Slider
            defaultValue={100}
            onAfterChange={(val: number) => setScooterBatteryFilter(val)}
            onChange={(val: number) => setScooterBatteryFilter(val)}
            value={scooterBatteryFilter}
          />
        </Col>
        <Col span={6}>
          <InputNumber
            style={{marginLeft: '5px', width: '100%'}}
            value={scooterBatteryFilter}
            onChange={e => setScooterBatteryFilter(e)}
          />
        </Col>
      </Row>
      <Divider
        plain={true}
        style={dividerStyle}
        orientation={'left'}
      >오토바이 배터리 필터</Divider>
      <Row>
        <Col span={18}>
          <Slider
            defaultValue={100}
            onAfterChange={(val: number) => setMopedBatteryFilter(val)}
            onChange={(val: number) => setMopedBatteryFilter(val)}
            value={mopedBatteryFilter}
          />
        </Col>
        <Col span={6}>
          <InputNumber
            style={{marginLeft: '5px', width: '100%'}}
            value={mopedBatteryFilter}
            onChange={e => setMopedBatteryFilter(e)}
          />
        </Col>
      </Row>
      <Divider
        plain={true}
        style={dividerStyle}
      >픽업존 필터</Divider>
      <Radio.Group
        style={{width: '100%', margin: '12px 0'}}
        value={placementRadio}
        onChange={e => setPlacementRadio(e.target.value)}
      >
        <Radio.Button style={placementRadioStyle} value={'ALL'}>전체</Radio.Button>
        <Radio.Button style={placementRadioStyle} value={'A'}>오킥</Radio.Button>
        <Radio.Button style={placementRadioStyle} value={'S'}>킥보드</Radio.Button>
        <Radio.Button style={placementRadioStyle} value={'M'}>오토바이</Radio.Button>
        <Radio.Button style={placementRadioStyle} value={'NO'}>없음</Radio.Button>
      </Radio.Group>
      <Radio.Group
        style={{width: '100%'}}
        value={placementRadio2}
        onChange={e => setPlacementRadio2(e.target.value)}
      >
        <Radio.Button style={placementRadioStyle2} value={'ALL'}>전체</Radio.Button>
        <Radio.Button style={placementRadioStyle2} value={true}>노출</Radio.Button>
        <Radio.Button style={placementRadioStyle2} value={false}>미노출</Radio.Button>
      </Radio.Group>
      <Divider
        plain={true}
        style={dividerStyle}
      >기기 필터</Divider>
      <Radio.Group
        style={{width: '100%'}}
        value={mobilityRadio}
        onChange={e => setMobilityRadio(e.target.value)}
      >
        <Radio.Button style={mobilityRadioStyle} value={'ALL'}>전체</Radio.Button>        
        <Radio.Button style={mobilityRadioStyle} value={'BS'}>R1</Radio.Button>
        <Radio.Button style={mobilityRadioStyle} value={'NIU'}>NIU</Radio.Button>
        <Radio.Button style={mobilityRadioStyle} value={'PCX'}>PCX</Radio.Button>
        <Radio.Button style={mobilityRadioStyle} value={'S9'}>S9</Radio.Button>
        <Radio.Button style={mobilityRadioStyle} value={'NO'}>없음</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default MapFilterControlPanel;
