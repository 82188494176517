import React, { FC, useEffect, useState } from 'react';
import BaseModal from '../../../../common/component/BaseModal';
import { Col, DatePicker, Form, Input, InputNumber, Radio, Row, Switch, Tooltip, Select } from 'antd';
import moment from 'moment';
import { addMasterProduct, updateMasterProduct } from '../../product.service';
import { PASS_IMAGE, PREMIUM_IMAGE, SUBSCRIBE_IMAGE } from '../../../../constants';
import { ProductCreateDto, ProductDto } from '../../product.interface';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editMasterProduct?: ProductDto | null;
}

const { Option } = Select;
const { TextArea } = Input;

const AddMasterProductModal: FC<Props> = ({ isVisible, setIsVisible, editMasterProduct }) => {

  const [formValues, setFormValues] = useState<ProductCreateDto | null>(null);
  const [formInstance] = Form.useForm();

  useEffect(() => {
    if (formInstance && editMasterProduct) formInstance.setFieldsValue(editMasterProduct);
  }, [editMasterProduct]);

  const { RangePicker } = DatePicker;

  const onSubmitForm = async (formValues: ProductCreateDto) => {

    const { salesPeriod } = formInstance.getFieldsValue(['salesPeriod']);

    const [startForm, endForm] = salesPeriod;

    const saleStartAt = moment(startForm).toISOString();
    const saleEndAt = moment(endForm).toISOString() ?? '9000-12-31T23:59:59';

    formValues.saleStartAt = saleStartAt;
    formValues.saleEndAt = saleEndAt;

    if (editMasterProduct) {
      if (window.confirm('정말 수정하시겠습니까?')) {
        try {
          await updateMasterProduct(editMasterProduct.productId!.toString(), formValues).then(e => {
            alert('성공적으로 수정 되었습니다');
            window.location.reload();
          });
        } catch (err) {
          alert('수정에 실패하였습니다 : ' + err);
          window.location.reload();
        }
      }
    } else {
      if (window.confirm('정말 등록하시겠습니까?')) {
        try {
          await addMasterProduct(formValues).then(e => {
            alert('성공적으로 등록 되었습니다');
            window.location.reload();
          });
        } catch (err) {
          alert('등록에 실패하였습니다 : ' + err.response.data.message);
          window.location.reload();
        }
      }
    }
  };

  const submitModal = async () => {
    if (formValues) { // 변경이 있는 경우
      formInstance.submit();
    } else { // 변경이 없는 경우
      setIsVisible(false);
    }
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label}은 숫자만 입력해주세요'
    }
  };

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={submitModal}
      handleCancel={() => {
        setIsVisible(false);
      }}
      headerTitle={'마스터 상품추가'}
      width={'70%'}
    >
      <Form
        name='masterProductForm'
        onValuesChange={(changedVal, allVal) => {
          setFormValues(allVal);
        }}
        validateMessages={validateMessages}
        form={formInstance}
        onFinish={onSubmitForm}
        initialValues={{
          'displayName': editMasterProduct ? editMasterProduct.title : null,
          'price': editMasterProduct?.price,
          'productType': editMasterProduct?.productType,
          'productSubType': editMasterProduct?.productSubType,
          'period': editMasterProduct?.period,
          'saleStatus': editMasterProduct ? editMasterProduct.saleStatus : '',
          'salesPeriod': editMasterProduct ? [moment(editMasterProduct.saleStartAt).format('YYYY-MM-DD'), moment(editMasterProduct.saleEndAt)] : undefined,
          'displayYn': editMasterProduct ? editMasterProduct.displaying : false,
          'lockLimitYn': editMasterProduct ? editMasterProduct.waitableYn : false,
          'batterySwapYn': editMasterProduct ? editMasterProduct.batterySwapYn : false
        }}
      >
        <Row gutter={[32, 32]}
             justify={'center'}
        >
          <Col span={12}>
            <Form.Item
              label='상품명'
              name='title'
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='상품명(단축형)'
              name='subTitle'
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='앱내 표기명'
              name='displayName'
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='상품가격'
              name='price'
              rules={[{
                required: true,
                type: 'number'
              }]}
            >
              <InputNumber
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='설명'
              name='description'
              rules={[{ required: true }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label='태그'
              name='tag'
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='이용기간'
              name='period'
              rules={[{
                required: true,
                type: 'number'
              }]}
            >
              <InputNumber
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='단위'
              name='unit'
              rules={[{
                required: true
              }]}
            >
              <Select defaultValue='M'>
                <Option value={'M'}>월</Option>
                <Option value={'D'}>일</Option>
                <Option value={'H'}>시</Option>
                <Option value={'I'}>분</Option>
                <Option value={'S'}>초</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='salesPeriod'
              label='판매기간'
              valuePropName='checked'
              // style={{marginBottom: 0}}
            >
              <RangePicker
                style={{ width: '100%' }}
                allowEmpty={[false, true]}
                placeholder={['시작일', '마감일']}
                format='YYYY-MM-DD'
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='saleStatus'
              label='판매여부'
              rules={[{ required: true }]}
            >
              <Radio.Group
                style={{ width: '100%' }}
              >
                <Radio.Button
                  style={{ width: '25%', textAlign: 'center' }}
                  value='READY'>판매준비</Radio.Button>
                <Radio.Button
                  style={{ width: '25%', textAlign: 'center' }}
                  value='ONSALE'>판매중</Radio.Button>
                <Radio.Button
                  style={{ width: '25%', textAlign: 'center' }}
                  value='SUSPEND'>판매중지</Radio.Button>
                <Radio.Button
                  style={{ width: '25%', textAlign: 'center' }}
                  value='TERMINATION'>판매종료</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              name='productType'
              label='요금제 선택'
              required={true}
            >
              <Radio.Group
                style={{ width: '100%' }}
              >
                <Radio.Button
                  style={{ width: '50%', textAlign: 'center' }}
                  value='TRANSACTION'>종량제</Radio.Button>
                <Radio.Button
                  style={{ width: '50%', textAlign: 'center' }}
                  value='SUBSCRIPTION'>정액제</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item
              name='productSubType'
              label='요금제 유형 선택'
              required={true}
            >
              <Radio.Group
                style={{ width: '100%' }}
              >
                <Radio.Button
                  style={{ width: '20%', textAlign: 'center' }}
                  value='PASS'>PASS</Radio.Button>
                <Radio.Button
                  style={{ width: '20%', textAlign: 'center' }}
                  value='LEASE'>리스</Radio.Button>
                <Radio.Button
                  style={{ width: '20%', textAlign: 'center' }}
                  value='RENTAL'>렌탈</Radio.Button>
                <Radio.Button
                  style={{ width: '20%', textAlign: 'center' }}
                  value='PENALTY'>패널티</Radio.Button>
                <Radio.Button
                  style={{ width: '20%', textAlign: 'center' }}
                  value='OTHER'>부가상품</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name='mobilityCode'
              label='기기 선택'
              required={true}
            >
              <Radio.Group
                style={{ width: '100%' }}
              >
                <Radio.Button
                  style={{ width: '50%', textAlign: 'center' }}
                  value='ST'>킥보드</Radio.Button>
                <Radio.Button
                  style={{ width: '50%', textAlign: 'center' }}
                  value='MC'>오토바이</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name='detailDescription'
              label='상세정보'
              required={true}
            >
              <TextArea showCount maxLength={100} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name='displayYn'
              label='앱내 노출여부'
              required={true}
            >
              <Switch
                defaultChecked={editMasterProduct ? editMasterProduct.displaying : false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='waitableYn'
              label='잠금 무제한'
              required={true}
            >
              <Switch
                defaultChecked={editMasterProduct ? editMasterProduct.waitableYn : false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='batterySwapYn'
              label='배터리 교체 여부'
              required={true}
            >
              <Switch
                defaultChecked={editMasterProduct ? editMasterProduct.batterySwapYn : false}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='returnable'
              label='반납 가능여부'
              required={true}
            >
              <Switch
                defaultChecked={editMasterProduct ? editMasterProduct.returnable : true}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name='displayImage1'
              label='이미지선택'
              required={true}
            >
              <Radio.Group style={{ width: '100%' }}>
                <Tooltip title={<img style={{ width: '50px', height: '40px' }} src={PASS_IMAGE} />}>
                  <Radio.Button
                    style={{ width: '33%', textAlign: 'center' }}
                    value={PASS_IMAGE}>이용권</Radio.Button>
                </Tooltip>
                <Tooltip title={<img style={{ width: '50px', height: '40px' }} src={SUBSCRIBE_IMAGE} />}>
                  <Radio.Button
                    style={{ width: '33%', textAlign: 'center' }}
                    value={SUBSCRIBE_IMAGE}>구독권</Radio.Button>
                </Tooltip>
                <Tooltip title={<img style={{ width: '50px', height: '40px' }} src={PREMIUM_IMAGE} />}>
                  <Radio.Button
                    style={{ width: '33%', textAlign: 'center' }}
                    value={PREMIUM_IMAGE}>프리미엄</Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
};

export default AddMasterProductModal;
