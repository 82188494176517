import React, {createContext, ReactNode, useState} from 'react';
import {AccountDto} from "../domain/account/account.interface";

export const {
  value: AccountContext,
  setValue: SetAdminContext,
  provider: AdminContextProvider,
} = createStateContext<AccountDto>();

export const {
  value: LoadingContext,
  setValue: SetLoadingContext,
  provider: LoadingContextProvider,
} = createStateContext<Boolean>();

interface AlertMessage {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
}

export const {
  value: AlertContext,
  setValue: SetAlertContext,
  provider: AlertContextProvider,
} = createStateContext<AlertMessage | null>();

export function ContextProvider({children}: { children: ReactNode }) {
  return (
    <AdminContextProvider>
      <AlertContextProvider>
        <LoadingContextProvider>{children}</LoadingContextProvider>
      </AlertContextProvider>
    </AdminContextProvider>
  );
}

function createStateContext<T>() {
  const ValueContext = createContext<T | null>(null);
  const SetValueContext = createContext<React.Dispatch<React.SetStateAction<T | null>> | null>(null);
  const Provider = function ({children}: { children: React.ReactNode }) {
    const [value, setValue] = useState<T | null>(null);

    return (
      <ValueContext.Provider value={value}>
        <SetValueContext.Provider value={setValue}>{children}</SetValueContext.Provider>
      </ValueContext.Provider>
    );
  };

  return {value: ValueContext, setValue: SetValueContext, provider: Provider};
}
