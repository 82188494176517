import { LocationDto } from '../common.interface';
import {
  MapWrapper,
  MarkerWrapper,
  MapType,
  CircleWrapper,
  DrawingManagerWrapper,
  PolygonWrapper,
  PolylineWrapper
} from './Types';
import {EventType, MarkerProps, Point} from './Map';
import { utils as googleUtils } from './impls/Google';
import { utils as kakaoUtils } from './impls/Kakao';
import {PlacementDto} from "../../domain/placement/placement.interface";
import {MobilityDto} from "../../domain/mobility/mobility.interface";

const utilImpls = {
  GOOGLE: googleUtils,
  KAKAO: kakaoUtils
};

export interface ListenerEventData {
  location?: LocationDto | LocationDto[]
}

export interface MapUtils {
  createMap: (mapElement: HTMLDivElement, level: number, center: LocationDto, mapType: MapType) => MapWrapper,
  createMarker: (target: MapWrapper, props: MarkerProps, data: any, draggable?: boolean) => MarkerWrapper,
  createCircle: (target: MapWrapper, placement: PlacementDto) => CircleWrapper,
  createDrawingManager: (target: MapWrapper, customDrawing?: boolean) => DrawingManagerWrapper,
  createInfoWindow: (target: MapWrapper, content: string, position: LocationDto) => void,
  createPolygon: (target: MapWrapper, paths: LocationDto[]) => PolygonWrapper,
  createPolyline: (target: MapWrapper, paths: LocationDto[]) => PolylineWrapper,
  registerDrawingManager: (map: MapWrapper, manager: DrawingManagerWrapper) => void,
  addListener: (
    target: MapWrapper | MarkerWrapper | DrawingManagerWrapper | PolygonWrapper,
    event: EventType | keyof kakao.maps.EventHandlerMap | string,
    handler: (event?: ListenerEventData) => void,
  ) => void,
  setMap: (target: MarkerWrapper | CircleWrapper | PolygonWrapper, map: MapWrapper | null) => void,
  setDraggable: (target: MapWrapper, isDraggable: boolean) => void,
  pointToLocation: (map: MapWrapper, point: Point) => LocationDto,
}

function createMap(mapElement: HTMLDivElement, level: number, center: LocationDto, mapType: MapType) {
  return utilImpls[mapType].createMap(mapElement, level, center, mapType);
}

function createMarker(target: MapWrapper, props: MarkerProps, data: any, draggable?: boolean) {
  return utilImpls[target.mapType].createMarker(target, props, data, draggable);
}

function createCircle(target: MapWrapper, placement: PlacementDto): CircleWrapper {
  return utilImpls[target.mapType].createCircle(target, placement);
}

function createDrawingManager(target: MapWrapper, customDrawing?: boolean) {
  return utilImpls[target.mapType].createDrawingManager(target, customDrawing);
}

function createInfoWindow(map: MapWrapper, content: string, position: LocationDto) {
  return utilImpls[map.mapType].createInfoWindow(map, content, position);
}

function createPolygon(map: MapWrapper, paths: LocationDto[]) {
  return utilImpls[map.mapType].createPolygon(map, paths);
}

function createPolyline(map: MapWrapper, paths: LocationDto[]) {
  return utilImpls[map.mapType].createPolyline(map, paths);
}

function registerDrawingManager(map: MapWrapper, manager: DrawingManagerWrapper) {
  return utilImpls[map.mapType].registerDrawingManager(map, manager);
}

function addListener(
  target: MapWrapper | MarkerWrapper | DrawingManagerWrapper | PolygonWrapper,
  event: EventType | keyof kakao.maps.EventHandlerMap | string,
  handler: (event?: ListenerEventData) => void
) {
  utilImpls[target.mapType].addListener(target, event, handler);
}

function setMap(target: MarkerWrapper | CircleWrapper | PolygonWrapper, map: MapWrapper | null) {
  return utilImpls[target.mapType].setMap(target, map);
}

function setDraggable(target: MapWrapper, isDraggable: boolean) {
  return utilImpls[target.mapType].setDraggable(target, isDraggable);
}

function pointToLocation(map: MapWrapper, point: Point): LocationDto {
  return utilImpls[map.mapType].pointToLocation(map, point);
}

export const utils: MapUtils = {
  createMap,
  createMarker,
  createCircle,
  createDrawingManager,
  createInfoWindow,
  createPolygon,
  createPolyline,
  registerDrawingManager,
  addListener,
  setMap,
  setDraggable,
  pointToLocation,
};

export function getIdentifier(target: MobilityDto | PlacementDto): string | number {
  if (Object.keys(target).includes('shortImei')) {
    return (target as MobilityDto).shortImei;
  }
  return (target as PlacementDto).placementId;
}
