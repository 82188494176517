import {AbstractDragBehavior} from "./AbstractDragBehavior";
import {MapWrapper, MarkerWrapper, PolygonWrapper} from "../../Types";
import {LocationDto} from "../../../common.interface";
import {ArrayList} from "../../../../utils/array_list";
import {Polygon} from "../../../polygon/polygon";

export class PolygonDragBehavior extends AbstractDragBehavior<PolygonWrapper> {
  private readonly polygon: PolygonWrapper;
  private readonly markers: ArrayList<MarkerWrapper>;
  private readonly subMarkers: ArrayList<MarkerWrapper>;
  private readonly polygonBuilder: Polygon.Builder;

  constructor(map: MapWrapper, polygon: PolygonWrapper, markers: ArrayList<MarkerWrapper>, subMarkers: ArrayList<MarkerWrapper>, polygonBuilder: Polygon.Builder) {
    super(map, polygon);
    this.polygon = polygon;
    this.markers = markers;
    this.subMarkers = subMarkers;
    this.polygonBuilder = polygonBuilder;
  }

  protected onDrag(delta: LocationDto): void {
    this.polygonBuilder.clear();

    this.markers.forEach(this.markerMover(delta, true));
    this.subMarkers.forEach(this.markerMover(delta, false));

    this.polygon.setPath(this.polygonBuilder.build().getCoordinates());
  }

  protected onDragDone(delta: LocationDto): void {}

  private markerMover(delta: LocationDto, appendToPolygonBuilder: boolean) {
    return (marker: MarkerWrapper) => {
      const {latitude, longitude} = marker.getPosition();
      const position = {latitude: latitude + delta.latitude, longitude: longitude + delta.longitude};
      marker.setPosition(position);
      marker.setData(position);

      if (appendToPolygonBuilder) {
        this.polygonBuilder.append(position);
      }
    };
  }
}
