import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Radio, RadioChangeEvent, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import AdminAccountTable from '../component/AdminAccountTable';
import useInput from '../../../hooks/useInput';
import { SetLoadingContext } from '../../../context';
import { addAccount, getAccounts } from '../account.service';
import { getBands } from '../../band/band.service';
import { AccountCreateBody, AccountDto } from '../account.interface';
import { BandDto } from '../../band/band.interface';

const AdminAccountPage = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const [accounts, setAccounts] = useState<AccountDto[]>([]);
  const [bands, setBands] = useState<BandDto[]>([]);

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [userName, onChangeUserName, setUserName] = useInput('');
  const [phoneNumber, onChangePhoneNumber, setPhoneNumber] = useInput('');
  const [password, onChangePassword, setPassword] = useInput('');
  const [checkPassword, onChangeCheckPassword, setCheckPassword] = useInput('');
  const [selectPermissions, setSelectPermission] = useState<'ADMIN' | 'OPERATOR'>('ADMIN');

  const setLoading = useContext(SetLoadingContext)!;

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const createModalCancel = () => {
    setIsCreateModalVisible(false);
  };

  const onFinish = async (formValue: AccountCreateBody) => {
    const { accountName, email, phoneNumber, role, password, bandId } = formValue;
    const newAccount: AccountCreateBody = { accountName, email, password, role, phoneNumber, bandId };
    try {
      await addAccount(newAccount);
      await getAccounts().then(setAccounts);
      message.success(t('operatorAccountCreated'));
      setIsCreateModalVisible(false);
    } catch (err) {
      message.error(`operatorAccountFailedToCreate ${err}`);
    }
  };

  const onFinishFailed = () => {
    console.log('onFinish Fail');
  };

  const onChangePermission = (e: RadioChangeEvent) => {
    setSelectPermission(e.target.value);
  };

  useEffect(() => {
    bootstrap();
  }, [JSON.stringify(accounts)]);

  const bootstrap = async () => {
    setLoading(true);
    await getAccounts().then(setAccounts);
    await getBands().then(setBands);
    setLoading(false);
  };

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h2>{t('operatorAccount')}({accounts && accounts.length})</h2>
          <Button onClick={showCreateModal}>{t('createOperatorAccount')}</Button>
        </div>
        <AdminAccountTable accounts={accounts} setAccounts={setAccounts} />
      </div>
      <Modal title={t('createOperatorAccount')} visible={isCreateModalVisible} onCancel={createModalCancel}
             footer={null}>
        <Form
          name='basic'
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ role: selectPermissions }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label={t('selectOperatorAuth')} name='role'
                     rules={[{ required: true, message: t('pleaseSelectOperatorAuth') }]}>
            <Radio.Group onChange={onChangePermission} value={selectPermissions}>
              <Radio value='ADMIN'>{t('common-operator')}</Radio>
              <Radio value='OPERATOR'>{t('common-basic')}</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('selectOperatorBand')} name='bandId' rules={[{ required: true, message: '밴드를 선택해주세요' }]}>
            <Select
              showSearch style={{ width: 200 }}
              placeholder={'밴드를 선택해주세요'}
              filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
            >
              {bands && bands
                .map(band => (
                  <Select.Option key={band.bandId} value={band.bandId}>{band.bandName}</Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label={t('common-name')} name='accountName'
                     rules={[{ required: true, message: t('pleaseFillName') }]}>
            <Input value={userName} onChange={onChangeUserName} />
          </Form.Item>

          <Form.Item
            label={t('common-email')}
            name='email'
            rules={[{ required: true, type: 'email', message: t('pleasePutValidEmail') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('common-phoneNumber')}
            name='phoneNumber'
            rules={[{ required: true, message: t('pleasePutPhoneNumber') }]}
          >
            <Input value={phoneNumber} onChange={onChangePhoneNumber} />
          </Form.Item>

          <Form.Item label={t('common-password')} name='password'
                     rules={[{ required: true, message: t('pleasePutPassword') }]}>
            <Input.Password value={password} onChange={onChangePassword} />
          </Form.Item>

          <Form.Item
            label={t('confirmPassword')}
            name='checkPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: t('pleaseConfirmPassword') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('passwordNotMatch')));
                }
              })
            ]}
          >
            <Input.Password value={checkPassword} onChange={onChangeCheckPassword} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 21, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              {t('common-submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AdminAccountPage;
