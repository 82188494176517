import React, {useEffect, useState} from 'react';
import {getAllTariffs} from "../tariff.service";
import {TariffDto} from "../tariff.interface";
import {TariffListTable} from "../components/TarrifListTable";
import {TableHeading} from "../../../common/component/TableHeading";

function TariffListPage() {

  const [tariffs, setTariffs] = useState<TariffDto[]>([]);
  const [filteredTariffs, setFilteredTariffs] = useState<TariffDto[]>([]);

  useEffect(() => {
    getAllTariffs().then(tariffs => {
      setTariffs(tariffs);
      setFilteredTariffs(tariffs);
    })
  }, []);

  return (
    <>
      <TableHeading
        headingTitle={'요금표'}
        row={filteredTariffs.length}
      />
      {/*<Card*/}
      {/*  title={'요금표 필터'}*/}
      {/*></Card>*/}
      <TariffListTable
        tariffs={filteredTariffs}
      />
    </>
  )
}

export default TariffListPage;