import React from 'react';
import {Typography} from "antd";

interface Props {
  textVal: string;
}

const {Paragraph} = Typography;

export default function PhoneNumber({textVal}: Props) {
  let phoneNumber = '';

  if (textVal) {
    phoneNumber = textVal
      .replace(/[^0-9]/g, '')
      .replace(/^8[0-9]/, '0')
      .replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
  }

  return <Paragraph style={{margin: 0}} copyable={true}>{phoneNumber}</Paragraph>;
}