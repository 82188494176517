import React, {useContext, useEffect, useState} from 'react';
import {MopedRideTable} from '../component/MopedRideTable';
import {AccountContext, SetLoadingContext} from '../../../context';
import {getMopedRideHistoryPagination} from '../ride.service';
import {useValidation} from '../../../hooks/useValidation';
import {useLocation} from "react-router-dom";
import {Card} from "antd";
import Search from "antd/es/input/Search";
import {DEFAULT_TABLE_SIZE} from '../../../constants'
import {HistoryDto, MopedRidePaginationReq} from "../ride.interface";
import {PaginationRes} from "../../../common/common.interface";
import {PathDomain} from "../../mobility/mobility.interface";
import {PathModalMap} from "../../../common/map/PathModalMap";

export function MopedRidePage() {
  const admin = useContext(AccountContext);
  const [rides, setRides] = useState<HistoryDto[]>([]);
  const setLoading = useContext(SetLoadingContext)!;
  const checkToken = useValidation;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [pagination, setPagination] = useState<MopedRidePaginationReq>({
    page: query.get("page") ? parseInt(query.get("page")!) : 0,
    size: query.get("size") ? parseInt(query.get("size")!) : DEFAULT_TABLE_SIZE,
    keyword: query.get("keyword") ?? '',
    battery: query.get("battery") ?? '100',
    band: query.get("band") ?? ''
  });

  const [paginationRes, setPaginationRes] = useState<PaginationRes>({
    page: 0,
    totalPage: 0,
    totalItem: 0
  });

  checkToken();

  useEffect(() => {
    bootstrap();
  }, [admin]);

  async function bootstrap() {
    setLoading(true);
    await getMopedRideHistoryPagination(pagination.page, pagination.size, pagination.keyword, pagination.band).then((res) => {
      setPaginationRes({
        page: res.page,
        totalItem: res.totalItem,
        totalPage: res.totalPage
      });
      setRides(res.items)
    });
    setLoading(false);
  }

  const handleSearch = (val: any) => {
    window.location.href = `/admin/moped-history?page=${0}&size=${DEFAULT_TABLE_SIZE}&keyword=${val}&band=${pagination.band}`
  }

  const [isPathModalVisible, setIsPathModalVisible] = useState<boolean>(false);
  const [selectedMobilityAndPath, setSelectedMobilityAndPath] = useState<PathDomain | null>(null);

  return (
    <div>
      <PathModalMap
        isVisible={isPathModalVisible}
        setIsVisible={setIsPathModalVisible}
        selectedMobilityAndPath={selectedMobilityAndPath}
      />
      <div>
        <div>
          <h2>오토바이 이용내역({paginationRes.totalItem})</h2>
        </div>
        <Card title={"오토바이 검색"}>
          <Search
            placeholder="성함 / SIMEI 를 입력해주세요"
            size={'large'}
            defaultValue={pagination.keyword}
            onSearch={handleSearch}
          />
        </Card>
      </div>
      <MopedRideTable
        rides={rides}
        pagination={pagination}
        paginationRes={paginationRes}
        setIsPathModalVisible={setIsPathModalVisible}
        setSelectedMobilityAndPath={setSelectedMobilityAndPath}
      />
    </div>
  );
}
