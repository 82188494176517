import React, { useContext, useState, VFC } from 'react';
import { Button, Form, Image, Input, Modal } from 'antd';
import useInput from '../../../../hooks/useInput';
import { removeScooter } from '../../../../services/scoot';
import { AccountContext } from '../../../../context';
import ADD_SCOOTER_IMAGE_EXAMPLE from '../../../../images/ADD_SCOOTER.png';

type Props = {
  open: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
};

const RemoveScooterModal: VFC<Props> = ({ open, close }) => {

  const [scooterInput, onChangeScooterInput, setScooterInput] = useInput('');
  const [failedScooter, setFailedScooter] = useState<string[]>([]);
  const account = useContext(AccountContext)!;
  const bandId = account.band.bandId;

  const handleOk = async () => {
    const scooterArray = scooterInput.split(' ');
    removeScooter({shortImeiList: scooterArray}).then(e => {
      alert('삭제 되었습니다');
      window.location.reload();
    })
  };

  const handleCancel = () => {
    setScooterInput('');
    close(false)
  };
  const { Item } = Form;
  return (
    <Modal
      title='스쿠터 삭제'
      footer={
        <Button onClick={handleOk}>
          삭제하기
        </Button>
      }
      visible={open}
      onCancel={handleCancel}
    >
      <Form>
        <Item label={'삭제 할 스쿠터(shortImei)'}>
          <Input
            onChange={onChangeScooterInput}
            value={scooterInput}
            placeholder={'shortImei를 입력해주세요 ex) BH1398'}
          />
        </Item>
        {failedScooter.length > 0 ? (
          <Item label={'실패한 스쿠터 목록'}>
            <Input value={failedScooter.join(' ')} disabled />
          </Item>
        ) : null}

        <div>
          <h2>삭제방법</h2>
          <div>1. 스쿠터를 삭제 할 밴드에 로그인한다.</div>
          <br />
          <div style={{ letterSpacing: 0.4, lineHeight: 1.5 }}>
            2. 삭제 할 스쿠터의 shortImei를 입력창에 넣어준다.
            <br /> <strong>( 삭제할 스쿠터의 개수가 2개이상일 경우 꼭 띄어쓰기로 구분해야됩니다! )</strong>
          </div>
          <br />
          <div>3. 스쿠터가 등록되지 않을 경우 해당 스쿠터가 이미 등록 되었을 경우가 있습니다.</div>
        </div>
        <br />
        <div>
          <h3>예시</h3>
          <Image src={ADD_SCOOTER_IMAGE_EXAMPLE} />
        </div>
      </Form>
    </Modal>
  );
};

export default RemoveScooterModal;
