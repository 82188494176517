import React, {CSSProperties, useEffect, useState} from 'react';
import {Button, Col, Input, Radio, Row, Divider, Select} from 'antd';
import {MapType} from '../../../../common/map/Types';
import {PlusCircleOutlined} from "@ant-design/icons";
import {getAllPlacements} from "../../placement.service";
import {getAllScooters} from "../../../../services/scoot";
import {getAllMopeds} from "../../../../services/moped";

type MobilityRadioValue = 'ALL' | 'BS' | 'S9' | 'NO'
type PlacementRadioValue = 'ALL' | 'A' | 'S' | 'M' | 'SP' | 'NO'

interface Props {
  mapType: MapType;
  setMapType: React.Dispatch<React.SetStateAction<MapType>>;
  setIsPlacementAddModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchStr: React.Dispatch<React.SetStateAction<string>>;
}

const { Option } = Select;

const MapSearchControlPanel = (
  {
    mapType,
    setMapType,
    setIsPlacementAddModalVisible,
    setSearchStr,
  }: Props) => {

  const [mobilitiesAndPlacements, setMobilitiesAndPlacements] = useState<string[]>([])

  const floatingStyle: CSSProperties = {
    position: 'absolute',
    width: '350px',
    top: '10px',
    right: '10px',
    backgroundColor: '#FFF',
    color: '#FFF',
    borderRadius: '15px',
    boxShadow: '2px 2px 3px #999',
    zIndex: 3,
    padding: '12px 12px',
    border: '2px solid #F36F21'
  }

  const dividerStyle: CSSProperties = {margin: '3px 0px'};
  const placementRadioStyle: CSSProperties = {width: '50%', textAlign: 'center', fontSize: '14px', padding: '0 5px'};

  useEffect(() => {
    getAllPlacements().then(placements => {
      getAllScooters().then(scooters => {
        getAllMopeds().then(mopeds => {
          const placementNames = placements.map(e => e.placementName);
          const scooterSimeis = scooters.filter(e => e.mobilityStatus !== 'CARGO').map(e => e.shortImei);
          const mopedSimeis = mopeds.map(e => e.shortImei);
          setMobilitiesAndPlacements([...placementNames, ...scooterSimeis, ...mopedSimeis]);
        })
      })
    })
  }, []);

  const mobilityPlacementOptions = mobilitiesAndPlacements.map(e => {
    return (
      <Option value={e} key={e}>{e}</Option>
    )
  })

  return (
    <div style={{width: '350px'}}>
      <Button icon={<PlusCircleOutlined/>} style={{ width: '100%', marginBottom: '3px'}} size={'middle'} onClick={() => setIsPlacementAddModalVisible(true)}>
        픽업존 추가
      </Button>
      <Divider plain={true} style={dividerStyle}>지도 선택</Divider>
      <Radio.Group
        style={{ width: '100%', margin: '12px 0' }}
        value={mapType}
        onChange={e => setMapType(e.target.value)}
        size={'middle'}
      >
          <Radio.Button style={placementRadioStyle} value={'GOOGLE'}>구글</Radio.Button>
          <Radio.Button style={placementRadioStyle} value={'KAKAO'}>카카오</Radio.Button>
      </Radio.Group>
      <Divider plain={true} style={dividerStyle}>픽업존 / 기기 검색</Divider>
      <Select
        style={{width: '100%'}}
        showSearch={true}
        placeholder={'기기 SIMEI, 픽업존 입력'}
        onSelect={(value) => {
          setSearchStr(value as string);
        }}
      >
        {mobilityPlacementOptions}
      </Select>
      {/*<Input.Search*/}
      {/*  style={{ width: '100%', margin: '3px 0'}}*/}
      {/*  enterButton*/}
      {/*  placeholder='기기 SIMEI, 픽업존 입력'*/}
      {/*  size={'middle'}*/}
      {/*  onSearch={(e) => setSearchStr(e.toUpperCase())}*/}
      {/*/>*/}
    </div>
  );
};

export default MapSearchControlPanel;
