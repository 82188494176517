import React from 'react';
import {parseDateTime} from '../../../utils/organizeData';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import {useTranslation} from "react-i18next";
import Table, { ColumnsType } from 'antd/lib/table';
import { Button, Typography } from 'antd';
import NameALink from '../../../common/component/NameALink';
import {NonPaymentDto} from "../ondemand.interface";
import {deleteNonPayment, putNonPayment} from "../ondemand.service";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";

interface Props {
  nonPaymentList: NonPaymentDto[]
}

export function NonPaymentListTable(props: Props) {
  const {t} = useTranslation();

  const ridesColumns: ColumnsType<NonPaymentDto> = [
    columnMaker('riderId', t('riderId'), columnRenderer(data => {
      return <NameALink urlVal={data.rider.riderId} textVal={data.rider.riderId}/>
    })),
    columnMaker('riderName', t('common-name'), columnRenderer(data => <NameALink urlVal={data.rider.riderId} textVal={data.rider.riderName}/>)),
    columnMaker('productId', t('common-product') + 'ID', columnRenderer(data => data.productId)),
    columnMaker('rideId', '라이드ID', columnRenderer(data => data.rideId)),
    columnMaker('nonPaidFare', t('common-purhcase-amount'), columnRenderer(data => data.nonPaidFare + '원')),
    columnMaker('nonPaidAt', '종료시간', columnRenderer(data => data.nonPaidAt ? parseDateTime(data.nonPaidAt) : '-')),
    columnMaker('nonPaidReason', '미결제 사유', columnRenderer(data => {
      return (
        <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
          {data.nonPaidReason ?? '-'}
        </Typography.Text>
      )
    })),
    columnMaker('paidAt', '결제 시간', columnRenderer(data => data.paidAt ? parseDateTime(data.paidAt) : '-')),
    columnMaker('purchase', '결제하기', columnRenderer(data => {
      return (
        <Button
          disabled={data.paidAt != null}
          onClick={() => {
            if(window.confirm("정말 결제하시겠습니까?")) {
              putNonPayment(data.rider.riderId, data.rideId)
                .then(e => alert('결제되었습니다'))
                .catch(err => {
                  alert('구매에 실패하였습니다: ' + err.response.data.message);
                  window.location.reload();
                })
            }
          }}
        >
          {t('pay')}
        </Button>
      )
    })),
    columnMaker('delete', '삭제', columnRenderer(data => {
      return (
        <Button
          disabled={data.paidAt != null}
          onClick={() => {
            if(window.confirm("정말 삭제하시겠습니까??")) {
              deleteNonPayment(data.rideId)
                .then(e => alert('삭제되었습니다'))
                .catch(err => {
                  alert('삭제에 실패하였습니다: ' + err.response.data.message);
                  window.location.reload();
                })
            }
          }}
        >삭제</Button>
      )
    })),
  ];

  return (
    <Table
      bordered
      columns={ridesColumns}
      dataSource={
        props.nonPaymentList.map((e: any, i: any) => {
          return { key: i, ...e };
        })
      }
      pagination={{
        defaultPageSize: DEFAULT_TABLE_SIZE,
        showSizeChanger: false
      }}
    />
  );
}
