import React, {useState} from 'react';
import NameALink from '../../../common/component/NameALink';
import PhoneNumber from "../../../common/component/PhoneNumber";
import {Typography} from "antd";
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {getBandTag, parseBirthday, parseDate, parsePhoneNumber} from '../../../utils/organizeData';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import { RiderTableList } from '../rider.interface';
import { PaginationRes } from '../../../common/common.interface';
import { Pagination } from '../../../common/url/pagination';
import { buildUrl } from '../../../common/url/utils';
import {useTranslation} from "react-i18next";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";

interface Props {
  riders: RiderTableList[],
  pagination: Pagination,
  paginationRes: PaginationRes
}

const {Paragraph} = Typography;

export function RiderTable({ riders, pagination, paginationRes }: Props) {

  const {t} = useTranslation();

  const riderColumn: ColumnsType<RiderTableList> = [
    columnMaker('id', t('riderId'),
      columnRenderer(data => <NameALink urlVal={data.riderId} textVal={data.riderId} />)),
    columnMaker('name', 'name', columnRenderer(data => <NameALink urlVal={data.riderId} textVal={data.riderName} />)),
    {
      key: 'birthday',
      title: t('common-birthday'),
      align: 'center',
      render: (val, row) => row.birth ? parseBirthday(row.birth) : '-'
    },
    {
      key: 'age',
      title: t('common-age'),
      align: 'center',
      render: (val, row) => {
        if (row.age) return row.age;
        else return '-';
      }
    },
    {
      key: 'phoneNo',
      title: t('common-phoneNumber'),
      align: 'center',
      render: (pn, row) => <PhoneNumber textVal={row.phoneNo}/>
    },
    {
      key: 'band',
      title: t('common-band'),
      align: 'center',
      render: (val, row, i) => getBandTag(row.band.bandName, i)
    },
    {
      key: 'license',
      title: '면허등록여부',
      align: 'center',
      render: (val, row) => row.license ? '등록됨' : '미등록'
    },
    {
      key: 'pass',
      title: t('usingPass'),
      align: 'center',
      render: (val, row) => {
        return row.pass
          ? row.pass.product.productName
          : '없음'
      }
    },
    {
      key: 'createdAt',
      title: t('registerAt'),
      dataIndex: 'createdAt',
      align: 'center',
      render: (createdAt) => {
        return parseDate(moment(createdAt).unix() * 1000);
      }
    }
  ];

  const handleTableChange = (pgn: any) => {
    const newPagination = Pagination.riderFrom(pagination, { page: pgn.current - 1 });
    window.location.href = buildUrl('/admin/riders', newPagination.getParams())
  };

  return (
    <>
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={
          riders.map((r, i) => ({ key: String(i + 1), ...r }))
        }
        onChange={handleTableChange}
        pagination={{
          current: paginationRes.page + 1,
          total: paginationRes.totalItem,
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
    </>
  );
}
