import {BaseMapPlugin} from "./BaseMapPlugin";
import {utils} from "../Utils";
import {EventType} from "../Map";
import {MarkerWrapper} from "../Types";
import {LocationDto} from "../../common.interface";
import {getAllPlacements} from "../../../domain/placement/placement.service";
import {PlacementMarker} from "../markers/PickupMarker";

export class DraggableMarkerPlugin extends BaseMapPlugin {
  private marker?: MarkerWrapper;
  private location: LocationDto;
  private level: number;

  constructor(placementLocation: LocationDto | undefined) {
    super();
    this.location = placementLocation
      ? {latitude: placementLocation.latitude, longitude: placementLocation.longitude}
      : {latitude: 37.519358, longitude: 127.002646}
    this.level = placementLocation ? 1 : 8
  }

  async onMapUpdated() {
    const map = await this.getMap();

    getAllPlacements().then(placements => placements.forEach(placement => utils.createMarker(map, {
      position: placement.location,
      image: PlacementMarker(placement).image
    }, placement.location, false)));

    map.move(this.location, this.level);
    const newMarker = utils.createMarker(map,
      {position : this.location},
      this.location, true);

    newMarker.setZIndex(10);
    this.marker = newMarker;

    utils.addListener(this.marker, EventType.DRAGEND, () => {
      this.location = this.marker?.getPosition()!;
    });
  }

  getLocation(): LocationDto | undefined {
    return this.location;
  }
}