import React, {CSSProperties} from 'react';
import {Col, Radio, Row, Button} from 'antd';
import {MapType} from "../../../../common/map/Types";
import {CustomDrawingMode} from "../../../geofence/page/GeofencePage";

interface Props {
  mapType: MapType,
  setMapType: React.Dispatch<React.SetStateAction<MapType>>,
  drawingMode: CustomDrawingMode,
  setDrawingMode: React.Dispatch<React.SetStateAction<CustomDrawingMode>>,
}

const CustomDrawingControlPanel = (props: Props) => {

  const floatingStyle: CSSProperties = {
    position: 'absolute',
    width: '350px',
    top: '20px',
    right: '10px',
    backgroundColor: '#FFF',
    color: '#FFF',
    borderRadius: '15px',
    boxShadow: '2px 2px 3px #999',
    zIndex: 3,
    padding: '8px 12px',
    border: '2px solid #F36F21'
  }

  const mobilityRadioStyle: CSSProperties = {width: '50%', textAlign: 'center', fontSize: '14px'};
  const drawingButtonStyle: CSSProperties = {width: '50%', padding: '0 5px'}

  return (
    <div style={floatingStyle}>
      <Radio.Group
        style={{width: '100%'}}
        value={props.mapType}
        onChange={e => props.setMapType(e.target.value)}
      >
        <Radio.Button style={mobilityRadioStyle} value={'KAKAO'}>카카오</Radio.Button>
        <Radio.Button style={mobilityRadioStyle} value={'GOOGLE'}>구글</Radio.Button>
      </Radio.Group>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Button style={drawingButtonStyle} onClick={() => props.setDrawingMode('FINISH')}>완료</Button>
        </Col>
        <Col span={24}></Col>
      </Row>
    </div>
  );
};

export default CustomDrawingControlPanel;
