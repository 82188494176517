type NODE_ENV = 'production' | 'development' | 'stage'
const nodeEnv = process.env.NODE_ENV as unknown as NODE_ENV;

const apiUrls: Record<NODE_ENV, string> = {
  'production': 'https://api.admin.dayrider.swingmobility.dev',
  'stage': 'https://stage.api.admin.dayrider.swingmobility.dev',
  'development': 'http://localhost:8080'
  // 'development': 'https://api.admin.dayrider.swingmobility.dev',
  // 'development': 'https://stage.api.admin.dayrider.swingmobility.dev',
};

const availableEnvs = ['production', 'development', 'stage'];
if (!availableEnvs.includes(nodeEnv)) {
  throw new Error(`Invalid node_env ${nodeEnv}`);
}

export const API_URL = apiUrls[nodeEnv];
// export const API_URL = apiUrls["production"]

export const Routes = {
  SUB_LIST: '/_subscriptions',
  RIDERS: '/riders',
  RIDERS_DETAIL: '/riders/:riderId',
  PICK_UP: '/pickup',
  BAND_PRODUCT: '/products',
  PAYMENTS_HISTORY: '/payments-history',
  PURCHASE_HISTORY: '/purchase-history',
  LOUNGES: '/lounges',
  ACCOUNTS: '/accounts',
  MASTER_PRODUCT: '/master-products',
  BAND: '/bands',
  BAND_LIST: '/bands/:bandId',
  DASHBOARD_PLACEMENT: '/dashboard-placement',
  DASHBOARD_RIDER: '/dashboard-rider',
  DASHBOARD: '/dashboard',
  SUBSCRIPTIONS: '/subscriptions',
  SCOOTER_LIST: '/scooters',
  SCOOTER_DETAIL: '/scooters/:scootId',
  SCOOTER_HISTORY: '/scooters-history',
  SCOOTER_EXPIRED_USAGE: '/scooter-expired',
  SUBSCRIPTION_HISTORY: '/subscription-history',
  MEMBER_LIST: '/riders',
  MEMBER_DETAIL: '/members/:memberId',
  PAYMENTS: '/payments',
  LOUNGES_DETAIL: '/lounges/:loungeId',
  BANNER: '/banner',
  APP_INFO: '/app-info',
  MOPED_LIST: '/mopeds',
  MOPED_DETAIL: '/moped/:mopedId',
  MOPED_HISTORY: '/moped-history',
  MOPED_EXPIRED_USAGE: '/moped-expired',
  MOPED_RENTAL: '/moped-rental',
  // Placement
  PLACEMENT_MAP: '/placement-map',
  PLACEMENT_LIST: '/placement-list',
  PLACEMENT_ADD: '/placement/add',
  LEASE_RENTAL_LIST: '/lease-rental',
  LEASE_RENTAL_ORDER_LIST: '/lease-rental-order',
  LEASE_RENTAL_CONSULT_LIST: '/lease-rental-consult',
  TARIFF_LIST: '/tariff-list',
  TARIFF_BAND: '/tariff-band',
  // SCOOTER_NONPAYMENT: '/scooter/nonpayment',
  // MOPED_NONPAYMENT: '/moped/nonpayment',
  NONPAYMENT: '/nonpayment',
  MAP: '/map'
};

export const PASS_IMAGE = 'https://dayrider-assets.s3.ap-northeast-2.amazonaws.com/pass_day.png';
export const SUBSCRIBE_IMAGE = 'https://dayrider-assets.s3.ap-northeast-2.amazonaws.com/pass_month.png';
export const PREMIUM_IMAGE = 'https://dayrider-assets.s3.ap-northeast-2.amazonaws.com/pass_premium.png';

export const DEFAULT_TABLE_SIZE = 30;

/* == 기기 명칭 == */

export const SCOOTER_KOR = '킥보드';
export const MOPED_KOR = '오토바이';
export const SCOOTER_ENG = 'scooter';
export const MOPED_ENG = 'moped';