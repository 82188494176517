import {AbstractDragBehavior} from "./AbstractDragBehavior";
import {MapWrapper, MarkerWrapper} from "../../Types";
import {LocationDto} from "../../../common.interface";
import {utils} from "../../Utils";

type OnCreateMarker = (map: MapWrapper, location: LocationDto, prev: MarkerWrapper, next: MarkerWrapper) => MarkerWrapper;
type OnSubMarkerDestroyed = (subMarker: MarkerWrapper, prev: MarkerWrapper, next: MarkerWrapper) => void;

export class SubMarkerDragBehavior extends AbstractDragBehavior<MarkerWrapper> {
  private readonly subMarker: MarkerWrapper;
  private readonly prev: MarkerWrapper;
  private readonly next: MarkerWrapper;
  private readonly onCreateMarker: OnCreateMarker;
  private readonly onSubMarkerDestroyed: OnSubMarkerDestroyed;

  constructor(map: MapWrapper, subMarker: MarkerWrapper, prev: MarkerWrapper, next: MarkerWrapper,
              onCreateMarker: OnCreateMarker, onSubMarkerDestroyed: OnSubMarkerDestroyed) {
    super(map, subMarker);
    this.subMarker = subMarker;
    this.prev = prev;
    this.next = next;
    this.onCreateMarker = onCreateMarker;
    this.onSubMarkerDestroyed = onSubMarkerDestroyed;
  }

  protected onDrag(delta: LocationDto): void {
    const {latitude, longitude} = this.subMarker.data;
    this.onCreateMarker(this.map, {
      latitude: latitude + delta.latitude,
      longitude: longitude + delta.longitude,
    }, this.prev, this.next);

    utils.setMap(this.subMarker, null);
    this.unregister();

    this.onSubMarkerDestroyed(this.subMarker, this.prev, this.next);
  }

  protected onDragDone(delta: LocationDto): void {}
}
