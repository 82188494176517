import React, {FC} from 'react';
import {Modal, Button} from 'antd';

interface Props {
  isVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  headerTitle: string;
  cancelText?: string;
  okText?: string;
  width?: number | string;
}

const BaseModal: FC<Props> = ({
                                isVisible,
                                handleOk,
                                handleCancel,
                                children,
                                headerTitle,
                                okText = '확인',
                                cancelText = '취소',
                                width = 500,
                              }) => {
  return (
    <>
      <Modal
        title={headerTitle}
        visible={isVisible}
        onCancel={handleCancel}
        width={width}
        footer={[
          <Button key="1" onClick={handleCancel}>
            {cancelText}
          </Button>,
          <Button key="2" onClick={handleOk}>
            {okText}
          </Button>
        ]}
      >
        {children}
      </Modal>
    </>
  );
};

export default BaseModal;
