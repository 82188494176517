import React, {useContext, useState} from 'react';
import {AccountContext} from '../../../context';
import BannerPreviewTab from "../component/tab/BannerPreviewTab";
import {BannerTableTab} from "../component/tab/BannerTableTab"

/* == antd == */

import {Tabs} from "antd";

const {TabPane} = Tabs;

export function BannerPage() {

  const admin = useContext(AccountContext);

  return (
    <div>
      <Tabs defaultActiveKey="TABLE">
        <TabPane tab="전체보기" key="TABLE">
          <BannerTableTab/>
        </TabPane>
        <TabPane tab="밴드별 프리뷰" key="PREVIEW">
          <BannerPreviewTab/>
        </TabPane>
      </Tabs>
    </div>
  );
}
