import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select, Radio, Divider } from 'antd';
import BaseModal from '../../../common/component/BaseModal';
import { AccountContext, SetLoadingContext } from '../../../context';
import { getAllBandProducts } from '../../product/product.service';
import { postRegisterRiderPass } from '../rider.service';
import { ProductBandDto, MobilityCode, ProductSubType, ProductType } from '../../product/product.interface';
import { PurchaseCreateBody } from '../../purchase/purchase.interface';

const { Option } = Select;

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  registerModalHandleOk: () => void;
  registerModalHandleClose: () => void;
  riderId: string;
}

interface ProductFilter {
  mobility: MobilityCode,
  subType: ProductSubType,
  productType: ProductType
}

const PurchaseProduct: FC<Props> = ({ isVisible, setIsVisible, registerModalHandleClose, riderId }) => {

  const setLoading = useContext(SetLoadingContext)!;
  const handleModalCancel = () => {
    registerModalHandleClose();
  };

  const userBandId = useContext(AccountContext)!.band.bandId;

  const [formValues, setFormValues] = useState<PurchaseCreateBody | null>(null);
  const [bandProducts, setBandProducts] = useState<ProductBandDto[]>([]);
  const [filteredBandProducts, setFilteredBandProducts] = useState<ProductBandDto[]>([]);
  const [selectedBandProduct, setSelectedBandProduct] = useState<ProductBandDto | null>(null);
  const [formInstance] = Form.useForm();

  const [productFilters, setProductFilters] = useState<ProductFilter>({
    mobility: 'ST',
    subType: 'PASS',
    productType: 'TRANSACTION',
  })


  const submitModal = async () => {
    if (formValues) { // 변경이 있는 경우
      formInstance.submit();
    } else { // 변경이 없는 경우
      setIsVisible(false);
    }
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label}은 숫자만 입력해주세요'
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    if (userBandId) {
      await getAllBandProducts()
        .then(res => {
          const notFrees = res.filter(e => e.price !== 0);
          setBandProducts((notFrees));
          setFilteredBandProducts(notFrees.filter(
            e => e.baseProduct.saleStatus === 'ONSALE'
            && e.band.bandId === userBandId
              && e.baseProduct.productType === productFilters.productType
              && e.baseProduct.productSubType === productFilters.subType
              && e.baseProduct.mobilityCode === productFilters.mobility
          ));
        })
        .then(() => setLoading(false));
    }
  }

  const handleSelectChange = (productId: number) => {
    const bandProduct = bandProducts.find(b => b.baseProduct.productId == productId);
    setSelectedBandProduct(bandProduct || null);
  };

  const onSubmitForm = async (formValues: PurchaseCreateBody) => {
    if (userBandId) {
      if (window.confirm('정말 구매하시겠습니까?')) {
        try {
          await postRegisterRiderPass({
            riderId: parseInt(riderId),
            productId: formValues.productId,
            bandId: userBandId
          }).then(e => {
            alert('성공적으로 구매 되었습니다');
            window.location.reload();
          });
        } catch (err) {
          alert('구매 실패하였습니다 : ' + err.response.data.message);
          window.location.reload();
        }
      }
    }
  };

  const bandProductionOptions = filteredBandProducts.map((e, i) => {
    return (
      <Option
        key={'bandProduction' + i}
        value={e.baseProduct.productId}
        label={e.baseProduct.title}
      >
        {e.baseProduct.title} ({e.price}원)
      </Option>
    );
  });

  useEffect(() => {
    const newFilteredBandProduct: ProductBandDto[] = [];

    bandProducts.filter(e =>
      e.baseProduct.saleStatus === 'ONSALE'
      && e.baseProduct.productSubType === productFilters.subType
      && e.baseProduct.productType === productFilters.productType
      && e.baseProduct.mobilityCode === productFilters.mobility
    ).forEach(l => newFilteredBandProduct.push(l));

    setFilteredBandProducts(newFilteredBandProduct);

  }, [productFilters])

  const handleProductFilters = (param: string, value: any) => {
    setProductFilters({
      mobility: param === 'mobility' ? value : productFilters.mobility,
      productType: param === 'productType' ? value : productFilters.productType,
      subType: param === 'subType' ? value : productFilters.subType
    })
  }

  return (
    <>
      <BaseModal
        isVisible={isVisible}
        headerTitle={'상품구매'}
        handleOk={submitModal}
        handleCancel={handleModalCancel}
        width={'70%'}
      >
        <Form
          name='masterProductForm'
          onValuesChange={(changedVal, allVal) => {
            setFormValues(allVal);
          }}
          validateMessages={validateMessages}
          form={formInstance}
          onFinish={onSubmitForm}
        >
          <Divider orientation={'center'}>상품필터</Divider>
          <Radio.Group
            style={{ width: '100%', marginBottom: '8px' }}
            onChange={e => handleProductFilters('mobility', e.target.value)}
            defaultValue={'ST'}
          >
            <Radio.Button style={{ width: '50%', textAlign: 'center' }} value={'ST'}>킥보드 상품</Radio.Button>
            <Radio.Button style={{ width: '50%', textAlign: 'center' }} value={'MC'}>오토바이 상품</Radio.Button>
          </Radio.Group>
          <Radio.Group
            style={{ width: '100%', marginBottom: '8px' }}
            onChange={e => handleProductFilters('subType', e.target.value)}
            defaultValue={'PASS'}
          >
            <Radio.Button style={{ width: '16.6%', textAlign: 'center' }} value={'PASS'}>이용권 상품</Radio.Button>
            <Radio.Button style={{ width: '16.6%', textAlign: 'center' }} value={'PENALTY'}>패널티 상품</Radio.Button>
            <Radio.Button style={{ width: '16.6%', textAlign: 'center' }} value={'RETURN_PENALTY'}>반납 패널티 상품</Radio.Button>
            <Radio.Button style={{ width: '16.6%', textAlign: 'center' }} value={'LEASE'}>리스 상품</Radio.Button>
            <Radio.Button style={{ width: '16.6%', textAlign: 'center' }} value={'RENTAL'}>렌탈 상품</Radio.Button>
            <Radio.Button style={{ width: '16.6%', textAlign: 'center' }} value={'OTHER'}>기타 상품</Radio.Button>
          </Radio.Group>
          <Radio.Group
            style={{ width: '100%', marginBottom: '8px' }}
            onChange={e => handleProductFilters('productType', e.target.value)}
            defaultValue={'TRANSACTION'}
          >
            <Radio.Button style={{ width: '50%', textAlign: 'center' }} value={'TRANSACTION'}>종량제 상품</Radio.Button>
            <Radio.Button style={{ width: '50%', textAlign: 'center' }} value={'SUBSCRIPTION'}>정액제 상품</Radio.Button>
          </Radio.Group>
          <Divider orientation={'center'}>상품선택</Divider>
          <Row gutter={[32, 32]}
               justify={'center'}
          >
            <Col span={24}>
              <Form.Item
                style={{ marginBottom: '0' }}
                name='productId'
                required={true}
              >
                <Select
                  showSearch={true}
                  style={{ width: '100%' }}
                  placeholder='구매할 상품을 선택해주세요'
                  onChange={handleSelectChange}
                  optionFilterProp={'value'}
                  filterOption={(input, option) => {
                    const labelStr = option?.label;
                    if (labelStr) {
                      return labelStr.toString().indexOf(input) !== -1;
                    } else {
                      return false;
                    }
                  }}
                >
                  {bandProductionOptions}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Input
                disabled={true}
                addonBefore={'이용기간'}
                value={selectedBandProduct ? selectedBandProduct.baseProduct.period : ''}
              />
            </Col>
            <Col span={12}>
              <Input
                disabled={true}
                addonBefore={'가격'}
                value={selectedBandProduct ? selectedBandProduct.price : ''}
              />
            </Col>

            <Col span={12}>
              <Input
                disabled={true}
                addonBefore={'종류'}
                value={selectedBandProduct ? selectedBandProduct.baseProduct.productId : ''}
              />
            </Col>
            <Col span={12}>
              <Input
                disabled={true}
                addonBefore={'타입'}
                value={selectedBandProduct ? selectedBandProduct.baseProduct.productSubType : ''}
              />
            </Col>
          </Row>
        </Form>
      </BaseModal>
    </>
  );
};

export default PurchaseProduct;
