import { LocalAdminApi } from '../../services';
import {
  MopedLendType,
  LeaseRentalDto,
  ScooterLeaseRentalDto,
  MopedLeaseRentalDto,
  MopedLeaseRentalCreateDto,
  ScooterLeaseRentalCreateDto, RiderLendOrderDto, UpdateDeliveryDto, UpdateRiderLendConsultDto, RiderLendConsultDto
} from './rental.interface';
import { cache } from '../../utils/cache';
import { MobilityType } from '../mobility/mobility.interface';
import { MobilityCode } from '../product/product.interface';

export async function getAllScooterLeaseRental() {
  return await cache.get<ScooterLeaseRentalDto[]>(`/lends/scooters`);
}

export async function getAllMopedLeaseRental() {
  return await cache.get<MopedLeaseRentalDto[]>(`/lends/mopeds`);
}

export async function postScooterLeaseRental(data: ScooterLeaseRentalCreateDto) {
  return await LocalAdminApi.post(`/lends/scooters`, data);
}

export async function postLeaseRental(data: MopedLeaseRentalCreateDto, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.post(`/lends/scooters`, data);
  } else {
    return await LocalAdminApi.post(`/lends/mopeds`, data);
  }
}

export async function putLeaseRentalPurchase(data: { riderId: number }, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put('/lends/scooters/purchase', data);
  } else {
    return await LocalAdminApi.put('/lends/mopeds/purchase', data);
  }
}

export async function returnLeaseRental(riderId: number, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put(`/lends/scooters/${riderId}/terminate`);
  } else {
    return await LocalAdminApi.put(`/lends/mopeds/${riderId}/terminate`);
  }
}

export async function pauseLeaseRentalRiderPass(riderId: number, passId: number, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put(`/lends/scooters/${riderId}/passes/${passId}/terminate`);
  } else {
    return await LocalAdminApi.put(`/lends/mopeds/${riderId}/passes/${passId}/terminate`);
  }
}

export async function recoverLeaseRentalRiderPass(riderId: number, passId: number, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put(`/lends/scooters/${riderId}/passes/${passId}/recovery`);
  } else {
    return await LocalAdminApi.put(`/lends/mopeds/${riderId}/passes/${passId}/recovery`);
  }
}

export async function putLeaseRentalMemo(riderId: number, data: { memo: string }, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put(`/lends/scooters/${riderId}/memo`, data);
  } else {
    return await LocalAdminApi.put(`/lends/mopeds/${riderId}/memo`, data);
  }
}

export async function putLeaseRentalPenalty(riderId: number, data: { penaltyAmount: string }, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put(`/lends/scooters/${riderId}/penalty`, data);
  } else {
    return await LocalAdminApi.put(`/lends/mopeds/${riderId}/penalty`, data);
  }
}

export async function putLeaseRentalPeriod(riderId: number, data: { startAt: string, endAt: string }, mode: MobilityType) {
  if (mode === 'SCOOTER') {
    return await LocalAdminApi.put(`/lends/scooters/${riderId}/period`, data);
  } else {
    return await LocalAdminApi.put(`/lends/mopeds/${riderId}/period`, data);
  }
}

/** 주문내역 */
export async function getAllLendOrders() {
  return await LocalAdminApi.get<RiderLendOrderDto[]>(`/lends/orders`);
}

export async function putRiderLendOrderDelivery(lendOrderId: number, body: UpdateDeliveryDto) {
  return await LocalAdminApi.put(`/lends/orders/${lendOrderId}/delivery`, body);
}

export async function putRiderLendOrderConsult(lendOrderId: number, body: { isConsultedYn: boolean }) {
  return await LocalAdminApi.put(`lends/orders/${lendOrderId}/consult`, body);
}

export async function putRiderLendOrderComplete(lendOrderId: number, body: { isOrderCompletedYn: boolean }) {
  return await LocalAdminApi.put(`lends/orders/${lendOrderId}/orderComplete`, body);
}

/** 상담내역 */
export async function getAllLendConsults() {
  return await LocalAdminApi.get<RiderLendConsultDto[]>(`/lends/consults`);
}

export async function putRiderLendConsult(consultId: number, body: UpdateRiderLendConsultDto) {
  return await LocalAdminApi.put(`/lends/consults/${consultId}`, body);
}