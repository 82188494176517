import Title from 'antd/lib/typography/Title';
import {LeftOutlined} from '@ant-design/icons';

import './page-header.css';
import React from 'react';

export function PageHeader({title, backTitle, onBack}: { title: string; backTitle?: string; onBack?: () => void }) {
    return (
        <div className="page-header">
            {backTitle && onBack && (
                <a onClick={onBack} className="back-button">
                    <LeftOutlined/>
                    {backTitle}
                </a>
            )}
            <Title level={2}>{title}</Title>
        </div>
    );
}
