import {AbstractDragBehavior} from "./AbstractDragBehavior";
import {MapWrapper, MarkerWrapper, PolygonWrapper} from "../../Types";
import {LocationDto} from "../../../common.interface";
import {Polygon} from "../../../polygon/polygon";
import {ArrayList} from "../../../../utils/array_list";

function moveMarker(marker: MarkerWrapper, delta: LocationDto) {
  const {latitude, longitude} = marker.getPosition();
  const position = {latitude: latitude + delta.latitude, longitude: longitude + delta.longitude};

  marker.setPosition(position);
  marker.setData(position);

  return position;
}

export class MarkerDragBehavior extends AbstractDragBehavior<MarkerWrapper> {
  private readonly marker: MarkerWrapper;
  private readonly polygonBuilder: Polygon.Builder;
  private readonly subMarkers: ArrayList<MarkerWrapper> = new ArrayList<MarkerWrapper>();
  private polygon?: PolygonWrapper;

  constructor(map: MapWrapper, marker: MarkerWrapper, polygonBuilder: Polygon.Builder, polygon?: PolygonWrapper) {
    super(map, marker);
    this.marker = marker;
    this.polygonBuilder = polygonBuilder;
    this.polygon = polygon;
  }

  setPolygon(polygon: PolygonWrapper) {
    this.polygon = polygon;
  }

  addSubMarker(subMarker: MarkerWrapper) {
    this.subMarkers.add(subMarker);
  }

  removeSubMarker(subMarker: MarkerWrapper) {
    this.subMarkers.remove(subMarker);
  }

  protected onDrag(delta: LocationDto): void {
    const original = this.marker.data;

    const position = moveMarker(this.marker, delta);
    this.polygonBuilder.edit(original, position);

    this.polygon?.setPath(this.polygonBuilder.build().getCoordinates());

    this.subMarkers.forEach(m => moveMarker(m, {
      latitude: delta.latitude / 2,
      longitude: delta.longitude / 2,
    }));
  }

  protected onDragDone(delta: LocationDto): void {}
}
