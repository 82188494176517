import React from 'react';
import { getBandTag, parseDateTime } from '../../../utils/organizeData';
import moment from 'moment';
import { forceEndRide } from '../ride.service';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import {useTranslation} from "react-i18next";
import {FileImageOutlined, NodeIndexOutlined} from '@ant-design/icons';

/* == ant design == */
import Table, { ColumnsType } from 'antd/lib/table';
import { Button, Tag, Typography } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Geocode } from '../../../common/unit/geocode';
import NameALink from '../../../common/component/NameALink';
import { HistoryDto, ScooterRidePaginationReq } from '../ride.interface';
import { PaginationRes } from '../../../common/common.interface';
import {PathDomain} from "../../mobility/mobility.interface";
import Copyable from "../../../common/component/Copyable";

interface Props {
  rides: HistoryDto[],
  pagination: ScooterRidePaginationReq,
  paginationRes: PaginationRes
  type?: 'ALL' | 'MEMBER',
  setIsPathModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedMobilityAndPath: React.Dispatch<React.SetStateAction<PathDomain | null>>
}

export function ScooterRideTable(
  {
    rides,
    type,
    pagination,
    paginationRes,
    setIsPathModalVisible,
    setSelectedMobilityAndPath
  }: Props) {
  const {t} = useTranslation();
  const handleForceEndRide = (rideId: number) => {
    forceEndRide(rideId).then(() => {
      alert('성공적으로 종료되었습니다');
      window.location.reload();
    }).catch((err) => {
      alert('강제종료에 실패하였습니다' + err);
    });
  };

  const ridesColumns: ColumnsType<HistoryDto> = [
    {
      key: 'band',
      title: t('common-band'),
      align: 'center',
      render: (val, row, i) => {
        return getBandTag(row.rider.band.bandName, i);
      }
    },
    {
      key: 'shortImei',
      dataIndex: 'shortImei',
      title: 'SIMEI',
      align: 'center',
      render: (val, row) => <Copyable textVal={row.mobility.shortImei}/>
    },
    {
      key: 'riderId',
      title: t('riderId'),
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderId}/>
    },
    {
      key: 'member',
      dataIndex: ['rider', 'name'],
      title: t('common-name'),
      align: 'center',
      render: (val, row) => <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName} />
    },
    {
      key: 'startAt',
      dataIndex: 'startAt',
      title: t('common-start-at'),
      align: 'center',
      render: (time) => (
        <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
          {parseDateTime(moment(time).unix() * Math.pow(10, 3))}
        </Typography.Text>
      )
    },
    {
      key: 'endAt',
      dataIndex: 'endAt',
      title: t('common-end-at'),
      align: 'center',
      render: (time) => (
        <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
          {time ? parseDateTime(moment(time).unix() * Math.pow(10, 3)) : '현재 이용중'}
        </Typography.Text>
      )
    },
    {
      key: 'startLocation',
      title: t('common-start-location'),
      align: 'center',
      render: (val, row) => {
        if (row.startPlacement) {
          return (<Tag icon={<EnvironmentOutlined />} color='processing'>
            {row.startPlacement.placementName}
          </Tag>);
        } else {
          if (row.startLocation) {
            const { startLocation: { latitude, longitude } } = row;
            return <Geocode lat={latitude} lng={longitude} />;
          } else {
            return '-';
          }
        }
      }
    },
    {
      key: 'endLocation',
      title: t('common-end-location'),
      align: 'center',
      render: (val, row) => {
        if (row.endPlacement) {
          return (
            <Tag icon={<EnvironmentOutlined />} color='processing'>
              {row.endPlacement.placementName}
            </Tag>
          );
        } else {
          if (row.endLocation) {
            const { endLocation: { latitude, longitude } } = row;
            return <Geocode lat={latitude} lng={longitude} />;
          } else {
            return '-';
          }
        }
      }
    },
    {
      key: 'paths',
      title: '경로',
      align:'center',
      render: (val, row) => <Button
        icon={<NodeIndexOutlined/>}
        disabled={!row.path || row.path.length <= 3}
        onClick={() => {
          setIsPathModalVisible(true);
          setSelectedMobilityAndPath({
            mobilityType: row.mobility.mobilityType,
            path: row.path
          })
        }
      }
      />
    },
    // {
    //   key: 'returnImg',
    //   title: '반납사진',
    //   align:'center',
    //   render: (val, row) => <Button icon={<FileImageOutlined />} disabled={!row.returnImg}/>
    // },
    {
      key: 'actionButtons',
      title: t('common-force-end'),
      align: 'center',
      render: (val, row) => {
        return row.endAt ? <span> - </span> : <Button onClick={() => handleForceEndRide(row.id)}>강제종료</Button>;
      }
    }
  ];

  const handleTableChange = (pgn: any) => {
    window.location.href = `/admin/scooters-history?page=${pgn.current - 1}&size=${pagination.size}&keyword=${pagination.keyword}`;
  };

  return (
    <Table
      bordered
      columns={ridesColumns}
      dataSource={
        rides.sort((p, n) => {
          return moment(n.startAt).unix() - moment(p.startAt).unix();
        }).map((e, i) => {
          return { key: i, ...e };
        })
      }
      onChange={handleTableChange}
      pagination={{
        current: paginationRes.page + 1,
        total: paginationRes.totalItem,
        defaultPageSize: DEFAULT_TABLE_SIZE,
        showSizeChanger: false
      }}
    />
  );
}
