import {Modal, Select} from 'antd';
import React, {useEffect, useState, VFC} from 'react';
import {getBands} from '../../band/band.service';
import {putRiderBand} from '../rider.service'
import {BandSimpleDto} from "../../band/band.interface";

interface Props {
  isModalVisible: boolean;
  handleCancel: React.Dispatch<React.SetStateAction<boolean>>;
  bandId: number | undefined;
  riderId: number | undefined
}

const {Option} = Select;

const UpdateRiderBandModal: VFC<Props> = ({isModalVisible, handleCancel, bandId, riderId}) => {

  const [band, setBand] = useState<BandSimpleDto[]>([]);
  const [selectedBand, setSelectedBand] = useState(bandId);

  const bandOptions = band.map((e, i) => {
    return (
      <Option value={e.bandId} key={'band-option-' + i}>{e.bandName}</Option>
    )
  })

  const onFinish = async () => {
    if (riderId && selectedBand) {
      putRiderBand(riderId, selectedBand)
        .then(e => {
          alert("밴드가 성공적으로 변경 되었습니다")
          window.location.reload()
        })
        .catch((err) => {
          alert(err)
          window.location.reload()
        })
    }

  };

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    await getBands().then(setBand);
  }

  return (
    <Modal
      visible={isModalVisible}
      onCancel={e => handleCancel(false)}
      title={'밴드 변경'}
      onOk={() => onFinish()}
    >
      <Select
        value={selectedBand}
        placeholder={'밴드를 선택해주세요'}
        size={'large'}
        style={{width: '100%'}}
        onChange={(e) => {
          setSelectedBand(e)
        }}
      >
        {bandOptions}
      </Select>
    </Modal>
  );
};

export default UpdateRiderBandModal;
