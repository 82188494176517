import React, {useEffect, useState} from 'react';
import {Card, Modal, Radio} from 'antd';
import {CommonMapProps, Map} from './Map';
import {PlacementMarkerPlugin} from "./plugins/PlacementMarkerPlugin";
import {PathDomain} from "../../domain/mobility/mobility.interface";
import {MapType} from "./Types";
import {MobilityPathMarkerPlugin} from './plugins/MobilityPathMarkerPlugin'
import {MapPluginManager} from "./plugins/MapPluginManager";

interface ModalMapProps extends CommonMapProps {
  mapType?: MapType,
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  selectedMobilityAndPath: PathDomain | null,
}

export const PathModalMap = (props: ModalMapProps) => {
  const [mapType, setMapType] = useState<MapType>('KAKAO');
  const [pluginManager, _] = useState<MapPluginManager>(new MapPluginManager().usePlugin(new MobilityPathMarkerPlugin()));

  useEffect(() => {
    pluginManager.applyPlugin(MobilityPathMarkerPlugin, p => p.updateMobilityAndPath(props.selectedMobilityAndPath));
  }, [props.selectedMobilityAndPath]);

  const handleMapTypeChange = () => {
    const newMapType = mapType === 'GOOGLE' ? 'KAKAO' : 'GOOGLE';

    pluginManager.applyPlugin(PlacementMarkerPlugin, p => p.setMapType(newMapType));
    setMapType(newMapType);
  };

  return (
    <Modal
      visible={props.isVisible}
      onOk={() => props.setIsVisible(false)}
      onCancel={() => props.setIsVisible(false)}
      width={'90%'}
      okText={'확인'}
      cancelText={'취소'}
    >
      <Card
        title={'운행기록지도'}
        extra={[
          <Radio.Group
            onChange={handleMapTypeChange}
            value={mapType}
          >
            <Radio.Button value={'GOOGLE'}>구글</Radio.Button>
            <Radio.Button value={'KAKAO'}>카카오</Radio.Button>
          </Radio.Group>
        ]}
      >
        <Map
          mapType={mapType}
          plugins={pluginManager.plugins}
          level={1}
        />
      </Card>
    </Modal>
  );
};