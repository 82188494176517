import React, { useEffect, useState } from 'react';
import { getPlacementDashboard, getPlacementDashboardExcel } from '../dashboard.service';
import { PlacementDashboardDto } from '../dashboard.interface';
import Table, { ColumnsType } from 'antd/lib/table';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import { Card, DatePicker, Button, Collapse } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {columnMaker, columnRenderer, columnWithChildren} from "../../../utils/componentUtils";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const PlacementDashboardPage = () => {
  const [placementDashboard, setPlacementDashboard] = useState<PlacementDashboardDto[]>([]);
  const [range, setRange] = useState<string[]>([
    moment().subtract(31, 'days').format('YYYY-MM-DDT00:00:00'),
    moment().subtract(1, 'day').format('YYYY-MM-DDT23:59:59'),
  ]);

  const handleRangePickerChange = (e: any) => {
    const from = e[0].format('YYYY-MM-DDT00:00:00');
    const to = e[1].format('YYYY-MM-DDT23:59:59');
    setRange([from, to]);
  };

  useEffect(() => {
    bootstrap();
  }, []);

  const bootstrap = async () => {
    getPlacementDashboard(range[0], range[1]).then(e => {
      const filtered = e.filter(l => l.placementId != null);
      setPlacementDashboard(filtered);
    });
  };

  const placementDashboardColumns: ColumnsType<PlacementDashboardDto> = [
    columnMaker('id', '픽업존ID', columnRenderer(data => data.placementId)),
    columnMaker('gu', '소속구', columnRenderer(data => data.placementDesc)),
    columnMaker('deployableMobilityType', '배치가능기기', columnRenderer(data => data.deployMobilityType)),
    columnMaker('name', '픽업존 이름', columnRenderer(data => data.placementName)),
    columnMaker(
      'deployableMobilityCount',
      '배치가능댓수',
      columnRenderer(data => data.deployCount),
      'center',
      (prev, next) => prev.deployCount - next.deployCount,
    ),
    columnWithChildren('scooter', '킥보드', [
      columnMaker(
        'scooter-start',
        '시작',
        columnRenderer(data => data.startCount.scooter),
        'center',
        (prev, next) => prev.startCount.scooter - next.startCount.scooter,
      ),
      columnMaker(
        'scooter-end',
        '종료',
        columnRenderer(data => data.endCount.scooter),
        'center',
        (prev, next) => prev.endCount.scooter - next.endCount.scooter,
      ),
    ]),
    columnWithChildren('moped', '오토바이', [
      columnMaker(
        'moped-start',
        '시작',
        columnRenderer(data => data.startCount.moped),
        'center',
        (prev, next) => prev.startCount.moped- next.startCount.moped,
      ),
      columnMaker(
        'moped-end',
        '종료',
        columnRenderer(data => data.endCount.moped),
        'center',
        (prev, next) => prev.endCount.moped - next.endCount.moped,
      ),
    ]),
  ];

  const handleDownload = () => {
    getPlacementDashboardExcel(range[0], range[1]).then(e => console.log(e));
  };

  const guSet: Set<string> = new Set();
  placementDashboard.forEach(e => {
    if (e.placementDesc && e.placementDesc.length > 0) guSet.add(e.placementDesc);
  });

  const placementDashboards = Array.from(guSet).map((e, i) => {
    return (
      <Panel header={e} key={e + i}>
        <Table
          columns={placementDashboardColumns}
          dataSource={placementDashboard.filter(l => l.placementDesc === e).map((e, i) => {
            return { key: 'dashboard-placement-' + i, ...e };
          })}
          bordered={true}
          pagination={{
            defaultPageSize: DEFAULT_TABLE_SIZE,
            showSizeChanger: false
          }}
        />
      </Panel>);
  });

  return (
    <>
      <Card
        title={'픽업존 대시보드'}
        extra={
          <Button
            icon={<DownloadOutlined />}
            onClick={() => handleDownload()}
          />
        }
      >
        <RangePicker
          style={{ width: '100%', marginBottom: '16px' }}
          value={[moment(range[0]), moment(range[1])]}
          onChange={handleRangePickerChange}
        />
        <Button
          icon={<SearchOutlined />}
          style={{ width: '100%' }}
          type={'primary'}
          onClick={() => getPlacementDashboard(range[0], range[1]).then(e => {
            const filtered = e.filter(l => l.placementId != null);
            setPlacementDashboard(filtered);
          })}
        >검색</Button>
      </Card>
      <Collapse>
        {placementDashboards}
      </Collapse>
    </>

  );
};

export default PlacementDashboardPage;