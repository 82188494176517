import React, {useContext, useEffect, useState} from 'react';
import {SetLoadingContext} from '../../../context';

import Table, {ColumnsType} from 'antd/lib/table';

import {getBandTag, getPlacementTag, parseDateTime} from "../../../utils/organizeData";
import moment from "moment";
import {Geocode} from "../../../common/unit/geocode";
import {Typography} from "antd";
import {getScooterRidesFromRider} from '../../ride/ride.service';
import {HistoryDto} from "../../ride/ride.interface";
import {getPlacement} from "../../placement/placement.service";
import NameALink from "../../../common/component/NameALink";

export function RiderScooterRideHistoryTable({riderId}: { riderId: string }) {

  const setLoading = useContext(SetLoadingContext)!;
  const [riderRideHistory, setRiderRideHistory] = useState<HistoryDto[]>([]);

  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getScooterRidesFromRider(riderId)
      .then((res) => setRiderRideHistory(res.sort((a, b) => b.id - a.id)))
      .then(() => setLoading(false))
  }

  const riderColumn: ColumnsType<HistoryDto> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: '라이드 ID',
      align: 'center',
      // render: (val, row) => <span style={{ cursor: 'pointer', color: '#ff922b', fontWeight: 'bold' }} onClick={goDetailPage(val)}>{val}</span>
    },
    {
      key: 'riderName',
      title: '라이더 이름',
      align: 'center',
      render: (val, row) => {
        return row ? row.rider ? <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName}/> : '' : '';
      }
    },
    {
      key: 'scooterShortImei',
      title: 'SIMEI',
      align: 'center',
      render: (val, row) => {
        return row ? row.mobility ? row.mobility.shortImei : '' : '';
      }
    },
    {
      key: 'bandName',
      title: '밴드명',
      align: 'center',
      render: (val, row, i) => {
        return row ? row.rider.band ? getBandTag(row.rider.band.bandName, i) : '-' : '-'
      }
    },
    {
      key: 'startAt',
      title: '시작일',
      dataIndex: 'startAt',
      align: 'center',
      render: (val, row) => {
        return parseDateTime(moment(val).unix() * 1000)
      }
    },
    {
      key: 'endAt',
      title: '종료일',
      dataIndex: 'endAt',
      align: 'center',
      render: (val) => <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
        {val ? parseDateTime(moment(val).unix() * Math.pow(10, 3)) : '현재 이용중'}
      </Typography.Text>
    },
    {
      key: 'startLoc',
      title: '시작 주소',
      align: 'center',
      render: (val, row) => {
        return row.startPlacement ? getPlacementTag(row.startPlacement.placementName) : <Geocode lat={row.startLocation.latitude} lng={row.startLocation.longitude}/>;
      }
    },
    {
      key: 'endLoc',
      title: '종료 주소',
      align: 'center',
      render: (val, row) => {
        return row.endPlacement
          ? getPlacementTag(row.endPlacement.placementName)
          : row.endPlacement
            ? <Geocode lat={row.endLocation.latitude} lng={row.endLocation.longitude}/>
            : '-';
      }
    },
  ];

  return (
    <>
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={
          riderRideHistory.map((r, i) => ({key: String(i + 1), ...r,}))
        }
      />
    </>
  );
}
