import React, { useEffect, useState } from 'react';
import { getPlacementPagination, getPlacementDescription } from '../placement.service';
import { Card, Input, Radio, Slider, Tag, Select, Space } from 'antd';
import { useLocation } from 'react-router';
import { TableHeading } from '../../../common/component/TableHeading';
import { PlacementDto } from '../placement.interface';
import BandOptionRadio from '../../../common/component/BandOptionRadio';
import { ModalMap } from '../../../common/map/ModalMap';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import { PaginationRes } from '../../../common/common.interface';
import { parseSearchString } from '../../../utils/organizeData';
import { Pagination } from '../../../common/url/pagination';
import { move } from '../../../common/url/utils';
import { PlacementListTable } from '../component/PlacementListTable';
// import {PlacementList} from "../component/PlacementList";

const { Search } = Input;
const { Option } = Select;
const BASE_URL = '/admin/placement-list';

export function PlacementListPage() {


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const pagination = Pagination.placement(
    query.get('page') ? parseInt(query.get('page')!) : 0,
    query.get('size') ? parseInt(query.get('size')!) : DEFAULT_TABLE_SIZE,
    query.get('keyword') ?? '',
    query.get('band') ?? '',
    query.get('mobility') ?? '',
    query.get('display') ?? 'all',
    query.get('sb') ?? '100',
    query.get('mb') ?? '100'
    // query.get('gu') ?? ''
  );

  const [paginationRes, setPaginationRes] = useState<PaginationRes>({
    page: 0,
    totalPage: 0,
    totalItem: 0
  });

  useEffect(() => {
    bootstrap();
  }, []);

  function bootstrap() {
    getPlacementPagination(pagination).then(p => {
      setPaginationRes({
        page: p.page,
        totalItem: p.totalItem,
        totalPage: p.totalPage
      });
      setPlacements(p.items.sort((a: { placementId: number; }, b: { placementId: number; }) => b.placementId - a.placementId));
    });
    getPlacementDescription().then(setGu);
  }

  const [placements, setPlacements] = useState<PlacementDto[]>([]);
  const [isPlacementDetailModalVisible, setIsPlacementDetailModalVisible] = useState<boolean>(false);
  const [isPlacementPositionMapModalVisible, setIsPlacementPositionMapModalVisible] = useState(false);
  const [selectedPlacementId, setSelectedPlacementId] = useState<number | null>(null);
  const [selectedBand, setSelectedBand] = useState('');
  const [selectedPositionPlacement, setSelectedPositionPlacement] = useState<PlacementDto | null>(null);
  const [gu, setGu] = useState<{
    description: string
  }[]>([]);
  const [selectedGu, setSelectedGu] = useState<string[]>([]);

  const handleSelectedPlacementPosition = (row: PlacementDto) => {
    setSelectedPositionPlacement(row);
    setIsPlacementPositionMapModalVisible(true);
  };

  useEffect(() => {
    if (selectedBand !== '') {
      const newPagination = Pagination.placementFrom(pagination, {
        band: selectedBand === '0' ? '' : selectedBand
      });
      move(BASE_URL, newPagination);
    }
  }, [selectedBand]);

  const handleSearch = (param: string, val: any) => {
    switch (param) {
      case 'KEYWORD':
        move(BASE_URL, Pagination.placementFrom(pagination, { keyword: parseSearchString(val) }));
        break;
      case 'MOBILITY':
        move(BASE_URL, Pagination.placementFrom(pagination, { mobility: val }));
        break;
      case 'SB':
        move(BASE_URL, Pagination.placementFrom(pagination, { sb: val }));
        break;
      case 'MB':
        move(BASE_URL, Pagination.placementFrom(pagination, { mb: val }));
        break;
      case 'DISPLAY':
        move(BASE_URL, Pagination.placementFrom(pagination, { display: val }));
        break;
    }
  };

  return (
    <>
      <ModalMap
        isVisible={isPlacementPositionMapModalVisible}
        setIsVisible={setIsPlacementPositionMapModalVisible}
        selectedDomain={selectedPositionPlacement}
      />
      <TableHeading
        headingTitle={'픽업존 목록'}
        row={paginationRes.totalItem}
      />
      <Card
        title={'픽업존 필터'}
      >
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Search
            style={{ width: '100%' }}
            placeholder='픽업존 이름 또는 소속구를 입력해주세요'
            size={'large'}
            defaultValue={pagination.fields.keyword ?? ''}
            onSearch={e => handleSearch('KEYWORD', e)}
          />
          {/*<Select*/}
          {/*  mode='multiple'*/}
          {/*  allowClear*/}
          {/*  style={{ width: '100%' }}*/}
          {/*  placeholder='소속구를 선택해주세요'*/}
          {/*  onChange={val => console.log(val)}*/}
          {/*>*/}
          {/*  {guOptions}*/}
          {/*</Select>*/}
          <BandOptionRadio
            selectedBand={pagination.fields.band ? pagination.fields.band : '0'}
            setSelectedBand={setSelectedBand}
          />
          <Radio.Group
            style={{ width: '100%' }}
            defaultValue={pagination.fields.display}
            onChange={e => handleSearch('DISPLAY', e.target.value)}
          >
            <Radio.Button style={{ width: '33.3%', textAlign: 'center' }} value={'all'}>
              <Tag color={'green'}>전체</Tag>
            </Radio.Button>
            <Radio.Button style={{ width: '33.3%', textAlign: 'center' }} value={'true'}>
              <Tag color={'green'}>노출중</Tag>
            </Radio.Button>
            <Radio.Button style={{ width: '33.3%', textAlign: 'center' }} value={'false'}><Tag
              color={'red'}>미노출</Tag></Radio.Button>
          </Radio.Group>
          <Radio.Group
            style={{ width: '100%' }}
            value={pagination.fields.mobility}
            onChange={e => handleSearch('MOBILITY', e.target.value)}
          >
            <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={''}><Tag
              color={'purple'}>전체</Tag></Radio.Button>
            <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'A'}><Tag
              color={'magenta'}>오킥</Tag></Radio.Button>
            <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'S'}><Tag
              color={'orange'}>킥보드</Tag></Radio.Button>
            <Radio.Button style={{ width: '25%', textAlign: 'center' }} value={'M'}><Tag
              color={'cyan'}>오토바이</Tag></Radio.Button>
          </Radio.Group>
        </Space>
        <h3
          style={{ marginTop: '15px' }}
        >픽업존 배치 킥보드 배터리 필터</h3>
        <Slider
          defaultValue={pagination.fields.sb ? parseInt(pagination.fields.sb) : 0}
          onAfterChange={(e: any) => handleSearch('SB', e)}
        />
        <h3>픽업존 배치 오토바이 배터리 필터</h3>
        <Slider
          defaultValue={pagination.fields.mb ? parseInt(pagination.fields.mb) : 0}
          onAfterChange={(e: any) => handleSearch('MB', e)}
        />
      </Card>

      <PlacementListTable
        setSelectedPlacementId={setSelectedPlacementId}
        setIsPlacementDetailModalVisible={setIsPlacementDetailModalVisible}
        placements={placements}
        handleSelectedPlacementPosition={handleSelectedPlacementPosition}
        pagination={pagination}
        paginationRes={paginationRes}
      />
      {/*<PlacementList*/}
      {/*  setSelectedPlacementId={setSelectedPlacementId}*/}
      {/*  setIsPlacementDetailModalVisible={setIsPlacementDetailModalVisible}*/}
      {/*  placements={placements}*/}
      {/*  handleSelectedPlacementPosition={handleSelectedPlacementPosition}*/}
      {/*  pagination={pagination}*/}
      {/*  paginationRes={paginationRes}*/}
      {/*/>*/}
    </>
  );
}