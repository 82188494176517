import {LocalAdminApi} from '../../services';

export async function getNonPaymentList() {
  return await LocalAdminApi.get(`/nonpayments`).then((resp) => resp.data);
}

export async function putNonPayment(riderId: number, rideId: number) {
  return await LocalAdminApi.put('/nonpayments/repay', {
    riderId: riderId,
    rideId: rideId,
  })
}

export async function deleteNonPayment(rideId: number) {
  return await LocalAdminApi.delete(`/nonpayments/${rideId}`)
}