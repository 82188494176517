import React from 'react';
import {getBandTag, parseDateTime, parsePhoneNumber, parseSecondsToDays} from '../../../utils/organizeData';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import Table, {ColumnsType} from 'antd/lib/table';
import {Typography, Button} from 'antd';
import NameALink from "../../../common/component/NameALink";
import {HistoryDto} from "../ride.interface";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";
import {forceEndRide, payExpiredAmount} from "../ride.service";

interface Props {
  expiredRides: HistoryDto[]
}

export function ExpiredRideTable({expiredRides}: Props) {
  const {t} = useTranslation();
  const ridesColumns: ColumnsType<HistoryDto> = [
    columnMaker('id', '라이드 ID', columnRenderer(data => data.id)),
    columnMaker('band', t('common-band'),
      columnRenderer((data, idx) => getBandTag(data.rider.band.bandName, idx))
    ),
    columnMaker('riderName', t('common-name'),
      columnRenderer(data => <NameALink urlVal={data.rider.riderId} textVal={data.rider.riderName}/>)
    ),
    columnMaker('riderPhoneNo', t('common-phoneNumber'),
      columnRenderer(data => parsePhoneNumber(data.rider.phoneNo))
    ),
    columnMaker('pass', '사용이용권', columnRenderer(data => data.riderPass.product.productName)),
    columnMaker('overtime', '초과시간', columnRenderer(data => {
      const from = new Date(data.riderPass.applyEndAt).getTime();
      const to = new Date().getTime();
      return parseSecondsToDays(to - from);
    })),
    columnMaker('applyEndAt', '만료시간', columnRenderer(data => parseDateTime(data.riderPass.applyEndAt))),
    columnMaker('simei', 'SIMEI', columnRenderer(data => data.mobility.shortImei)),
    columnMaker('battery', '배터리', columnRenderer(data => data.mobility.battery)),
    columnMaker('startAt', t('common-start-at'),
      columnRenderer(data => (
        <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
          {parseDateTime(moment(data.startAt).unix() * Math.pow(10, 3))}
        </Typography.Text>
      ))
    ),
    columnMaker('startPlacement', t('common-start-location'),
      columnRenderer(data => data.startPlacement ? data.startPlacement.placementName : '-')
    ),
    columnMaker('stop', '종료',
      columnRenderer(data => {
        return (
          <Button
            onClick={() => {
              forceEndRide(data.id).then(e => {
                alert('종료되었습니다');
                window.location.reload();
              }).catch(err => {
                alert('강제종료에 실패하였습니다')
                window.location.reload();
              })
            }}
          >종료</Button>
        )
      })),
    columnMaker('pay', '종료 및 과금',
      columnRenderer(data => {
        return (
          <Button
            onClick={() => {
              payExpiredAmount(data.id).then(e => {
                alert('종료 및 과금에 성공했습니다');
                window.location.reload();
              }).catch(err => {
                alert('종료 및 과금에 실패하였습니다')
                window.location.reload();
              })
            }}
          >종료 및 과금</Button>
        )
      })),
  ];

  return (
    <Table
      bordered
      columns={ridesColumns}
      dataSource={
        expiredRides.sort((p, n) => {
          return moment(n.startAt).unix() - moment(p.startAt).unix();
        }).map((e, i) => {
          return {key: i, ...e}
        })
      }
      pagination={{
        defaultPageSize: 20,
        showSizeChanger: false
      }}
    />
  );
}
