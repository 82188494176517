export class ArrayList<T> {
  private array: T[];
  private readonly comparator: (a: T, b: T) => boolean;

  constructor(comparator: (a: T, b: T) => boolean = (a, b) => a === b) {
    this.array = [];
    this.comparator = comparator;
  }

  add(element: T) {
    this.array.push(element);
  }

  insert(index: number, ...element: T[]) {
    this.array.splice(index, 0, ...element);
  }

  remove(element: T): number {
    const index = this.indexOf(element);
    if (index === -1) return -1;
    this.removeAt(index);
    return index;
  }

  removeAt(index: number, count: number = 1): T[] {
    return this.array.splice(index, count);
  }

  get(index: number): T {
    return this.array[index];
  }

  set(index: number, element: T) {
    this.array[index] = element;
  }

  getArray(): T[] {
    return [...this.array];
  }

  indexOf(element: T): number {
    return this.findIndex(e => this.comparator(e, element));
  }

  findIndex(predicate: (e: T) => boolean): number {
    return this.array.findIndex(predicate);
  }

  subList(start: number, end?: number): ArrayList<T> {
    return ArrayList.from(this.array.slice(start, end), this.comparator);
  }

  forEach(callback: (e: T) => void) {
    this.array.forEach(callback);
  }

  map<U>(callback: (e: T) => U): ArrayList<U> {
    return ArrayList.from(this.array.map(callback));
  }

  clear() {
    this.array = [];
  }

  size() {
    return this.array.length;
  }

  static from<T>(original: T[], comparator: (a: T, b: T) => boolean = (a, b) => a === b) {
    const arrayList = new ArrayList(comparator);
    for (const e of original) {
      arrayList.add(e);
    }
    return arrayList;
  }
}
