import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, SetLoadingContext } from '../../../context';
import { getAppInfos } from '../appinfo.service';
import { AppInfoTable } from '../component/AppInfoTable';
import AppInfoAddModal from '../component/AddAppInfoModal';
import { useTranslation } from 'react-i18next';

/* == antd == */
import { Button } from 'antd';
import { AppInfoDto } from '../appinfo.interface';

export function AppInfoPage() {

  const { t, i18n } = useTranslation();
  const admin = useContext(AccountContext);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const [appinfos, setAppInfos] = useState<AppInfoDto[]>([]);

  useEffect(() => {
    bootstrap();
  }, [admin]);

  const setLoading = useContext(SetLoadingContext)!;

  async function bootstrap() {
    if (admin) {
      setLoading(true);
      await getAppInfos()
        .then((res) => setAppInfos(res.sort((a, b) => parseFloat(b.appVersion) - parseFloat(a.appVersion))))
        .then(() => setLoading(false));
    }
  }

  return (
    <div>
      <AppInfoAddModal
        isVisible={isAddModalVisible}
        setIsVisible={setIsAddModalVisible}
      />
      <div>
        <h2>{`${t('appInfoList')}(${appinfos.length})`}</h2>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => setIsAddModalVisible(true)}>{t('addAppInfo')}</Button>
        </div>
      </div>
      <AppInfoTable
        appinfos={appinfos}
      />
    </div>
  );
}
