import React from 'react';
import {getBandTag, getDeployableMobilityTag} from '../../../utils/organizeData';
import Table, {ColumnsType} from 'antd/lib/table';
import {PlacementDto} from '../placement.interface';
import {Switch} from 'antd';
import {AimOutlined} from '@ant-design/icons';
import {DEFAULT_TABLE_SIZE} from '../../../constants';
import {putPlacementDisplayYn} from '../placement.service';
import {PaginationRes} from '../../../common/common.interface';
import {Pagination} from '../../../common/url/pagination';
import {buildUrl} from '../../../common/url/utils';
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";
import Copyable from "../../../common/component/Copyable";

interface Props {
  setSelectedPlacementId: React.Dispatch<React.SetStateAction<number | null>>;
  setIsPlacementDetailModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  placements: PlacementDto[];
  handleSelectedPlacementPosition: (selectedPlacement: PlacementDto) => void;
  pagination: Pagination,
  paginationRes: PaginationRes,
}

export function PlacementListTable(
  {
    placements,
    handleSelectedPlacementPosition,
    pagination,
    paginationRes
  }: Props) {

  const handleSwitchChange = (val: boolean, row: PlacementDto) => {
    if (window.confirm('정말 수정하시겠습니까?')) {
      putPlacementDisplayYn(row.placementId, val).then(e => {
        alert('성공적으로 수정되었습니다');
        window.location.reload();
      }).catch(e => {
        alert('업데이트에 실패하였습니다');
        window.location.reload();
      });
    } else {
      window.location.reload()
    }
  };

  const placementColumn: ColumnsType<PlacementDto> = [
    columnMaker('id', '픽업존 ID', columnRenderer(data => data.placementId)),
    columnMaker('placement-name', '이름', columnRenderer(data => <Copyable textVal={data.placementName}/>)),
    columnMaker('band', '소속 밴드', columnRenderer(data => getBandTag(data.band.bandName, data.placementId))),
    columnMaker('description', '소속구', columnRenderer(data => data.description)),
    columnMaker('deployableCount', '배치가능 댓수', columnRenderer(data => data.deployableCount)),
    columnMaker('countOfScooters', '배치 킥보드', columnRenderer(data => data.placementCount.scooters)),
    columnMaker('countOfMopeds', '배치 오토바이', columnRenderer(data => data.placementCount.mopeds)),
    columnMaker('mobilityLocation', '위치', columnRenderer(data => {
      return (
        <AimOutlined onClick={() => handleSelectedPlacementPosition(data)}/>
      )
    })),
    columnMaker('deployableMobility', '배치가능 모빌리티', columnRenderer(data => getDeployableMobilityTag(data.deployableMobilityType))),
    columnMaker('action', '노출여부', columnRenderer(data => {
      return (
        <Switch
          defaultChecked={data.displayYn}
          checkedChildren="노출중"
          unCheckedChildren="미노출"
          onChange={(e) => handleSwitchChange(e, data)}
        />
      )
    })),
  ];

  const handleTableChange = (pgn: any) => {
    const newPagination = Pagination.placementFrom(pagination, {page: pgn.current - 1});
    window.location.href = buildUrl('/admin/placement-list', newPagination.getParams());
  };

  return (
    <Table
      bordered
      columns={placementColumn}
      dataSource={placements.map((e, i) => {
        return {key: i, ...e};
      })}
      onChange={handleTableChange}
      pagination={{
        current: paginationRes.page + 1,
        total: paginationRes.totalItem,
        defaultPageSize: DEFAULT_TABLE_SIZE,
        showSizeChanger: false
      }}
    />
  );
}
