import { useParams } from 'react-router';
import { Tabs } from 'antd';
import { RiderDetailTab } from '../component/RiderDetailTab';
import { RiderSubscriptionHistoryTable } from '../table/RiderSubscriptionHistoryTable';
import { RiderScooterRideHistoryTable } from '../table/RiderScooterRideHistoryTable';
import { RiderMopedRideHistoryTable } from '../table/RiderMopedRideHistoryTable';
import { RiderPurchaseHistoryTable } from '../table/RiderPurchaseHistoryTable';
import { RiderPenaltyHistoryTable } from '../table/RiderPenaltyHistoryTable';
import { RiderNonpaymentTable } from '../table/RiderNonpaymentTable';
import React from 'react';


export function RiderDetailPage() {
  const { riderId } = useParams<{ riderId: string }>();
  const { TabPane } = Tabs;

  return (
    <Tabs>
      <TabPane tab='기본정보' key='1'>
        <RiderDetailTab
          riderId={riderId}
        />
      </TabPane>

      <TabPane tab='구독 이력' key='2'>
        <RiderSubscriptionHistoryTable
          riderId={riderId}
        />
      </TabPane>

      <TabPane tab='구매 이력' key='3'>
        <RiderPurchaseHistoryTable riderId={riderId} />
      </TabPane>

      <TabPane tab='킥보드 사용이력' key='4'>
        <RiderScooterRideHistoryTable riderId={riderId} />
      </TabPane>

      <TabPane tab='오토바이 사용이력' key='5'>
        <RiderMopedRideHistoryTable riderId={riderId} />
      </TabPane>

      <TabPane tab='패널티 이력' key='6'>
        <RiderPenaltyHistoryTable riderId={riderId} />
      </TabPane>

      <TabPane tab='미결제 이력' key='7'>
        <RiderNonpaymentTable riderId={riderId} />
      </TabPane>
    </Tabs>
  );
}
