import React, {useState} from 'react';
import {getBandTag, getAmountNumberFormat, getBandTagById} from '../../utils/organizeData';
import Table, {ColumnsType} from 'antd/lib/table';
import {Switch} from 'antd';
import {OrganizedPurchaseDashboard} from './dashboard.interface';
import {columnMaker, columnRenderer, columnWithChildren} from "../../utils/componentUtils";

interface Props {
  organizedData: OrganizedPurchaseDashboard[];
}

const Sum = (numbers: number[]) => {
  return numbers.reduce((acc, cur) => acc + cur);
};

export function DashboardSalesTable({organizedData}: Props) {

  const [isAmountUnit, setIsAmountUnit] = useState<boolean>(true);

  const reducer = (acc: number, cur: number) => acc + cur;

  const dashboardSalesTableColumn: ColumnsType<OrganizedPurchaseDashboard> = [
    columnMaker('band', '밴드', columnRenderer((data, idx) => getBandTagById(data.bandId, idx)),
      'center',
      undefined,
      'left'),
    columnWithChildren('scooterPass', '킥보드 이용권', [
      columnMaker('one-hour', '1시간', columnRenderer(data => (
        isAmountUnit
          ? data.passes.scooter.hour.length > 0
            ? getAmountNumberFormat(data.passes.scooter.hour.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
            : 0
          : data.passes.scooter.hour.length + '개'
      ))),
      columnMaker('one-day', '1일권', columnRenderer(data => (
        isAmountUnit
          ? data.passes.scooter.one.length > 0
            ? getAmountNumberFormat(data.passes.scooter.one.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
            : 0
          : data.passes.scooter.one.length + '개'
      ))),
      columnMaker('seven-day', '7일권', columnRenderer(data => (
        isAmountUnit
          ? data.passes.scooter.seven.length > 0
            ? getAmountNumberFormat(data.passes.scooter.seven.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
            : 0
          : data.passes.scooter.seven.length + '개'
      ))),
      columnMaker('thirty-day', '30일권', columnRenderer(data => (
        isAmountUnit
          ? data.passes.scooter.thirty.length > 0
            ? getAmountNumberFormat(data.passes.scooter.thirty.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
            : 0
          : data.passes.scooter.thirty.length + '개'
      ))),
      columnMaker('ninety-day', '90일권', columnRenderer(data => (
        isAmountUnit
          ? data.passes.scooter.ninety.length > 0
            ? getAmountNumberFormat(data.passes.scooter.ninety.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
            : 0
          : data.passes.scooter.ninety.length + '개'
      ))),
    ]),
    {
      key: 'mopedPass',
      title: '오토바이 이용권',
      children: [
        {
          title: '1일권',
          key: 'one-day',
          align: 'center',
          width: '6%',
          render: (val, row) => {
            return isAmountUnit
              ? row.passes.moped.one.length > 0
                ? getAmountNumberFormat(row.passes.moped.one.map(e => e.purchaseAmount)
                  .reduce((acc, cur) => acc + cur))
                : 0
              : row.passes.moped.one.length + '개';
          }
        },
        {
          title: '7일권',
          key: 'seven-day',
          align: 'center',
          width: '6%',
          render: (val, row) => {
            return isAmountUnit
              ? row.passes.moped.seven.length > 0
                ? getAmountNumberFormat(row.passes.moped.seven.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
                : 0
              : row.passes.moped.seven.length + '개';
          }
        },
        {
          title: '30일권',
          key: 'thirty-day',
          align: 'center',
          width: '6%',
          render: (val, row) => {
            return isAmountUnit
              ? row.passes.moped.thirty.length > 0
                ? getAmountNumberFormat(row.passes.moped.thirty.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
                : 0
              : row.passes.moped.thirty.length + '개';
          }
        }
      ]
    },
    columnWithChildren(
      'ondemand',
      '후불요금제',
      [
        columnMaker('ondemand-scooter', '킥보드', columnRenderer(data => (
          isAmountUnit
            ? data.ondemands.scooter.length > 0
              ? getAmountNumberFormat(data.ondemands.scooter.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
              : 0
            : data.ondemands.scooter.length + '개'
        ))),
        columnMaker('ondemand-moped', '오토바이', columnRenderer(data => (
          isAmountUnit
            ? data.ondemands.moped.length > 0
              ? getAmountNumberFormat(data.ondemands.moped.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
              : 0
            : data.ondemands.moped.length + '개'
        ))),
      ]
    ),
    {
      key: 'lease',
      title: '리스',
      align: 'center',
      width: '6%',
      children: [
        {
          key: 'scooter-lease',
          title: '킥보드',
          align: 'center',
          render: (val, row) => {
            const leasePurchases = row.lease.scooter;
            if (!isAmountUnit) return leasePurchases.length;
            if (leasePurchases.length == 0) return 0;
            const totalPrice = Sum(leasePurchases.map(p => p.purchaseAmount));
            return getAmountNumberFormat(totalPrice);
          }
        },
        {
          key: 'moped-lease',
          title: '오토바이',
          align: 'center',
          render: (val, row) => {
            const leasePurchases = row.lease.moped;
            if (!isAmountUnit) return leasePurchases.length;
            if (leasePurchases.length == 0) return 0;
            const totalPrice = Sum(leasePurchases.map(p => p.purchaseAmount));
            return getAmountNumberFormat(totalPrice);
          }
        }
      ]
    },
    {
      key: 'rental',
      title: '렌탈',
      align: 'center',
      width: '6%',
      children: [
        {
          key: 'scooter-rental',
          title: '킥보드',
          align: 'center',
          render: (val, row) => {
            const rentalPurchases = row.rental.scooter;
            if (!isAmountUnit) return rentalPurchases.length;
            if (rentalPurchases.length == 0) return 0;
            const totalPrice = Sum(rentalPurchases.map(p => p.purchaseAmount));
            return getAmountNumberFormat(totalPrice);
          }
        },
        {
          key: 'moped-rental',
          title: '오토바이',
          align: 'center',
          render: (val, row) => {
            const rentalPurchases = row.rental.moped;
            if (!isAmountUnit) return rentalPurchases.length;
            if (rentalPurchases.length == 0) return 0;
            const totalPrice = Sum(rentalPurchases.map(p => p.purchaseAmount));
            return getAmountNumberFormat(totalPrice);
          }
        }
      ]
    },
    {
      key: 'returnPenalty',
      title: '반납 패널티',
      align: 'center',
      children: [
        {
          title: '킥보드',
          key: 'one-day',
          align: 'center',
          width: '6%',
          render: (val, row) => {
            return isAmountUnit
              ? row.penalties.return.scooter.length > 0
                ? getAmountNumberFormat(row.penalties.return.scooter.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
                : 0
              : row.penalties.return.scooter.length + '개';
          }
        },
        {
          title: '오토바이',
          key: 'one-day',
          align: 'center',
          width: '6%',
          render: (val, row) => {
            return isAmountUnit
              ? row.penalties.return.moped.length > 0
                ? getAmountNumberFormat(row.penalties.return.moped.map(e => e.purchaseAmount).reduce((acc, cur) => acc + cur))
                : 0
              : row.penalties.return.moped.length + '개';
          }
        }
      ]
    },
    {
      key: 'scooter-sum',
      title: <span>{'킥보드'}<br/>{'합계'}</span>,
      align: 'center',
      width: '6%',
      render: (val, row) => {
        const allPassesByPeriod: number[] = [];
        row.passes.scooter.one.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.scooter.seven.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.scooter.thirty.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.scooter.ninety.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.penalties.return.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.ondemands.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.lease.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.rental.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        return isAmountUnit
          ? allPassesByPeriod.length > 0
            ? getAmountNumberFormat(allPassesByPeriod.reduce((acc, cur) => acc + cur))
            : 0
          : allPassesByPeriod.length + '개';
      }
    },
    {
      key: 'moped-sum',
      title: <span>{'오토바이'}<br/>{'합계'}</span>,
      align: 'center',
      width: '6%',
      render: (val, row) => {
        const allPassesByPeriod: number[] = [];
        row.passes.moped.one.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.moped.seven.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.moped.thirty.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.penalties.return.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.ondemands.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.lease.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.rental.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        return isAmountUnit
          ? allPassesByPeriod.length > 0
            ? getAmountNumberFormat(allPassesByPeriod.reduce((acc, cur) => acc + cur))
            : 0
          : allPassesByPeriod.length + '개';
      }
    },
    {
      key: 'all',
      title: <span>{'전체'}<br/>{'합계'}</span>,
      align: 'center',
      width: '8%',
      render: (val, row) => {
        const allPassesByPeriod: number[] = [];

        row.passes.scooter.one.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.scooter.seven.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.scooter.thirty.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.scooter.ninety.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.penalties.return.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.ondemands.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.lease.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.rental.scooter.forEach(e => allPassesByPeriod.push(e.purchaseAmount));

        row.passes.moped.one.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.moped.seven.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.passes.moped.thirty.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.others.filter(e => e.purchaseAmount > 90000).forEach(l => allPassesByPeriod.push(l.purchaseAmount));
        row.penalties.return.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.ondemands.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.lease.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        row.rental.moped.forEach(e => allPassesByPeriod.push(e.purchaseAmount));
        return isAmountUnit
          ? allPassesByPeriod.length > 0
            ? getAmountNumberFormat(allPassesByPeriod.reduce((acc, cur) => acc + cur))
            : 0
          : allPassesByPeriod.length + '개';
      }
    }
  ];

  return (
    <Table
      columns={dashboardSalesTableColumn}
      bordered={true}
      dataSource={organizedData.map((e, i) => ({key: String(i + 1), ...e}))}
      pagination={{
        defaultPageSize: 20,
        showSizeChanger: false
      }}
      scroll={{ x: 2300, y: 300 }}
      footer={() => <Switch
        checkedChildren='금액'
        unCheckedChildren='갯수'
        defaultChecked
        onChange={(e) => setIsAmountUnit(e)}
      />}
    />
  );
}