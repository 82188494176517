import React, {useEffect, useState} from 'react';
import {Map} from "../../../common/map/Map";
import CustomDrawingControlPanel from "../../placement/component/MapController/CustomDrawingControlPanel";
import {MapType} from "../../../common/map/Types";
import {CustomDrawingPlugin} from "../../../common/map/plugins/CustomDrawingPlugin";
import {MapPluginManager} from "../../../common/map/plugins/MapPluginManager";
import {PolygonDrawingPlugin} from "../../../common/map/plugins/polygon_drawing_plugin/PolygonDrawingPlugin";

export type CustomDrawingMode = 'CLICK' | 'FINISH' | 'COPY' | 'PASTE' | 'EDIT';

const GeofencePage = () => {
  const [mapType, setMapType] = useState<MapType>('KAKAO');
  const [pluginManager] = useState(new MapPluginManager().usePlugin(new PolygonDrawingPlugin()));
  const [drawingMode, setDrawingMode] = useState<CustomDrawingMode>('CLICK');

  useEffect(() => {
    switch (drawingMode) {
      case "FINISH": pluginManager.applyPlugin(CustomDrawingPlugin, p => p.finishDrawing());
    }
  }, [drawingMode]);

  return (
    <div style={{position: 'relative'}}>
      <CustomDrawingControlPanel
        mapType={mapType}
        setMapType={setMapType}
        drawingMode={drawingMode}
        setDrawingMode={setDrawingMode}
      />
      <Map
        mapType={mapType}
        plugins={pluginManager.plugins}
        level={8}
      />
    </div>
  )
}

export default GeofencePage;