import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthService } from '../services/auth';

export const useValidation = () => {
  useEffect(() => {
    (async function bootstrap() {
      try {
        await AuthService.getMyInfo();
      } catch (err) {
        alert('토큰이 만료되었습니다. 다시 로그인해주세요.');
        localStorage.removeItem('access_token');
        window.location.reload();
      }
    })();
  }, []);
};
