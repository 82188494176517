import React, { FC, useState } from 'react';
import BaseModal from '../../../common/component/BaseModal';
import { DatePicker } from 'antd';
import { SimpleBanner } from '../banner.interface';
import { putBannerDuration } from '../banner.service';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDurationEditBanner: SimpleBanner | null;
}

const BannerDurationEditModal: FC<Props> = ({ isVisible, setIsVisible, selectedDurationEditBanner }) => {

  const [duration, setDuration] = useState<string[]>([]);

  const handleSubmit = () => {
    if (selectedDurationEditBanner && duration) {
      putBannerDuration(selectedDurationEditBanner.bannerId, {
        displayStartAt: duration[0],
        displayEndAt: duration[1]
      })
        .then(e => {
          alert('수정되었습니다.');
          window.location.reload()
        })
        .catch(e => {
          alert('실패하였습니다');
          window.location.reload()
        })
    }
  };

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={() => handleSubmit()}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'배너 노출 시간 수정'}
      width={'80%'}
    >
      <RangePicker
        showTime
        style={{ width: '100%' }}
        defaultValue={[
          moment(selectedDurationEditBanner?.displayStartAt),
          moment(selectedDurationEditBanner?.displayEndAt)
        ]}
        onOk={e => {
          if (e) {
            setDuration([
              e[0]!.format('YYYY-MM-DDTHH:mm:SS'),
              e[1]!.format('YYYY-MM-DDTHH:mm:SS')
            ])
          }
        }}
      />
    </BaseModal>
  )
    ;
};

export default BannerDurationEditModal;