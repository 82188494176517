import React, {useContext, useState, VFC} from 'react';
import { Modal, Tabs, Row, Col } from 'antd';
import AddPlacementForm from './form/AddPlacementForm';
import { addPlacement, updatePlacement } from '../placement.service';
import { PlacementCreateDto, PlacementDto, PlacementType } from '../placement.interface';
import { AccountContext } from '../../../context';
import { Map } from '../../../common/map/Map';
import { MapType } from '../../../common/map/Types';
import { PlacementMarker } from '../../../common/map/markers/PickupMarker';
import {DraggableMarkerPlugin} from "../../../common/map/plugins/DraggableMarkerPlugin";
import {UploadFile} from "antd/es/upload/interface";

type Props = {
  mapType: MapType,
  isVisible: boolean,
  setIsPlacementAddModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  editPlacement?: PlacementDto;
};

const { TabPane } = Tabs;

export const AddEditPlacementModal: VFC<Props> = (
  {
    isVisible,
    setIsPlacementAddModalVisible,
    mapType,
    editPlacement
  }
) => {

  const account = useContext(AccountContext)!;

  const [placementType, setPlacementType] = useState<PlacementType>('PICKUP');
  const [formValues, setFormValues] = useState<PlacementCreateDto | null>(null);
  const [imageWall, setImageWall] = useState<UploadFile[]>([]);

  // const [pluginManager] = useState(new MapPluginManager()
  //   .usePlugin(new DraggableMarkerPlugin(editPlacement?.location))
  //   .usePlugin(new PlacementMarkerPlugin(mapType))
  // );
  const [draggableMarkerPlugin,] = useState(new DraggableMarkerPlugin(editPlacement?.location));

  const onSubmit = async () => {
    const newPlacementCoord = draggableMarkerPlugin.getLocation();
    if (!newPlacementCoord) alert('잘못된 위치정보입니다.');
    const bandId = account.band.bandId;
    const [firImgUlr, secImgUrl, thiImgUrl, fouImgUrl, fifImgUrl] = imageWall.map(e => e.url);
    if (formValues && newPlacementCoord) {
      if (editPlacement) {
        const newPlacement: PlacementCreateDto = {
          placementType: placementType,
          parentId: formValues.parentId,
          bandId: bandId,
          location: {
            latitude: newPlacementCoord.latitude,
            longitude: newPlacementCoord.longitude
          },
          placementName: formValues.placementName,
          deployableCount: formValues.deployableCount,
          deployableMobilityType: formValues.deployableMobilityType,
          imageFileName: firImgUlr,
          imageFileName2: secImgUrl,
          imageFileName3: thiImgUrl,
          imageFileName4: fouImgUrl,
          imageFileName5: fifImgUrl,
          description: formValues.description
        };
        updatePlacement(editPlacement.placementId, newPlacement).then(e => {
          if (e.status === 200) window.location.reload();
        }).catch(c => {
          alert('등록에 실패하였습니다');
          window.location.reload();
        });
      } else {
        const newPlacement: PlacementCreateDto = {
          placementType: placementType,
          parentId: formValues.parentId,
          bandId: bandId,
          location: {
            latitude: newPlacementCoord.latitude,
            longitude: newPlacementCoord.longitude
          },
          placementName: formValues.placementName,
          deployableCount: formValues.deployableCount,
          deployableMobilityType: formValues.deployableMobilityType,
          imageFileName: firImgUlr,
          imageFileName2: secImgUrl,
          imageFileName3: thiImgUrl,
          imageFileName4: fouImgUrl,
          imageFileName5: fifImgUrl,
          description: formValues.description
        };
        addPlacement(newPlacement).then(e => {
          if (e.status === 200) window.location.reload();
        }).catch(c => {
          alert('등록에 실패하였습니다');
          window.location.reload();
        });
      }
    }
  };

  const editingPlacementMarker = [];

  if (editPlacement) {
    editingPlacementMarker.push(PlacementMarker(editPlacement));
  }

  return (
    <Modal
      title='라운지 / 픽업존 추가하기'
      width={'90%'}
      visible={isVisible}
      onCancel={() => setIsPlacementAddModalVisible(false)}
      cancelText={'취소'}
      okText={editPlacement ? '수정하기' : '추가하기'}
      onOk={onSubmit}
    >
      <Row gutter={[4, 4]}>
        <Col span={10}>
          <Tabs defaultActiveKey={placementType}>
            <TabPane tab='픽업존 추가' key='PICKUP'>
              <AddPlacementForm
                formValues={formValues}
                setFormValues={setFormValues}
                imageWall={imageWall}
                setImageWall={setImageWall}
                editPlacement={editPlacement}
              />
            </TabPane>
            <TabPane tab='라운지 추가' key='LOUNGE'>
              라운지
            </TabPane>
          </Tabs>
        </Col>
        <Col span={14}>
          <Map
            mapType={mapType}
            style={{ height: '70vh' }}
            drawMode={'MARKER'}
            markers={editingPlacementMarker}
            showToolBox={false}
            addMode={true}
            plugins={[draggableMarkerPlugin]}
          />
        </Col>
      </Row>
    </Modal>
  );
};