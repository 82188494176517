import React, {useContext, useEffect, useState} from 'react';
import {SetLoadingContext} from '../../../context';

import {Badge} from 'antd';
import Table, {ColumnsType} from 'antd/lib/table';
import {cancelPenaltyHistory, getRiderPenaltyHistory} from '../rider.service';
import {parseDateTime} from '../../../utils/organizeData';
import moment from 'moment';
import {RiderPenaltyHistory} from "../rider.interface";

const isPaid = {
  SUCCESS: <Badge status="success" text="결제완료"/>,
  FAIL: <Badge status="error" text="미결제"/>,
  CANCEL: <Badge status="success" text="삭제됨"/>,
};

export function RiderPenaltyHistoryTable({riderId}: { riderId: string }) {
  const setLoading = useContext(SetLoadingContext)!;
  const [riderPenaltyHistory, setRiderPenaltyHistory] = useState<RiderPenaltyHistory[]>([]);

  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getRiderPenaltyHistory(riderId)
      .then((res) => setRiderPenaltyHistory(res.sort((a, b) => b.id - a.id)))
      .then(() => setLoading(false));
  }

  const cancelPenaltyClickHandler = (riderId: string, id: number) => async () => {
    try {
      await cancelPenaltyHistory(riderId, String(id));
      alert('정상적으로 처리됐습니다.!');
      window.location.reload();
    } catch (err) {
      alert('실패했습니다.' + err);
    }
  };

  const riderColumn: ColumnsType<RiderPenaltyHistory> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: '라이드 ID',
      align: 'center',
    },
    {
      key: 'rideId',
      title: '라이드번호',
      dataIndex: 'rideId',
      align: 'center',
    },
    {
      key: 'penaltyAt',
      title: '패널티 적용일',
      align: 'center',
      render: (val, row) => {
        return parseDateTime(moment(row.penaltyAt).unix() * 1000);
      },
    },
    {
      key: 'paidAmount',
      title: '패널티 금액',
      align: 'center',
      render: (val, row) => row.paidAmount,
    },
    {
      key: 'paidStatus',
      title: '결제여부',
      align: 'center',
      render: (val, row) => {
        return row.paidStatus === 'SUCCESS' ? (
          <Badge status="success" text="결제완료"/>
        ) : row.paidStatus === 'FAIL' ? (
          <Badge status="error" text="미결제"/>
        ) : (
          <Badge status="error" text="취소됨"/>
        );
      },
    },
    {
      title: '패널티 취소',
      key: 'cancelPenalty',
      dataIndex: 'productId',
      align: 'center',
      render: (t, r) => (
        <>
          {r.paidStatus === 'FAIL' ? (
            <a onClick={cancelPenaltyClickHandler(riderId, r.id)}> 패널티 취소</a>
          ) : (
            <span>-</span>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={riderPenaltyHistory.map((r, i) => ({key: String(i + 1), ...r}))}
      />
    </>
  );
}
