import React, {FC, useState} from 'react';
import BaseModal from '../../../common/component/BaseModal';
import {Row, Input} from 'antd';
import {Classes} from "reactcss";
import {registerLicense} from '../rider.service'

interface Props {
  riderId: string;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterLicenseModal: FC<Props> = ({riderId, isVisible, setIsVisible}) => {

  const [region, setRegion] = useState('');
  const [licenseNo, setLicenseNo] = useState('')

  const handleSubmitButton = () => {
    registerLicense(riderId, {
      licenseNo: licenseNo,
      region: region
    }).then(e => {
      alert("성공적으로 등록 되었습니다");
      window.location.reload();
    })
  }

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={handleSubmitButton}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'라이더 정보 수정'}
      width={'80%'}
    >
      <Row
        gutter={[16, 16]}
        justify={'center'}
        align={'middle'}
      >
        <Input placeholder={"지역을 입력해주세요"} value={region} onChange={e => {setRegion(e.target.value)}}/>
        <Input placeholder={"면허번호를 입력해주세요"} value={licenseNo} onChange={e => {setLicenseNo(e.target.value)}}/>
      </Row>

    </BaseModal>
  );
};

export default RegisterLicenseModal;