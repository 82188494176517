import {LocationDto} from "../common.interface";
import {Undoable} from "../work_history/work_history";
import {ArrayList} from "../../utils/array_list";

export class DeleteWork implements Undoable {
  private readonly index: number;
  private readonly coordinate: LocationDto;
  private readonly coordinates: ArrayList<LocationDto>;

  constructor(coordinate: LocationDto, coordinates: ArrayList<LocationDto>) {
    const index = coordinates.remove(coordinate);
    if (index === -1) throw new Error('No such coordinate');

    this.index = index;
    this.coordinate = coordinate;
    this.coordinates = coordinates;
  }

  undo() {
    this.coordinates.insert(this.index, this.coordinate);
  }
}
