import React, { useEffect, useState } from 'react';
import { Card, Switch, Radio } from 'antd';
import { getProductSales, getOnSaleProducts } from '../dashboard.service';
import { Line } from '@ant-design/charts';
import { MobilityCode } from '../../product/product.interface';

interface DashboardOnSaleProduct {
  productId: number,
  productName: string,
  mobilityCode: MobilityCode,
}

interface DashboardProductSaleData extends DashboardOnSaleProduct {
  date: string,
  count: number,
  sum: number,
}

const PurchaseDashboardDailyChart = () => {

  const [isScooterSale, setIsScooterSale] = useState(true);
  const [isUnitCount, setIsUnitCount] = useState(true);
  const [scooterSaleData, setScooterSaleData] = useState<DashboardProductSaleData[]>([]);
  const [mopedSaleData, setMopedSaleData] = useState<DashboardProductSaleData[]>([]);

  useEffect(() => {
    getOnSaleProducts().then((dashboardOnSaleProducts: DashboardOnSaleProduct[]) => {

      const productIds = dashboardOnSaleProducts.map(e => e.productId);
      const preparedScooterData: DashboardProductSaleData[] = [];
      const preparedMopedData: DashboardProductSaleData[] = [];

      Promise.all(productIds.map(getProductSales)).then(data => {
        if (productIds.length !== data.length) return;
        for (let i = 0; i < productIds.length; i++) {
          const product = dashboardOnSaleProducts[i];
          const datum = data[i].sort((a: { date: string | number | Date; }, b: { date: string | number | Date; }) => new Date(a.date).getTime() - new Date(b.date).getTime());
          for (let j = 0; j < datum.length; j++) {
            if (product.mobilityCode == 'ST') {
              preparedScooterData.push({
                productId: product.productId,
                productName: product.productName,
                mobilityCode: product.mobilityCode,
                date: datum[j].date,
                count: datum[j].count,
                sum: datum[j].sum
              });
            } else {
              preparedMopedData.push({
                productId: product.productId,
                productName: product.productName,
                mobilityCode: product.mobilityCode,
                date: datum[j].date,
                count: datum[j].count,
                sum: datum[j].sum
              });
            }
          }
        }
        console.log('chaged');
        setScooterSaleData(preparedScooterData);
        setMopedSaleData(preparedMopedData);
      });
    });
  }, []);

  const lineGraphConfig = {
    data: isScooterSale ? scooterSaleData : mopedSaleData,
    xField: 'date',
    yField: isUnitCount ? 'count' : 'sum',
    seriesField: 'productName',
    yAxis: {},
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000
      }
    }
  };

  return (
    <Card
      title={'상품별 최근 30일 판매현황'}
      extra={[
        <Switch
          onChange={() => setIsUnitCount(!isUnitCount)}
          checkedChildren='금액'
          unCheckedChildren='갯수'
        />,
        <Radio.Group
          value={isScooterSale}
          onChange={() => setIsScooterSale(!isScooterSale)}
          style={{ marginLeft: '16px' }}
        >
          <Radio.Button value={true}>킥보드</Radio.Button>
          <Radio.Button value={false}>오토바이</Radio.Button>
        </Radio.Group>
      ]}
    >
      <Line {...lineGraphConfig} />
    </Card>
  );
};

export default PurchaseDashboardDailyChart;