import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, SetLoadingContext } from '../../../context';
import { getPurchaseListExcelFile2, getPurchasePagination } from '../purchase.service';
import { PurchaseHistoryTable } from '../component/PurchaseHistoryTable';
import { Button, Card, Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { PurchaseDto } from '../purchase.interface';
import { DEFAULT_TABLE_SIZE } from '../../../constants';
import { useLocation } from 'react-router-dom';
import { PaginationRes } from '../../../common/common.interface';
import BandOptionRadio from '../../../common/component/BandOptionRadio';
import { Pagination } from '../../../common/url/pagination';
import { move } from '../../../common/url/utils';
import {useTranslation} from "react-i18next";

const BASE_URL = '/admin/purchase-history';

export function PurchasePage() {
  const {t} = useTranslation();
  const bandId = useContext(AccountContext)!.band.bandId;
  const setLoading = useContext(SetLoadingContext)!;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const pagination = Pagination.purchase(
    query.get('page') ? parseInt(query.get('page')!) : 0,
    query.get('size') ? parseInt(query.get('size')!) : DEFAULT_TABLE_SIZE,
    query.get('band') ?? '',
    query.get('from') ?? '',
    query.get('to') ?? ''
  );

  const [purchases, setPurchases] = useState<PurchaseDto[]>([]);
  const [paginationRes, setPaginationRes] = useState<PaginationRes>({
    page: 0,
    totalPage: 0,
    totalItem: 0
  });
  const [selectedBand, setSelectedBand] = useState(pagination.fields.band ? pagination.fields.band : '0');
  const dateFormat = 'YYYY-MM-DD';
  const [dateRange, setDateRange] = useState<string[]>([]);

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    if (bandId) {
      setLoading(true);
      await getPurchasePagination(pagination).then((res) => {
        setPaginationRes({
          page: res.page,
          totalItem: res.totalItem,
          totalPage: res.totalPage
        });
        setPurchases(res.items.sort((a, b) => b.purchaseId - a.purchaseId));
      }).then(() => setLoading(false));
    }
  }

  // useEffect(() => {
  //   if (selectedBand !== '') {
  //     const newPagination = Pagination.purchaseFrom(pagination, {
  //       band: selectedBand === '0' ? '' : selectedBand
  //     });
  //     move(BASE_URL, newPagination);
  //   }
  // }, [selectedBand]);

  const handleDateSearch = (range: any) => {
    if (range) {
      const start = moment(range[0]).format('YYYY-MM-DDT00:00:00');
      const end = moment(range[1]).format('YYYY-MM-DDT23:59:59');
      setDateRange([start, end]);
    } else {
      alert('기간조회를 확인해주세요');
    }
  };

  const { RangePicker } = DatePicker;

  const exportExcel = () => {
    if (pagination.fields.from && pagination.fields.to) {
      getPurchaseListExcelFile2(pagination.fields.from, pagination.fields.to, pagination.fields.band ? pagination.fields.band : '').then(e => {console.log(e);});
    } else {
      alert('날짜를 선택후 조회 버튼을 눌러주세요');
    }
  };

  const handleSearch = (param: string) => {
    switch (param) {
      case 'DATE':
        move(BASE_URL, Pagination.purchaseFrom(pagination, {
          from: dateRange[0],
          to: dateRange[1],
          band: selectedBand === '0' ? '' : selectedBand,
        }));
        break;
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '30px' }}>
        <h2>{`${t('side-bar-rider-purchase')} (${purchases.length})`}</h2>
      </div>
      <Card
        title={t('purchaseListSearchDownload')}
        extra={
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            size={'large'}
            onClick={exportExcel}
          />
        }
      >
        <Row gutter={[4, 4]}>
          <Col span={24}>
            <RangePicker
              defaultValue={[
                pagination.fields.from ? moment(pagination.fields.from) : null,
                pagination.fields.to ? moment(pagination.fields.to) : null
              ]}
              style={{ width: '100%' }}
              format={dateFormat}
              disabledDate={(cur) => cur && cur < moment('2021-08-23').endOf('day')}
              onChange={(e) => {handleDateSearch(e);}}
            />
          </Col>
          <Col span={24}>
            <BandOptionRadio
              selectedBand={selectedBand}
              setSelectedBand={setSelectedBand}
            />
          </Col>
          <Col
            span={24}
          >
            <Button type='primary' style={{ width: '100%', marginTop: '16px' }}
                    onClick={(() => handleSearch('DATE'))}>{t('common-search')}</Button>
          </Col>
        </Row>
      </Card>
      <PurchaseHistoryTable
        purchases={purchases}
        pagination={pagination}
        paginationRes={paginationRes}
      />
    </div>
  );
}

// getPurchaseListExcelFile(dateRange[0], dateRange[1]).then(res => {
//   console.log("here");
//
//   const excelJson = res.map(e => {
//     const date = e.purchaseAt.split("T");
//     return {
//       "이름": e.rider.riderName,
//       "연락처": e.rider.phoneNo,
//       "소속밴드": e.rider.band.bandName,
//       "금액": e.purchaseAmount,
//       "보증금": "",
//       "매출용 금액": e.canceled ? 0 : e.purchaseAmount,
//       "유형": e.product.productSubType,
//       "상품명": e.product.productName,
//       "날짜": date[0] + " " + date[1],
//       "취소 / 환불여부": e.canceled ? 'Y' : 'N'
//     }
//   })
//   const ws = xlsx.utils.json_to_sheet(excelJson);
//   const wb = xlsx.utils.book_new();
//   xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
//   xlsx.writeFile(wb, "매출.xlsx");
// });