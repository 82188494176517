export const languageKo = {
  'common-all': '전체',
  'common-confirm' : '확인',
  'common-cancel' : '취소',
  'common-battery' : '배터리',
  'common-none': '없음',
  'common-price': '가격',
  'common-submit': '제출',
  'common-type': '타입',
  'common-kind': '종류',
  'common-status': '상태',
  'common-rider': '라이더',
  'common-license': '면허',
  'common-no-license': '무면허',
  'common-area': '지역',
  'common-list': '목록',
  'common-year': '년',
  'common-month': '월',
  'common-day' : '일',
  'common-hour': '시',
  'common-minute': '분',
  'common-second': '초',
  'common-operator': '관리자',
  'common-basic': '기본',
  'common-name': '이름',
  'common-email': '이메일',
  'common-phoneNumber': '휴대폰번호',
  'common-password': '비밀번호',
  'common-band': '밴드',
  'common-auth': '권한',
  'common-edit' : '수정',
  'common-delete': '삭제',
  'common-pass-all': '모든 이용권',
  'common-pass-scooter': '킥보드 이용권',
  'common-pass-moped': '오토바이 이용권',
  'common-pass-expired': '만료된 이용권',
  'common-male': '남자',
  'common-female': '여자',
  'common-gender': '성별',
  'common-birthday': '생일',
  'common-age': '나이',
  'common-product-name': '상품명',
  'common-search': '조회',
  'common-purchase': '구매',
  'common-cancelYn': '취소여부',
  'common-product': '상품',
  'common-purhcase-amount': '금액',
  'common-scooter': '스쿠터',
  'common-riding': '운행중',
  'common-current-position': '현재위치',
  'common-start-at': '시작시간',
  'common-end-at': '종료시간',
  'common-start-location': '시작위치',
  'common-end-location': '종료위치',
  'common-force-end': '강제종료',

  'header-language': '언어',
  'header-language-kor': '한국어',
  'header-language-eng': '영어',
  'header-language-jpn': '일어',
  'header-language-logout': '로그아웃',

  'side-bar-operator': '관리자',
  'side-bar-operator-account': '계정관리',
  'side-bar-operator-band': '밴드관리',
  'side-bar-operator-master-product': '마스터 상품관리',

  'side-bar-dashboard': '대쉬보드',
  'side-bar-dashboard-signup': '가입자 대쉬보드',
  'side-bar-dashboard-purchase': '구매 대쉬보드',
  'side-bar-dashboard-placement': '픽업존 대쉬보드',

  'side-bar-rider': '라이더',
  'side-bar-rider-list': '라이더 목록',
  'side-bar-rider-subscription': '구독 이력',
  'side-bar-rider-purchase': '구매 이력',
  'side-bar-rider-nonpayment': '미결제 이력',

  'side-bar-scooter': '스쿠터',
  'side-bar-scooter-list': '스쿠터 목록',
  'side-bar-scooter-ride': '이용 내역',
  'side-bar-scooter-expired': '만료된 이용',
  'side-bar-scooter-nonpayment': '미결제',

  'side-bar-moped': '오토바이',
  'side-bar-moped-list': '오토바이 목록',
  'side-bar-moped-ride': '이용 내역',
  'side-bar-moped-rental': '리스/렌탈',
  'side-bar-moped-expired': '만료된 이용',
  'side-bar-moped-nonpayment': '미결제',

  'side-bar-placement': '픽업존',
  'side-bar-placement-list': '픽업존 목록',
  'side-bar-placement-map': '픽업존 지도',

  'side-bar-product': '상품',
  'side-bar-product-list': '목록',

  'side-bar-tariff' : '요금표',
  'side-bar-tariff-list' : '요금표 목록',
  'side-bar-tariff-band' : '밴드 요금표',

  'side-bar-application': '어플리케이션',
  'side-bar-application-app': '앱정보',
  'side-bar-application-banner': '배너',

  //account
  'operatorAccountCreated': '계정을 생성했습니다',
  'operatorAccountFailedToCreate': '계정생성에 실패했습니다',
  'operatorAccount': '관리자계정',
  'createOperatorAccount': '계정생성',
  'selectOperatorAuth': '권한선택',
  'pleaseSelectOperatorAuth': '권한을 선택해주세요',
  'selectOperatorBand': '밴드설정',
  'pleaseSelectOperatorBand': '밴드를 선택해주세요',
  'pleaseFillName': '이름을 입력하세요',
  'pleasePutValidEmail': '이메일 형식을 맞추주세요',
  'pleasePutPhoneNumber': '휴대폰번호를 입력해주세요',
  'pleasePutPassword': '패스워드를 입력해주세요',
  'confirmPassword': '패스워드 확인',
  'pleaseConfirmPassword': '패스워드 확인',
  'passwordNotMatch' : '비밀번호가 맞치 않습니다',
  'changePw': '비밀번호 수정',
  'deleteSuccessful' : '정상적으로 삭제되었습니다',
  'deleteFail': '삭제실패',
  'deleteAccount': '계정삭제',
  'deleteAccount?': '정말로 계정을 삭제하시겠습니까?',

  //appinfo
  'appInfoList': '앱정보목록',
  'appKey': '앱 키',
  'appName': '앱 이름',
  'appVersion': '앱 버전',
  'addAppInfo': '정보추가',
  'appService': '서비스 여부',

  //riderListPage
  'riderList': '라이더 목록',
  'searchRider': '라이더 검색',
  'pleasePutNamePhoneNumberID': '성함 / 휴대폰번호 / 아이디를 입력해주세요',
  'riderId': '라이더ID',
  'usingPass': '사용이용권',
  'registerAt': '가입일',

  //riderSubscriptionPage
  'purchaseDate': '구매일',
  'nextPurchaseDate': '다음 결제일',
  'subscriptionYn': '구독여부',
  'subscriptionCancel': '구독취소',
  'subscriptionProgress': '구독중',

  //purchaseList
  'purchaseListSearchDownload': '구매 이력 검색 및 다운로드',
  'purchaseCanceled': '취소됨',

  //unpaid
  'unpaid': '미결제내역',
  'pay': '결제하기',

  //scooter-riding-history
  'scooterRidingHistory': '스쿠터 이용내역',
  'pleasePutNameSIMEI': '성함 / SIMEI 를 입력해주세요',

  //expired-ride
  'expiredRide': '만료된 이용',
};