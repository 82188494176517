import React, {useContext, useEffect, useState} from 'react';
import {SetLoadingContext} from '../../../context';

import {Badge, Button} from 'antd';
import Table, {ColumnsType} from 'antd/lib/table';

import {getRiderPurchase, refundRiderPenalty} from '../rider.service';
import {getBandTag, parseDateTime} from '../../../utils/organizeData';
import moment from 'moment';
import PurchaseProduct from '../component/PurchaseProduct';
import RefundPurchase from '../../../components/modal/payment/RefundPurchase';
import {PurchaseDto} from '../../purchase/purchase.interface';
import NameALink from "../../../common/component/NameALink";

export function RiderPurchaseHistoryTable({riderId}: { riderId: string }) {

  const setLoading = useContext(SetLoadingContext)!;
  const [riderPurchase, setRiderPurchase] = useState<PurchaseDto[]>([]);
  const [selectedRefundPurchase, setSelectedRefundPurchase] = useState<PurchaseDto | null>(null);
  const [isPurchaseProductModalVisible, setIsPurchaseProductModalVisible] = useState(false);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
  const [isRefundAction, setIsRefundAction] = useState(false);

  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, [isRefundAction]);

  async function bootstrap() {
    setLoading(true);
    await getRiderPurchase(riderId)
      .then(setRiderPurchase)
      .then(() => setLoading(false));
  }

  const showRegisterModal = () => {
    setIsPurchaseProductModalVisible(true);
  };

  const registerModalHandleOk = () => {
    setIsPurchaseProductModalVisible(false);
  };

  const registerModalHandleClose = () => {
    setIsPurchaseProductModalVisible(false);
  };

  const handlePenaltyRefund = (purchaseId: string) => {
    if (window.confirm('정말 환불 하시겠습니까?')) {
      refundRiderPenalty(riderId, purchaseId, '패널티환불').then(e => {
        alert('성공적으로 환불 되었습니다');
        window.location.reload();
      });
    }
  };

  const riderColumn: ColumnsType<PurchaseDto> = [
    {
      key: 'id',
      title: '구매 ID',
      align: 'center',
      render: (val, row) => row.purchaseId
    },
    {
      key: 'riderName',
      title: '라이더 이름',
      align: 'center',
      render: (val, row) => {
        return row ? row.rider ? <NameALink urlVal={row.rider.riderId} textVal={row.rider.riderName}/> : '' : '';
      }
    },
    {
      key: 'bandName',
      title: '밴드명',
      align: 'center',
      render: (val, row, i) => {
        return row ? row.rider ? getBandTag(row.rider.band.bandName, i) : '' : '';
      }
    },
    {
      key: 'productName',
      title: '상품명',
      align: 'center',
      render: (val, row) => {
        return row ? row.product.title : '';
      }
    },
    {
      key: 'purchaseAmount',
      title: '구매 가격',
      dataIndex: 'purchaseAmount',
      align: 'center',
      render: (val) => val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
    },
    {
      key: 'cancelYn',
      title: '취소 여부',
      align: 'center',
      render: (val, row) => !row.canceled ? <Badge color='green' text='결제됨'/> : <Badge color='red' text='취소됨'/>
    },
    {
      key: 'purchaseDate',
      title: '결제일',
      dataIndex: 'purchaseAt',
      align: 'center',
      render: (val) => val ? parseDateTime(moment(val).unix() * 1000) : ''
    },
    {
      title: '환불처리',
      key: 'cancelPenalty',
      dataIndex: 'productId',
      align: 'center',
      render: (t, r) => {
        return (
          <Button
            disabled={r.canceled}
            onClick={() => {
              setSelectedRefundPurchase(r);
              setIsRefundModalVisible(true);
            }}
          >
            {r.product.productSubType === 'PENALTY' ? '패널티환불' : '환불'}
          </Button>
        );
      }
    }
  ];

  return (
    <>
      <div
        style={{textAlign: 'right'}}
      >
        <Button
          onClick={showRegisterModal}
          style={{marginRight: '8px'}}
        >
          상품구매
        </Button>
      </div>
      <PurchaseProduct
        isVisible={isPurchaseProductModalVisible}
        setIsVisible={setIsPurchaseProductModalVisible}
        registerModalHandleOk={registerModalHandleOk}
        registerModalHandleClose={registerModalHandleClose}
        riderId={riderId}
      />
      <RefundPurchase
        isVisible={isRefundModalVisible}
        setIsVisible={setIsRefundModalVisible}
        purchase={selectedRefundPurchase!}
        setIsRefundAction={setIsRefundAction}
      />
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={
          riderPurchase.map((r, i) => ({key: String(i + 1), ...r}))
        }
      />
    </>
  );
}
