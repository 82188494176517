import { Modal, Radio } from 'antd';
import React, { useState, VFC } from 'react';
import { putMobilityLeaseRental } from '../../mobility.service';
import { MobilityDto } from '../../mobility.interface';
import { MobilityLendType } from '../../mobility.interface';

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  scooter: MobilityDto | null;
}

const ScooterRentalModal: VFC<Props> = ({ isVisible, setIsVisible, scooter }) => {
  const [lendType, setLendType] = useState<MobilityLendType | null>();

  const okHandler = () => {
    if (scooter && scooter.scooterId && lendType) {
      putMobilityLeaseRental(scooter.scooterId, lendType, scooter.mobilityType)
        .then(e => {
          alert('성공적으로 수정되었습니다');
          window.location.reload()
        })
        .catch(l => {
          alert('수정에 실패하였습니다');
          window.location.reload()
        });
    }
  };

  return (
    <Modal
      title={`오토바이(${scooter?.shortImei}) 리스/렌탈용 변경`}
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      onOk={okHandler}
      width={'90%'}
    >
      <Radio.Group
        style={{ width: '100%' }}
        value={lendType}
        onChange={(e) => setLendType(e.target.value)}
      >
        <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value={'NONE'}>오늘은 라이더</Radio.Button>
        <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value={'LEASE'}>리스</Radio.Button>
        <Radio.Button style={{ width: '33.33%', textAlign: 'center' }} value={'RENTAL'}>렌탈</Radio.Button>
      </Radio.Group>
    </Modal>
  );
};

export default ScooterRentalModal;
