import React, {useEffect, useState} from 'react';
import {Map} from '../../../common/map/Map';
import {MapType} from '../../../common/map/Types';
import {ScooterMarkerPlugin} from '../../../common/map/plugins/ScooterMarkerPlugin';
import {MopedMarkerPlugin} from '../../../common/map/plugins/MopedMarkerPlugin';
import {PlacementMarkerPlugin} from '../../../common/map/plugins/PlacementMarkerPlugin';
import {DrawingManagerPlugin} from "../../../common/map/plugins/DrawingManagerPlugin";
import {PlacementDto} from '../placement.interface';
import {SearchPlugin} from '../../../common/map/plugins/SearchPlugin';
import {PlacementCirclePlugin} from "../../../common/map/plugins/PlacementCirclePlugin";
import {MapPluginManager} from "../../../common/map/plugins/MapPluginManager";
import {MobilityRadioValue} from "../page/PlacementMapPage";

interface Props {
  mapType: MapType,
  query: string,
  mobilityRadio: MobilityRadioValue
  placementEntityVisibilityPredicate: (placementDto: PlacementDto) => boolean,
  placementEntityVisibilityPredicate2: (placementDto: PlacementDto) => boolean,
  setIsPlacementAddModalVisible: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  mopedBatteryFilter: number,
  scooterBatteryFilter: number,
}

const PlacementMap = (props: Props) => {
  const [pluginManager] = useState(new MapPluginManager()
    .usePlugin(new ScooterMarkerPlugin())
    .usePlugin(new MopedMarkerPlugin())
    .usePlugin(new PlacementMarkerPlugin(props.mapType))
    .usePlugin(new SearchPlugin())
    .usePlugin(new PlacementCirclePlugin())
    .usePlugin(new DrawingManagerPlugin()));

  useEffect(() => {
    pluginManager.applyPlugin(PlacementMarkerPlugin,
      p => p.setVisibilityPredicate(mw =>
        props.placementEntityVisibilityPredicate(mw.data)
        && props.placementEntityVisibilityPredicate2(mw.data)
      ));

    pluginManager.applyPlugin(PlacementCirclePlugin,
      p => p.setVisibilityPredicate(cw =>
        props.placementEntityVisibilityPredicate(cw.data)
        && props.placementEntityVisibilityPredicate2(cw.data)
      ));

  }, [props.placementEntityVisibilityPredicate, props.placementEntityVisibilityPredicate2]);

  useEffect(() => {
    pluginManager.applyPlugin(SearchPlugin, s => s.search(props.query));
  }, [props.query]);

  useEffect(() => {
    pluginManager.applyPlugin(DrawingManagerPlugin, p => p.updateBatteryFilter(props.scooterBatteryFilter, props.mopedBatteryFilter));
    pluginManager.applyPlugin(ScooterMarkerPlugin, p => p.setVisibilityPredicate((e) => {
      return (
        e.data.battery <= props.scooterBatteryFilter
        && ((props.mobilityRadio === 'ALL') || (props.mobilityRadio === 'S9'))
      )
    }));
  }, [props.scooterBatteryFilter, props.mobilityRadio]);

  useEffect(() => {
    pluginManager.applyPlugin(DrawingManagerPlugin, p => p.updateBatteryFilter(props.scooterBatteryFilter, props.mopedBatteryFilter));
    pluginManager.applyPlugin(MopedMarkerPlugin,
      p => p.setVisibilityPredicate((e) => {
        return (
          e.data.battery <= props.mopedBatteryFilter
          && ((props.mobilityRadio === 'ALL')
            || (e.data.modelType === 'R1-Lite' && props.mobilityRadio === 'BS')
            || (e.data.modelType === 'NIU' && props.mobilityRadio === 'NIU')
            || (e.data.modelType === 'PCX' && props.mobilityRadio === 'PCX'))
        )
      }));

  }, [props.mopedBatteryFilter, props.mobilityRadio]);

  return (
    <Map
      mapType={props.mapType}
      plugins={pluginManager.plugins}
    />
  );
};

export default PlacementMap;
