import { Pagination } from './pagination';

export function buildUrl(baseUrl: string, queryParams?: Map<string, any>): string {
  let url = baseUrl;

  if (queryParams) {
    url += '?' + Array.from(queryParams.entries()).map(([key, val]) => key + '=' + val).join('&');
  }

  return url;
}

export function move(baseUrl: string, pagination: Pagination) {
  window.location.href = buildUrl(baseUrl, pagination.getParams());
}