import {ListenerEventData, MapUtils} from '../Utils';
import {LocationDto, TooltipType} from '../../common.interface';
import {
  CircleWrapper,
  DrawingManagerWrapper,
  MapType,
  MapWrapper,
  MarkerWrapper,
  PolygonWrapper,
  PolylineWrapper,
} from '../Types';
import {EventType, MarkerProps, Point} from '../Map';
import {PlacementDto} from "../../../domain/placement/placement.interface";

function createMap(mapElement: HTMLDivElement, level: number, center: LocationDto, mapType: MapType) {
  if (mapType !== 'KAKAO') {
    throw new Error();
  }

  const options: kakao.maps.MapOption = {
    center: new kakao.maps.LatLng(center.latitude, center.longitude),
    level: level
  };

  return new MapWrapper(new kakao.maps.Map(mapElement, options), mapElement);
}

function createMarker(target: MapWrapper, { position, image, infoWindowHTML }: MarkerProps, data: any, draggable: boolean = false) {
  const offset = image?.anchor ? new kakao.maps.Point(image.anchor.x, image.anchor.y) : undefined;
  const kakaoImage = image ? new kakao.maps.MarkerImage(image.src, new kakao.maps.Size(image.size.width, image.size.height), {offset}) : undefined;

  const options = {
    map: target.map as kakao.maps.Map,
    position: new kakao.maps.LatLng(position.latitude, position.longitude),
    zIndex: 1,
    image: kakaoImage,
    draggable: draggable,
  };
  return new MarkerWrapper(new kakao.maps.Marker(options), infoWindowHTML, data);
}

function createCircle(target: MapWrapper, placement: PlacementDto) {
  const center = placement.location;
  return new CircleWrapper(new kakao.maps.Circle({
      center: new kakao.maps.LatLng(center.latitude, center.longitude),
      map: target.map as kakao.maps.Map,
      radius: 25
  }), placement);
}

function createDrawingManager(map: MapWrapper, customDrawing?: boolean): DrawingManagerWrapper {
  if (map.mapType !== 'KAKAO') {
    throw new Error();
  }

  const controlOptions = {
    map: map.map as kakao.maps.Map,
    drawingMode: [
      kakao.maps.Drawing.OverlayType.POLYGON,
      // kakao.maps.Drawing.OverlayType.CIRCLE,
      // kakao.maps.Drawing.OverlayType.RECTANGLE,
    ],
    guideTooltip: ['draw' as TooltipType, 'edit' as TooltipType, 'drag' as TooltipType],
    markerOptions: {
      draggable: customDrawing ? customDrawing : false,
      editable: customDrawing ? customDrawing : false,
    },
    polygonOptions: {
      strokeColor: '#39f',
      fillColor: '#39f',
      fillOpacity: 0.5,
      hintStrokeStyle: 'dash',
      hintStrokeOpacity: 0.5,
      removable: true,
      editable: customDrawing ?? false,
      clickable: customDrawing ?? false,
      draggable: customDrawing ?? false,
    }
  };
  return new DrawingManagerWrapper(new kakao.maps.Drawing.DrawingManager(controlOptions));
}

function createInfoWindow(map: MapWrapper, content: string, position: LocationDto) {
  const polygonInfoWindowContent = new kakao.maps.InfoWindow({
    map: map.map as kakao.maps.Map,
    content: content,
    removable: true,
    position: new kakao.maps.LatLng(position.latitude, position.longitude),
    zIndex: 2
  });
}

function createPolygon(map: MapWrapper, paths: LocationDto[]): PolygonWrapper {
  const polygon = new kakao.maps.Polygon({
    map: map.map as kakao.maps.Map,
    strokeWeight: 2,
    strokeColor: '#0732cb',
    strokeOpacity: 0.5,
    fillColor: '#00EEEE',
    fillOpacity: 0.3,
    path: paths.map(e => new kakao.maps.LatLng(e.latitude, e.longitude)),
    draggable: true,
  });

  return new PolygonWrapper(polygon, paths);
}

function createPolyline(map: MapWrapper, paths: LocationDto[]): PolylineWrapper {
  const options = {
    path: paths.map(e => new kakao.maps.LatLng(e.latitude, e.longitude)),
    strokeWeight: 3,
    strokeColor: '#FA6800',
    strokeOpacity: 1,
    strokeStyle: 'solid',
  }

  return new PolylineWrapper(new kakao.maps.Polyline(options));
}

function registerDrawingManager(map: MapWrapper, manager: DrawingManagerWrapper) {
  if (map.mapType !== 'KAKAO') {
    throw new Error();
  }

  manager.setMap(map);
}

function addListener(
  target: MapWrapper | MarkerWrapper | DrawingManagerWrapper | PolygonWrapper,
  event: EventType | keyof kakao.maps.EventHandlerMap | string,
  handler: (event?: ListenerEventData) => void) {
  if (target.mapType !== 'KAKAO') {
    throw new Error();
  }

  kakao.maps.event.addListener(
    target.target as kakao.maps.Map | kakao.maps.Marker | kakao.maps.Drawing.DrawingManager | kakao.maps.Polygon,
    event,
    handlerParser(handler),
  );
}

function setMap(target: MarkerWrapper | CircleWrapper | PolygonWrapper, map: MapWrapper | null) {
  if (target.mapType !== 'KAKAO' || (map && map.mapType !== 'KAKAO')) {
    throw new Error();
  }
  (target.target as kakao.maps.Marker | kakao.maps.Circle | kakao.maps.Polygon).setMap(map?.map as kakao.maps.Map);
}

function handlerParser(handler: (event?: ListenerEventData) => void) {
  return (e: kakao.maps.MouseEvent) => {
    if (e) {
      if (e.target) {
        const polygonCoords: LocationDto[] = [];
        e.target.getPath().forEach(l => {
          const { La, Ma } = new kakao.maps.Coords(l.La, l.Ma).toLatLng();
          polygonCoords.push({
            latitude: Ma,
            longitude: La,
          });
        });
        handler({
          location: polygonCoords
        });
      } else {
        handler({
          location: {
            latitude: e.latLng.Ma,
            longitude: e.latLng.La
          },
        });
      }
    } else {
      handler();
    }
  };
}

function setDraggable(target: MapWrapper, isDraggable: boolean) {
  const map = target.map as kakao.maps.Map;
  map.setDraggable(isDraggable);
}

function pointToLocation(map: MapWrapper, point: Point): LocationDto {
  const kMap = map.map as kakao.maps.Map;

  const projection = kMap.getProjection()!;
  const {ha, oa, pa, qa} = kMap.getBounds();

  console.log(qa, pa, ha, oa);

  const {y} = projection.pointFromCoords(new kakao.maps.LatLng(pa, oa));
  const {x} = projection.pointFromCoords(new kakao.maps.LatLng(qa, ha));

  console.log(x, y);

  const scale = 1 << kMap.getLevel();
  const location = projection.coordsFromPoint(new kakao.maps.Point(point.x / scale + x, point.y / scale + y));
  return {
    latitude: location.Ma,
    longitude: location.La,
  };
}

export const utils: MapUtils = {
  createMap,
  createMarker,
  createCircle,
  createDrawingManager,
  createInfoWindow,
  createPolygon,
  createPolyline,
  registerDrawingManager,
  addListener,
  setMap,
  setDraggable,
  pointToLocation
};

// const defaultGuOptions = {
//   strokeWeight: 2,
//   strokeColor: '#004c80',
//   strokeOpacity: 0.8,
//   fillColor: '#e3d1d1',
//   fillOpacity: 0.7
// };