import {LocalAdminApi} from "../../services";
import {BandTariffDto, TariffDto} from "./tariff.interface";

export async function getAllTariffs(): Promise<TariffDto[]> {
  return await LocalAdminApi.get("/tariffs").then((resp) => resp.data);
}

export async function getAllBandTariffs(): Promise<BandTariffDto[]> {
  return await LocalAdminApi.get("/tariff-band").then((resp) => resp.data);
}
