import React, {useEffect, useState} from 'react';
import {AddBandModal} from '../modal/AddBandModal';
import {getBands, putBandDisplayYn, updateBandLicenseCheckStatus, putBandProperty} from '../band.service';
import useModalHandler from '../../../hooks/useModalHandler';
import BaseTable from '../../../common/component/BaseTable';
import {useValidation} from '../../../hooks/useValidation';
import {getBandTag} from '../../../utils/organizeData';

import {Button, Switch} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {BandDto} from '../band.interface';
import {columnMaker, columnRenderer, columnWithChildren} from "../../../utils/componentUtils";

const BandPage = () => {
  const [isVisibleAddModal, onChangeAddModalHandler] = useModalHandler(false);
  const checkToken = useValidation;
  const [bandList, setBandList] = useState<BandDto[]>([]);

  useEffect(() => {
    bootstrap();
  }, []);

  checkToken();

  async function bootstrap() {
    await getBands().then(setBandList);
  }

  const handleSwitchChange = (val: boolean, domain: string, row: BandDto) => {
    if (window.confirm('정말 수정하시겠습니까?')) {
      switch (domain) {
        case 'display':
          putBandProperty(row.bandId, 'display', {displayYn: val}).then(e => {
            alert('성공적으로 수정되었습니다');
            window.location.reload();
          });
          break;
        case 'penalty':
          putBandProperty(row.bandId, 'penalty', {penaltyYn: val}).then(e => {
            alert('성공적으로 수정되었습니다');
            window.location.reload();
          });
          break;
        case 'ondemand':
          putBandProperty(row.bandId, 'ondemand', {ondemandYn: val}).then(e => {
            alert('성공적으로 수정되었습니다');
            window.location.reload();
          });
          break;
        case 'waitable-sec':
          putBandProperty(row.bandId, 'waitable-sec', {waitableSec: val}).then(e => {
            alert('성공적으로 수정되었습니다');
            window.location.reload();
          });
          break;
      }
    }
  };

  const bandLicenseCheckHandler = (e: any, mobilityType: string, bandId: number, scooterStatus: boolean, mopedStatus: boolean) => {
    let newScooterStatus = true;
    let newMopedStatus = true;

    if (mobilityType === 'S') {
      newScooterStatus = e;
      newMopedStatus = mopedStatus;
    } else {
      newScooterStatus = scooterStatus;
      newMopedStatus = e;
    }
    if (window.confirm("정말 수정 하시겠습니까?")) {
      updateBandLicenseCheckStatus(bandId, newScooterStatus, newMopedStatus)
        .then(e => {
          alert('업데이트 되었습니다');
          window.location.reload();
        })
        .catch((err) => {
          alert('업데이트에 실패하였습니다');
          window.location.reload();
        });
    } else {
      window.location.reload()
    }
  };

  const columns: ColumnsType<BandDto> = [
    columnMaker('name', '밴드명', columnRenderer((data, idx) => getBandTag(data.bandName, idx))),
    columnMaker('countOfRiders', '라이더 수', columnRenderer(data => data.statistics.riderCount)),
    columnMaker('countOfPlacements', '라운지 / 픽업존 수', columnRenderer(data => data.statistics.placementCount)),
    columnMaker('countOfScooters', '스쿠터 개수', columnRenderer(data => data.statistics.scooterCount)),
    columnMaker('countOfMopeds', '오토바이 개수', columnRenderer(data => data.statistics.mopedCount)),
    columnMaker('waitableSec', '잠금대기시간', columnRenderer(data => data.waitableSec / 60 + '분')),
    columnWithChildren(
      'licenseCheckStatus',
      '면허인증 상태',
      [
        columnMaker('forScooter', '스쿠터', columnRenderer(data => (
          <Switch
            checkedChildren='검사중'
            unCheckedChildren='미검사'
            checked={data.licenseMode.scooter}
            onChange={(e) => bandLicenseCheckHandler(e, 'S', data.bandId, data.licenseMode.scooter, data.licenseMode.moped)}
          />
        ))),
        columnMaker('forMoped', '오토바이', columnRenderer(data => (
          <Switch
            checkedChildren='검사중'
            unCheckedChildren='미검사'
            checked={data.licenseMode.moped}
            onChange={(e) => bandLicenseCheckHandler(e, 'M', data.bandId, data.licenseMode.scooter, data.licenseMode.moped)}
          />
        ))),
      ]
    ),
    columnMaker('action', '온/오프라인', columnRenderer(data => (
      <Switch
        checkedChildren='온라인'
        unCheckedChildren='오프라인'
        defaultChecked={data.displayYn}
        onChange={(e) => handleSwitchChange(e, 'display', data)}
      />
    ))),
    columnMaker('penaltyYn', '패널티 적용 여부', columnRenderer(data => (
      <Switch
        checkedChildren='적용'
        unCheckedChildren='미적용'
        defaultChecked={data.penaltyYn}
        onChange={(e) => handleSwitchChange(e, 'penalty', data)}
      />
    ))),
    columnMaker('ondemandYn', '후불 요금제 적용 여부', columnRenderer(data => (
      <Switch
        checkedChildren='적용'
        unCheckedChildren='미적용'
        defaultChecked={data.ondemandYn}
        onChange={(e) => handleSwitchChange(e, 'ondemand', data)}
      />
    ))),
  ];

  return (
    <>
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h2>{`밴드(${bandList.length})`}</h2>
          <Button onClick={onChangeAddModalHandler}>밴드 추가하기</Button>
        </div>
        <BaseTable
          columnType={columns}
          datasource={bandList.map((ele, idx) => (
              {...ele, key: `band-table-${idx}`}
            )
          )}
        />
        <AddBandModal
          isModalVisible={isVisibleAddModal}
          handleOk={onChangeAddModalHandler}
          handleCancel={onChangeAddModalHandler}
        />
      </div>
    </>
  );
};

export default BandPage;
