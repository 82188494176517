import {LocalAdminApi} from "../../services";
import {SimpleSubscription, AddSubscriptionForm} from "./subscription.interface";

export async function getAllSubscriptions(): Promise<SimpleSubscription[]> {
  return await LocalAdminApi.get("/subscriptions").then((resp) => resp.data);
}

export async function getSubscriptionsPagination(page: number, size: number): Promise<{
  page: number,
  totalPage: number,
  totalItem: number,
  items: SimpleSubscription[]
}> {
  return await LocalAdminApi.get(`/subscriptions?page=${page}&size=${size}`).then((resp) => resp.data);
}

// 특정 subscription 가져오기
export async function getSubscription(id: number) {
  return await LocalAdminApi.get<SimpleSubscription>(`/subscriptions/${id}`).then((resp) => resp.data);
}

// subscription 기간 연장하기 가져오기
export async function updateSubscriptionPeriodEvent(id: number, extendDay: { extendDays: number }) {
  return await LocalAdminApi.put<SimpleSubscription>(`/subscriptions/${id}/event`, extendDay).then((resp) => resp);
}

export async function postSubscription(data: AddSubscriptionForm) {
  return await LocalAdminApi.post(`/subscriptions`, data).then(resp => resp.data)
}
