import React, {useContext, useEffect, useState} from 'react';
import {Classes} from "reactcss";
import {getBands} from '../../../band/band.service';
import {getBannerByBandId} from '../../banner.service'
import {AccountContext} from '../../../../context';
import {DoubleRightOutlined, PauseOutlined} from "@ant-design/icons";
import {Card, Carousel, Col, Image, Row} from "antd";
import {SimpleBanner} from "../../banner.interface";
import BandOptionRadio from "../../../../common/component/BandOptionRadio";

const {Meta} = Card;
type bannerViewMode = "CAROUSEL" | "CARD";

const BannerPreviewTab = () => {

  const admin = useContext(AccountContext);
  const bandId = useContext(AccountContext)!.band.bandId;

  const [viewMode, setViewMode] = useState<bannerViewMode>("CAROUSEL")
  const [bandBanners, setBandBanners] = useState<SimpleBanner[]>([])
  const [bands, setBands] = useState<{ id: number, name: string }[]>([])
  const [selectedBand, setSelectedBand] = useState<string>(bandId.toString());

  useEffect(() => {
    getBands().then(e => {
      setBands(e.map(e => {
        return {
          id: e.bandId,
          name: e.bandName
        }
      }))
    })
  }, [])

  useEffect(() => {
    if (selectedBand) {
      getBannerByBandId(selectedBand!).then(e => {
        setBandBanners(e);
      })
    }
  }, [selectedBand])

  const carouselImages = [];
  const cardImages = [];

  for (let i = 0; i < bandBanners.length; i++) {
    carouselImages.push((
      <div key={"carousel-image-" + i}>
        <img src={bandBanners[i].imageUrl} style={{margin: '0 auto', width: '30%'}}/>
      </div>
    ));
    cardImages.push((
      <div key={"card-image-" + i} style={{textAlign: 'center', marginBottom: '10px'}}>
        <img src={bandBanners[i].imageUrl} style={{margin: '0 auto', width: '30%'}}/>
      </div>
    ));
  }

  return (
    <Card
      title={"베너 Preview"}
      actions={[
        <DoubleRightOutlined onClick={() => setViewMode("CAROUSEL")}/>,
        <PauseOutlined onClick={() => setViewMode("CARD")}/>
      ]}
    >
      <Row gutter={[4, 16]}>
        <Col span={24}>
          <BandOptionRadio selectedBand={selectedBand} setSelectedBand={setSelectedBand} isAll={false}/>
        </Col>
        <Col span={24}>
          {
            viewMode === "CAROUSEL"
              ? <Carousel autoplay={true}>{carouselImages}</Carousel>
              : (<Image.PreviewGroup>{cardImages}</Image.PreviewGroup>)
          }
        </Col>
        <Meta
          style={{margin: '0 auto'}}
          description={viewMode === "CAROUSEL" ? "어플리케이션에서 위처럼 보입니다." : "배너 순서 입니다"}
        />
      </Row>
    </Card>
  )
    ;
};

export default BannerPreviewTab;