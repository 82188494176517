import React, { useEffect, useState, VFC } from 'react';
import { Button, Col, Descriptions, Image, List, Modal, Row } from 'antd';
import { getDeployableMobilityTag } from '../../../utils/organizeData';
import { getPlacementMobilities } from '../placement.service';
import { MobilityDto } from '../../mobility/mobility.interface';
import { PlacementDto } from '../placement.interface';
import { AddEditPlacementModal } from './AddEditPlacementModal';
import { MapType } from '../../../common/map/Types';

type Props = {
  mapType: MapType
  isVisible: boolean
  placement: PlacementDto | null
  handleClose: () => void
  editModalHandler: React.Dispatch<React.SetStateAction<boolean>>
};

const { Item } = Descriptions;

export const PlacementDetailModal: VFC<Props> = (
  {
    mapType,
    isVisible,
    handleClose,
    placement
  }) => {

  const [scooters, setScooters] = useState<MobilityDto[]>([]);
  const [mopeds, setMopeds] = useState<MobilityDto[]>([]);

  const scooterCount = placement?.placementCount.scooters;
  const mopedCount = placement?.placementCount.mopeds;

  useEffect(() => {
    if (placement) {
      getPlacementMobilities(placement.placementId).then(mo => {
        setScooters(mo.filter(e => e.mobilityType === 'SCOOTER'));
        setMopeds(mo.filter(e => e.mobilityType === 'MOPED'));
      });
    }
  }, [placement]);

  const [isAddEditPlacementModalVisible, setIsAddEditPlacementModalVisible] = useState(false);

  if (!placement) {
    return <></>;
  }

  return (
    <Modal
      visible={isVisible}
      onCancel={handleClose}
      width={1200}
      footer={[
        <Button key={'edit-button'} onClick={() => setIsAddEditPlacementModalVisible(true)}>수정</Button>,
        <Button key={'close-button'} onClick={handleClose}>닫기</Button>
      ]}
    >
      <AddEditPlacementModal
        mapType={mapType}
        isVisible={isAddEditPlacementModalVisible}
        setIsPlacementAddModalVisible={setIsAddEditPlacementModalVisible}
        editPlacement={placement}
      />
      <Row align={'middle'} gutter={[4, 4]}>
        <PlacementImage placement={placement} />
        <Col span={14}>
          <Descriptions
            title={placement.placementName}
            layout='vertical'
            bordered column={2}
          >
            <Item span={1} label='배치 가능 모빌리티'>
              {getDeployableMobilityTag(placement.deployableMobilityType)}
            </Item>
            <Item span={1} label='배치 가능 댓수'>
              {placement.deployableCount}
            </Item>
            <Item span={1} label='킥보드 수'>
              {scooterCount}
            </Item>
            <Item span={1} label='오토바이 수'>
              {mopedCount}
            </Item>
            <Item span={1} label='배치된 킥보드'>
              <ScooterList scooters={scooters} />
            </Item>
            <Item span={1} label='배치된 오토바이'>
              <MopedList mopeds={mopeds} />
            </Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>
  );
};

function PlacementImage(props: { placement: PlacementDto }) {
  const { placement } = props;
  return <Col span={10}>
    <Descriptions title={placement.placementName} layout='vertical' bordered column={2}>
      <Item>
        <Image src={placement.imageFileName} />
      </Item>
    </Descriptions>
  </Col>;
}

function ScooterList(props: { scooters: MobilityDto[] }) {
  const { scooters } = props;
  return <List
    itemLayout='horizontal'
    dataSource={scooters.map((ele,idx) => ({...ele,key:idx}))}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          title={item.shortImei}
          description={`배터리 : ${item.battery}%`}
        />
      </List.Item>
    )}
  />;
}

function MopedList(props: { mopeds: MobilityDto[] }) {
  const { mopeds } = props;
  return <List
    itemLayout='horizontal'
    dataSource={mopeds.map((ele,idx) => ({...ele,key:idx}))}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          title={item.shortImei}
          description={`배터리 : ${item.battery}%`}
        />
      </List.Item>
    )}
  />;
}