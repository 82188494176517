import axios from 'axios';
import {API_URL} from '../constants';

export const LocalAdminApi = axios.create({
  baseURL: API_URL,
});

interface IErrorResponse {
  status: number
  code: string,
  message: string
}

// LocalAdminApi.interceptors.response.use((response) => response, (error) => {
//   const errorResponse = error.response.data as IErrorResponse
//   alert(errorResponse.message)
//   return Promise.reject(error)
// })
