import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { SearchConditions } from '../../pages/LeaseRentalConsultList';

const { RangePicker } = DatePicker;

interface Props {
  setSearchConditions: React.Dispatch<React.SetStateAction<SearchConditions>>,
}

function LeaseRentalConsultRangePicker({ setSearchConditions }: Props) {

  function handleSalesPeriod(e: any) {
    const from = e[0].format('YYYY-MM-DD');
    const to = e[1].format('YYYY-MM-DD');
    setSearchConditions(prev => ({ ...prev, consultPeriod: [from, to] }));
  }

  function periodPreset(): { [key: string]: [moment.Moment, moment.Moment] } {
    const today = moment();
    const yesterday = today.clone().subtract(1, 'days');
    const lastMonth = today.clone().subtract(1, 'month');

    return {
      '오늘': [today.clone().startOf('days'), today],
      '어제': [yesterday.clone().startOf('days'), yesterday.clone().endOf('days')],
      '이번주': [today.clone().startOf('week'), today],
      '이번달': [today.clone().startOf('month'), today],
      '지난달': [lastMonth.clone().startOf('month'), lastMonth.clone().endOf('month')],
      '전체': [moment('2022-01-01'), today]
    };
  }

  return (
    <RangePicker
      style={{width:'100%'}}
      allowClear={false}
      disabledDate={cur => cur && cur > moment().endOf('days')}
      ranges={periodPreset()}
      onChange={handleSalesPeriod}
    />
  );
}

export default LeaseRentalConsultRangePicker;