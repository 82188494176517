import React, {useEffect, useState} from 'react';
import BaseModal from '../../../../common/component/BaseModal';
import {Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Switch} from 'antd';
import moment from "moment";
import {addBandProduct, getAllMasterProducts, updateBandProduct} from "../../product.service";
import {ProductBandDto, ProductDto} from "../../product.interface";
import {BandSimpleDto} from "../../../band/band.interface";
import {getBands} from "../../../band/band.service";

const {Option} = Select;

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  masterProducts: ProductDto[];
  editBandProduct?: ProductBandDto | null;
}

const AddBandProductModal = ({isVisible, masterProducts, setIsVisible, editBandProduct}: Props) => {

  const [products, setProducts] = useState<ProductDto[]>([]);
  const [bands, setBands] = useState<BandSimpleDto[]>([])

  useEffect(() => {
    getAllMasterProducts().then(setProducts);
    getBands().then(setBands);
  }, [])


  const [formValues, setFormValues] = useState<ProductDto | null>(null);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState<{
    period: number,
    price: number,
    type: string,
    subType: string,
  } | null>(null);
  const [formInstance] = Form.useForm();

  useEffect(() => {
    if (formInstance && editBandProduct) {
      formInstance.setFieldsValue(editBandProduct);
      for (const content of masterProducts) {
        if (content.productId === editBandProduct.baseProduct.productId) {
          setSelectedMasterProduct({
            price: content.price,
            type: content.productType === 'SUBSCRIPTION' ? '정액제' : '종량제',
            subType: content.productSubType === 'PASS' ? '이용권' : '패널티',
            period: content.period
          });
        }
      }
    }
  }, [editBandProduct]);

  const {RangePicker} = DatePicker;

  const submitModal = async () => {
    if (formValues) { // 변경이 있는 경우
      formInstance.submit();
    } else { // 변경이 없는 경우
      setIsVisible(false);
    }
  }

  const onSubmitForm = async (formValues: ProductDto) => {

    const {salesPeriod} = formInstance.getFieldsValue(['salesPeriod']);
    const [startForm, endForm] = salesPeriod;

    const saleStartAt = moment(startForm).toISOString();
    const saleEndAt = moment(endForm).toISOString() ?? "9000-12-31T23:59:59";

    formValues.saleStartAt = saleStartAt;
    formValues.saleEndAt = saleEndAt;

    if (editBandProduct) {
      if (window.confirm("정말 수정하시겠습니까?")) {
        try {
          await updateBandProduct(editBandProduct.baseProduct.productId, formValues).then(e => {
            alert("성공적으로 수정 되었습니다");
            window.location.reload();
          })
        } catch (err) {
          alert("수정에 실패하였습니다 : " + err);
          window.location.reload();
        }
      }
    } else {
      if (window.confirm("정말 등록하시겠습니까?")) {
        try {
          await addBandProduct(formValues).then(e => {
            alert("성공적으로 등록 되었습니다");
            window.location.reload();
          });
        } catch (err) {
          alert("등록에 실패하였습니다 : " + err);
          window.location.reload();
        }
      }
    }
  }

  const handleProductSelectChange = (value: any) => {
    for (const content of masterProducts) {
      if (content.productId === value) {
        setSelectedMasterProduct({
          price: content.price,
          type: content.productType === 'SUBSCRIPTION' ? '정액제' : '종량제',
          subType: content.productSubType === 'PASS' ? '이용권' : '패널티',
          period: content.period
        });
      }
    }
  }

  const masterProductOptions = products.map((e, i) => {
    return (
      <Option
        key={'masterProductOption' + i}
        value={e.productId!}
        label={e.title}
      >
        {e.title + " / " + e.price + ' / ' + e.productId}
      </Option>
    )
  });

  const bandOptions = bands.map((e, i) => {
    return (
      <Option
        key={'band-option-' + i}
        value={e.bandId}
      >
        {e.bandName}
      </Option>
    )
  })

  return (
    <BaseModal
      isVisible={isVisible}
      handleOk={submitModal}
      handleCancel={() => setIsVisible(false)}
      headerTitle={'상품추가'}
      width={'70%'}
    >
      <Form
        name="productForm"
        onValuesChange={(changedVal, allVal) => {
          setFormValues(allVal);
        }}
        form={formInstance}
        onFinish={onSubmitForm}
        initialValues={{
          displaying: false
        }}
      >
        <Row gutter={[32, 32]}
             justify={'center'}
        >
          <Col span={24}>
            <Form.Item
              style={{marginBottom: '0'}}
              name="bandId"
              required={true}
            >
              <Select
                style={{width: '100%'}}
                placeholder="밴드를 선택해주세요"
                optionLabelProp="label"
                disabled={!!editBandProduct}
              >
                {bandOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{marginBottom: '0'}}
              name="productId"
              required={true}
            >
              <Select
                style={{width: '100%'}}
                placeholder="마스터 상품을 선택해주세요"
                onChange={handleProductSelectChange}
                optionLabelProp="label"
                disabled={!!editBandProduct}
              >
                {masterProductOptions}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Input
              disabled={true}
              addonBefore={"이용기간"}
              value={selectedMasterProduct ? selectedMasterProduct.period : ''}
            />
          </Col>
          <Col span={12}>
            <Input
              disabled={true}
              addonBefore={"마스터 가격"}
              value={selectedMasterProduct ? selectedMasterProduct.price : ''}
            />
          </Col>
          <Col span={12}>
            <Input
              disabled={true}
              addonBefore={"상품 종류"}
              value={selectedMasterProduct ? selectedMasterProduct.type : ''}
            />
          </Col>
          <Col span={12}>
            <Input
              disabled={true}
              addonBefore={"상품 타입"}
              value={selectedMasterProduct ? selectedMasterProduct.subType : ''}
            />
          </Col>

          <Col span={10}>
            <Form.Item
              style={{marginBottom: '0'}}
              label="상품가격"
              name="price"
              required={true}
            >
              <InputNumber style={{width: '100%'}}/>
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              name="mobilityCode"
              label="기기를 선택"
              required={true}
            >
              <Radio.Group
                style={{width: '100%'}}
              >
                <Radio.Button
                  style={{width: '50%', textAlign: 'center'}}
                  value="ST">킥보드</Radio.Button>
                <Radio.Button
                  style={{width: '50%', textAlign: 'center'}}
                  value="MC">오토바이</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              style={{marginBottom: '0'}}
              name="displaying"
              label="노출여부"
              valuePropName="checked"
              required={true}
            >
              <Switch/>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="salesPeriod"
              label="판매기간"
              valuePropName="checked"
              required={true}
            >
              <RangePicker
                style={{width: '100%'}}
                allowEmpty={[false, true]}
                placeholder={['시작일', '마감일']}
                format="YYYY-MM-DD"
                defaultValue={
                  editBandProduct ? [moment(editBandProduct.saleStartAt), moment(editBandProduct.saleEndAt)] :
                    undefined
                }
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </BaseModal>
  );
};

export default AddBandProductModal;
