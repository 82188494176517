import React, {FC, useEffect, useState} from 'react';
import {Row, Col, Input, InputNumber, Form,} from 'antd';
import BaseModal from '../../../common/component/BaseModal';
import {refundPurchase} from "../../../domain/purchase/purchase.service";
import {PurchaseDto} from "../../../domain/purchase/purchase.interface";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  purchase: PurchaseDto;
  setIsRefundAction: React.Dispatch<React.SetStateAction<boolean>>
}

const RefundPurchase: FC<Props> = ({isVisible, setIsVisible, purchase, setIsRefundAction}) => {
  const [form] = Form.useForm();

  const submitModal = async () => {
    if (purchase && window.confirm("정말 환불 하시겠습니까?")) {
      const refundReason = form.getFieldValue('refundReason');
      try {
        await refundPurchase(purchase.purchaseId, purchase.purchaseAmount, refundReason)
          .then(e => {
            alert("성공적으로 환불 되었습니다");
            setIsRefundAction(prev => !prev);
            setIsVisible(false);
          })
      } catch (err) {
        alert("환불에 실패하였습니다");
      }
    }
  }

  useEffect(() => {
    if (purchase) {
      form.setFieldsValue(purchase)
    }
  }, [purchase]);

  return (
    <BaseModal
      isVisible={isVisible}
      headerTitle={'환불'}
      handleOk={submitModal}
      handleCancel={() => setIsVisible(false)}
      width={'70%'}
    >
      <Form
        form={form}
        initialValues={purchase}
      >
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item name={'purchaseId'}>
              <Input
                addonBefore={"구매 ID"}
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'purchaseAmount'}>
              <Input
                addonBefore={"구매 금액"}
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'purchaseAmount'}>
              <InputNumber
                disabled={true}
                style={{width: '100%'}}
                placeholder={"환불금액을 입력해주세요"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'refundReason'}>
              <Input
                placeholder={"환불 사유를 입력해주세요"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
};

export default RefundPurchase;
