import React, { useContext, useEffect, useState } from 'react';
import { SetLoadingContext } from '../../../context';
import { getRiderNonpayment } from '../rider.service';
import {NonPaymentDto} from "../../ondemand/ondemand.interface";
import {NonPaymentListTable} from "../../ondemand/components/NonPaymentListTable";

export function RiderNonpaymentTable({ riderId }: { riderId: string }) {

  const setLoading = useContext(SetLoadingContext)!;

  const [riderNonpayments, setRiderNonpayments] = useState<NonPaymentDto[]>([]);

  useEffect(() => {
    setLoading(true);
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getRiderNonpayment(riderId)
      .then(setRiderNonpayments)
      .then(() => setLoading(false));
  }

  return (
    <NonPaymentListTable
      nonPaymentList={riderNonpayments}
    />
  );
}
