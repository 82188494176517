import React, { useContext, useState, VFC } from 'react';
import { Button, Form, Image, Input, Modal } from 'antd';
import useInput from '../../../../hooks/useInput';
import { addScooter } from '../../../../services/scoot';
import { AccountContext } from '../../../../context';
import ADD_SCOOTER_IMAGE_EXAMPLE from '../../../../images/ADD_SCOOTER.png';

type Props = {
  open: boolean;
  openAndCloseModalHandler: () => void;
};

const AddScootModal: VFC<Props> = ({ open, openAndCloseModalHandler }) => {

  const [scooterInput, onChangeScooterInput, setScooterInput] = useInput('');
  const [failedScooter, setFailedScooter] = useState<string[]>([]);
  const account = useContext(AccountContext)!;
  const bandId = account.band.bandId;

  const handleOk = async () => {
    const scooterArray = scooterInput.split(' ');
    const failed: string[] = [];
    if (bandId) {
      for (const scooter of scooterArray) {
        const body = { shortImei: scooter, bandId };
        await addScooter(body).catch(() => {
          failed.push(scooter);
        });
      }
      if (failed.length > 0) {
        alert(`${failed.length}개를 추가하지 못했습니다.`);
        setFailedScooter(failed);
        return;
      }
      alert('정상적으로 등록되었습니다 \n 페이지 새로고침 후 확인해주세요');
      openAndCloseModalHandler();
    } else {
      alert('밴드 아이디가 없습니다. 로그아웃 후 시작해주세요.');
    }
  };

  const handleCancel = () => {
    openAndCloseModalHandler();
    // 초기화
    setScooterInput('');
    setFailedScooter([]);
  };
  const { Item } = Form;
  return (
    <Modal
      title='스쿠터 추가'
      footer={
        <Button onClick={handleOk}>
          추가하기
        </Button>
      }
      visible={open}
      onCancel={handleCancel}
    >
      <Form>
        <Item label={'추가 할 스쿠터(shortImei)'}>
          <Input
            onChange={onChangeScooterInput}
            value={scooterInput}
            placeholder={'shortImei를 입력해주세요 ex) BH1398'}
          />
        </Item>
        {failedScooter.length > 0 ? (
          <Item label={'실패한 스쿠터 목록'}>
            <Input value={failedScooter.join(' ')} disabled />
          </Item>
        ) : null}

        <div>
          <h2>추가방법</h2>
          <div>1. 스쿠터를 추가 할 밴드에 로그인한다.</div>
          <br />
          <div style={{ letterSpacing: 0.4, lineHeight: 1.5 }}>
            2. 추가 할 스쿠터의 shortImei를 입력창에 넣어준다.
            <br /> <strong>( 추가할 스쿠터의 개수가 2개이상일 경우 꼭 띄어쓰기로 구분해야됩니다! )</strong>
          </div>
          <br />
          <div>3. 스쿠터가 등록되지 않을 경우 해당 스쿠터가 이미 등록 되었을 경우가 있습니다.</div>
        </div>
        <br />
        <div>
          <h3>예시</h3>
          <Image src={ADD_SCOOTER_IMAGE_EXAMPLE} />
        </div>
      </Form>
    </Modal>
  );
};

export default AddScootModal;
