import React, {useEffect, useState} from 'react';
import {Card, Modal, Radio} from 'antd';
import {CommonMapProps, Map} from './Map';
import {PlacementDto} from '../../domain/placement/placement.interface';
import {PlacementMarkerPlugin} from "./plugins/PlacementMarkerPlugin";
import {MopedMarkerPlugin} from "./plugins/MopedMarkerPlugin";
import {ScooterMarkerPlugin} from "./plugins/ScooterMarkerPlugin";
import {MobilityDto} from "../../domain/mobility/mobility.interface";
import {MapType} from "./Types";
import {getIdentifier} from "./Utils";
import {MapPluginManager} from "./plugins/MapPluginManager";

interface ModalMapProps extends CommonMapProps {
  mapType?: MapType,
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  selectedDomain: MobilityDto | PlacementDto | null,
}

export const ModalMap = (props: ModalMapProps) => {
  const [mapType, setMapType] = useState<MapType>('KAKAO');

  const [pluginManager] = useState(new MapPluginManager()
    .usePlugin(new PlacementMarkerPlugin(mapType))
    .usePlugin(new MopedMarkerPlugin())
    .usePlugin(new ScooterMarkerPlugin()));

  useEffect(() => {
    const selectedId = props.selectedDomain ? getIdentifier(props.selectedDomain) : null;
    const predicate = (p: MopedMarkerPlugin | ScooterMarkerPlugin) => p.setVisibilityPredicate(mw => getIdentifier(mw.data) === selectedId)
    pluginManager.applyPlugin(MopedMarkerPlugin, predicate);
    pluginManager.applyPlugin(ScooterMarkerPlugin, predicate);
  }, [props.selectedDomain]);

  const handleMapTypeChange = () => {
    const newMapType = mapType === 'GOOGLE' ? 'KAKAO' : 'GOOGLE';
    pluginManager.applyPlugin(PlacementMarkerPlugin, p => p.setMapType(newMapType));
    setMapType(newMapType);
  };

  return (
    <Modal
      visible={props.isVisible}
      onOk={() => props.setIsVisible(false)}
      onCancel={() => props.setIsVisible(false)}
      width={'90%'}
      okText={'확인'}
      cancelText={'취소'}
    >
      <Card
        title={'기기 및 픽업존 위치 지도'}
        extra={<>
          <Radio.Group
            onChange={handleMapTypeChange}
            value={mapType}
          >
            <Radio.Button value={'GOOGLE'}>구글</Radio.Button>
            <Radio.Button value={'KAKAO'}>카카오</Radio.Button>
          </Radio.Group>
        </>}
      >
        <Map
          mapType={mapType}
          plugins={pluginManager.plugins}
          center={props.selectedDomain?.location}
          level={1}
        />
      </Card>
    </Modal>
  );
};