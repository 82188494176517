import React, {useState,} from 'react';
import Table, {ColumnsType} from 'antd/lib/table';
import {getBandTag, parseDateTime, parsePhoneNumber} from "../../../utils/organizeData";
import moment from "moment";
import {getSubscription} from "../subscription.service";
import SubscriptionDetailModal from './SubscriptionDetailModal'
import NameALink from "../../../common/component/NameALink";
import {DEFAULT_TABLE_SIZE} from "../../../constants";
import {SimpleSubscription} from "../subscription.interface";
import {PaginationReqDto} from "../../../common/common.interface";
import {useTranslation} from "react-i18next";
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";
import PhoneNumber from "../../../common/component/PhoneNumber";

interface Props {
  subscriptions: SimpleSubscription[],
  pagination: PaginationReqDto,
  paginationRes: { page: number, totalPage: number, totalItem: number }
}

export function SubscriptionHistoryTable({subscriptions, pagination, paginationRes}: Props) {

  const {t} = useTranslation();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [clickedSubscription, setClickedSubscription] = useState<SimpleSubscription | null>(null);

  const modalCloseOpenHandler = () => {
    setIsVisibleModal((prevState) => !prevState);
  };

  const onSubscriptionIdClick = (id: number) => async () => {
    await getSubscription(id).then(setClickedSubscription);
    modalCloseOpenHandler();
  };

  const riderColumn: ColumnsType<SimpleSubscription> = [
    columnMaker('id', t('side-bar-rider-subscription'),
      columnRenderer(data => <a onClick={onSubscriptionIdClick(data.subscriptionId)}>{data.subscriptionId}</a>)
    ),
    columnMaker('riderName', t('common-name'),
      columnRenderer(data => <NameALink urlVal={data.rider.riderId} textVal={data.rider.riderName}/>)
    ),
    columnMaker('phoneNo', t('common-phoneNumber'),
      columnRenderer(data => <PhoneNumber textVal={data.rider.phoneNo}/>)),
    columnMaker('bandName', t('common-band'), columnRenderer((data, idx) => getBandTag(data.rider.band.bandName, idx))),
    columnMaker('productName', t('common-product-name'), columnRenderer(data => data.product.productName)),
    columnMaker('startAt', t('purchaseDate'),
      columnRenderer(data => parseDateTime(moment(data.startAt).unix() * 1000))
    ),
    columnMaker('nextAt', t('nextPurchaseDate'),
      columnRenderer(data => data.nextAt ? parseDateTime(moment(data.nextAt).unix() * 1000) : '-')
    ),
    columnMaker('cancelAt', t('subscriptionYn'),
      columnRenderer(data => data.cancelAt ? t('subscriptionCancel') : t('subscriptionProgress'))
    ),
  ];

  const handleTableChange = (pgn: any) => {
    window.location.href = `/admin/subscription-history?page=${pgn.current - 1}&size=${DEFAULT_TABLE_SIZE}`
  }

  return (
    <>
      <Table
        columns={riderColumn}
        bordered={true}
        dataSource={
          subscriptions.map((r, i) => ({key: String(i + 1), ...r,}))
        }
        onChange={handleTableChange}
        pagination={{
          current: paginationRes.page + 1,
          total: paginationRes.totalItem,
          defaultPageSize: DEFAULT_TABLE_SIZE,
          showSizeChanger: false
        }}
      />
      <SubscriptionDetailModal
        isVisible={isVisibleModal}
        modalCloseOpenHandler={modalCloseOpenHandler}
        modalHandler={modalCloseOpenHandler}
        subscription={clickedSubscription}
      />
    </>
  );
}
