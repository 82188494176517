import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, InputNumber, Radio, Select, Upload} from 'antd';
import {uploadImage, getAllPlacements} from '../../placement.service';
import {PlacementCreateDto, PlacementDto} from '../../placement.interface';
import {UploadFile} from "antd/es/upload/interface";

const {Option} = Select;

interface Props {
  formValues: PlacementCreateDto | null,
  setFormValues: React.Dispatch<React.SetStateAction<PlacementCreateDto | null>>,
  imageWall: UploadFile[],
  setImageWall: React.Dispatch<React.SetStateAction<UploadFile[]>>,
  editPlacement?: PlacementDto;
}

const AddPlacementForm = ({setFormValues, imageWall, setImageWall, editPlacement}: Props) => {
  const inputOpenImageRef = useRef<HTMLInputElement>(null);
  const [formInstance] = Form.useForm();

  const [lounges, setLounges] = useState<PlacementDto[]>([]);

  useEffect(() => {
    getAllPlacements().then(res => {
      setLounges(res.filter(e => e.placementType === 'LOUNGE'));
    });
  }, []);

  useEffect(() => {
    if (formInstance && editPlacement) {
      const imageFileNameUrl = editPlacement['imageFileName']
      if (imageFileNameUrl) {
        setImageWall([{
          uid: 'img' + Math.random(),
          name: imageFileNameUrl,
          url: imageFileNameUrl,
        }]);
      }
      formInstance.setFieldsValue(editPlacement);
    }
  }, [editPlacement]);

  // 이미지 업로드 이벤트
  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const formData = new FormData();

    formData.append('image', e.target.files[0]);
    await uploadImage(formData).then(e => {
      setImageWall([...imageWall, {
        uid: 'img' + Math.random(),
        name: e,
        url: e,
      }]);
    });
  };

  const handleImageClick = () => {
    if (inputOpenImageRef && inputOpenImageRef.current) {
      inputOpenImageRef.current.click();
    }
  };

  const loungeOptions = lounges.map((e, i) => {
    return (
      <Option value={e.placementId} key={'lounge-option-' + i}>{e.placementName}</Option>
    );
  });

  const handleChg = ({fileList}: { fileList: UploadFile[] }) => setImageWall(fileList)

  return (
    <Form
      name='placementForm'
      onValuesChange={(changed, all) => setFormValues(all)}
      wrapperCol={{span: 16}}
      form={formInstance}
    >
      <Form.Item label={'소속 라운지'} name={'parentId'}>
        <Select>
          {loungeOptions}
        </Select>
      </Form.Item>
      <Form.Item label={'픽업존 이름'} name={'placementName'}>
        <Input placeholder={'픽업존 이름을 입력해주세요'}/>
      </Form.Item>
      <Form.Item label={'배치가능댓수'} name={'deployableCount'}>
        <InputNumber placeholder={'배치가능댓수를 입력해주세요. 숫자만 입력해주세요'}/>
      </Form.Item>
      <Form.Item label={'소속구'} name={'description'}>
        <Input placeholder={'소속된 구를 입력해주세요'}/>
      </Form.Item>
      <Form.Item label={'배치가능기기'} name={'deployableMobilityType'}>
        <Radio.Group>
          <Radio.Button value={'A'}>오킥</Radio.Button>
          <Radio.Button value={'S'}>킥보드</Radio.Button>
          <Radio.Button value={'M'}>오토바이</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label='이미지' name='image'>
        <Button onClick={handleImageClick}>이미지 업로드</Button>
      </Form.Item>
      <Form.Item/>
      <input
        type='file'
        accept='image/jpeg, image/png'

        style={{display: 'none'}}
        ref={inputOpenImageRef}
        onChange={handleImageUpload}
      />
      <Form.Item name={'imageFileName'}>
        <Upload
          listType="picture-card"
          fileList={imageWall}
          onChange={handleChg}
        >
        </Upload>
      </Form.Item>
    </Form>
  );
};

export default AddPlacementForm;
