import React, { useEffect, useState, VFC } from 'react';
import { Button, Table, Select, message, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AimOutlined } from '@ant-design/icons';

import { DEFAULT_TABLE_SIZE } from '../../../../constants';
import { getMobilityLendTypeTag } from '../../../../utils/organizeData';
import { columnMaker, columnRenderer } from '../../../../utils/componentUtils';
import Copyable from '../../../../common/component/Copyable';

import { MobilityType, MobilityDto } from '../../mobility.interface';
import { BandDto } from '../../../band/band.interface';
import { getBands } from '../../../band/band.service';
import { putMopedBand, putPCXSimei } from '../../mobility.service';
import { FilterCondition } from '../page/MopedPage';

const { Option } = Select;

interface Props {
  mopeds: MobilityDto[];
  mopedIdOnClickHandler: (id: number) => () => Promise<void>;
  handleSelectedMopedPosition: (row: MobilityDto) => void;
  setIsMopedRentalModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRentalChangeMoped: React.Dispatch<React.SetStateAction<MobilityDto | null>>;
  filterCondition: FilterCondition;
}

const MopedListTable: VFC<Props> = (
  {
    mopeds,
    mopedIdOnClickHandler,
    handleSelectedMopedPosition,
    setIsMopedRentalModalVisible,
    setSelectedRentalChangeMoped,
    filterCondition
  }) => {
  const [allBands, setAllBands] = useState<BandDto[]>([]);
  const [isEditMoped, setIsEditMoped] = useState([{
    mopedId: 0,
    putBandId: 0
  }]);

  // console.log(mopeds)

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    try {
      const data = await getBands();
      setAllBands(data);
    } catch (err) {
      message.error('밴드정보를 불러오지 못하였습니다.');
      console.log(err);
    }
  }

  async function handleChangeBand(mopedId: number, bandId: number) {
    if (window.confirm('밴드를 변경하시겠습니까?')) {
      try {
        await putMopedBand(mopedId, { bandId });
        setIsEditMoped(prev => {
          const filteredPreviousUpdateMoped = prev.filter(e => e.mopedId !== mopedId);
          return [...filteredPreviousUpdateMoped, { mopedId, putBandId: bandId }];
        });
        alert('성공적으로 밴드를 변경하였습니다');
        window.location.reload();
      } catch (err) {
        message.error('밴드변경에 실패하였습니다');
        console.log(err);
      }
    }
  }

  function bandValue(bandId: number, mopedId: number) {
    // fixme 모든 moped에 대해서 아래 find 함수가 돌기 때문에 성능이슈가 있을 수 있음
    const editMoped = isEditMoped.find(e => e.mopedId === mopedId);

    if (editMoped) {
      return editMoped.putBandId;
    } else {
      return bandId;
    }
  }

  async function updatePCXSimei(value: string, mopedId: number) {
    if (!window.confirm('수정하시겠습니까?')) return;

    try {
      await putPCXSimei(mopedId, value);
      alert('수정되었습니다');
      window.location.reload();
    } catch (err) {
      message.error('수정에 실패하였습니다');
      console.log(err);
    }
  }

  const mopedColumns: ColumnsType<MobilityDto> = [
    columnMaker('id', '오토바이 ID', columnRenderer(data => {
      const el: Record<MobilityType, JSX.Element> = {
        SCOOTER: <a onClick={mopedIdOnClickHandler(data.scooterId!)}>{data.scooterId}</a>,
        MOPED: <a onClick={mopedIdOnClickHandler(data.mopedId!)}>{data.mopedId}</a>
      };
      return el[data.mobilityType];
    })),
    columnMaker('modelType', '기종', columnRenderer(data => data.modelType)),
    columnMaker('firmware', '펌웨어 버전', columnRenderer(data => data.firmwareVersion, true)),
    columnMaker('mopedVin', '차대번호', columnRenderer(data => data.modelType === 'PCX' ? data.mopedVin : '-')),
    columnMaker('shortImei', 'SIMEI',
      columnRenderer(data => data.modelType === 'PCX'
        ? <Typography.Paragraph
          editable={{ onChange: (value) => updatePCXSimei(value, data.mopedId!) }}>{data.shortImei}</Typography.Paragraph>
        : <Copyable textVal={data.shortImei} />)),
    columnMaker('imei', 'IMEI', columnRenderer(data => data.imei, true)),
    columnMaker(
      'battery',
      '배터리 잔량',
      columnRenderer(data => data.battery),
      'center',
      (prev, next) => prev.battery - next.battery
    ),
    columnMaker('mobilityStatus', '사용가능 여부', columnRenderer(data => data.mobilityStatus === 'AVAILABLE' ? '탑승 가능' : '탑승 불가')),
    columnMaker('seaterLocked', '시트 상태', columnRenderer(data => data.seaterLocked ? '잠금' : '열림')),
    columnMaker('locked', '잠금 상태', columnRenderer(data => data.locked ? '잠금' : '열림')),
    // columnMaker('totalDistance', '총 주행거리(km)', columnRenderer(data => getAmountNumberFormat(data.totalDistance) )),
    columnMaker('moped-position', '위치', columnRenderer(data => (
      <AimOutlined onClick={() => handleSelectedMopedPosition(data)} />))
    ),

    columnMaker('bandName', '밴드 변경', columnRenderer((data) => (
      <Select
        showSearch
        style={{ width: '180px' }}
        value={data.band.bandId}
        onChange={selectBandId => handleChangeBand(data.mopedId!, selectBandId)}
        filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
      >
        {allBands.map((band, i) => (
          <Option key={`bandSelect-${band.bandId}`} value={band.bandId}>
            {band.bandName}
          </Option>))}
      </Select>
    ))),

    columnMaker('moped-rental', '리스/렌탈 변경', columnRenderer(data => (
        <Button
          type={'text'}
          onClick={() => {
            setSelectedRentalChangeMoped(data);
            setIsMopedRentalModalVisible(true);
          }}>
          {data.mobilityLendType ? getMobilityLendTypeTag(data.mobilityLendType) : '-'}
        </Button>
      ))
    )
  ];

  return (
    <Table
      dataSource={mopeds.map((m, i) => ({ key: 'moped-' + i, ...m }))}
      columns={mopedColumns}
      showHeader={true}
      pagination={{
        defaultPageSize: DEFAULT_TABLE_SIZE
      }}
    />
  );
};

export default MopedListTable;
