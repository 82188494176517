import React from 'react';
import { AbstractMarkerPlugin } from './AbstractMarkerPlugin';
import { MobilityDto } from '../../../domain/mobility/mobility.interface';
import { MarkerProps } from '../Map';
import { ScooterMarker } from '../markers/ScooterMarker';
import { getAllScooters } from '../../../services/scoot';
import ScooterDetailModal from '../../../domain/mobility/scooter/component/ScooterDetailModal';

// 1 day
const REFRESH_SECONDS = 24 * 60 * 60;

export class ScooterMarkerPlugin extends AbstractMarkerPlugin<MobilityDto> {
  constructor() {
    super(REFRESH_SECONDS);
  }

  protected async getData(): Promise<MobilityDto[]> {
    const scooters = await getAllScooters();
    return scooters.filter(e => e.mobilityStatus !== 'CARGO');
  }

  protected convertToMarkerProps(data: MobilityDto[]): { entity: MobilityDto; props: MarkerProps }[] {
    return data.map(s => ({
      entity: s,
      props: ScooterMarker(s)
    }));
  }

  protected renderSelectedComponent(selectedData: MobilityDto): JSX.Element {
    return <ScooterDetailModal
      isVisible={true}
      handleClose={() => this.setSelectedData(null)}
      scooter={selectedData}
    />;
  }
}
