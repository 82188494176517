import React, {useContext, useEffect, useState} from 'react';

import {SetLoadingContext} from '../../../../context';
import MasterProductTable from '../component/MasterProductTable';
import AddMasterProductModal from '../component/AddMasterProductModal';
import {getAllMasterProducts} from "../../product.service";
import {TableHeading} from "../../../../common/component/TableHeading";
import {Button, Col, Input, Radio, Row, Card} from 'antd';
import {ProductDto} from "../../product.interface";

type MasterProductSubType = 'ALL' | 'PASS' | 'PENALTY' | 'OTHER';
type MasterProductMobility = 'ALL' | 'ST' | 'MC';

const MasterProductPage = () => {

  const setLoading = useContext(SetLoadingContext)!;
  const [isAddMasterProductModalVisible, setIsAddMasterProductModalVisible] = useState<boolean>(false);
  const [masterProducts, setMasterProducts] = useState<ProductDto[]>([]);
  const [filteredMasterProducts, setFilteredMasterProducts] = useState<ProductDto[]>([]);

  const [masterProductSubTypeRadio, setMasterProductSubTypeRadio] = useState<MasterProductSubType>('ALL')
  const [masterProductMobilityRadio, setMasterProductMobilityRadio] = useState<MasterProductMobility>('ALL')
  const [searchStr, setSearchStr] = useState<string>('')

  // 필터용 기준
  const masterProductSubTypes = [
    {val: 'ALL', text: '전체'},
    {val: 'PASS', text: '이용권'},
    {val: 'PENALTY', text: '패널티'},
    {val: 'OTHER', text: '기타'},
    {val: 'ONDEMAND', text: '온디맨드'},
  ];

  const masterProductSubTypeRadioButtons = masterProductSubTypes.map((e, i) => {
    const width = `${100 / masterProductSubTypes.length}%`
    return (
      <Radio.Button
        key={'masterProductSubTypeRadioButtons' + i}
        style={{width: width, textAlign: 'center'}}
        value={e.val}
      >
        {e.text}
      </Radio.Button>
    )
  })

  const masterProductMobilities = [
    {val: 'ALL', text: '전체'},
    {val: 'MC', text: '오토바이'},
    {val: 'ST', text: '킥보드'},
  ];

  const masterProductMobilityRadioButtons = masterProductMobilities.map((e, i) => {
    const width = `${100 / masterProductMobilities.length}%`
    return (
      <Radio.Button
        key={'masterProductMobilityRadioButtons' + i}
        style={{width: width, textAlign: 'center'}}
        value={e.val}
      >
        {e.text}
      </Radio.Button>
    )
  })

  useEffect(() => {
    bootstrap();
  }, []);

  async function bootstrap() {
    setLoading(true);
    await getAllMasterProducts().then((res) => {
      const sortedAllMasterProducts = res
        .sort((a, b) => b.productId - a.productId)
        .filter(e => e.saleStatus !== 'TERMINATION');
      setMasterProducts(sortedAllMasterProducts);
      setFilteredMasterProducts(sortedAllMasterProducts)
    }).then(() => setLoading(false))
  }

  useEffect(() => {
    switch (masterProductSubTypeRadio) {
      case "ALL":
        setFilteredMasterProducts(masterProducts);
        break;
      case "PASS":
        setFilteredMasterProducts(masterProducts.filter(e => e.productSubType === 'PASS'));
        break;
      case "PENALTY":
        setFilteredMasterProducts(masterProducts.filter(e => e.productSubType === 'PENALTY'));
        break;
      case "OTHER":
        setFilteredMasterProducts(masterProducts.filter(e => e.productSubType === 'OTHER'));
        break;
    }
  }, [masterProductSubTypeRadio]);

  useEffect(() => {
    switch (masterProductMobilityRadio) {
      case "ALL":
        setFilteredMasterProducts(masterProducts);
        break;
      case "ST":
        setFilteredMasterProducts(masterProducts.filter(e => e.mobilityCode === 'ST'));
        break;
      case "MC":
        setFilteredMasterProducts(masterProducts.filter(e => e.mobilityCode === 'MC'));
        break;
    }
  }, [masterProductMobilityRadio]);

  useEffect(() => {
    if (searchStr === '') {
      setFilteredMasterProducts(masterProducts)
    } else {
      setFilteredMasterProducts(masterProducts.filter(e => e.title.indexOf(searchStr) !== -1));
    }
  }, [searchStr])

  return (
    <>
      <TableHeading
        headingTitle={'마스터 상품목록'}
        row={filteredMasterProducts.length}
      />
      <Card
        title="목록 필터"
        extra={<Button onClick={() => setIsAddMasterProductModalVisible(true)}>마스터 상품 추가</Button>}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Input
              value={searchStr}
              onChange={e => setSearchStr(e.target.value)}
              placeholder={'마스터상품명을 입력해주세요'}
            />
          </Col>
          <Col span={24} style={{marginBottom: '12px'}}>
            <Radio.Group
              style={{width: '100%'}}
              onChange={(e) => setMasterProductSubTypeRadio(e.target.value)}
              value={masterProductSubTypeRadio}
            >
              {masterProductSubTypeRadioButtons}
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Radio.Group
              style={{width: '100%'}}
              onChange={(e) => setMasterProductMobilityRadio(e.target.value)}
              value={masterProductMobilityRadio}
            >
              {masterProductMobilityRadioButtons}
            </Radio.Group>
          </Col>
        </Row>
      </Card>
      <MasterProductTable datasource={filteredMasterProducts}/>
      <AddMasterProductModal
        isVisible={isAddMasterProductModalVisible}
        setIsVisible={setIsAddMasterProductModalVisible}
      />
    </>
  );
};

export default MasterProductPage;
