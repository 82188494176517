import { LocalAdminApi } from '../../services';
import { PurchaseDto } from './purchase.interface';
import { PaginationResDto } from '../../common/common.interface';
import axios from 'axios';
import { Pagination } from '../../common/url/pagination';
import { buildUrl } from '../../common/url/utils';

export async function getPurchaseList() {
  return await LocalAdminApi.get<PurchaseDto[]>(`/purchases`).then((resp) => resp.data);
}

export async function getPurchasePagination(pagination: Pagination): Promise<PaginationResDto<PurchaseDto>> {
  return (await LocalAdminApi.get(buildUrl('/purchases', pagination.getParams()))).data;
}

export async function refundPurchase(purchaseId: number, refundAmount: string, refundReason: string) {
  return await LocalAdminApi.put<BasicPurchase[]>(`/purchases/${purchaseId}/cancel`, {
    cancelAmount: refundAmount,
    cancelMessage: refundReason
  }).then((resp) => resp.data);
}

export async function getPurchaseListExcelFile(from: string, to: string) {
  return await LocalAdminApi.get<PurchaseDto[]>(`/purchases/excel/list?from=${from}&to=${to}`).then(resp => resp.data);
}

export async function getPurchaseListExcelFile2(from: string, to: string, band: string) {
  return await LocalAdminApi.get(`/purchases/excel/list?band=${band}&from=${from}&to=${to}`, {
    responseType: 'blob'
  }).then(resp => {
    const url = window.URL.createObjectURL(new Blob([resp.data], { type: resp.headers['content-type'] }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${from}-${to} 매출.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}