import React from 'react';
import {Classes} from 'reactcss';
import {SimpleBanner} from '../banner.interface';
import Table, {ColumnsType} from 'antd/lib/table';
import {getBandTag, parseDateTime} from '../../../utils/organizeData';
import {CalendarOutlined, DeleteOutlined, OrderedListOutlined} from '@ant-design/icons';
import {columnMaker, columnRenderer} from "../../../utils/componentUtils";

interface Props {
  banners: SimpleBanner[],
  handleDeleteBanner: (id: number) => void,
  setIsViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  isViewModalVisible: boolean,
  isEditOrderModalVisible: boolean,
  setSelectedViewBanner: React.Dispatch<React.SetStateAction<SimpleBanner | null>>,
  setSelectedBannerIdx: React.Dispatch<React.SetStateAction<number | null>>,
  setIsEditOrderModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedOrderEditBannerId: React.Dispatch<React.SetStateAction<number | null>>,
  setIsEditDurationModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedDurationEditBanner: React.Dispatch<React.SetStateAction<SimpleBanner | null>>
}

const BannerTable = (
  {
    banners,
    handleDeleteBanner,
    setIsViewModalVisible,
    isViewModalVisible,
    setSelectedViewBanner,
    setSelectedBannerIdx,
    setIsEditOrderModalVisible,
    setSelectedOrderEditBannerId,
    setIsEditDurationModalVisible,
    setSelectedDurationEditBanner
  }: Props) => {

  const bannerColumn: ColumnsType<SimpleBanner> = [
    columnMaker('id', '배너 ID', columnRenderer(data => (
      <div
        style={{color: 'darkorange', fontWeight: 'bold'}}
        onClick={() => {
          setIsViewModalVisible(!isViewModalVisible);
          setSelectedViewBanner(data);
        }}
      >
        {data.bannerId}
      </div>
    ))),
    columnMaker('displayOrder', '순서', columnRenderer(data => data.displayOrder)),
    columnMaker('title', '배너 제목', columnRenderer(data => data.title)),
    columnMaker('displayStartAt', '노출 시작일', columnRenderer(data => parseDateTime(data.displayStartAt))),
    columnMaker('displayEndAt', '노출 종료일', columnRenderer(data => parseDateTime(data.displayEndAt))),
    columnMaker('bands', '노출 밴드', columnRenderer(data => data.bands.length
      ? data.bands.map((e, i) => getBandTag(e.bandName, i))
      : '-')
    ),
    columnMaker('displayOrderEdit', '순서 변경', columnRenderer(data => (
      <OrderedListOutlined onClick={() => {
        setSelectedOrderEditBannerId(data.bannerId);
        setIsEditOrderModalVisible(true);
      }}/>))
    ),
    columnMaker('displayDateEdit', '노출일 변경', columnRenderer(data => (
      <CalendarOutlined onClick={() => {
        setSelectedDurationEditBanner(data);
        setIsEditDurationModalVisible(true);
      }}/>))
    ),
    columnMaker('delete', '삭제', columnRenderer(data => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around'
        }}
      >
        <DeleteOutlined
          style={{fontSize: '16px'}}
          onClick={() => handleDeleteBanner(data.bannerId)}
        />
      </div>
    ))),
  ];

  return (
    <Table
      columns={bannerColumn}
      bordered={true}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            if (rowIndex != undefined) setSelectedBannerIdx(rowIndex);
          } // click row
        };
      }}
      dataSource={
        banners.map((r, i) => ({key: String(i + 1), ...r}))
      }
      pagination={{
        defaultPageSize: 20,
        showSizeChanger: false
      }}
    />
  )
    ;
};

export default BannerTable;