import React, {FC, useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import {ProductPreviewDto} from "../../product.interface";


const ProductPreivewCard = ({product}: { product: ProductPreviewDto }) => {
  return (
    <Row>
      <Col span={6} style={{textAlign: 'center'}}>
        <img src={product.imageUrl} width={60}/>
      </Col>
      <Col span={18}>
        <p>{product.displayPrice}</p>
        <p>{product.waitableTime}</p>
      </Col>
    </Row>
  );
};

export default ProductPreivewCard;
