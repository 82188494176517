import React, {useContext, useEffect, useState} from 'react';
import {AccountContext, SetLoadingContext} from '../../../context';
import {getSubscriptionsPagination} from '../subscription.service';
import {SubscriptionHistoryTable} from '../component/SubscriptionHistoryTable';
import {SimpleSubscription} from "../subscription.interface";
import {useLocation} from "react-router-dom";
import {DEFAULT_TABLE_SIZE} from "../../../constants";
import {TableHeading} from "../../../common/component/TableHeading";
import {PaginationReqDto} from "../../../common/common.interface";
import {useTranslation} from "react-i18next";

export function SubscriptionPage() {
  const {t} = useTranslation();
  const bandId = useContext(AccountContext)!.band.bandId;
  const [subscriptions, setSubscriptions] = useState<SimpleSubscription[]>([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [pagination, setPagination] = useState<PaginationReqDto>({
    page: query.get("page") ? parseInt(query.get("page")!) : 0,
    size: query.get("size") ? parseInt(query.get("size")!) : DEFAULT_TABLE_SIZE,
  });

  const [paginationRes, setPaginationRes] = useState<{ page: number, totalPage: number, totalItem: number }>({
    page: 0,
    totalPage: 0,
    totalItem: 0
  });

  useEffect(() => {
    bootstrap();
  }, []);

  const setLoading = useContext(SetLoadingContext)!;

  async function bootstrap() {
    if (bandId) {
      setLoading(true);
      await getSubscriptionsPagination(pagination.page, pagination.size).then(res => {
        setPaginationRes({
          page: res.page,
          totalItem: res.totalItem,
          totalPage: res.totalPage
        });
        setSubscriptions(res.items.sort((a, b) => b.subscriptionId - a.subscriptionId))
      }).then(e => setLoading(false))
    }
  }

  return (
    <div>
      <TableHeading
        headingTitle={t('side-bar-rider-subscription')}
        row={paginationRes.totalItem}
      />
      <SubscriptionHistoryTable
        subscriptions={subscriptions}
        paginationRes={paginationRes}
        pagination={pagination}
      />
    </div>
  );
}
