import { Button, Col, Descriptions, Modal, Row } from 'antd';
import React, { useEffect, useState, VFC } from 'react';
import { Geocode } from '../../../../common/unit/geocode';
import { drawOneScooterOnMapUtil } from '../../../../utils/kakaoMap/getAllPlacements';
import { RiderRideTable } from '../../../rider/table/RiderRideTable';
import { getScooterRidesHistory } from '../../../../services/scoot';
import { HistoryDto } from '../../../ride/ride.interface';
import { MobilityDto } from '../../mobility.interface';
import { MarkerProp } from '../../../marker/marker.interface';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  scooter: MobilityDto | null;
}

const ScooterDetailModal: VFC<Props> = ({ isVisible, handleClose, scooter }) => {
  const [scooterMetaData, setScooterMetaData] = useState<MarkerProp[]>([]);
  const [scooterHistory, setScooterHistory] = useState<HistoryDto[]>([]);

  useEffect(() => {
    bootstrap();
  }, [JSON.stringify(scooter)]);

  const bootstrap = async () => {
    if (scooter && scooter.scooterId) {
      await drawOneScooterOnMapUtil(scooter.scooterId).then(setScooterMetaData);
      await getScooterRidesHistory(scooter.scooterId).then(setScooterHistory);
    }
  };

  return (
    <Modal
      title={`${scooter?.shortImei} 상세`}
      visible={isVisible}
      onCancel={handleClose}
      width={'90%'}
      footer={[
        <Button onClick={handleClose}>
          닫기
        </Button>
      ]}
    >
      <Row>
        <Col span={8}>
          <Descriptions
            bordered
            column={1}
          >
            <Descriptions.Item label='스쿠터ID'>{scooter?.scooterId}</Descriptions.Item>
            <Descriptions.Item label='배터리'>{scooter?.battery}%</Descriptions.Item>
            <Descriptions.Item label='현재위치'>
              <Geocode
                lat={scooter && scooter?.location.latitude}
                lng={scooter && scooter?.location.longitude}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label='이용여부'>{scooter?.mobilityStatus === 'AVAILABLE' ? '없음' : '사용중'}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={16}>
          <RiderRideTable
            rides={scooterHistory}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ScooterDetailModal;
