import { MobilityType } from '../../domain/mobility/mobility.interface';

type PaginationMobilityType = MobilityType | string;
type PaginationLicenseType = string | '';

interface PaginationFields {
  page: number;
  size: number;

  keyword?: string;
  band?: string;
  mobility?: PaginationMobilityType;
  mobilityType?: PaginationMobilityType;
  license?: PaginationLicenseType;
  from?: string;
  to?: string;
  display?: string;
  sb?: string;
  mb?: string;
  battery?: string;
  // gu?: string;
}

/** pagination에 필요한 모든 정보는 Pagination 클래스 안에있어야 함 */
export class Pagination {
  readonly fields: PaginationFields;

  getParams(): Map<string, any> {
    const map = new Map<string, any>();
    for (const [k, v] of Object.entries(this.fields)) {
      map.set(k, v);
    }
    return map;
  }

  static scooterRide = Pagination.ride;
  static mopedRide = Pagination.ride;

  static rider(page: number, size: number, keyword: string, band: string, mobility: PaginationMobilityType, license: PaginationLicenseType) {
    return new Pagination({ page, size, keyword, band, mobility, license });
  }

  static riderFrom(original: Pagination, options: {
    page? : number,
    keyword? : string,
    band? : string,
    mobility? : PaginationMobilityType,
    license? : PaginationLicenseType,
  }) {
    return this.rider(
      options.page ?? original.fields.page,
      original.fields.size,
      options.keyword ?? original.fields.keyword!,
      options.band ?? original.fields.band!,
      options.mobility ?? original.fields.mobility!,
      options.license ?? original.fields.license!,
    )
  }

  static placement(page: number, size: number, keyword: string, band: string, mobility: PaginationMobilityType, display: string, sb: string, mb: string) {
    return new Pagination({ page, size, keyword, band, mobility, display, sb, mb});
  }

  static placementFrom(original: Pagination, options: {
    page?: number,
    keyword?: string,
    band?: string,
    mobility?: PaginationMobilityType,
    display?: string,
    sb?: string,
    mb?: string,
  }) {
    return this.placement(
      options.page ?? original.fields.page,
      original.fields.size,
      options.keyword ?? original.fields.keyword!,
      options.band ?? original.fields.band!,
      options.mobility ?? original.fields.mobility!,
      options.display ?? original.fields.display!,
      options.sb ?? original.fields.sb!,
      options.mb ?? original.fields.mb!,
    );
  }

  static purchase(page: number, size: number, band: string, from: string, to: string) {
    return new Pagination({ page, size, band, from, to });
  }

  static purchaseFrom(original: Pagination, options: {
    page?: number,
    band?: string,
    from?: string,
    to?: string,
  }) {
    return this.purchase(
      options.page ?? original.fields.page!,
      original.fields.size,
      options.band ?? original.fields.band!,
      options.from ?? original.fields.from!,
      options.to ?? original.fields.to!,
    )
  }

  private static ride(page: number, size: number, keyword: string, band: string, battery: string) {
    return new Pagination({ page, size, keyword, band, battery });
  }

  private constructor(fields: PaginationFields) {
    this.fields = fields;
  }
}
